import RangeInput from "../../ui_new/RangeInput"
import { useContext, useState } from "react";
import { NotificationContext } from "../../../context";
import React from 'react'
import { useDropzone } from 'react-dropzone'
import { Input, ResponseLoader } from "../../ui_new";
import { get_anonymous } from "../../../services/firestore";
import { FilePresent } from "@mui/icons-material";
import { Delete } from "@material-ui/icons";
import { Attachment } from "../../../assets/icons";

const MailModalContentDocuments = ({ onReturn, onContinue, documents, setDocuments }) => {

  const onDrop = async acceptedFiles => {
    for await (let file of acceptedFiles) {
      await processAddAttachment(null, file, documents)
    }
  }

  const attachmentTotalMaxSize = 2.56e+8

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    multiple: true,
    noClick: true,
    maxSize: attachmentTotalMaxSize
  })

  const [addingLink, setAddingLink] = useState(false)


  const [loading, setLoading] = useState(false)

  const [urlInputValue, setUrlInputValue] = useState('')

  const { setNotification } = useContext(NotificationContext)

  const handleSubmit = () => {
    onContinue()
  }

  // const acceptedTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf', 'image/gif']
  const acceptedTypes = ['application/pdf']

  const handleUrlSubmit = async () => {
    setLoading(true)
    const fileBlob = await get_anonymous(urlInputValue)
    if (fileBlob.error) {
      setNotification({ msg: `Impossible de télécharger le fichier. ${fileBlob.error.message || ''}`, type: 'danger' })
      setLoading(false)
      return
    }
    const { size, type } = fileBlob
    if (!acceptedTypes.includes(type)) {
      setNotification({ msg: 'Type de document invalide. Seuls les documents de type PDF sont acceptés.', type: 'danger' })
      setLoading(false)
      return
    }
    let components = urlInputValue.split('?')[0].split('/')
    let name = components[components.length - 1].split('.')[0]
    let atts = [...documents]
    try {
      const base64 = await fileOrBlobToBase64(fileBlob)
      atts.push({
        size, type, data: base64, name, extension: type.split('/')[1]
      })
    } catch (err) { }
    setUrlInputValue('')
    setAddingLink(false)
    setDocuments(atts)
    setLoading(false)
  }

  const processAddAttachment = async (blob, file, atts) => {
    if (blob) {
      const { size, type } = blob
      if (!acceptedTypes.includes(type)) {
        setNotification({ msg: 'Type de document invalide. Seuls les documents de type PDF sont acceptés.', type: 'danger' })
        setLoading(false)
        return
      }
      let components = urlInputValue.split('?')[0].split('/')
      let name = components[components.length - 1].split('.')[0]
      try {
        const base64 = await fileOrBlobToBase64(blob)
        atts.push({
          size, type, data: base64, name, extension: type.split('/')[1]
        })
      } catch (err) { }
      setUrlInputValue('')
      setAddingLink(false)
      setDocuments([...atts])
    } else if (file) {
      let { size, type, name } = file
      if (!acceptedTypes.includes(type)) {
        setNotification({ msg: 'Type de document invalide. Seuls les documents de type PDF sont acceptés.', type: 'danger' })
        setLoading(false)
        return
      }
      name = name.split('.')[0]
      // const buffer = await file.arrayBuffer()
      try {
        const base64 = await fileOrBlobToBase64(file)
        atts.push({
          size, type, data: base64, name, extension: type.split('/')[1]
        })
      } catch (err) { }
      setUrlInputValue('')
      setAddingLink(false)
      setDocuments([...atts])
    }

  }

  function fileOrBlobToBase64(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = () => resolve(reader.result.split(',')[1])
      reader.readAsDataURL(file);
      reader.onerror = (error) => {
        console.log('Error: ', error);
        reject(error)
      }
    })
  }

  const printSize = (bytes) => {
    if (bytes > 1000000) {
      return `${Math.round(bytes / 1000000).toFixed(0)}MB`
    } else if (bytes > 1000) {
      return `${Math.round(bytes / 1000).toFixed(0)}KB`
    }
  }

  // const printType = (mimetype) => {
  //     return mimetype.split('/')[1].toUpperCase()
  // }

  const calculateSize = () => {
    let size = 0
    for (let att of documents) {
      size += att.size
    }
    return size
  }



  return (
    <div className='registered-mail-send-modal-annexes-container'>
      {loading && <ResponseLoader text={'Chargement'} className="response-loader--dark" />}


      <div className='flex flex-row modal-section-title mb-10'>
        {/* <FilePresent className="modal-section-title--icon" /> */}
        <span className="modal-section-title--icon">
          <Attachment />
        </span>
        Sélectionner les documents à joindre (.PDF)
      </div>
      <table className='registered-mail-send-modal-list-table'>
        <thead>
          <tr>
            <th>Nom du document</th>
            <th>Taille</th>
            <th>Type</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {documents.map((att, attIndex) =>
            <tr key={`attachment_${attIndex}`}>
              <td>{att.name}</td>
              <td>{printSize(att.size)}</td>
              <td>{`.${att.extension.toUpperCase()}`}</td>
              <td>
                <button
                  className='btn btn--transparent'
                  onClick={() => {
                    setDocuments(documents.filter((_, i) => i !== attIndex))
                  }}
                >
                  <Delete color="error" />
                </button>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {documents.length > 0 && <div className="flex flex-row justify-end align-center mt-20">
        <div className='range-container'>
          <RangeInput value={calculateSize()} max={attachmentTotalMaxSize} />
          <p className="mt-5">
            <b>{`Poids actuel: ${calculateSize() ? printSize(calculateSize()) : '0 MB'}`}</b>
            {` (Quota max ${printSize(attachmentTotalMaxSize)})`}
          </p>
        </div>
      </div>}
      {addingLink ?
        <div className="dropzone mt-20 mb-20">
          <Input className="dropzone-input" placeholder={'Tapez le lien ici'} value={urlInputValue} onChange={e => setUrlInputValue(e.target.value)} />
          <div className="flex flex-row justify-center align-center gap-10 mt-10 mb-10">
            <button
              className='btn'
              onClick={() => setAddingLink(false)}>
              Annuler
            </button>
            <button
              className='btn'
              onClick={handleUrlSubmit}>
              Soumettre
            </button>
          </div>
        </div> :
        <div
          className='dropzone mt-20 mb-20'
          {...getRootProps()}
        >
          <input {...getInputProps()} accept={acceptedTypes.join(", ")} />
          {
            isDragActive ?
              <p>Déposez le fichier ici ...</p> :
              <>
                <p>Glisser-déposer un document</p>
                <div className="flex flex-row justify-center align-center gap-10 mt-10 mb-10">
                  <button
                    className='btn'
                    onClick={open}
                  >
                    Sélectionner un document
                  </button>
                  <button
                    className='btn'
                    onClick={() => setAddingLink(true)}
                  >
                    Ajoutez une url
                  </button>
                </div>
              </>
          }
        </div>
      }
      {calculateSize() > attachmentTotalMaxSize && <div className='mb-20'>
        <p className='text-error' >Le poids total des documents joints dépasse {printSize(attachmentTotalMaxSize)}</p>
      </div>}
      <div className='modal-btn-box'>
        <button
          className='btn btn--large btn--transparent btn--border-primary'
          onClick={onReturn}
        >
          Précédent
        </button>
        <button
          className='btn btn--large btn--primary'
          onClick={handleSubmit}
          disabled={calculateSize() > attachmentTotalMaxSize}
        >
          Suivant
        </button>
      </div>
    </div>
  )
}

export default MailModalContentDocuments