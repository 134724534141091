import { generate_document } from '../services/lawstudioApi'
import { coverPageConfig, getTemplateFormulas, insertFormulaResults, mergeDeep } from '../utils'

// Fetch document data
const fetch_document_data = async (templateObject, document, extension, agency, user, options = {}, partner) => {
  let cpConfig = coverPageConfig(document.custom_cover, agency, user, document.name, templateObject.hasCoverPage, partner)
  let values = {...document.values, ...cpConfig.coverPageVariables}
  const documentFormulas = getTemplateFormulas(templateObject)
  values = insertFormulaResults(values, documentFormulas)
  const images = {...cpConfig.coverPageImages, ...(document.images || {})}
  const completeOptions = mergeDeep(options, {...cpConfig.coverPageOptions, checkboxValues: document.checkboxValues})
  let doc = await generate_document(templateObject, values, extension, images, completeOptions)
  if(doc.error) {
    return null
  }
  return doc.data
}

export {
  fetch_document_data,
}