import { useContext, useState, useEffect, useCallback } from 'react'
import { CSVLink } from 'react-csv'
import moment from 'moment'

import { MainLayout } from '../layouts'
import { Signature, SlidersH } from '../assets/icons'
import {
  SignaturesContentSidebar,
  SignaturesTable,
  SignaturesStatusPanel,
  SignaturesActions,
  SignaturePreviewModal,
  SignaturesNotAvailable,
  SignatureButtonsModal,
  RegisteredMailSendModal
} from '../components/misc'
import { ResourceNotFound, Modal } from '../components/ui_new'
import { DocumentsContext, LoaderContext, NotificationContext, RegisteredMailContext, SignatureContext, UserContext } from '../context'
import { sortArrayOfObjects, getSorting, saveSortingToLS } from '../utils'
import { fetch_signatures } from '../services/signatures'
import useVialinkActions from '../hooks/useVialinkActions'
import SingleDocumentSignVialink from '../components/misc/SingleDocumentSignVialink'
import { getSignatureFiles, getSignaturePreview, getSignatureProofs, getVialinkDocumentContent } from '../services/functions'
import { fetch_document_data } from '../helpers/documents'
import JSZip from 'jszip'
import { SIGNATURE_STATUS_TRANLSATIONS } from '../constants'

const Signatures = () => {
  const { setSignaturesSelectedFilters, signaturesSelectedFilters, signatures, signaturesFetched, fetchMoreSignatures } = useContext(SignatureContext)
  const { siteConfigsLoaded, canUseSignatures, isNotSQHorCAIpartner, urlStore, agency, user, partner } = useContext(UserContext)
  const { documents, templates } = useContext(DocumentsContext)
  // const { setShowRegisteredMailSendModal } = useContext(RegisteredMailContext)
  const { setShowGlobalResponseLoader, setGlobalResponseLoaderText } = useContext(LoaderContext)
  const { setNotification } = useContext(NotificationContext)
  const { getRecipientsStatus } = useVialinkActions()
  const [selectedSignatures, setSelectedSignatures] = useState([])
  const [allChecked, setAllChecked] = useState(false)
  const [activeSignatures, setActiveSignatures] = useState([])
  const [filteredSignatures, setFilteredSignatures] = useState([])
  const [activeSort, setActiveSort] = useState('title')
  const [activeOrder, setActiveOrder] = useState('desc')
  const [ready, setReady] = useState(false)
  const [showStatusPanel, setShowStatusPanel] = useState(false)
  const [activeSignature, setActiveSignature] = useState(null)
  const [singleStatusLoading, setSingleStatusLoading] = useState(false)
  const [showPreviewDocModal, setShowPreviewDocModal] = useState(false)
  const [iframeSrc, setIframeSrc] = useState('')
  const [previewLoading, setPreviewLoading] = useState(false)
  const [previewError, setPreviewError] = useState('')
  const [showSignaturesNotAvailableModal, setShowSignaturesNotAvailableModal] = useState(true)
  const [showSignatureButtonsModal, setShowSignatureButtonsModal] = useState(false)
  const [signatureToUseInButtonsModal, setSignatureToUseInButtonsModal] = useState(null)
  const [csvData, setCsvData] = useState([])
  const [showDetailId, setShowDetailId] = useState('')
  const [currentSignature, setCurrentSignature] = useState(null)

  // set status from search string
  useEffect(() => {
    let query = window.location.search
    if (!query) {
      return
    }
    if (query[0] === '?') {
      query = query.substring(1)
    }
    let components = query.split('&')
    let statusFound
    for (let comp of components) {
      let keyValue = comp.split('=')
      if (keyValue.length < 2) {
        continue
      }
      if (keyValue[0] === 'status') {
        let filters = signaturesSelectedFilters
        filters.status = keyValue[1]
        setSignaturesSelectedFilters(filters)
        statusFound = true
      } else if (keyValue[0] === 'detail') {
        setShowDetailId(keyValue[1])
      }
    }
    if (!statusFound) {
      let filters = signaturesSelectedFilters
      filters.status = 'all'
      setSignaturesSelectedFilters(filters)
    }
  }, [])

  // Set signatures - active/filtered
  useEffect(() => {
    const setupSignatures = () => {
      let sort = 'title'
      let order = 'desc'
      const sortLS = getSorting()
      if (sortLS && sortLS.signatures) {
        sort = sortLS.signatures.value
        order = sortLS.signatures.order
      }
      setActiveSort(sort)
      setActiveOrder(order)
      let modifiedArr = []
      for (let i = 0; i < signatures.length; i++) {
        let recipients = signatures[i].recipients.map(r => r.email)
        modifiedArr.push({
          ...signatures[i],
          sort_recipients: recipients,
          sort_recipients_num: recipients.length,
          sort_status: SIGNATURE_STATUS_TRANLSATIONS[signatures[i].status]
        })
      }
      let arr = sortArrayOfObjects(modifiedArr, sort, order)
      setActiveSignatures(arr)
      setFilteredSignatures(arr)
      setReady(true)
    }

    if (signaturesFetched && siteConfigsLoaded && canUseSignatures) {
      setupSignatures()
    }
  }, [signatures, signaturesFetched, siteConfigsLoaded, canUseSignatures])

  useEffect(() => {
    if (!showDetailId) {
      return
    }
    if (!(signaturesFetched && siteConfigsLoaded && canUseSignatures)) {
      return
    }
    const signatureToShow = signatures.find(s => s.id === showDetailId)
    if (!signatureToShow) {
      return
    }
    setShowDetailId('')
  }, [signatures, signaturesFetched, siteConfigsLoaded, canUseSignatures, showDetailId])

  // On filter
  const handleFilter = useCallback(() => {
    let filtered = [...activeSignatures]

    for (let key in signaturesSelectedFilters) {
      if (key === 'status' && signaturesSelectedFilters[key] !== 'all') {
        filtered = filtered.filter(s => s.status === signaturesSelectedFilters[key])
      }
      if (key === 'date_after' && signaturesSelectedFilters[key] !== '') {
        filtered = filtered.filter(s => s.meta.created > signaturesSelectedFilters[key])
      }
      if (key === 'date_before' && signaturesSelectedFilters[key] !== '') {
        filtered = filtered.filter(s => s.meta.created < signaturesSelectedFilters[key])
      }
      if (key === 'search' && signaturesSelectedFilters[key] !== '') {
        filtered = filtered.filter(s => s.title.toLowerCase().includes(signaturesSelectedFilters[key].trim().toLowerCase()))
      }
    }

    setFilteredSignatures(filtered)
  }, [activeSignatures, signaturesSelectedFilters])

  // Watch for filters change
  useEffect(() => {
    handleFilter()
  }, [signaturesSelectedFilters, handleFilter])

  // Handle sort
  const handleSort = (sort_by) => {
    const docs = sortArrayOfObjects(filteredSignatures, sort_by, activeOrder === 'desc' ? 'asc' : 'desc')
    setActiveSort(sort_by)
    setActiveOrder(activeOrder === 'desc' ? 'asc' : 'desc')
    setFilteredSignatures(docs)
    saveSortingToLS(sort_by, activeOrder === 'desc' ? 'asc' : 'desc', 'signatures')
  }

  // On open status panel
  const handleOpenStatusPanel = async (signature) => {
    setShowStatusPanel(true)
    getRecipientsStatus({ signature, setActiveSignature, setSingleStatusLoading })
  }

  // On close status panel
  const handleCloseStatusPanel = () => {
    setShowStatusPanel(false)
  }

  const handleOpenDetail = (signature) => {
    const document = documents[signature.doc_id]
    let template
    if(document) {
      template = templates[document.template]
    }
    setCurrentSignature({...signature, document: document || { name: signature.title + `${signature.title ? ' ' : ''}[Document supprimé]`}, template: template})
  }

  const handleCloseDetail = () => {
    setCurrentSignature(null)
  }

  // On signature title click
  const handleSignatureTitleClick = async (signature) => {
    setShowPreviewDocModal(true)
    setPreviewLoading(true)
    let previewData
    let previewUrl
    previewData = await getSignaturePreview(signature)
    if(previewData.document_url) {
      previewUrl = previewData.document_url
    } else if(previewData.document) {
      previewData = previewData.document
    }
    if(previewData.error) {
      setPreviewError(previewData.error.message)
      setPreviewLoading(false)
      setShowPreviewDocModal(false)
      return
    }
    if(previewData) {
      setIframeSrc(`data:application/pdf;base64,${previewData}`)
    } else if(previewUrl) {
      setIframeSrc(previewUrl)
    }
    setPreviewLoading(false)
  }

  const fileNameWithoutExtension = (fileName) => {
    const lastDotIndex = fileName.lastIndexOf('.')
    if(lastDotIndex === -1) return fileName
    return fileName.substring(0, lastDotIndex)
  }
  
  const handleSignatureDownload = async (signature) => {
    setShowGlobalResponseLoader(true)
    setGlobalResponseLoaderText("Téléchargement en cours...")

    const proofsResponse = await getSignatureProofs(signature)
    const signatureDocuments = await getSignatureFiles(signature)

    const largeDocumentUrls = []

    const documentPromises = [
      ...signatureDocuments.documents.map(async (document, index) => {
        if(index >= signatureDocuments.documents.length - 2) {
          // skip terms of service documents
          return
        }
        const documentData = await getSignaturePreview(signature, index)
        if(documentData.document_url) {
          largeDocumentUrls.push(documentData.document_url)
          return
        }
        if(!documentData.document) {
          return
        }
        return {
          name: `${fileNameWithoutExtension(document.fileName)}_Signé.pdf`,
          data: documentData.document
        }
      }),
      ...proofsResponse.proofs.map(async proof => {
      const proofData = await getVialinkDocumentContent({ file_uri: proof.uri })
      return {
        name: `${proof.participant.firstName}-${proof.participant.lastName}_Preuve-de-signature.pdf`,
        participant: proof.participant,
        data: proofData.document
      }})
    ]

    let documents = await Promise.all(documentPromises)
    documents = documents.filter(d => !!d)
    
    const zip = new JSZip()

    for(let i = 0; i < documents.length; i++) {
      const document = documents[i]
      zip.file(document.name, document.data, { base64: true })
    }

    setGlobalResponseLoaderText('')
    setShowGlobalResponseLoader(false)

    const zipData = await zip.generateAsync({ type: "base64" })
    
    const a = document.createElement('a')
    a.href = `data:application/zip;base64,${zipData}`
    a.download = `${signature.title}_Signature-avec-preuve.zip`
    a.click()
    if(largeDocumentUrls.length > 0) {
      let response = window.confirm(`Certains documents sont trop volumineux pour être téléchargés directement. Souhaitez-vous les ouvrir dans des onglets séparés ? (${largeDocumentUrls.length} documents à ouvrir)`)
      if(response) {
        for(let i = 0; i < largeDocumentUrls.length; i++) {
          window.open(largeDocumentUrls[i], '_blank', 'noopener,noreferrer')
        }
      }
    }
  }

  // On close preview modal
  const handleClosePreviewModal = () => {
    setShowPreviewDocModal(false)
  }

  // On close signatures not available modal
  const handleCloseSignaturesNotAvailableModal = () => {
    setShowSignaturesNotAvailableModal(false)
  }

  // On close signature buttons modal
  const handleCloseSignatureButtonsModal = () => {
    setShowSignatureButtonsModal(false)
    setSignatureToUseInButtonsModal(null)
  }

  // Export signatures
  const handleExportSignatures = async () => {
    const data = await fetch_signatures()
    if (!data) return
    if (data && !Array.isArray(data)) return
    let arr = [
      [
        "Manufacturer id",
        "Date",
        "Sent by",
        "Status",
        "Title",
        "Recipients"
      ]
    ]
    for (let i = 0; i < data.length; i++) {
      let signature = data[i]
      let recipients = []
      signature.recipients.forEach(r => recipients.push(r.email))
      arr.push([
        signature.owner,
        moment(signature.createdAt).format('DD/MM/YYYY'),
        signature.sentBy,
        signature.status,
        signature.title,
        recipients.join(' - ')
      ])
    }
    setCsvData(arr)
  }

  if (siteConfigsLoaded && !canUseSignatures) {
    return (
      <MainLayout className="page-signatures" pageTitle="Signatures" titleIcon={<Signature />} showSearch={true} searchIn="signatures">
        <ResourceNotFound title="Signatures non disponibles" />
        {showSignaturesNotAvailableModal && (
          <Modal onClose={handleCloseSignaturesNotAvailableModal}>
            <SignaturesNotAvailable store={isNotSQHorCAIpartner ? urlStore : null} />
          </Modal>
        )}
      </MainLayout>
    )
  }

  return (
    <MainLayout className="page-signatures" pageTitle="Signatures" titleIcon={<Signature />} showSearch={true} searchIn="signatures">
      <div className={`signatures-content ${showStatusPanel ? 'panel-opened' : ''}`}>
        <SignaturesContentSidebar onSetSelectedFilters={setSignaturesSelectedFilters} selectedFilters={signaturesSelectedFilters} />
        <div className="signatures-content__main">
          {/* {selectedSignatures.length > 0 && (
            <div className="signatures-content__main_actions">
              <SignaturesActions 
                selectedSignatures={selectedSignatures} 
                onSetSelectedSignatures={setSelectedSignatures} 
                onSetAllChecked={setAllChecked} 
              />
            </div>
          )} */}
          <div className="signatures-content__main_mobile">
            <div className="filters-item">
              <p><SlidersH /> Filtres</p>
            </div>
          </div>

          {/* USE THIS ONLY WHENY EXPORTING SIGNATURES FOR AC3 - not for production, staging or development, only for exporting */}
          {/* <button onClick={handleExportSignatures}>get signatures</button>
          <CSVLink data={csvData}>export signatures</CSVLink> */}

          {!ready && (
            <div className="loader-wrapper">
              <div className="loader-v2"></div>
            </div>
          )}

          {ready && (
            <SignaturesTable
              // selectedSignatures={selectedSignatures}
              // onSetSelectedSignatures={setSelectedSignatures}
              // allChecked={allChecked}
              // onSetAllChecked={setAllChecked}
              filteredSignatures={filteredSignatures}
              onSort={handleSort}
              activeSort={activeSort}
              onOpenStatusPanel={handleOpenStatusPanel}
              onOpenSignatureDetail={handleOpenDetail}
              onTitleClick={handleSignatureTitleClick}
              onDownload={handleSignatureDownload}
            />
          )}
          {ready && <div className='load-more-wrapper'>
            <button className="btn btn-primary" onClick={fetchMoreSignatures}>Charger plus</button>
          </div>}

        </div>
        <SignaturesStatusPanel onClose={handleCloseStatusPanel} loading={singleStatusLoading} signature={activeSignature} />


        {Boolean(currentSignature) && (
          <SingleDocumentSignVialink
            onClose={handleCloseDetail}
            documentName={currentSignature.document.name}
            docId={currentSignature.document.id}
            docAttachments={currentSignature.document.attachments}
            docSignatureRecipients={[]}
            singleDoc={{
              ...currentSignature.document,
              name: currentSignature.document.name,
              values: currentSignature.document.values,
              custom_cover: currentSignature.document.custom_cover,
              id: currentSignature.document.id,
            }}
            // docContacts={docContacts}
            template={currentSignature.template}
            signatureObject={currentSignature}
          />
        )}
      </div>

      {showPreviewDocModal && (
        <SignaturePreviewModal 
          onClose={handleClosePreviewModal}
          previewError={previewError}
          previewLoading={previewLoading}
          iframeSrc={iframeSrc}
        />
      )}

      {/* {showSignatureButtonsModal && (
        <SignatureButtonsModal 
          onClose={handleCloseSignatureButtonsModal}
          signature={signatureToUseInButtonsModal}
          onPreview={handlePreviewFromButtonsModal}
        />
      )} */}
    </MainLayout>
  )
}

export default Signatures 