import { useState, memo } from 'react'
import { Document, Page } from 'react-pdf'

import { Redo } from '../../assets/icons'

const SingleDocumentPreview = ({ data, loading, onPreviewRefresh, previewSettings = { size: 'normal', watermark: false }, onPreviewSettingsChange, }) => {
    const [docPages, setDocPages] = useState([])

    // On document load success
    const handleDocumentLoadSuccess = ({ numPages }) => {
        const arr = []
        for (let i = 1; i < numPages + 1; i++) {
            arr.push(<Page pageNumber={i} width={870} loading="" key={i} />)
        }

        setDocPages(arr)
    }

    // On document preview
    const handleRefreshPreview = () => {
        if (!onPreviewRefresh) return
        onPreviewRefresh()
    }

    return (
        <div className="single-document-preview">
            <div className="single-document-preview__head">
                <h2>
                    <span className="text">APERÇU DU DOCUMENT</span>
                    <Redo onClick={handleRefreshPreview} />
                </h2>
                <div className="single-document-preview__settings">
                    <div>
                        <label>Taille des caractères</label>
                        <div className="button-group button-group--small mt-10">
                        <button className={`button-group--button ${previewSettings.size === 'small' ? 'primary' : ''}`} onClick={() => onPreviewSettingsChange({ ...previewSettings, size: 'small' })}>Petit</button>
                        <button className={`button-group--button ${previewSettings.size === 'normal' ? 'primary' : ''}`} onClick={() => onPreviewSettingsChange({ ...previewSettings, size: 'normal' })}>Normal</button>
                        <button className={`button-group--button ${previewSettings.size === 'large' ? 'primary' : ''}`} onClick={() => onPreviewSettingsChange({ ...previewSettings, size: 'large' })}>Grand</button>
                        </div>
                    </div>
                    <div>
                        <label>Appliquer le filigrane "projet"</label>
                        <div className="button-group button-group--small mt-10">
                        <button className={`button-group--button ${!previewSettings.watermark ? 'primary' : ''}`} onClick={() => onPreviewSettingsChange({ ...previewSettings, watermark: false })}>Non</button>
                        <button className={`button-group--button ${previewSettings.watermark ? 'primary' : ''}`} onClick={() => onPreviewSettingsChange({ ...previewSettings, watermark: true })}>Oui</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="single-document-preview__body">
                {loading ? (
                    <div className="single-document-preview__body_loader-wrapper">
                        <div className="loader-v2"></div>
                    </div>
                ) : (
                    <Document
                        options={{}}
                        file={data}
                        onLoadSuccess={handleDocumentLoadSuccess}
                        renderMode="canvas"
                        loading="Chargement..."
                    >
                        {docPages}
                    </Document>
                )}
            </div>
        </div>
    )
}

export default memo(SingleDocumentPreview)
