import { useContext, useCallback, useMemo } from 'react'
import { PDFDocument } from 'pdf-lib'
import moment from 'moment'

import { SignatureContext, UserContext, DocumentsContext, NotificationContext } from '../context'
import { get_signature_status, get_anonymous, add_document_event } from '../services/firestore'
import { cancelSignatureRequest, createVialinkFile, sendSignatureRequest, updateActiveSignature } from '../services/functions'
import { fetch_document_data } from '../helpers/documents'
import { upload_file_to_storage } from '../services/storage'

const useVialinkActions = (template, doc) => {
  const { addSignature, updateSignature, updateSignatureLocal } = useContext(SignatureContext)
  const { isNotSQHorCAIpartner, user, agency, partner } = useContext(UserContext)
  const { updateDocument } = useContext(DocumentsContext)
  const { setNotification } = useContext(NotificationContext)

  // Get recipients status
  const getRecipientsStatus = async ({ signature, setActiveSignature, setSingleStatusLoading }) => {
    setActiveSignature(null)
    setSingleStatusLoading(true)
    try {
      const statusResponse = await get_signature_status({ folder_id: signature.folder_id })
      if(statusResponse.status) {
        const s = {...signature}
        let statusChanged = signature.status !== statusResponse.status
        s.recipients = s.recipients.map((participant, index) => {
          let p =  statusResponse.participants?._embedded?.["vl:participant"][index]
          if(!p._embedded["vl:step"]) {
            return participant
          }
          if(participant.status !== p._embedded["vl:step"].status) {
            statusChanged = true
          }
          return {
            ...participant,
            status: p._embedded["vl:step"].status,
          }
        })
        setActiveSignature(s)
        if(statusChanged) {
          await updateSignature(s.id, { status: statusResponse.status, recipients: s.recipients })
        }
      } else {
        const message = statusResponse?.message && Array.isArray(statusResponse?.message) ? statusResponse.message[0] : statusResponse?.message || "Aucune donnée reçue"
        throw new Error(message)
      }
      setSingleStatusLoading(false)
    } catch (err) {
      console.log(err)
      setSingleStatusLoading(false)
      setActiveSignature(signature)
      const errorMessage = err.message || ""
      setNotification({ msg: `Erreur API Vialink${errorMessage ? ` : ${errorMessage}` : ''}`, type: 'danger' })
    }
  }

  // Refresh status
  const refreshStatus = async ({ signature, setRefreshing }) => {
    setRefreshing(true)
    // setShowDashboardOverlay(true)
    try {
      const statusResponse = await get_signature_status({ folder_id: signature.folder_id })
      if(statusResponse.status) {
      //   const s = {...signature}
        await updateSignature(signature.id, { status: statusResponse.status, status_updated_at: Date.now() })
        setNotification({ msg: "L'état de la signature a été actualisé avec succès", type: "success" })
      } else {
        const message = statusResponse?.message && Array.isArray(statusResponse?.message) ? statusResponse?.message[0] : statusResponse?.message || "Aucune donnée reçue"
        throw new Error(message)
      }
      setRefreshing(false)
    } catch (err) {
      console.log(err)
      setRefreshing(false)
      const errorMessage = err.message || ""
      setNotification({ msg: `Erreur API Vialink${errorMessage ? ` : ${errorMessage}` : ''}`, type: 'danger' })
    } finally {
      // setShowDashboardOverlay(false)
    }
  }

  const fetchAttachmentBase64 = (file) => {
    if(file.url) {
      return new Promise((resolve, reject) => {
        resolve({
          url: file.url,
          name: file.name,
          type: file.type,
        })
      })
    }
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        resolve({
          data: reader.result.split(',')[1],
          name: file.name,
          type: file.type,
        })
      }
      reader.onerror = (error) => {
        reject(error)
      }
    })
  }

  // Submit form
  const submitForm = async ({
    setShowSendRequestAlert,
    setShowResponseLoader,
    attachments,
    signers,
    completionRecipients,
    signaturesOrder,
    maxAge,
    reminder,
    documentPages,
    emailData,
    setRequestSent,
    setSuccessMessage,
    setRequestFailed,
    setSignatureErrors,
  }) => {
    setShowSendRequestAlert(false)


    try {
      setShowResponseLoader(true)
      
      const attachmentPromises = attachments.map((attachment) => {
        if(attachment.file) {
          return fetchAttachmentBase64(attachment.file)
        } else if(attachment.url) {
          // process attachments with URL first to avoid passing preview into signature
          return Promise.resolve({
            url: attachment.url,
            name: attachment.name,
            type: attachment.type,
          })
        } else if(attachment.data) {
          // process attachments with data (main document)
          return Promise.resolve({
            data: attachment.data,
            name: attachment.name,
            type: attachment.type
          })
        }
        
        return Promise.resolve(null)
      })
      let attachmentsDataArray = await Promise.all(attachmentPromises)
      attachmentsDataArray = attachmentsDataArray.filter(a => a !== null)

      const now = moment().valueOf()

      const uploadPromises = attachmentsDataArray.map(async (attachment, attachmentIndex) => {
        if(attachment.url) {
          return {
            name: attachment.name,
            type: attachment.type,
            url: attachment.url,
          }
        }
        const uploadResponse = await upload_file_to_storage(attachment.data, `${attachment.name}_${attachmentIndex}_${now}.pdf`, 'vialink_attachments', attachment.type)
        return {
          name: attachment.name,
          type: attachment.type,
          url: uploadResponse.url,
          path: uploadResponse.path
        }
      })
      const uploadedAttachments = await Promise.all(uploadPromises)

      const filePromises = uploadedAttachments.map(async (attachment, attachmentIndex) => {
        const fileResponse = await createVialinkFile({ ...attachment, attachmentIndex, documentId: doc.id })
        if(fileResponse.error) {
          throw new Error(fileResponse.error.message || `Une erreur est survenue lors de la création du fichier Vialink (${attachment.name})`)
        }
        return fileResponse.file
      })

      const files = await Promise.all(filePromises)

      completionRecipients = completionRecipients.filter(r => (r.firstName && r.lastName && r.email))

      const signatureRequestData = {
        signatories: signers,
        documentName: doc.name,
        documentId: doc.id,
        // attachments: uploadedAttachments,
        files: files.filter(f => f !== null),
        completionRecipients,
        emailData,
        maxAge,
        reminder,
        signaturesOrder,
        documentPages,
        agency: user.manufacturer
      }

      const signatureRequestResponse = await sendSignatureRequest(signatureRequestData)

      if(signatureRequestResponse.success) {
        // Add signature to database
    
        const signatureData = {
          title: doc.name,
          recipients: signatureRequestResponse.data.participants,
          completion_recipients: signatureRequestResponse.data.completionRecipients,
          email_data: emailData,
          sender: user,
          meta: {
            created: new Date(signatureRequestResponse.data.createdAt).getTime(),
            updated: new Date(signatureRequestResponse.data.updatedAt).getTime(),
          },
          expires: signatureRequestResponse.data.expiresOn,
          folder_id: signatureRequestResponse.data._links.self.href.split('/').pop(),
          doc_id: doc.id,
          status: signatureRequestResponse.data.status,
          order: signaturesOrder,
          attachments: attachmentsDataArray.map(a => ({ name: a.name, type: a.type })),
          max_age: maxAge,
          reminder,
        }
        await addSignature(signatureData)

        await add_document_event(doc.id, {
          type: 'signature_request_vialink',
          values: doc.values,
          content_changes: doc.content_changes,
        })

        setRequestSent(true)
        setSuccessMessage('Le(s) document(s) ont bien été envoyé(s)')
        setShowResponseLoader(false)
        setShowSendRequestAlert(false)
      } else {
        // error
        setRequestFailed(true)
        if (signatureRequestResponse.error?.message) {
          setSignatureErrors([signatureRequestResponse.error.message])
        } else {
          setSignatureErrors(['Une erreur est survenue, merci de réessayer'])
        }
      }
      setShowResponseLoader(false)
    }catch(err) {
      console.log(err)
      setRequestFailed(true)
      if (err.message) {
        setSignatureErrors([err.message])
      } else {
        setSignatureErrors(['Une erreur est survenue, merci de réessayer'])
      }
      setShowResponseLoader(false)
    }
  }

  const submitSignatureUpdate = async ({
    setShowResponseLoader,
    signers,
    completionRecipients,
    reminder,
    signature,
  }) => {
    setShowResponseLoader(true)
    try {
      const signatureUpdateData = {
        signatories: signers,
        completion_recipients: completionRecipients,
        reminder,
      }
      const response = await updateActiveSignature({ signatureId: signature.id, data: signatureUpdateData })
      if(response.success) {
        // TODO update signature
        updateSignatureLocal(signature.id, response.data)
        setNotification({ msg: "La signature a été mise à jour avec succès", type: "success" })
      } else {
        throw new Error(response.message)
      }
      setShowResponseLoader(false)
    } catch(err) {
      console.log(err)
      setNotification({ msg: "Une erreur est survenue, merci de réessayer", type: "danger" })
      // setSignatureErrors(['Une erreur est survenue, merci de réessayer'])
      setShowResponseLoader(false)
    }
  }

  const cancelSignature = async ({ signatureId, folderId, setShowResponseLoader, setSuccessMessage }) => {
    setShowResponseLoader(true)
    try {
      const cancelResponse = await cancelSignatureRequest({ folder_id: folderId })
      console.log(cancelResponse)
      if(cancelResponse.success) {
        // TODO update signature status
        // setNotification({ msg: "La signature a été annulée avec succès", type: "success" })
        updateSignature(signatureId, { status: cancelResponse.data.status })
        setSuccessMessage('La signature a été annulée avec succès')
      } else {
        throw new Error(cancelResponse.message)
      }
      setShowResponseLoader(false)
    } catch(err) {
      console.log(err)
      // setSignatureErrors(['Une erreur est survenue, merci de réessayer'])
      setShowResponseLoader(false)
    }
  }

  // Load pdf 
  const loadPdf = useCallback(async ({ documentData, setDocumentData, setShowPlaceSignatureInterface, setShowResponseLoader }, openModal = true) => {
    try {
      if(!documentData) {
        const pdfBase64 = await fetch_document_data(template, doc, 'pdf', agency, user, {}, partner)
        documentData = pdfBase64
      }

      let numOfPages
      
      const pdf = await PDFDocument.load(documentData)
      const pages = await pdf.copyPages(pdf, pdf.getPageIndices())
      numOfPages = pages.length
      
      return { pages: numOfPages }
    } catch (err) {
      console.log(err)
      setShowResponseLoader(false)
      setNotification({ msg: 'Une erreur est survenue, merci de réessayer', type: 'danger' })
    }
  }, [setNotification, agency, doc, template, user])

  return {
    submitForm,
    getRecipientsStatus,
    refreshStatus,
    cancelSignature,
    submitSignatureUpdate,
    loadPdf
  }
}

export default useVialinkActions