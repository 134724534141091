import React from 'react'
import { InfoCircle } from '../../../../assets/icons'
import { CustomTooltip } from '../../../ui_new'
const SenderForm = ({ data = {}, onDataChange = () => {}, errors = {}, emailTooltip = '', emailDisabled = false, editable }) => {

  return (
    <div>
      <div className={`form form--group w-half ${errors['company'] ? 'invalid' : ''}`}>
        <label className='form-label'>Dénomination sociale</label>
        <input
          className='form-input'
          type='text'
          placeholder='Dénomination sociale'
          name='company'
          value={data.company}
          onChange={onDataChange}
          disabled={!editable}
        />
        {errors['company'] && <div className='form--error'>{errors['company']}</div>}
      </div>
      <div className='flex flex-row justify-between gap-10'>
        {/* <div className={`form--group flex-grow ${errors['gender'] ? 'invalid' : ''}`}>
          <label className='form-label'>Civilité</label>
          <select
            className='form-input'
            type='select'
            placeholder='Civilité'
            name='gender'
            onChange={onDataChange}
            disabled={!editable}
            value={data.gender}
          >
            <option value='' disabled>Civilité</option>
            <option value='f'>Madame</option>
            <option value='m'>Monsieur</option>
          </select>
          <input
            className='form-input'
            type='text'
            placeholder='Civilité'
            name='gender'
            value={data.gender}
            onChange={onDataChange}
            disabled={!editable}
          />
          {errors['gender'] && <div className='form--error'>{errors['gender']}</div>}
        </div> */}
        <div className={`form--group flex-grow ${errors['firstname'] ? 'invalid' : ''}`}>
          <label className='form-label'>Prénom</label>
          <input
            className='form-input'
            type='text'
            placeholder='Prénom'
            name='firstname'
            value={data.firstname}
            onChange={onDataChange}
            disabled={!editable}
          />
          {errors['firstname'] && <div className='form--error'>{errors['firstname']}</div>}
        </div>
        <div className={`form--group flex-grow ${errors['lastname'] ? 'invalid' : ''}`}>
          <label className='form-label'>Nom</label>
          <input
            className='form-input'
            type='text'
            placeholder='Nom'
            name='lastname'
            value={data.lastname}
            onChange={onDataChange}
            disabled={!editable}
          />
          {errors['lastname'] && <div className='form--error'>{errors['lastname']}</div>}
        </div>
        <div className={`form--group flex-grow ${errors['email'] ? 'invalid' : ''}`}>
          <label className='form-label'>Email</label>
          <div className='form-wrapper'>
            <input
              className='form-input'
              type='text'
              placeholder='Email'
              name='email'
              value={data.email}
              disabled={!editable || emailDisabled}
              onChange={onDataChange}
            />
            {emailTooltip && <div className='input-tooltip align-end'>
              <CustomTooltip content={emailTooltip}>
                <div className="info"> <InfoCircle /></div>
              </CustomTooltip>
            </div>}
          </div>
          {errors['email'] && <div className='form--error'>{errors['email']}</div>}
        </div>
      </div>
      <div className='flex flex-row justify-between gap-10'>
        <div className={`form--group w-half ${errors['address1'] ? 'invalid' : ''}`}>
          <label className='form-label'>Rue</label>
          <input
            className='form-input'
            type='text'
            placeholder='Rue'
            name='address1'
            value={data.address1}
            onChange={onDataChange}
            disabled={!editable}
          />
          {errors['address1'] && <div className='form--error'>{errors['address1']}</div>}
        </div>
        <div className={`form--group flex-grow ${errors['zipcode'] ? 'invalid' : ''}`}>
          <label className='form-label'>Code postal</label>
          <input
            className='form-input'
            type='text'
            placeholder='Code postal'
            name='zipcode'
            value={data.zipcode}
            onChange={onDataChange}
            disabled={!editable}
          />
          {errors['zipcode'] && <div className='form--error'>{errors['zipcode']}</div>}
        </div>
        <div className={`form--group flex-grow ${errors['city'] ? 'invalid' : ''}`}>
          <label className='form-label'>Ville</label>
          <input
            className='form-input'
            type='text'
            placeholder='Ville'
            name='city'
            value={data.city}
            onChange={onDataChange}
            disabled={!editable}
          />
          {errors['city'] && <div className='form--error'>{errors['city']}</div>}
        </div>
      </div>
    </div>
  )
}

export default SenderForm