import { memo } from 'react'

import { TEXTAREA_LINES } from '../../constants'
import { Textarea } from '../ui_new'

const VariableTextarea = ({ fieldVariable, handle, val, repeatableIds, repetitionIndices, linesAddedTo, onTextAreaInputChange, isHandleLocked, onRemoveLines }) => {
  const { variable, tooltip, type, idx} = fieldVariable
  let linesAdded 
  // if(repeatableSectionId && repeatableIndex !== undefined && repeatableIndex > -1) {
  //   linesAdded = linesAddedTo.find(v => v.variable === variable && v.belongs_to === repeatableSectionId && v.index === repeatableIndex)
  //   if(!linesAdded && linesAddedTo.find(v => v.belongs_to === repeatableSectionId) && val === TEXTAREA_LINES) {
  //     val = ''
  //   }
  // }else {
  //   linesAdded = linesAddedTo.find(v => v.variable === variable)
  // }
  return (
    <div className={`tooltip-wrapper textarea-el doc-variable-el ${linesAdded ? 'lines-added' : ''}`} data-id={idx}>
      <div style={{padding: '5px 0', position: 'relative'}}>
        <Textarea 
          id={variable} value={val || ''} 
          type={type} 
          onChange={onTextAreaInputChange(variable)} 
          className={val ? 'not-empty' : ''} 
          disabled={linesAdded || isHandleLocked(handle)} 
          tooltip={tooltip}
        />
        {linesAdded && <div className="textarea-lines" onClick={() => onRemoveLines(variable, repeatableIds, repetitionIndices)}></div>} 
      </div>
    </div>
  )
}

export default memo(VariableTextarea)