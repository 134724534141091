import { useEffect, useState } from 'react'

import { Folder, AngleRight, FolderPlus } from '../../assets/icons'
import { Modal } from '../ui_new'
import { getAllParentFolders, getFirstLevelSubfolders } from '../../utils'
import { CreateEditFolderModal } from './'

const MoveSelectedItemsToFolderModal = ({ onClose, folders, items, currentFolder, loading, onMove }) => {
  const [activeFolder, setActiveFolder] = useState(currentFolder)
  const [subfolders, setSubfolders] = useState(getFirstLevelSubfolders(folders, activeFolder))
  const [parentFolders, setParentFolders] = useState(getAllParentFolders(folders, currentFolder).reverse())
  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false)
  const [newFolderCreated, setNewFolderCreated] = useState(false)

  // Set subfolders if new folder created
  useEffect(() => {
    if(newFolderCreated) {
      setSubfolders(getFirstLevelSubfolders(folders, activeFolder))
      setNewFolderCreated(false)
    }
  }, [newFolderCreated, activeFolder, folders])

  // On item click
  const handleItemClick = (folder) => {
    setActiveFolder(folder)
    if(folder) {
      setParentFolders([...getAllParentFolders(folders, folder).reverse(), folder])
    }else {
      setParentFolders([])
    }
    setSubfolders(getFirstLevelSubfolders(folders, folder))
  }

  // Render breadcrumbs
  const renderBreadcrumbs = () => {
    let content 

    if(parentFolders.length > 0) {
      content = (
        <>
          <li className="breadcrumbs-v2__item" onClick={() => handleItemClick(null)}><p>Tous</p></li>
          {parentFolders.map((f, i, arr) => {
            if(i < arr.length - 1) {
              return <li className="breadcrumbs-v2__item" key={i} onClick={() => handleItemClick(f)}>
                <AngleRight />
                <p>{f.name}</p>
              </li>
            }else {
              return <li className="breadcrumbs-v2__item active" key={i}>
                <AngleRight />
                {f.name}
              </li>
            }
          })}
        </>
      )
    }else {
      content = activeFolder ? (
        <>
          <li className="breadcrumbs-v2__item" onClick={() => handleItemClick(null)}><p>Tous</p></li>
          <li className="breadcrumbs-v2__item active"><AngleRight />  {currentFolder.name}</li>
        </>
      ) : (
        <li className="breadcrumbs-v2__item active">Tous</li>
      )
    }

    return content
  }

  // On move click
  const handleMove = () => {
    onMove(activeFolder)
  }

  // On open create folder modal
  const handleOpenCreateFolderModal = () => {
    setShowCreateFolderModal(true)
  }

  // On close create folder modal
  const handleCloseCreateFolderModal = () => {
    setShowCreateFolderModal(false)
  }

  return (
    <Modal onClose={onClose}>
      <div className="move-to-folder-v2">
        <div className="move-to-folder-v2__head">
          <h4>Déplacer <strong>{`${items.length} élément(s)`}</strong> vers...</h4>
        </div>
        <div className="move-to-folder-v2__body">
          <div className="move-to-folder-v2__body_top">
            <ul className="breadcrumbs-v2">
              {renderBreadcrumbs()}
            </ul>
            <button className="icon-btn" onClick={handleOpenCreateFolderModal}><FolderPlus /></button>
          </div>
          <div className="move-to-folder-v2__body_main">
            {subfolders.map((f, i) => (
              <div className="folder-inner" key={i} onClick={() => handleItemClick(f)}><Folder /> {f.name}</div>
            ))}
            {subfolders.length === 0 && <div className="no-folders">
              <h3>Pas de dossiers</h3>
              <p>Créer un dossier</p>  
            </div>}
          </div>
        </div>
        <div className="move-to-folder-v2__foot">
          <button className="btn btn--transparent" onClick={onClose}>Annuler</button>
          <button className="btn" onClick={handleMove} disabled={loading}>{loading ? "Déplacement..." : "Déplacer ici"}</button>
        </div>
      </div>

      {showCreateFolderModal && (
        <CreateEditFolderModal 
          onClose={handleCloseCreateFolderModal}
          current={activeFolder}
          onSetNewFolderCreated={setNewFolderCreated}
        />
      )}
    </Modal>
  )
}

export default MoveSelectedItemsToFolderModal