import { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { UserSolid, Building, BgImage } from '../assets/icons'
import { SettingsLayout } from '../layouts'
import { Input } from '../components/ui_new'
import { UserContext } from '../context'
import { FEATURE, isFeatureEnabled } from '../utils'

const SettingsProfile = () => {
  const { user, partner } = useContext(UserContext)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [role, setRole] = useState('')
  const [phone, setPhone] = useState('')
  const [mobile, setMobile] = useState('')

  useEffect(() => {
    if(user) {
      setFirstName(user.firstname || '')
      setLastName(user.lastname || '')
      setEmail(user.email || '')
      if(user.fonctions && user.fonctions[0]) {
        setRole(user.fonctions[0].name || '')
      }
      setPhone(user.phone || '')
      setMobile(user.mobile_phone || '')
    }
  }, [user])

  return (
    <SettingsLayout>
      <div className="settings-profile">
        <div className="settings-profile__mobile-nav-top">
          <Link to="/settings/profile"><UserSolid /> Profil</Link>
        </div>
        <div className="settings-profile__box">
          <h2>Profil</h2>
          {user && Object.keys(user).length > 0 
            ? (
              <div className="fields">
                <Input label="Prénom" disabled={true} className="field-name" value={firstName} />
                <Input label="Nom" disabled={true} className="field-last_name" value={lastName} />
                <Input label="E-mail" disabled={true} className="field-email" value={email} />
                <Input label="Fonctions" disabled={true} className="field-role" value={role} />
                <Input label="Téléphone" disabled={true} className="field-phone" value={phone} />
                <Input label="Téléphone mobile" disabled={true} className="field-mobile" value={mobile} />
              </div>
            ) : (
              <div className="loader-v2"></div>
            )}
        </div>  
        <div className="settings-profile__mobile-nav-bottom">
          <Link to="/settings/agency" className="with-line"><Building /> Agences</Link>
          { isFeatureEnabled(FEATURE.COVER_PAGE_SETTINGS, [], partner, user) && <Link to="/settings/cover-page"><BgImage /> Page de couverture</Link> }
        </div>
      </div> 
    </SettingsLayout>
  )
}

export default SettingsProfile