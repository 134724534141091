import moment from 'moment'
import { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { AR24_DATE_FORMAT, AR24_STATUS_LABELS } from '../constants'
import { NotificationContext, RegisteredMailContext } from '../context'
import RegistredMailLayout from '../layouts/RegisteredMailLayout'
import { sortArrayOfObjects } from '../utils'
import { Redo, Sort } from '../assets/icons'
import { CustomTooltip } from '../components/ui_new'

const labelForStatus = (status) => {
  switch (status) {
    case 'waiting':
      return 'Expédiée'
    case 'dp':
    case 'ev':
      return 'Livrée'
    case 'AR':
      return 'Acceptée'
    case 'refused':
      return 'Refusée'
    case 'negligence':
      return 'Négligence'
    default:
      return status
  }
}

const statusClassForStatus = (status) => {
  switch (status) {
    case 'waiting':
    case 'dp':
    case 'ev':
      return 'status--pending'
    case 'refused':
    case 'negligence':
      return 'status--error'
    case 'AR':
      return 'status--success'
    default:
      return 'status--pending'
  }
}

const formatDate = (date) => {
  const dateObj = moment(date, AR24_DATE_FORMAT)
  return dateObj.format('DD MMM YYYY HH:mm')
}

const RegisteredMailTracking = () => {

  const {
    ar24MailFilter,
    ar24Mail,
    ar24MailFetched,
    ar24Sender,
    showSenderModal,
    ar24SenderFetched
  } = useContext(RegisteredMailContext)

  const [filteredRegisteredMail, setFilteredRegisteredMail] = useState([])

  const [allRecipients, setAllRecipients] = useState({})

  const [activeSort, setActiveSort] = useState('meta.created')
  const [activeOrder, setActiveOrder] = useState('desc')

  const onSort = (sort) => {
    const filteredMail = sortArrayOfObjects(filteredRegisteredMail, activeSort, activeOrder === 'desc' ? 'asc' : 'desc')
    setActiveSort(sort)
    setActiveOrder(activeOrder === 'desc' ? 'asc' : 'desc')
    setFilteredRegisteredMail(filteredMail)
    // saveSortingToLS(sort_by, activeOrder === 'desc' ? 'asc' : 'desc', 'documents')
  }

  useEffect(() => {
    let filtered = [...ar24Mail]
    if (ar24MailFilter.status && ar24MailFilter.status.length > 0) {
      filtered = filtered.filter(mail => ar24MailFilter.status.includes(mail.status))
    }
    if (ar24MailFilter.dateAfter) {
      const afterTimestamp = ar24MailFilter.dateAfter.getTime()
      filtered = filtered.filter(mail => moment(mail.date, AR24_DATE_FORMAT).valueOf() >= afterTimestamp)
    }
    if (ar24MailFilter.dateBefore) {
      const beforeTimestamp = ar24MailFilter.dateBefore.getTime()
      filtered = filtered.filter(mail => moment(mail.date, AR24_DATE_FORMAT).valueOf() <= beforeTimestamp)
    }
    setFilteredRegisteredMail(filtered)
  }, [ar24Mail, ar24MailFilter, setFilteredRegisteredMail])

  useEffect(() => {
    if (!ar24Mail) {
      return
    }
    let recipients = {}
    for (let mail of ar24Mail) {
      let recipientLabel = ''
      if (mail.to_firstname && mail.to_lastname) {
        recipientLabel = `${mail.to_firstname} ${mail.to_lastname}`
      } else if (mail.to_name) {
        recipientLabel = mail.to_name
      }
      if (mail.to_company) {
        recipientLabel += `${recipientLabel ? ' (' : ''}${mail.to_company}${recipientLabel ? ')' : ''}`
      }
      recipients[mail.to_email] = recipientLabel
    }
    setAllRecipients({ ...recipients })
  }, [ar24Mail, setAllRecipients])

  const onCreateSender = () => {
    if(!ar24Sender) {
      showSenderModal('add')
    } else {
      showSenderModal('modify')
    }
  }

  const handlePreview = (mail, type) => {
    if (type === 'download') {
      window.open(mail.pdf_content, '_blank');
    } else if (type === 'preview') {
      window.open(previewUrl(mail), '_blank');
    } else if (type === 'full') {
      window.open(mail.zip, '_blank');
    }
  }

  const previewUrl = (mail) => {
    if(!mail) return
    if(mail[`${mail.status}_proof_url`]) {
      return mail[`${mail.status}_proof_url`]
    }
    const proofTypes = ['AR', 'ev']
    for(let type of proofTypes) {
      if(mail[`${type}_proof_url`]) {
        return mail[`${type}_proof_url`]
      }
    }
    return mail.proof_url
  }

  const mailSorter = (a, b) => {
    const aTimestamp = moment(a.date, AR24_DATE_FORMAT)
    const bTimestamp = moment(b.date, AR24_DATE_FORMAT)
    if (aTimestamp < bTimestamp) return 1
    if (aTimestamp > bTimestamp) return -1
    return 0
  }

  return (
    <RegistredMailLayout
      recipients={allRecipients}
      sidebarSection="tracking">
      <div className="registered-mail-sent">
        <div className="settings-profile__mobile-nav-top">
          <Link to="/registered-mail/tracking">Suivi des envois</Link>
        </div>
        <div className="registered-mail__box">
          {ar24SenderFetched && !ar24Sender && (
            <div className='mt-20 mb-40'>
              <p className='text-left'>
                {!ar24Sender ? 'Aucun compte expéditeur n\'a été ajouté.' : 'Le compte expéditeur n\'est pas vérifié ou est en attente de validation.'}</p>
              <button
                className='btn item-btn mt-20'
                onClick={onCreateSender}
              >
                {!ar24Sender ? 'Ajouter un compte AR24' : 'Accéder au compte'}
              </button>
            </div>
          )}
          {!ar24MailFetched &&
            <div className="loader-wrapper">
              <div className="loader-v2"></div>
            </div>}
          {ar24MailFetched && ar24Mail.length > 0 && <div className="registered-mail-table">
            <div className="registered-mail-table__head">
              <div className="registered-mail-table-row">
                <div className="registered-mail-table-column" onClick={() => onSort('status')}>
                  <p className={`${activeSort === 'status' ? 'active' : ''}`}>Etat de l'envoi</p>
                  <Sort />
                </div>
                <div className="registered-mail-table-column" onClick={() => onSort('to_firstname')}>
                  <p className={`${activeSort === 'to_firstname' ? 'active' : ''}`}>Destinaire</p>
                  <Sort />
                </div>
                <div className="registered-mail-table-column" onClick={() => onSort('to_email')}>
                  <p className={`${activeSort === 'to_email' ? 'active' : ''}`}>Email destinaire</p>
                  <Sort />
                </div>
                <div className="registered-mail-table-column" onClick={() => onSort('date')}>
                  <p className={`${activeSort === 'date' ? 'active' : ''}`}>Date d'envoi</p>
                  <Sort />
                </div>
                <div className="registered-mail-table-column" onClick={() => onSort('from_email')}>
                  <p className={`${activeSort === 'from_email' ? 'active' : ''}`}>Compte expéditeur</p>
                  <Sort />
                </div>
                <div className="registered-mail-table-column" onClick={() => onSort('from_firstname')}>
                  <p className={`${activeSort === 'from_firstname' ? 'active' : ''}`}>Envoyé par</p>
                  <Sort />
                </div>
                <div className="registered-mail-table-column" onClick={() => onSort('price_ht')}>
                  <p className={`${activeSort === 'price_ht' ? 'active' : ''}`}>Montant</p>
                  <Sort />
                </div>
                <div className="registered-mail-table-column">
                  <p>Preuves</p>
                </div>
              </div>
            </div>
            <div className="registered-mail-table__body">
              {filteredRegisteredMail.map((mail, idx) => (
                <RegisteredMailTableRow
                  mail={mail}
                  idx={idx}
                  onPreview={() => handlePreview(mail, 'preview')}
                  previewDisabled={!previewUrl(mail)}
                />
              ))}
            </div>
          </div>}
        </div>
        <div className="settings-profile__mobile-nav-bottom">
          <Link to="/registered-mail/sender" className="with-line">Gestion compte expéditeur</Link>
        </div>
      </div>
    </RegistredMailLayout>
  )
}

const RegisteredMailTableRow = ({ mail, idx, onPreview, previewDisabled }) => {

  const { refreshAr24MailStatus } = useContext(RegisteredMailContext)
  const { setNotification } = useContext(NotificationContext)

  const [refreshing, setRefreshing] = useState(false)
  
  // On status refresh click
  const handleStatusRefreshClick = async () => {
    setRefreshing(true)
    const refreshResponse = await refreshAr24MailStatus(mail.id)
    setRefreshing(false)
    if(refreshResponse.error) {
      // handle error
      setNotification({ msg: 'Erreur lors de la mise à jour du statut. ' + refreshResponse.error.message || '', type: 'danger' })
      console.error('Error refreshing status', refreshResponse.error)
    }
  }

  return (
    <div className={`registered-mail-table-row`} key={`registered_mail_table_row_${idx}`}>
      <div className="registered-mail-table-column">
        <CustomTooltip content={labelForStatus(mail.status)}>
          <p className='flex flex-row justify-start align-center'>
            <span className={`status ${statusClassForStatus(mail.status)}`}></span>
            <span className="text">
              {labelForStatus(mail.status)}            
            </span>
            <button className={`icon-btn icon-btn--transparent refresh-status ${refreshing ? 'refreshing' : ''}`} onClick={handleStatusRefreshClick}><Redo /></button>
          </p>
        </CustomTooltip>
      </div>
      <div className="registered-mail-table-column">
        <CustomTooltip content={`${mail.to_firstname} ${mail.to_lastname}`}>
          <p className='text'>{mail.to_firstname} {mail.to_lastname}</p>
        </CustomTooltip>
      </div>
      <div className="registered-mail-table-column">
        <CustomTooltip content={mail.to_email}>
          <p className='text'>{mail.to_email}</p>
        </CustomTooltip>
      </div>
      <div className="registered-mail-table-column">
        <CustomTooltip content={formatDate(mail.date)}>
          <p className='text'>{formatDate(mail.date)}</p>
        </CustomTooltip>
      </div>
      <div className="registered-mail-table-column">
        <CustomTooltip content={mail.from_email}>
          <p className='text'>{mail.from_email}</p>
        </CustomTooltip>
      </div>
      <div className="registered-mail-table-column">
        <CustomTooltip content={mail.sent_by?.admin_name}>
          <p className='text'>{mail.sent_by?.admin_name}</p>
        </CustomTooltip>
      </div>
      <div className="registered-mail-table-column">
        <p className='text'>{mail.price_ht}€ HT</p>
      </div>
      <div className="registered-mail-table-column">
        <button className="btn" type='button' onClick={onPreview} disabled={previewDisabled}>Télécharger</button>
      </div>
    </div>
  )
}

export default RegisteredMailTracking