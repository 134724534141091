import { useState, useEffect, useContext, useRef } from 'react'
import moment from 'moment'
import { areFolderActionsAuthorized, dateDisplayFormat } from '../../utils'
import CustomTooltip from './CustomTooltip'
import { Folder, FolderPlus, Pen, MoveToFolder, Delete } from '../../assets/icons'
import { ClickAwayListener } from '@material-ui/core'
import { useFoldersActions, useTemplatesActions } from '../../hooks'
import { CreateEditFolderModal, FoldersPopup } from '../misc'
import { Alert } from '.'
import { DocumentsContext, FolderContext, UserContext } from '../../context'


const TemplatesFoldersTableRow = ({ id, folder, onSelectFolder, view = 'templates' }) => {
  const [createdAt, setCreatedAt] = useState('')
  const [modifiedAt, setModifiedAt] = useState('')
  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false)
  const [showEditFolderModal, setShowEditFolderModal] = useState(false)
  const [showFoldersPopup, setShowFoldersPopup] = useState(false)
  const { deleteFolder, checkIfFolderCanBeDeleted } = useFoldersActions()
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  const popupEl = useRef()
  const { folders, foldersLoading, standardTemplatesFolders, standardTemplatesFoldersLoading } = useContext(FolderContext)
  const { templates, standardTemplates } = useContext(DocumentsContext)
  const { userClaims } = useContext(UserContext)
  const [popupElPosition, setPopupElPosition] = useState(null)
  // const [folder, setFolder] = useState(false)
  const { moveTemplateToFolder } = useTemplatesActions()

  // Set metadata
  useEffect(() => {
    // setFolder(template)
    if (folder?.meta?.created) {
      setCreatedAt(moment(folder.meta.created).format(dateDisplayFormat))
    } else if (folder.createdAt) {
      setCreatedAt(moment(folder.createdAt).format(dateDisplayFormat))
    }
    if (folder?.meta?.updated) {
      setModifiedAt(moment(folder.meta.updated).format(dateDisplayFormat))
    } else if (folder.updatedAt) {
      setModifiedAt(moment(folder.updatedAt).format(dateDisplayFormat))
    }
  }, [folder])


  // On click away
  const handleClickAway = () => {
    setShowFoldersPopup(false)
  }

  // On create new folder click
  const handleCreateNewFolderClick = (e) => {
    e.preventDefault()
    setShowCreateFolderModal(true)
  }

  // On edit click
  const handleEditFolderClick = (e) => {
    e.preventDefault()
    setShowEditFolderModal(true)
  }

  // On move folder click
  const handleMoveFolderClick = (e) => {
    e.preventDefault()
    setShowFoldersPopup(true)
    setPopupElPosition(e.currentTarget.getBoundingClientRect())
  }

  // On delete folder click
  const handleDeleteFolderClick = (e) => {
    e.preventDefault()
    if (checkIfFolderCanBeDeleted(folder, view)) {
      setShowDeleteAlert(true)
    }
  }

  // On close delete alert
  const handleCloseDeleteAlert = () => {
    setShowDeleteAlert(false)
  }

  // On delete 
  const handleDelete = async () => {
    setShowDeleteAlert(false)
    await deleteFolder(folder, view)
    // if (activeFolder === folder.id) {
    //   onFilter(folder.parentFolder || 'all')
    //   return
    // }
  }


  // On close edit folder modal
  const handleCloseEditFolderModal = () => {
    setShowEditFolderModal(false)
  }

  // On close create folder modal
  const handleCloseCreateFolderModal = () => {
    setShowCreateFolderModal(false)
  }

  // On close folders popup
  const handleCloseFoldersPopup = () => {
    setShowFoldersPopup(false)
  }

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  // drop template to folder
  const handleDrop = (e) => {
    const template = e.dataTransfer.getData('template');
    const view = e.dataTransfer.getData('view');
    moveTemplateToFolder({ folder, template: JSON.parse(template), view: view });
  };

  return (<>
    <div className={`documents-table-row no-cursor`}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      style={{ backgroundColor: 'white' }}>
      <div className="documents-table-column documents-table-column__name" style={{ width: '160px', justifyContent: 'flex-start', cursor: "pointer" }} onClick={() => onSelectFolder(folder)}>
        <CustomTooltip content={folder.name} position='top'>
          <p className='doc_name flex'> &nbsp;
            <div style={{ width: '16px' }}> <Folder /></div> &nbsp;
            {folder.name}</p>
        </CustomTooltip>
      </div>
      <div className="documents-table-column">
        {areFolderActionsAuthorized(view, userClaims) && (
          <>
            <CustomTooltip content="Nouveau dossier"><button className="icon-btn icon-btn--white icon-btn--svg-md" onClick={handleCreateNewFolderClick}><FolderPlus /></button></CustomTooltip>
            <CustomTooltip content="Renommer"><button className="icon-btn icon-btn--white icon-btn--svg-md" onClick={handleEditFolderClick}><Pen /> </button></CustomTooltip>
            <CustomTooltip content="Déplacer"><button className="icon-btn icon-btn--white icon-btn--svg-md" onClick={handleMoveFolderClick}><MoveToFolder /></button></CustomTooltip>
            <CustomTooltip content="Supprimer"><button className="icon-btn icon-btn--white icon-btn--svg-md" onClick={handleDeleteFolderClick}><Delete /></button></CustomTooltip>
          </>
        )}
      </div>
      <div className="documents-table-column documents-table-column__date">
        <p>{createdAt}</p>
      </div>
      <div className="documents-table-column documents-table-column__date">
        <p>{modifiedAt}</p>
      </div>
      <div className="documents-table-column documents-table-column__history">
      </div>
      <div className="documents-table-column-mobile">
        <div className="date">{createdAt}</div>
        {/* {folder.folder && <div className="folder"><Folder /> {folder.folder}</div>} */}
      </div>
    </div>

    {showFoldersPopup && <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <FoldersPopup
          ref={popupEl}
          onCancel={handleCloseFoldersPopup}
          folders={view === 'templates' ? folders : view === 'standard-templates' ? standardTemplatesFolders : []}
          foldersLoading={view === 'templates' ? foldersLoading : view === 'standard-templates' ? standardTemplatesFoldersLoading : false}
          loading={false}
          data={view === 'templates' ? templates : view === 'standard-templates' ? standardTemplates : []}
          resource={folder}
          position={popupElPosition}
          view={view}
        />

      </div>
    </ClickAwayListener>}
    {showCreateFolderModal && (
      <CreateEditFolderModal
        onClose={handleCloseCreateFolderModal}
        current={folder}
        view={view}
      />
    )}

    {showEditFolderModal && (
      <CreateEditFolderModal
        onClose={handleCloseEditFolderModal}
        current={folder}
        mode="edit"
        view={view}
      />
    )}

    {showDeleteAlert && (
      <Alert
        onClose={handleCloseDeleteAlert}
        onSubmit={handleDelete}
        deleteAlert={true}
        text="Êtes-vous sûr de vouloir supprimer ce dossier ?"
      />
    )}

  </>

  )
}

export default TemplatesFoldersTableRow