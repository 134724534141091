import { useState, useEffect } from "react"
import DatePicker from "react-datepicker"

import { CustomTooltip } from './'
import { CalendarAlt, TimesCircle } from "../../assets/icons"

const DateInput = ({ label, onChange, selected, peekNextMonth = false, dateFormat = 'dd/MM/yyyy', showMonthDropdown = false, showYearDropdown = false, dropdownMode = '', disabled = false, tooltip, isClearable, onClearableClick, onChangeRaw, minDate = '', maxDate = '', className }) => {
  const [startDate, setStartDate] = useState('')

  // Set start date if selected is set
  useEffect(() => {
    if(selected !== undefined || selected !== null) {
      setStartDate(selected)
    }
  }, [selected])

  // On date change
  const handleDateChange = (date) => {
    setStartDate(date)
    onChange(date)
  }

  // On clear date
  const handleClear = () => {
    setStartDate('')
    if(onClearableClick) onClearableClick()
  }

  return (
    <div className={`date-input ${className ? className : ''}`}>
      {label && <div className="date-input__label">{label}</div>}
      {tooltip ? (
        <CustomTooltip content={tooltip}>
          <div className={!isClearable ? "date-input__body" : "date-input__body clearable"}>
            <CalendarAlt />
            <DatePicker 
              selected={startDate} 
              onChange={handleDateChange} 
              portalId="modal-root" 
              locale="fr" 
              dateFormat={dateFormat}
              peekNextMonth={peekNextMonth}
              showMonthDropdown={showMonthDropdown}
              showYearDropdown={showYearDropdown}
              dropdownMode={dropdownMode}
              disabled={disabled}
              onChangeRaw={onChangeRaw}
              minDate={minDate}
              maxDate={maxDate}
            />
            {isClearable && <button className="clear-date-btn" onClick={handleClear}><TimesCircle /></button>}
          </div>
        </CustomTooltip>
      ) : (
        <div className={!isClearable ? "date-input__body" : "date-input__body clearable"}>
          <CalendarAlt />
          <DatePicker 
            selected={selected || startDate} 
            onChange={handleDateChange} 
            portalId="modal-root" 
            locale="fr" 
            dateFormat={dateFormat}
            peekNextMonth={peekNextMonth}
            showMonthDropdown={showMonthDropdown}
            showYearDropdown={showYearDropdown}
            dropdownMode={dropdownMode}
            disabled={disabled}
            onChangeRaw={onChangeRaw}
            minDate={minDate}
            maxDate={maxDate}
          />
          {isClearable && <button className="clear-date-btn" type="button" onClick={handleClear}><TimesCircle /></button>}
        </div>
      )}
    </div>
  )
}

export default DateInput