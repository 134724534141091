import { Modal } from '../ui_new'

const SignaturePreviewModal = ({ onClose, previewLoading, previewError, iframeSrc }) => {
  return (
    <Modal onClose={onClose} className="modal--padding-sm">
      {previewLoading 
        ? <div className="loader-wrapper"><div className="loader-v2"></div></div>
        : iframeSrc 
          ? <div>
            <div className='mb-20'>
              <span>Si le document ne s’affiche pas,&nbsp;&nbsp;</span>
              <a className='btn' href={iframeSrc} target="_blank" rel="noreferrer" download="signature-preview">téléchargez-le ici</a>
            </div>
            <iframe title="Pdf" src={iframeSrc} className="iframe" />
          </div>
          : <p className="preview-error">{previewError}</p>
      }
    </Modal>
  )
}

export default SignaturePreviewModal