import { useState } from 'react'

import { Archive, Download, Delete, Duplicate, Close } from '../../assets/icons'
import { useDocumentsActions } from '../../hooks'
import { Alert, CustomTooltip } from '../ui_new'

const DashboardActions = ({ selectedDocuments, onSetSelectedDocuments }) => {
  const { downloadDocuments, archiveDocuments, duplicateDocuments, moveDocumentsToDeletedFolder } = useDocumentsActions(selectedDocuments, onSetSelectedDocuments)
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)

  // On documents duplicate
  const handleDocumentsDuplicate = (e) => {
    e.preventDefault()
    duplicateDocuments()
  }

  // On documents download
  const handleDocumentsDownload = (e) => {
    e.preventDefault()
    downloadDocuments()
  }

  // On documents archive
  const handleDocumentsArchive = (e) => {
    e.preventDefault()
    archiveDocuments()
  }

  // On documents delete
  const handleDocumentsDelete = (e) => {
    e.preventDefault()
    moveDocumentsToDeletedFolder()
  }

  // On actions close
  const handleCloseActions = () => {
    onSetSelectedDocuments([])
  }

  // On open delete alert
  const handleOpenDeleteAlert = (e) => {
    e.preventDefault()
    setShowDeleteAlert(true)
  }

  // On close delete alert
  const handleCloseDeleteAlert = () => {
    setShowDeleteAlert(false)
  }

  return (
    <div className="dashboard-actions">
      <div className="dashboard-actions__selected">
        <div className="number">{selectedDocuments.length}</div>
        <p>Élément(s) sélectionné(s)</p>
      </div>
      <div className="dashboard-actions__buttons">
        <CustomTooltip content="Dupliquer">
          <a href="/" onClick={handleDocumentsDuplicate} className="icon-duplicate"><Duplicate /></a>
        </CustomTooltip>
        <CustomTooltip content="Télécharger">
          <a href="/" onClick={handleDocumentsDownload} className="download-icon"><Download /></a> 
        </CustomTooltip>
        <CustomTooltip content="Archiver">
          <a href="/" onClick={handleDocumentsArchive} className="archive"><Archive /></a>
        </CustomTooltip>
        <CustomTooltip content="Supprimer">
          <a href="/" onClick={handleOpenDeleteAlert} className="icon-delete"><Delete /></a>
        </CustomTooltip>
      </div>
      <div className="dashboard-actions__close" onClick={handleCloseActions}>
        <Close />
      </div>

      {showDeleteAlert && <Alert 
        onClose={handleCloseDeleteAlert}
        text="Êtes-vous sûr de vouloir supprimer le(s) document(s) sélectionné(s) ?"
        onSubmit={handleDocumentsDelete}
        deleteAlert
      />}
    </div>
  )
}

export default DashboardActions