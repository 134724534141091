const MoveToFolder = () => {
  return (
    <span className="svg-icon">
      <svg width="100%" height="100%" viewBox="0 0 17 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve">
        <g transform="matrix(1,0,0,1,-0.282851,-2.33372)">
          <g transform="matrix(1,0,0,1,-532.733,-79.735)">
            <g id="Path_1610" transform="matrix(-0.438371,-0.898794,0.898794,-0.438371,537.306,95.508)">
              <path d="M4.171,10.094L2.055,10.154L6.014,15.231L9.822,10.063L7.435,10.123C7.132,3.989 4.352,1 0,0C3.052,1.934 4.382,5.259 4.171,10.094Z" />
            </g>
          </g>
          <g transform="matrix(1,0,0,1,-532.733,-79.735)">
            <g id="folder-regular" transform="matrix(0.0332031,0,0,0.0278931,541.516,95.6557)">
              <g transform="matrix(1,0,0,1,-256,-256)">
                <clipPath id="_clip1">
                  <rect x="0" y="0" width="512" height="512"/>
                </clipPath>
                <g clipPath="url(#_clip1)">
                  <path d="M447.1,96L275,96L226.7,50.75C214.7,38.74 198.5,32 181.5,32L63.1,32C27.75,32 -0.9,60.66 -0.9,96L-0.9,416C-0.9,451.34 27.75,480 63.1,480L447.1,480C482.45,480 511.1,451.34 511.1,416L511.1,160C511.1,124.7 483.3,96 447.1,96ZM463.1,416C463.1,424.824 455.922,432 447.1,432L63.1,432C54.278,432 47.1,424.824 47.1,416L47.1,96C47.1,87.176 54.278,80 63.1,80L180.6,80C184.873,80 188.893,81.664 191.91,84.688L255.1,144L447.1,144C455.922,144 463.1,151.176 463.1,160L463.1,416Z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </span>
  )
}

export default MoveToFolder