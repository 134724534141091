import { Signature } from '../assets/icons'
import { MainLayout } from './'
import { RegisteredMailSidebar } from '../components/misc'

const RegistredMailLayout = ({ children, ...props }) => {
  return (
    <MainLayout className="registered-mail" pageTitle="Lettres recommandées" titleIcon={<Signature />} showSearch={true} searchIn="registeredMail">
      <div className="registered-mail-content">
        <RegisteredMailSidebar {...props} />
        <div className="registered-mail-content__main">
          {children}
        </div>
      </div>
    </MainLayout>
  )
}

export default RegistredMailLayout