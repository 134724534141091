import { useEffect } from 'react'
import { Check, Sort } from '../../assets/icons'
import { DocumentsTableFolderRow, DocumentsTableRow } from '../ui_new'
import { FEATURE, isFeatureEnabled } from '../../utils'

const DocumentsTable = ({ selectedDocuments, onSetSelectedDocuments, allChecked, onSetAllChecked, filteredDocs, onSort, activeSort, activeOrder, filteredFolders, onSelectFolder }) => {

  // On all checkbox click
  const handleAllChecked = () => {
    onSetAllChecked(!allChecked)
    if(allChecked) {
      onSetSelectedDocuments([])
    }else {
      onSetSelectedDocuments(filteredDocs)
    }
  }

  return (
    <div className="documents-table">
      <div className="documents-table__head">
        <div className="documents-table-row">
          <div className="documents-table-column documents-table-column__checkbox">
            <div className={`documents-table-column__check ${allChecked ? 'checked' : ''}`} onClick={handleAllChecked}><Check /></div>
          </div>
          <div className="documents-table-column documents-table-column__name" onClick={() => onSort('name')}>
            <p className={`${activeSort === 'name' ? 'active' : ''}`}>Nom</p>
            <Sort />
          </div>
          <div className="documents-table-column documents-table-column__status" onClick={() => onSort('status')}>
            <p className={`${activeSort === 'status' ? 'active' : ''}`}>Statut</p>
            <Sort />
          </div>
          <div className="documents-table-column documents-table-column__progress" onClick={() => onSort('progress')}>
            <p className={`${activeSort === 'progress' ? 'active' : ''}`}>%</p>
            <Sort />
          </div>
          {isFeatureEnabled(FEATURE.INTERAGENCY) &&
          <div className="documents-table-column documents-table-column__author" onClick={() => onSort('author')}>
            <p className={`${activeSort === 'author' ? 'active' : ''}`}>Créé par</p>
            <Sort />
          </div>}
          {isFeatureEnabled(FEATURE.INTERAGENCY) && 
          <div className="documents-table-column documents-table-column__owner" onClick={() => onSort('owner')}>
            <p className={`${activeSort === 'owner' ? 'active' : ''}`}>Agence</p>
            <Sort />
          </div> }
          <div className="documents-table-column documents-table-column__date" onClick={() => onSort('meta.created')}>
            <p className={`${activeSort === 'meta.created' ? 'active' : ''}`}>Date de<br /> création</p>
            <Sort />
          </div>
          <div className="documents-table-column documents-table-column__date" onClick={() => onSort('meta.updated')}>
            <p className={`${activeSort === 'meta.updated' ? 'active' : ''}`}>Date de<br /> modification</p>
            <Sort />
          </div>
        </div>
      </div>
      <div className="documents-table__body">
        {filteredFolders.map((folder, fi) => {
          return (
            <DocumentsTableFolderRow
              key={fi}
              folder={folder}
              onSelectFolder={onSelectFolder}
            />
          )
        })}
        {filteredDocs.map((doc, idx) => {
          return (
            <DocumentsTableRow 
              key={idx} 
              id={doc.id} 
              doc={doc} 
              selectedDocuments={selectedDocuments}
              onSetSelectedDocuments={onSetSelectedDocuments} 
              allChecked={allChecked}
            />
          )
        })}
      </div>
    </div>
  )
}

export default DocumentsTable