const Radio = ({ choices, name, onChange, selectedValue, error }) => {
  return(
    <div className={"radio-v2"}>
      <div className="radio-v2__choices">
        {choices.map((choice, index) => (
          <div key={index}>
            <label className="radio-v2__choices_label">
              <input 
                type="radio" 
                name={name} 
                onChange={onChange}
                value={choice.value}
                checked={selectedValue && selectedValue === choice.value}
                disabled={choice.disabled}
              />
              <div className="radio-v2__choices_btn"></div>
              <p>{choice.label}</p>
            </label>
          </div>
        ))}
      </div>
      {error && <div className="radio-v2__error">{error}</div>}
    </div>
  )
}

export default Radio