import { useContext, useRef } from 'react'

import { Plus, Times } from '../../assets/icons'
import { NotificationContext } from '../../context'

const FileUploadDnD = ({ btnText = 'Ajouter', multiple = false, accept = '', onChange, name = '', imagePreview, onImageRemove, note, minimumImageDimensions, disabled }) => {
  const fileRef = useRef()
  const { setNotification } = useContext(NotificationContext)

  const validateImageDimensions = (file) => {
    return new Promise((resolve) => {
      const img = new Image()
      img.src = window.URL.createObjectURL(file)
      img.onload = () => {
        const { width, height } = img
        if(width < minimumImageDimensions.width || height < minimumImageDimensions.height) {
          setNotification({ msg: `Le fichier ${file.name} ne respecte pas les dimensions requises`, type: 'warning' })
          resolve(null)
        } else {
          resolve(file)
        }
      }
    })
  }

  // On change
  const handleChange = async (e) => {
    if(!minimumImageDimensions) {
      onChange(e.target.files, name)
      e.target.value = null
      return
    }
    const promises = []
    for(let i = 0; i < e.target.files.length; i++) {
      promises.push(validateImageDimensions(e.target.files[i]))
    }
    const files = await Promise.all(promises)
    onChange(files.filter(f => Boolean(f)), name)
    e.target.value = null
  }

  // On button click
  const handleOpenFileWindow = () => {
    if(!fileRef.current) return 
    fileRef.current.click()
  }

  // On drag enter
  const handleDragEnter = (e) => {
    e.preventDefault()
  }

  // On drag leave
  const handleDragLeave = (e) => {
    e.preventDefault()
  }

  // On drag over
  const handleDragOver = (e) => {
    e.preventDefault()
  }

  // On drop
  const handleDrop = (e) => {
    onChange(e.dataTransfer.files, name)
    e.preventDefault()
  }

  // On image remove
  const handleImageRemove = () => {
    onImageRemove(name)
  }

  return (
    <div className={`file-upload-dnd ${disabled && "disabled"}`}>
      <input type="file" onChange={handleChange} multiple={multiple} accept={accept} ref={fileRef} disabled={disabled} />
      <div 
        className="file-upload-dnd__box"
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {!imagePreview ? (
          <>
            <button type="button" onClick={handleOpenFileWindow}><Plus /> {btnText}</button>
            <p>ou glisser-déposer une pièce jointe</p>
          </>
        ) : (
          <div className="image-preview">
            <img src={imagePreview} alt="" />
            <div className="image-preview__close" onClick={handleImageRemove}><Times /></div>
          </div>
        )}
      </div>
      { note && <span className='file-upload-dnd__note'>{note}</span> }
    </div>
  )
}

export default FileUploadDnD