import moment from 'moment'

import { FileAlt, Paperclip } from '../../assets/icons'
import { Select, Option, CustomTooltip } from '../ui_new'
import { STATUS_LABELS_SHORT } from '../../constants'
import { SingleDocumentSummary } from '.'
import { useSingleDocumentActions } from '../../hooks'
import useWindowDimensions from '../../hooks/useWindowDimensions'

const StandardTemplateSidebar = ({
    singleDoc,
    currentDocVersionTime,
    documentValues,
    onVariableItemClick,
    scrollingToItem,
    onSectionClick,
    templateData,
    linesAddedTo,
    tabActive,
    onNameChange,
    view,
    onSetView,
    numOfAttachments,
    status,
    onSetStatus,
    progress,
    templateMetaSections,
    summarySections,
}) => {
    const { extractVariableTypesFromSections } = useSingleDocumentActions()

    // On status change
    const handleStatusChange = (value) => {
        onSetStatus(value)
    }
    const { isMobile } = useWindowDimensions()

    return (
        <div
            className={`single-document__left single-document-tab ${
                tabActive ? 'tab-active' : ''
            }`}
            style={{ height: isMobile ? '80.5%' : '' }}
        >
            <div className="single-document__left_nav">
                <CustomTooltip content="Variables">
                    <button
                        className={`icon-btn icon-btn--transparent ${
                            view === 'variables' ? 'active' : ''
                        }`}
                        onClick={() => onSetView('variables')}
                    >
                        <FileAlt />
                    </button>
                </CustomTooltip>
                <CustomTooltip content="Annexes">
                    <button
                        className={`icon-btn icon-btn--transparent ${
                            view === 'attachments' ? 'active' : ''
                        }`}
                        onClick={() => onSetView('attachments')}
                    >
                        <Paperclip />{' '}
                        {numOfAttachments > 0 && (
                            <span className="num">{numOfAttachments}</span>
                        )}
                    </button>
                </CustomTooltip>
            </div>
            <section className="head">
                <h1>
                    <span>Mes modèles types</span>
                </h1>
            </section>
            <section className="doc-info">
                <h2
                    contentEditable={true}
                    suppressContentEditableWarning={true}
                    onInput={onNameChange}
                >
                    {singleDoc.name}
                </h2>

                <div className="status-row">
                    <Select
                        selected={status}
                        onChange={handleStatusChange}
                        type="status"
                    >
                        {Object.entries(STATUS_LABELS_SHORT).map(
                            ([stKey, stValue], idx) => (
                                <Option key={idx} value={stKey}>
                                    {stValue}
                                </Option>
                            )
                        )}
                    </Select>

                    <p className="doc-progress"><span>{ `${(progress * 100).toFixed(0)}%` }</span></p>
                </div>

                {/* <p className="doc-status"><span className={`${singleDoc.status}`}></span> {STATUS_LABELS_SHORT[singleDoc.status]}</p> */}

                <div className="doc-version">
                    <FileAlt />
                    <p>Version du</p>
                    <p>
                        {currentDocVersionTime &&
                            currentDocVersionTime.time &&
                            moment(currentDocVersionTime.time).format(
                                'DD/MM/YYYY HH:mm'
                            )}
                    </p>
                </div>
            </section>
            <section className="summary">
                <h3>Sommaire</h3>
                <SingleDocumentSummary
                    documentValues={documentValues}
                    variableTypes={extractVariableTypesFromSections()}
                    onItemClick={onVariableItemClick}
                    scrollingToItem={scrollingToItem}
                    onSectionClick={onSectionClick}
                    templateData={templateData}
                    templateMetaSections={templateMetaSections}
                    summarySections={summarySections}
                />
            </section>
        </div>
    )
}

export default StandardTemplateSidebar
