import React, { useContext } from 'react' 
import { NavLink, Link } from 'react-router-dom'

import { Cog, FileAlt2, FolderOpen, RegisteredMail, Signature } from '../../assets/icons'
import { FEATURE, isFeatureEnabled, availableOn } from '../../utils'
import { UserContext } from '../../context'
import { Star } from '@material-ui/icons'

const Sidebar = ({ full = false }) => {

  let sidebarClass = 'main-sidebar u-custom-scrollbar'

  if(!full) {
    sidebarClass += ' main-sidebar--shrinked'
  }

  const isNavlinkActive = (match) => (...args)  => {
    return args[1].pathname.substring(0, match.length) === match
  }

  const { showSignatures, siteConfigs } = useContext(UserContext)

  return (
    <div className={sidebarClass}>
      <ul>
        <li>
          <NavLink to="/documents">
            <span className="icon icon-documents"><FileAlt2 /></span> 
            <span className="text">Mes documents</span>
          </NavLink>
        </li>
        { isFeatureEnabled(FEATURE.STANDARD_TEMPLATES, siteConfigs) && <li>
          <NavLink to="/standard-templates">
            <span className="icon icon-signatures"><Star /></span>
            <span className="text">Modèles types</span>
          </NavLink>
        </li> }
        <li>
          <NavLink to="/templates">
            <span className="icon icon-templates"><FolderOpen /></span> 
            <span className="text">Modèles</span>
          </NavLink>
        </li>
        {showSignatures && <li>
          <NavLink to="/signatures">
            <span className="icon icon-signatures"><Signature /></span> 
            <span className="text">Signatures</span>
          </NavLink>
        </li> }
        { isFeatureEnabled(FEATURE.AR24, siteConfigs) && <li>
          <NavLink isActive={isNavlinkActive('/registered-mail')} to="/registered-mail/tracking">
            <span className="icon icon-signatures"><RegisteredMail /></span>
            <span className="text">Lettres recommandées</span>
          </NavLink>
        </li> }
        <li>
          <NavLink isActive={isNavlinkActive('/settings')} to="/settings/profile">
            <span className="icon icon-settings"><Cog /></span> 
            <span className="text">Paramètres</span>
          </NavLink>
        </li>
      </ul>
      
      { availableOn(['development', 'staging']) && <div className="main-sidebar__bottom">
        <Link to="/help">?</Link>
      </div> }
    </div>
  )
}

export default Sidebar