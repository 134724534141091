import React, { useState, useEffect, useContext } from 'react'
import BallotIcon from '@material-ui/icons/BallotOutlined'

import Modal from '../../UI/Modal'
import Checkbox from '../../UI/Checkbox'
import Button from '../../UI/Button'
import ResponseLoader from '../../UI/ResponseLoader'
import Loader from '../../UI/Loader'
import { UserContext, HelpContext } from '../../../context'

const VoteUpcomingFeaturesModal = ({ onClose }) => {
  const { fetchFeatures, featuresFetched, features, updateFeature } = useContext(HelpContext)
  const { user } = useContext(UserContext)
  const [newFeatures, setNewFeatures] = useState([])
  const [showLoader, setShowLoader] = useState(false)

  // Fetch features if they are not fetched yet
  useEffect(() => {
    if(!featuresFetched) {
      fetchFeatures()
    }
  }, [featuresFetched, fetchFeatures])

  // Set new features
  useEffect(() => {
    if(featuresFetched) {
      const formattedFeatures = features.map(f => ({ name: f.description['en'], checked: false, id: f.id }))
      setNewFeatures(formattedFeatures)
    }
  }, [features, featuresFetched])

  // Checkbox change handler
  const checkboxChangeHandler = (e, id) => {
    setNewFeatures(prev => {
      const current = prev.find(f => f.id === id)
      current.checked = e.target.checked 
      return prev.map(f => f.id === id ? current : f)
    })
  }

  // Submit votes handler
  const submitHandler = async (e) => {
    e.preventDefault()
    let checkedItems = newFeatures.filter(f => f.checked)
    if(checkedItems.length) {
      setShowLoader(true)
      let promises = []

      checkedItems.forEach(item => {
        const { id } = item
        let feature = features.find(f => f.id === id)
        if(feature) {
          let votes = feature.votes || []
          if(!votes.includes(user.uid)) {
            votes.push(user.uid)
            promises.push(updateFeature(id, votes))
          }
        }
      })

      if(promises.length) {
        await Promise.all(promises) 
      }
      setShowLoader(false)
      onClose()
    }
  }

  // Check if already voted
  const alreadyVoted = () => {
    const voted = features.find(f => f.votes.includes(user.uid))
    return !!voted
  }

  return (
    <Modal onClose={onClose} medium noPadding>
      <div className="report-a-bug">
        <div className="report-a-bug__head">
          <h3><BallotIcon /> {`Votez pour les fonctionnalités à venir`}</h3>
        </div>
        <div className="report-a-bug__body">
          <p>{`Que voudriez-vous voir dans la prochaine version de l'application ?`}</p>
          {!featuresFetched 
            ? <div className="loader-wrapper"><Loader primary normal small /></div>
            : !alreadyVoted() 
              ? 
                newFeatures.length > 0 ? <form onSubmit={submitHandler}>
                  <ul className="features u-custom-scrollbar">
                    {newFeatures.map((feature, idx) => {
                      return <li className="features__item" key={idx}>
                        <Checkbox 
                          name={feature.id}
                          checked={newFeatures.find(f => f.id === feature.id).checked} 
                          onChange={(e) => checkboxChangeHandler(e, feature.id)} 
                          label={feature.name}
                        />
                      </li>
                    })}
                  </ul>
                  <div className="btn-wrapper">
                    <Button type="submit" text={'Soumettre votre vote'} primary medium disabled={newFeatures.filter(f => f.checked).length === 0} />
                  </div>
                </form>
                : <p className="already-voted">{`Pas de nouvelles fonctionnalités`}</p>
              :
              <p className="already-voted">{`Vous avez déjà voté`}</p>
          }
        </div>
      </div>

      {showLoader && <ResponseLoader text={`Soumettre un vote`} />}
    </Modal>
  )
}

export default VoteUpcomingFeaturesModal