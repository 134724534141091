const Close = () => {
  return (
    <span className="svg-icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="9.673" height="10.497" viewBox="0 0 9.673 10.497">
        <g id="Group_817" data-name="Group 817" transform="translate(-845.442 -176.248)">
          <g id="Group_795" data-name="Group 795" transform="translate(561 -437.245)">
            <path id="Path_1461" data-name="Path 1461" d="M0,0,8.341,9.3" transform="translate(285 614.188)" fill="#1e548a" stroke="#1e548a" strokeWidth="1.5"/>
            <path id="Path_1462" data-name="Path 1462" d="M8.472-.221-.09,9.114" transform="translate(285.09 614.221)" fill="#1e548a" stroke="#1e548a" strokeWidth="1.5"/>
          </g>
        </g>
      </svg>
    </span>
  )
}

export default Close