import { Sort } from '../../assets/icons'

import { SignaturesTableRow } from '../ui_new'

const SignaturesTable = ({ selectedSignatures, onSetSelectedSignatures, allChecked, onSetAllChecked, filteredSignatures, onSort, activeSort, onOpenStatusPanel, onTitleClick, onOpenSignatureDetail, onDownload }) => {

  // On all checkbox click
  // const handleAllChecked = () => {
  //   onSetAllChecked(!allChecked)
  //   if(allChecked) {
  //     onSetSelectedSignatures([])
  //   }else {
  //     onSetSelectedSignatures(filteredSignatures)
  //   }
  // }

  return (
    <div className="signatures-table">
      <div className="signatures-table__head">
        <div className="signatures-table-row">
          {/* <div className="signatures-table-column zero-width"> */}
            {/* <div className={`signatures-table-column__check ${allChecked ? 'checked' : ''}`} onClick={handleAllChecked}><Check /></div> */}
          {/* </div> */}
          <div className="signatures-table-column align-left stretch" onClick={() => onSort('title')}>
            <p className={`${activeSort === 'title' ? 'active' : ''}`}>Nom</p>
            <Sort />
          </div>
          <div className="signatures-table-column" onClick={() => onSort('meta.created')}>
            <p className={`${activeSort === 'meta.created' ? 'active' : ''}`}>Date d'envoi</p>
            <Sort />
          </div>
          <div className="signatures-table-column" onClick={() => onSort('sort_recipients_num')}>
            <p className={`${activeSort === 'sort_recipients_num' ? 'active' : ''}`}>Destinataires</p>
            <Sort />
          </div>
          <div className="signatures-table-column" onClick={() => onSort('sender.email')}>
            <p className={`${activeSort === 'sender.email' ? 'active' : ''}`}>Envoyé par</p>
            <Sort />
          </div>
          <div className="signatures-table-column" onClick={() => onSort('status')}>
            <p className={`${activeSort === 'status' ? 'active' : ''}`}>Statut</p>
            <Sort />
          </div>
          <div className="signatures-table-column" onClick={() => onSort('expires')}>
            <p className={`${activeSort === 'expires' ? 'active' : ''}`}>Date<br/>d'expiration</p>
            <Sort />
          </div>
          <div className="signatures-table-column">
            {/* <p>Actions</p> */}
            {/* empty header for modification & LRE */}
            <p />
          </div>
        </div>
      </div>
      <div className="signatures-table__body">
        {filteredSignatures.map((signature, idx) => {

          return (
            <SignaturesTableRow 
              key={idx} 
              id={signature.id} 
              signature={signature} 
              selectedSignatures={selectedSignatures}
              onSetSelectedSignatures={onSetSelectedSignatures} 
              allChecked={allChecked}
              onOpenStatusPanel={onOpenStatusPanel}
              onTitleClick={onTitleClick}
              onOpenSignatureDetail={onOpenSignatureDetail}
              onDownload={onDownload}
            />
          )
          })}
      </div>
    </div>
  )
}

export default SignaturesTable