const BgImage = () => {
  return (
    <span className="svg-icon">
      <svg id="Group_1181" data-name="Group 1181" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 15.561 20.39">
        <g id="Group_1183" data-name="Group 1183">
          <path id="Path_1637" data-name="Path 1637" d="M139.76,282.209h-9.3a.2.2,0,0,0-.2.2V289.7a.2.2,0,0,0,.2.2h9.3a.2.2,0,0,0,.2-.2v-7.292a.2.2,0,0,0-.2-.2m-2.511.9a1.033,1.033,0,1,1-1.033,1.033,1.033,1.033,0,0,1,1.033-1.033m1.6,5.706a.294.294,0,0,1-.3.262h-6.881a.294.294,0,0,1-.254-.455h0l2.264-3.473a.294.294,0,0,1,.484-.014h0l2.264,3.074.975-.928a.294.294,0,0,1,.444.038l.949,1.289a.3.3,0,0,1,.055.207" transform="translate(-127.328 -275.857)" />
          <path id="Path_1638" data-name="Path 1638" d="M14.217,0h-1.19V3.548l-1-.829-1.072.829V0H1.344A1.344,1.344,0,0,0,0,1.344v17.7A1.344,1.344,0,0,0,1.344,20.39H14.217a1.344,1.344,0,0,0,1.344-1.344V1.344A1.344,1.344,0,0,0,14.217,0m-.892,13.841a.893.893,0,0,1-.893.893h-9.3a.893.893,0,0,1-.893-.893V6.549a.893.893,0,0,1,.893-.893h9.3a.893.893,0,0,1,.893.893Z" />
        </g>
      </svg>
    </span>
  )
}

export default BgImage