import { useContext, useEffect, useState } from 'react'

import { Checkbox, CustomTooltip } from '../ui_new'
import { UserContext } from '../../context'
import { FEATURE, areSectionConditionsMet, isCoverPageVariable } from '../../utils'
import { SingleDocumentVariablesSection } from './'
import { useVariablesActions } from '../../hooks'
import { Duplicate, Delete } from '../../assets/icons'

const SingleDocumentVariables = ({
    documentValues,
    onValuesChange,
    useCustomCover,
    onCustomCoverChange,
    templateData,
    onAgencyModalOpen,
    onNotariesModalOpen,
    linesAddedTo,
    onRemoveLines,
    onSetLinesAddedTo,
    documentCheckboxValues,
    onCheckboxValueChange,
    isContentEditable,
    onSectionContentChanged,
    onShowUploadOrSelectButton,
    templateMetaSections
}) => {

    const { duplicatableValuesArray } = useVariablesActions({ values: documentValues })


    // Check if should show custom cover checkbox
    const shouldShowCustomCoverToggle = () => {
        if(!templateData) return false
        let hasCoverPage = templateData.hasCoverPage
        if(!hasCoverPage) {
            for (let section of templateData.sections) {
                if (section.variable) {
                    if (isCoverPageVariable(section.variable)) {
                        hasCoverPage = true
                        break
                    }
                } else if (section.variables) {
                    for (let v of section.variables) {
                        if (isCoverPageVariable(v.variable)) {
                            hasCoverPage = true
                            break
                        }
                    }
                }
                if (hasCoverPage) {
                    break
                }
            }
        }
        return hasCoverPage
    }

    const duplicateSection = (repSectionIds, repIndices) => {
        const repeatableSectionIds = [...repSectionIds]
        const repetitionIndices = repIndices ? [...repIndices] : undefined
        // console.log('duplicateSection', { repeatableSectionIds: JSON.stringify(repeatableSectionIds), repetitionIndices: JSON.stringify(repetitionIndices) })
        if(repeatableSectionIds) {
            const rootLevelRepeatableId = repeatableSectionIds.splice(0, 1)[0]
            // console.log('rootLevelRepeatableId', rootLevelRepeatableId)
            const rootLevelValue = duplicatableValuesArray([rootLevelRepeatableId], [])
            
            let val = rootLevelValue

            while(repeatableSectionIds.length > 0) {
                val = val[repetitionIndices.splice(0, 1)[0]]
                if(!val) {
                    throw new Error('Invalid repetition index')
                }
                if(!val[repeatableSectionIds[0]]) {
                    val[repeatableSectionIds[0]] = [{}]
                }
                val = val[repeatableSectionIds.splice(0, 1)[0]]
            }
            val.push({})

            // console.log("update", rootLevelRepeatableId, rootLevelValue)

            onValuesChange(rootLevelRepeatableId, [...rootLevelValue])
        }
        // let vals = documentValues[repeatableSectionId]
        // if (!vals) {
        //     vals = [{}]
        // }

        // vals.push({})
        // onValuesChange(repeatableSectionId, vals)
    }

    // Check if variables contain only customer
    const variablesContainOnlyCustomer = (variables) => {
        return (
            variables &&
            variables.length === 1 &&
            variables[0].variable === 'co_customers_id'
        )
    }

    const removeRepetition = (repSectionIds, repIndices) => {
        console.log('removeRepetition', { repSectionIds, repIndices, documentValues })
        const repeatableSectionIds = [...repSectionIds]
        const repetitionIndices = [...repIndices]
        const rootLevelRepeatableId = repeatableSectionIds[0]
        const rootLevelValue = duplicatableValuesArray([rootLevelRepeatableId], [])
        console.log('removeRepetition', { rootLevelRepeatableId, rootLevelValue })
        let val = rootLevelValue
        console.log("initial val", JSON.stringify(val))
        for(let i = 1; i < repeatableSectionIds.length; i++) {
            val = val[repetitionIndices[i - 1]]
            val = val[repeatableSectionIds[i]]
            if(!val) {
                throw new Error('Invalid repetition index')
            }
            console.log("val", JSON.stringify(val))
        }
        val.splice(repIndices[repIndices.length - 1], 1)

        onValuesChange(rootLevelRepeatableId, [...rootLevelValue])
    }

    const shouldShowDuplicationControls = (metaBlock, repetitionIndices) => {
        // console.log('shouldShowDuplicationControls', { metaBlock, repetitionIndices })
        const shouldShow = areMetaSectionConditionsMet(metaBlock, repetitionIndices)
        // console.log('shouldShowDuplicationControls', { shouldShow })
        return shouldShow
    }

    const areMetaSectionConditionsMet = (metaBlock, repetitionIndices) => {
        const valuesArray = duplicatableValuesArray(metaBlock.repeatableIds, repetitionIndices)
        // console.log('areMetaSectionConditionsMet', { metaBlock, repetitionIndices, valuesArray })
        const sectionsArray = metaBlock.sections
        for(let i = 0; i < sectionsArray.length; i++) {
            const section = sectionsArray[i]
            if(section.isMetaSection) {
                if(areMetaSectionConditionsMet(section, repetitionIndices)) {
                    return true
                } else {
                    continue
                }
            }
            for(let j = 0; j < valuesArray.length; j++) {
                const shouldRender = areSectionConditionsMet({ ...section, repeatableSectionId: metaBlock.repeatableId, repeatableIndex: j, repeatableIds: metaBlock.repeatableIds, repetitionIndices }, documentValues)
                if(shouldRender) {
                    return true
                }
            }
        }
        return false
    }

    const shouldShowDuplicateButton = (metaBlock, repetitionIndices) => {
        return shouldShowDuplicationControls(metaBlock, repetitionIndices)
    }

    const shouldShowDeleteDuplicationButton = (metaBlock, repetitionIndices) => {
        if(!repetitionIndices || repetitionIndices[repetitionIndices.length - 1] === 0) {
            return false
        }
        return shouldShowDuplicationControls(metaBlock, [...(repetitionIndices || [])])
    }

    

    return (
        <div className="single-document-variables">
            {shouldShowCustomCoverToggle() ? (
                <div className="row v-centered mb-20">
                    <Checkbox
                        checked={useCustomCover}
                        onChange={onCustomCoverChange}
                        label="Utilisez la page de couverture personnalisée"
                    />
                </div>
            ) : null}

            <div className="single-document-variables__body">
                {templateMetaSections.map((metaBlock, metaBlockIndex) => 
                    <MetaSection
                        key={`meta_block_${metaBlockIndex}`}
                        metaBlock={metaBlock}
                        metaBlockIndex={metaBlockIndex}
                        documentValues={documentValues}
                        onValuesChange={onValuesChange}
                        documentCheckboxValues={documentCheckboxValues}
                        onCheckboxValueChange={onCheckboxValueChange}
                        onAgencyModalOpen={onAgencyModalOpen}
                        onNotariesModalOpen={onNotariesModalOpen}
                        linesAddedTo={linesAddedTo}
                        onRemoveLines={onRemoveLines}
                        onSetLinesAddedTo={onSetLinesAddedTo}
                        onRemoveRepetition={removeRepetition}
                        shouldShowDuplicationControls={shouldShowDuplicationControls}
                        duplicateSection={duplicateSection}
                        duplicatableValuesArray={duplicatableValuesArray}
                        shouldShowDuplicateButton={shouldShowDuplicateButton}
                        shouldShowDeleteDuplicationButton={shouldShowDeleteDuplicationButton}
                    />
                )}
            </div>
            {templateData?.footer && (
                <div className="single-document-variables__foot">
                    <h4>
                        <span>Pied de page</span>
                    </h4>
                    <SingleDocumentVariablesSection
                        values={documentValues}
                        onValuesChange={onValuesChange}
                        documentCheckboxValues={documentCheckboxValues}
                        onCheckboxValueChange={onCheckboxValueChange}
                        sections={[templateData.footer]}
                        type={templateData.footer.type || 'text'}
                        content={templateData.footer.content}
                        items={templateData.footer.items || []}
                        question={templateData.footer.question}
                        variable={templateData.footer.variable}
                        variables={templateData.footer.variables}
                        data_type={templateData.footer.data_type}
                        options={templateData.footer.options}
                        condition={templateData.footer.condition}
                        conditions={templateData.footer.conditions}
                        tooltip={templateData.footer.tooltip}
                        locked={templateData.footer.locked}
                        index={'footer'}
                        varIndex={''}
                        repeatableIds={[]}
                        onAgencyModalOpen={onAgencyModalOpen}
                        onNotariesModalOpen={onNotariesModalOpen}
                        linesAddedTo={linesAddedTo}
                        onRemoveLines={onRemoveLines}
                        onSetLinesAddedTo={onSetLinesAddedTo}
                    />
                </div>
            )}
        </div>
    )
}

const MetaSection = ({
    metaBlock,
    metaBlockIndex,
    documentValues,
    onValuesChange,
    documentCheckboxValues,
    onCheckboxValueChange,
    onAgencyModalOpen,
    onNotariesModalOpen,
    linesAddedTo,
    onRemoveLines,
    onSetLinesAddedTo,
    isContentEditable,
    onSectionContentChanged,
    onShowUploadOrSelectButton,
    onRemoveRepetition,
    isSectionEditable = () => false,
    duplicateSection,
    duplicatableValuesArray,
    repetitionIndices,
    shouldShowDuplicateButton,
    shouldShowDeleteDuplicationButton
}) => {
    if(metaBlock.repeatableIds.length === 0) {
        return metaBlock.sections.map((section, sectionIndex) => {
            const sectionKey = section.idx || `section-${section.index || sectionIndex}`
            if(section.isMetaSection) {
                return (
                    <MetaSection
                        key={sectionKey}
                        metaBlock={section}
                        metaBlockIndex={sectionIndex}
                        documentValues={documentValues}
                        onValuesChange={onValuesChange}
                        documentCheckboxValues={documentCheckboxValues}
                        onCheckboxValueChange={onCheckboxValueChange}
                        onAgencyModalOpen={onAgencyModalOpen}
                        onNotariesModalOpen={onNotariesModalOpen}
                        linesAddedTo={linesAddedTo}
                        onRemoveLines={onRemoveLines}
                        onSetLinesAddedTo={onSetLinesAddedTo}
                        isContentEditable={isContentEditable}
                        onSectionContentChanged={onSectionContentChanged}
                        onShowUploadOrSelectButton={onShowUploadOrSelectButton}
                        onRemoveRepetition={onRemoveRepetition}
                        isSectionEditable={isSectionEditable}
                        duplicateSection={duplicateSection}
                        duplicatableValuesArray={duplicatableValuesArray}
                        repetitionIndices={repetitionIndices}
                        shouldShowDuplicateButton={shouldShowDuplicateButton}
                        shouldShowDeleteDuplicationButton={shouldShowDeleteDuplicationButton}
                    />
                )
            }
            return (
                <SingleDocumentVariablesSection
                    key={sectionKey}
                    values={documentValues}
                    onValuesChange={onValuesChange}
                    documentCheckboxValues={documentCheckboxValues}
                    onCheckboxValueChange={onCheckboxValueChange}
                    sections={section.sections}
                    type={section.type}
                    content={section.content}
                    items={section.items}
                    question={section.question}
                    variable={section.variable}
                    variables={section.variables}
                    data_type={section.data_type}
                    options={section.options}
                    condition={section.condition}
                    conditions={section.conditions}
                    tooltip={section.tooltip}
                    index={section.index}
                    varIndex={section.idx}
                    head={section.head}
                    row={section.row}
                    repeatableRowId={section.repeatableRowId}
                    rows={section.rows}
                    locked={section.locked}
                    onAgencyModalOpen={onAgencyModalOpen}
                    onNotariesModalOpen={onNotariesModalOpen}
                    linesAddedTo={linesAddedTo}
                    onRemoveLines={onRemoveLines}
                    onSetLinesAddedTo={onSetLinesAddedTo}
                    rowsLocked={section.rowsLocked}
                    isContentEditable={isSectionEditable(section, isContentEditable)}
                    onSectionContentChanged={onSectionContentChanged}
                    onShowUploadOrSelectButton={onShowUploadOrSelectButton}
                    sectionIdx={section.idx}
                />
            )
        })
    } else {
        return (
            <div key={`repeatable_block_${metaBlockIndex}`} className='position-relative'>
                {shouldShowDuplicateButton(metaBlock, repetitionIndices) && <span
                    className="u-cursor--pointer"
                    onClick={() => duplicateSection(metaBlock.repeatableIds, repetitionIndices)}
                >
                    <CustomTooltip content="Dupliquer">
                        <button className="duplicate-button btn button--danger btn--with-icon" ><Duplicate /></button>
                    </CustomTooltip>
                    {/* {variablesContainOnlyCustomer()
                        ? 'Ajouter un contact'
                        : <button class="btn">Dupliquer</button>} */}
                </span>}
                {duplicatableValuesArray(metaBlock.repeatableIds, repetitionIndices).map((item, repetitionIndex) => {
                    return (
                        <div key={`repeatable_block_${metaBlockIndex}_${repetitionIndex}`}
                            className={`position-relative ${shouldShowDuplicateButton(metaBlock, repetitionIndices) ? (repetitionIndex === 0 ? 'duplicate-border' : 'delete-border') : 'no-border'}`}>
                            {metaBlock.sections.map((section, sectionIndex) => {
                                const sectionKey = section.idx || `section-${section.index || sectionIndex}`
                                if(section.isMetaSection) {
                                    return (
                                        <MetaSection
                                            key={sectionKey}
                                            metaBlock={section}
                                            metaBlockIndex={sectionIndex}
                                            documentValues={documentValues}
                                            onValuesChange={onValuesChange}
                                            documentCheckboxValues={documentCheckboxValues}
                                            onCheckboxValueChange={onCheckboxValueChange}
                                            onAgencyModalOpen={onAgencyModalOpen}
                                            onNotariesModalOpen={onNotariesModalOpen}
                                            linesAddedTo={linesAddedTo}
                                            onRemoveLines={onRemoveLines}
                                            onSetLinesAddedTo={onSetLinesAddedTo}
                                            isContentEditable={isContentEditable}
                                            onSectionContentChanged={onSectionContentChanged}
                                            onShowUploadOrSelectButton={onShowUploadOrSelectButton}
                                            onRemoveRepetition={onRemoveRepetition}
                                            isSectionEditable={isSectionEditable}
                                            duplicateSection={duplicateSection}
                                            duplicatableValuesArray={duplicatableValuesArray}
                                            repetitionIndices={[...(repetitionIndices || []), repetitionIndex]}
                                            shouldShowDuplicateButton={shouldShowDuplicateButton}
                                            shouldShowDeleteDuplicationButton={shouldShowDeleteDuplicationButton}
                                        />
                                    )
                                }
                                return (
                                        <SingleDocumentVariablesSection
                                            key={sectionKey}
                                            values={documentValues}
                                            onValuesChange={onValuesChange}
                                            documentCheckboxValues={documentCheckboxValues}
                                            onCheckboxValueChange={onCheckboxValueChange}
                                            sections={section.sections}
                                            type={section.type}
                                            content={section.content}
                                            items={section.items}
                                            question={section.question}
                                            variable={section.variable}
                                            variables={section.variables}
                                            data_type={section.data_type}
                                            options={section.options}
                                            condition={section.condition}
                                            conditions={section.conditions}
                                            tooltip={section.tooltip}
                                            index={section.index}
                                            varIndex={section.idx}
                                            repeatableIds={metaBlock.repeatableIds}
                                            repetitionIndices={[...(repetitionIndices || []), repetitionIndex]}
                                            head={section.head}
                                            row={section.row}
                                            repeatableRowId={section.repeatableRowId}
                                            rows={section.rows}
                                            locked={section.locked}
                                            onAgencyModalOpen={onAgencyModalOpen}
                                            onNotariesModalOpen={onNotariesModalOpen}
                                            linesAddedTo={linesAddedTo}
                                            onRemoveLines={onRemoveLines}
                                            onSetLinesAddedTo={onSetLinesAddedTo}
                                            rowsLocked={section.rowsLocked}
                                            isContentEditable={isSectionEditable(section, isContentEditable)}
                                            onSectionContentChanged={onSectionContentChanged}
                                            sectionIdx={section.idx}
                                        />
                                )
                            })}

                            {shouldShowDeleteDuplicationButton(metaBlock, [...(repetitionIndices || []), repetitionIndex]) && (
                                    <span className="u-cursor--pointer" onClick={() => onRemoveRepetition(metaBlock.repeatableIds, [...(repetitionIndices || []), repetitionIndex])}>
                                        <CustomTooltip content="Supprimer">
                                            <button className="delete-button btn button--danger btn--with-icon"><Delete /></button>
                                        </CustomTooltip>
                                    </span>
                                )}
                        </div>
                    )
                })}
            </div>
        )
    }
}

export default SingleDocumentVariables
