import React, { useState, useContext, useEffect } from 'react' 
import { useParams, Link } from 'react-router-dom'
import draftToHtml from 'draftjs-to-html'
import moment from 'moment'
import { ChevronLeft } from '@material-ui/icons'
import Loader from '../../UI/Loader'
import SupportPageLayout from '../../layouts/SupportPageLayout'
import { HelpContext } from '../../../context'
import { MainLayout } from '../../../layouts'

const WhatsNewArticle = ({v2}) => {
  const { articles, articlesFetched } = useContext(HelpContext)
  const { id } = useParams()
  const [article, setArticle] = useState({})
  const [articleDoesNotExist, setArticleDoesNotExist] = useState(false)

  // Set article from id
  useEffect(() => {
    if(articlesFetched) {
      if(articles[id] && articles[id].category === 'whats_new') {
        setArticle(articles[id])
      }else {
        setArticleDoesNotExist(true)
      }
    }
  }, [id, articles, articlesFetched])

  return (
    <MainLayout className="page-help" pageTitle="Nouveautés">
      <SupportPageLayout title={`Nouveautés`} v2={v2}>
        {!articlesFetched 
          ? <div className="loader-wrapper loader-wrapper--2"><Loader normal small primary /></div> 
          : Object.keys(article).length > 0 && 
          <div className="help-article">
            <div className="help-article__head">
              <div className="link-wrapper">
                <Link to="/what-is-new"><ChevronLeft />{`Retourner`}</Link>
              </div>
              <h2>{article.title?.en}</h2>
              <p>{moment(article.date).format('DD MMM YYYY @ HH:mm')}</p>
            </div>
            <div className="help-article__body">
              <div dangerouslySetInnerHTML={{ __html: draftToHtml(article.content.en) }}></div>
            </div>
          </div>
        }
        {articleDoesNotExist &&
          <div className="support-page__no-data">
            <p>{`L'article avec l'id(${id}) n'existe pas.`}</p>
          </div>
        }
      </SupportPageLayout>
    </MainLayout>
  )
}

export default WhatsNewArticle