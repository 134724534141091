import { useState, useEffect, useContext, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import { DocumentsContext, UserContext } from '../../context'
import { dateDisplayFormat, isFeatureAuthorized } from '../../utils'
import CustomTooltip from './CustomTooltip'
import { useSingleDocumentActions } from '../../hooks'
import { Folder, Eye, Plus, Signature, InfoCircle, CalendarAlt } from '../../assets/icons';
import { AdminFeatureWrapper } from '../utils/AdminFeatureWrapper'
import ActionsDropdown from '../UI/ActionsDropdown'
import { canUseTemplateCategories, urlSuffixForEnvironment } from '../../utils'
import { AccountTree, DeleteForever, Edit, MoreHoriz, Publish, Share, Visibility, VisibilityOff } from '@material-ui/icons'
import config from '../../config.json'
import SectionsPopup from '../sections/SectionsPopup'
import SignaturesPopup from '../sections/SignaturesPopup'


const TemplatesTableRow = ({ id, template, onPreview, onCreateDocument, standard, onTemplateEdit, onTemplateDelete, onTemplateToggleHidden, onInfo, onHistory }) => {
  const { partner, user, userClaims } = useContext(UserContext)
  const [createdAt, setCreatedAt] = useState('')
  const [modifiedAt, setModifiedAt] = useState('')
  const [showDropdown, setShowDropdown] = useState(false);
  const [showSectionsPopup, setShowSectionsPopup] = useState(false);
  const [showSignaturesPopup, setShowSignaturesPopup] = useState(false);
  const sectionsPopupEl = useRef();
  const signaturesPopupEl = useRef();


  // Set metadata
  useEffect(() => {
    if (template?.meta?.created) {
      setCreatedAt(moment(template.meta.created).format(dateDisplayFormat))
    } else if (template.createdAt) {
      setCreatedAt(moment(template.createdAt).format(dateDisplayFormat))
    }
    if (template?.meta?.updated) {
      setModifiedAt(moment(template.meta.updated).format(dateDisplayFormat))
    } else if (template.updatedAt) {
      setModifiedAt(moment(template.updatedAt).format(dateDisplayFormat))
    }
  }, [template])


  // On preview modal open
  const handleOpenPreviewModal = () => {
    onPreview(template)
  }
  // On create document
  const handleCreateDocument = () => {
    onCreateDocument(template)
  }

  // On info modal open
  const handleOpenInfoModal = () => {
    onInfo(template)
  }

  const handleOpenHistoryModal = () => {
    onHistory(template)
  }

  const shareTemplateClickHandler = (tmpl) => {
    window.open(`https://jurisur-document${urlSuffixForEnvironment(config.environment).replace('_', '-')}.web.app/document/${tmpl.id}?token=${user.clientAccessToken}`, '_blank');
  }

  const singleTemplateDownload = (tmpl) => {
    const a = document.createElement("a");
    a.setAttribute('download', tmpl.name);
    a.setAttribute('href', `${tmpl.templateUrl}`);
    document.body.appendChild(a);
    a.click();
  }

  // drag and drop template to folder
  const handleDragStart = (e) => {
    e.dataTransfer.setData('template', JSON.stringify(template));
    e.dataTransfer.setData('view', 'templates');
  };

  return (
    <div className={`documents-table-row no-cursor`}
      draggable={isFeatureAuthorized({ userClaims, rule: "any_admin" })}
      onDragStart={handleDragStart}
      style={{ cursor: isFeatureAuthorized({ userClaims, rule: "any_admin" }) ? 'move' : 'default' }}
    >
      <div className="documents-table-column documents-table-column__name" style={{ width: '160px', justifyContent: 'flex-start' }}>
        <CustomTooltip content={template.name} position='top'>
          <p className='doc_name cursor-pointer' onClick={handleCreateDocument}> &nbsp;{template.name}{template.hidden && <VisibilityOff style={{ fontSize: 14, marginLeft: 5, marginBottom: -3 }} />}</p>
        </CustomTooltip>
      </div>
      <div className="documents-table-column">
        {!standard && <CustomTooltip content="Consulter les détails relatif à ce modèle"><button className="icon-btn icon-btn--white icon-btn--svg-md" onClick={handleOpenInfoModal}><InfoCircle /></button></CustomTooltip>}
        {!standard && <CustomTooltip content="Créer un document"><button className="icon-btn icon-btn--white icon-btn--svg-md" onClick={handleCreateDocument}><Plus /></button></CustomTooltip>}
        <AdminFeatureWrapper rule="any_admin">
          <ActionsDropdown
            trigger={<button className="icon-btn icon-btn--white icon-btn--svg-md" onClick={() => setShowDropdown(!showDropdown)}><span className="svg-icon"><MoreHoriz /></span></button>}
            hideHeader
            width={120}
            dropdownClass="actions-dropdown--no-padding"
          >
            <ul className="dashboard-column__dropdown">
              {!standard && partner.includes('mls') && <li onClick={() => shareTemplateClickHandler(template)} className="edit"><span className="icon"><Share /></span>Partager</li>}
              {!standard && <li onClick={() => singleTemplateDownload(template)}><span className="icon rotate-180"><Publish /></span>Télécharger</li>}
              <li onClick={() => onTemplateEdit(template)} className="edit"><span className="icon"><Edit /></span>Rennomer</li>
              {!standard && canUseTemplateCategories(partner) &&
                <li>
                  <ActionsDropdown
                    trigger={<div className="row"><span className="icon"><AccountTree /></span>Catégories</div>}
                    onTriggerClick={() => setShowSectionsPopup(true)}
                    hideHeader
                    dropdownClass="actions-dropdown--no-padding folders-popup-dropdown"
                    width={253}
                    onClose={() => setShowSectionsPopup(false)}
                    hide={!showSectionsPopup}
                  >
                    <SectionsPopup
                      ref={sectionsPopupEl}
                      onCancel={() => setShowSectionsPopup(false)}
                      resource={template}
                    />
                  </ActionsDropdown>
                </li>}
              {!standard && <li onClick={() => onTemplateToggleHidden(template)}><span className="icon">{template.hidden ? <Visibility /> : <VisibilityOff />}</span>{template.hidden ? 'Rendre visible' : 'Masquer'}</li>}
              {!standard && <li>
                <ActionsDropdown
                  trigger={<div className="row"><span className="icon template-box"><Signature /></span>{'Paramètres signature électronique'}</div>}
                  onTriggerClick={() => setShowSignaturesPopup(true)}
                  hideHeader
                  dropdownClass="actions-dropdown--no-padding folders-popup-dropdown"
                  width={300}
                  onClose={() => setShowSignaturesPopup(false)}
                  hide={!showSignaturesPopup}
                >
                  <SignaturesPopup
                    ref={signaturesPopupEl}
                    onCancel={() => setShowSignaturesPopup(false)}
                    resource={template}
                  />
                </ActionsDropdown>
              </li>}
              {!standard && <li onClick={() => onTemplateToggleHidden(template)}></li>}
              <li onClick={() => onTemplateDelete(template)}><span className="icon"><DeleteForever /></span>Supprimer</li>
            </ul>
          </ActionsDropdown>
        </AdminFeatureWrapper>
      </div>
      <div className="documents-table-column documents-table-column__date">
        <p>{createdAt}</p>
      </div>
      <div className="documents-table-column documents-table-column__date">
        <p>{modifiedAt}</p>
      </div>
     <div className="documents-table-column documents-table-column__history">
        {(template.versionNotes && template.versionNotes.length > 0 && !standard) && <CustomTooltip content="Historique"><button className="icon-btn icon-btn--white icon-btn--svg-md" onClick={handleOpenHistoryModal}><CalendarAlt /></button></CustomTooltip>}
      </div>
      
      <div className="documents-table-column-mobile">
        <div className="date">{createdAt}</div>
        {template.folder && <div className="folder"><Folder /> {template.folder}</div>}
      </div>
    </div>
  )
}

export default TemplatesTableRow