import { useState, useEffect, useContext, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import { DocumentsContext, UserContext } from '../../context'
import { dateDisplayFormat } from '../../utils'
import CustomTooltip from './CustomTooltip'
import { useSingleDocumentActions } from '../../hooks'
import { Folder, Eye, Plus, Signature, InfoCircle, CalendarAlt } from '../../assets/icons';
import { AdminFeatureWrapper } from '../utils/AdminFeatureWrapper'
import ActionsDropdown from '../UI/ActionsDropdown'
import { canUseTemplateCategories, urlSuffixForEnvironment } from '../../utils'
import { AccountTree, DeleteForever, Edit, MoreHoriz, Publish, Share, Visibility, VisibilityOff } from '@material-ui/icons'
import config from '../../config.json'
import SectionsPopup from '../sections/SectionsPopup'
import SignaturesPopup from '../sections/SignaturesPopup'


const StandardTemplatesTableRow = ({ template, onPreview, onCreateDocument, onTemplateDelete, onTemplateEdit, standard, onOpenStandardTemplate, onInfo, onHistory }) => {
  const { partner, user } = useContext(UserContext)
  const { templates } = useContext(DocumentsContext)
  const [createdAt, setCreatedAt] = useState('')
  const [modifiedAt, setModifiedAt] = useState('')
  const [showDropdown, setShowDropdown] = useState(false);
  const [showSectionsPopup, setShowSectionsPopup] = useState(false);
  const [showSignaturesPopup, setShowSignaturesPopup] = useState(false);
  const sectionsPopupEl = useRef();
  const signaturesPopupEl = useRef();


  // Set metadata
  useEffect(() => {
    if (template?.meta?.created) {
      setCreatedAt(moment(template.meta.created).format(dateDisplayFormat))
    } else if (template.createdAt) {
      setCreatedAt(moment(template.createdAt).format(dateDisplayFormat))
    }
    if (template?.meta?.updated) {
      setModifiedAt(moment(template.meta.updated).format(dateDisplayFormat))
    } else if (template.updatedAt) {
      setModifiedAt(moment(template.updatedAt).format(dateDisplayFormat))
    }
  }, [template])


  // On preview modal open
  const handleOpenPreviewModal = () => {
    onPreview(template)
  }
  // On create document
  const handleCreateDocument = () => {
    onCreateDocument(template)
  }

  // On info modal open
  const handleOpenInfoModal = () => {
    onInfo(template)
  }

  const handleOpenHistoryModal = () => {
    onHistory(template)
  }

  const shareTemplateClickHandler = (tmpl) => {
    window.open(`https://jurisur-document${urlSuffixForEnvironment(config.environment).replace('_', '-')}.web.app/document/${tmpl.id}?token=${user.clientAccessToken}`, '_blank');
  }

  const singleTemplateDownload = (tmpl) => {
    const a = document.createElement("a");
    a.setAttribute('download', tmpl.name);
    a.setAttribute('href', `${tmpl.templateUrl}`);
    document.body.appendChild(a);
    a.click();
  }

  const baseTemplate = templates?.[template?.template]

   // drag and drop template to folder
   const handleDragStart = (e) => {
    e.dataTransfer.setData('template', JSON.stringify(template));
    e.dataTransfer.setData('view', 'standard-templates');
  };

  return (
    <div className={`documents-table-row no-cursor`}
      draggable
      onDragStart={handleDragStart}
      style={{ cursor: 'move' }}
    >
      <div className="documents-table-column documents-table-column__name" style={{ width: '160px', justifyContent: 'flex-start' }}>
        <CustomTooltip content={template.name} position='top'>
          <p className='doc_name cursor-pointer' onClick={handleCreateDocument}> &nbsp;{template.name}</p>
        </CustomTooltip>
      </div>
      <div className="documents-table-column">
        <CustomTooltip content="Consulter les détails relatif à ce modèle type"><button className="icon-btn icon-btn--white icon-btn--svg-md" onClick={handleOpenInfoModal}><InfoCircle /></button></CustomTooltip>
        <CustomTooltip content="Créer un document"><button className="icon-btn icon-btn--white icon-btn--svg-md" onClick={handleCreateDocument}><Plus /></button></CustomTooltip>
        {<CustomTooltip content="Voir le modèle"><button className="icon-btn icon-btn--white icon-btn--svg-md" onClick={handleOpenPreviewModal}><Eye /></button></CustomTooltip>}
        <AdminFeatureWrapper rule="any_admin">
          <ActionsDropdown
            trigger={<button className="icon-btn icon-btn--white icon-btn--svg-md" onClick={() => setShowDropdown(!showDropdown)}><span className="svg-icon"><MoreHoriz /></span></button>}
            hideHeader
            width={120}
            dropdownClass="actions-dropdown--no-padding"
          >
            <ul className="dashboard-column__dropdown">
              <li onClick={() => onTemplateEdit(template)} className="edit"><span className="icon"><Edit /></span>Rennomer</li>
              <li onClick={() => onTemplateDelete(template)}><span className="icon"><DeleteForever /></span>Supprimer</li>
            </ul>
          </ActionsDropdown>
        </AdminFeatureWrapper>
      </div>
      <div className="documents-table-column documents-table-column__date">
        <p>{createdAt}</p>
      </div>
      <div className="documents-table-column documents-table-column__date">
        <p>{modifiedAt}</p>
      </div>
     <div className="documents-table-column documents-table-column__history">
        {(baseTemplate?.versionNotes && baseTemplate?.versionNotes.length > 0) && <CustomTooltip content="Historique"><button className="icon-btn icon-btn--white icon-btn--svg-md" onClick={handleOpenHistoryModal}><CalendarAlt /></button></CustomTooltip>}
      </div>
      
      <div className="documents-table-column-mobile">
        <div className="date">{createdAt}</div>
        {template.folder && <div className="folder"><Folder /> {template.folder}</div>}
      </div>
    </div>
  )
}

export default StandardTemplatesTableRow