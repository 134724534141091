import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context";
import React from 'react'
import { EditorState, ContentState, convertFromHTML, convertToRaw } from "draft-js";
import { Message } from "@material-ui/icons";
import draftToHtml from "draftjs-to-html";
import { adminProperty, manufacturerProperty } from "../../../utils";

const MailModalContentMessage = ({ message, setMessage, onReturn, onSubmit }) => {

  const { user, agency } = useContext(UserContext)

  const messageTemplate = (agencyName, agencyAddress, agencyZip, agencyCity) => {
    return `
        <p>
            <p> Bonjour,</p>
            <br/>
            <p>Veuillez trouver en pièce-jointe les documents.<br/>Bonne réception.</p>
            <br/>
            <p>Cordialement,<br/><b>${user.firstname} ${user.lastname.toUpperCase()}</b><br/>${adminProperty(user, 'email')} / ${adminProperty(user, 'phone')}</p>
            <br/>
            <p>${agencyName || ""}<br/>${agencyAddress || '&lt;Adresse de l’agence&gt;'} ${agencyZip || '&lt;Code postal de l’agence&gt;'} ${agencyCity || '&lt;Ville de l’agence&gt;'}
            </p>           
        </p>
    `
  }

  const blocksFromHTML = convertFromHTML(messageTemplate(manufacturerProperty(user?.manufacturer, 'name'), manufacturerProperty(user?.manufacturer, 'address'), manufacturerProperty(user?.manufacturer, 'zip'), manufacturerProperty(user?.manufacturer, 'city')))
  const [editorState, setEditorState] = useState(EditorState.createWithContent(
    ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    )
  ));

  useEffect(() => {
    if (!message) {
      return
    }
    const blocksFromHTML = convertFromHTML(message)
    setEditorState(EditorState.createWithContent(
      ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      )
    ))
  }, [message])

  useEffect(() => {
    console.log({ user, agency })
  }, [user, agency])

  const handleChangeEditorState = (state) => {
    setEditorState(state)
  }

  const handleContinue = () => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const messageHtml = draftToHtml(rawContentState)
    setMessage(messageHtml)
    onSubmit()
  }

  const handleReturn = () => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const messageHtml = draftToHtml(rawContentState)
    setMessage(messageHtml)
    onReturn()
  }


  return (
    <div className='registered-mail-send-modal-message-container'>
      <div className='flex flex-row modal-section-title mb-20'>
        <span className="modal-section-title--icon">
          <Message />
        </span>
        Transmettre le message suivant aux destinataires
      </div>
      <Editor
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={handleChangeEditorState}
        toolbar={{
          options: [
            "history",
            "inline",
            "blockType",
            "fontSize",
            "fontFamily",
            "list",
            "textAlign"
          ],
          history: {
            inDropdown: false,
            options: ["undo", "redo"]
          },
          inline: {
            inDropdown: false,
            options: [
              "bold",
              "italic",
              "underline",
              "strikethrough",
              "monospace",
            ]
          },
          fontFamily: {
            options: [
              "Arial",
              "Georgia",
              "Impact",
              "Tahoma",
              "Times New Roman",
              "Verdana"
            ]
          },
          textAlign: {
            inDropdown: false,
            options: ["left", "center", "right", "justify"]
          },
          blockType: {
            inDropdown: true,
            options: [
              "Normal",
              "H1",
              "H2",
              "H3",
              "H4",
              "H5",
              "H6",
              "Blockquote",
              "Code"
            ]
          },
          list: {
            inDropdown: false,
            options: ["unordered", "ordered", "indent", "outdent"]
          }
        }}
      />

      <div className='modal-btn-box mt-20'>
        <button
          className='btn btn--large btn--transparent btn--border-primary'
          onClick={handleReturn}
        >
          Précédent
        </button>
        <button
          className='btn btn--large btn--primary'
          onClick={handleContinue}
        >
          Suivant
        </button>
      </div>
    </div>
  )
}

export default MailModalContentMessage