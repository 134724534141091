import axios from 'axios'
import { urlSuffixForEnvironment } from '../utils'
import firebase from './firebase'
import config from '../config.json'
import { FUNCTIONS_BASE_URL, FUNCTIONS_BASE_URL_LOCAL } from '../constants'

const functionsBaseUrl = config.useLocalApi ? FUNCTIONS_BASE_URL_LOCAL : FUNCTIONS_BASE_URL

const baseUrl = `${functionsBaseUrl}/api${urlSuffixForEnvironment(config.environment)}`


const fetch_templates = async (exclude = null) => {
  return await get_request(`${baseUrl}/templates`, null, `exclude=${exclude}&thumbnail=true`)
}

const fetch_single_template = async (id) => {
  const templateData = await get_request(`${baseUrl}/templates/${id}`)
  if(!templateData) {
    return null
  }
  if(templateData.mongo_id) {
    const templateDataFromMongo = await get_request(`${baseUrl}/mongodb_templates/${templateData.mongo_id}`)
    if(!templateDataFromMongo) {
      return templateData
    }
    return {
      ...templateData,
      sections: templateDataFromMongo.sections,
      footer: templateDataFromMongo.footer,
    }
  }
  return templateData
}

const fetch_documents = async (exclude = null, oldestDocs) => {
  let params = ''
  let paramsArray = []
  if(exclude) {
    paramsArray.push(`exclude=${exclude}`)
  }
  if(oldestDocs) {
    paramsArray.push(`oldestDocs=${oldestDocs}`)
  }
  if(paramsArray.length > 0) {
    params = paramsArray.join('&')
  }
  return await get_request(`${baseUrl}/documents`, null, params)
}

const fetch_single_document = async (id) => {
  return await get_request(`${baseUrl}/documents/${id}`)
}

const fetch_folders_order = async () => {
  return await get_request(`${baseUrl}/folders_order`)
}

const update_folders_order = async (type, data) => {
  return await patch_request(`${baseUrl}/folders_order/${type}`, data)
}

const fetch_folders = async () => {
  return await get_request(`${baseUrl}/folders`)
}

const fetch_documents_folders = async () => {
  return await get_request(`${baseUrl}/documents_folders`)
}

const fetch_document_history = async (documentId) => {
  return await get_request(`${baseUrl}/document_history/${documentId}`)
}

const fetch_document_data = async (dataId) => {
  return await get_request(`${baseUrl}/document_data/${dataId}`)
}

const fetch_actions = async () => {
  return await get_request(`${baseUrl}/actions`)
}

const get_site_configs = async (token) => {
  return await get_request(`${baseUrl}/get_site_configs`, token)
}

const fetch_tag_matchings = async () => {
  return await get_request(`${baseUrl}/tag_matchings`)
}

const fetch_sections = async () => {
  return await get_request(`${baseUrl}/sections`)
}

const fetch_signature_templates = async () => {
  return await get_request(`${baseUrl}/signature_templates`)
}

const fetch_email_user = async () => {
  return await get_request(`${baseUrl}/email_user`)
}

const create_document = async (documentData, attachments) => {
  documentData.meta = {
    created: Date.now(),
    updated: Date.now()
  }
  documentData.archived = false;
  documentData.deleted = false;
  let atts = await process_attachments(attachments)
  documentData.attachments = atts
  return await post_request(`${baseUrl}/document`, documentData)
}

const update_document = async (documentId, documentData, attachments) => {
  documentData[`meta.updated`] = Date.now()
  let atts = await process_attachments(attachments)
  documentData.attachments = atts
  return await patch_request(`${baseUrl}/document/${documentId}`, documentData)
}

const add_document_event = async (documentId, eventData) => {
  return await post_request(`${baseUrl}/document/${documentId}/events`, eventData)
}

const fetch_document_events = async (documentId) => {
  return await get_request(`${baseUrl}/document/${documentId}/events`)
}

const process_attachments = async (attachments) => {
  let atts = []
  if(attachments) {
    for(let i in attachments) {
      let att = {
        name: attachments[i].name,
        format: attachments[i].format,
        type: attachments[i].type,
        share: attachments[i].share || false
      }
      if(attachments[i].size) {
        att.size = attachments[i].size
      }
      if(attachments[i].data) {
        let uploadResult = await upload_file(attachments[i].data, attachments[i].name, attachments[i].format, attachments[i].type, 'attachments')
        if(!uploadResult.url) {
          continue
        }
        att.url = uploadResult.url
      } else if(attachments[i].url) {
        att.url = attachments[i].url
      }
      atts.push(att)
    }
  }
  return atts
}

const delete_document = async (documentId) => {
  return await delete_request(`${baseUrl}/document/${documentId}`)
}

const update_template = async (data, templateId) => {
  // return await patch_request(`${baseUrl}/template/${templateId}`, data);
  return await patch_request(`${baseUrl}/template/${templateId}`, data);
}

const delete_template = async (templateId) => {
  return await delete_request(`${baseUrl}/template/${templateId}`)
}

const create_folder = async (folderData) => {
  folderData.meta = {
    created: Date.now(),
    updated: Date.now()
  }
  return await post_request(`${baseUrl}/folders`, folderData)
}

const create_documents_folder = async (folderData) => {
  folderData.meta = {
    created: Date.now(),
    updated: Date.now()
  }
  return await post_request(`${baseUrl}/documents_folders`, folderData)
}

const create_section = async (data) => {
  data.meta = {
    created: Date.now(),
    updated: Date.now()
  }
  return await post_request(`${baseUrl}/sections`, data)
}

const create_signature_template = async (data) => {
  data.meta = {
    created: Date.now(),
    updated: Date.now()
  }
  return await post_request(`${baseUrl}/signature_templates`, data)
}

const update_folder = async (folderData, folderId) => {
  folderData[`meta.updated`] = Date.now();
  return await patch_request(`${baseUrl}/folders/${folderId}`, folderData);
}

const update_documents_folder = async (folderData, folderId) => {
  folderData[`meta.updated`] = Date.now();
  return await patch_request(`${baseUrl}/documents_folders/${folderId}`, folderData);
}

const update_section = async (data, id) => {
  data[`meta.updated`] = Date.now();
  return await patch_request(`${baseUrl}/sections/${id}`, data);
}

const update_signature_template = async (data, id) => {
  data[`meta.updated`] = Date.now();
  return await patch_request(`${baseUrl}/signature_templates/${id}`, data);
}

const delete_folder = async (folderId) => {
  return await delete_request(`${baseUrl}/folders/${folderId}`);
}

const delete_documents_folder = async (folderId) => {
  return await delete_request(`${baseUrl}/documents_folders/${folderId}`);
}

const delete_section = async (id) => {
  return await delete_request(`${baseUrl}/sections/${id}`);
}

const delete_signature_template = async (id) => {
  return await delete_request(`${baseUrl}/signature_templates/${id}`);
}

const create_signature = async (data) => {
  return await post_request(`${baseUrl}/signatures`, data);
}

const fetch_signatures = async (oldestSignatures) => {
  const params = oldestSignatures ? `oldestSignatures=${oldestSignatures}` : ''
  return await get_request(`${baseUrl}/signatures`, null, params)
}

const get_signature_status = async (data) => {
  return await post_request(`${baseUrl}/signature_status`, data);
}

const get_signature_document = async (data) => {
  return await post_request(`${baseUrl}/signature_document`, data);
}

const get_signatures_credits = async () => {
  return await get_request(`${baseUrl}/onespan_signatures_credits`);
}

const delete_signature = async (id) => {
  return await delete_request(`${baseUrl}/signatures/${id}`);
}

const update_signature = async (id, data) => {
  return await patch_request(`${baseUrl}/signatures/${id}`, data);
}

const place_signature = async (data) => {
  return await post_request(`${baseUrl}/place_signature`, data);
}

const get_signature_placement_interface = async (fileId) => {
  return await get_request(`${baseUrl}/signature_placement_interface/${fileId}`);
}

const get_signature_position = async (fileId) => {
  return await get_request(`${baseUrl}/signature_position/${fileId}`);
}

const push_to_immocloud = async (fileBase64, file_name, site_id, identification_handle, id) => {
  return await post_request(`${functionsBaseUrl}/push_to_immocloud${urlSuffixForEnvironment(config.environment)}`, { fileBase64, file_name, site_id, [identification_handle]: id })
}

const fetch_agency = async (agencyId) => {
  // console.log('fetch agency', `${baseUrl}/agency/${agencyId}`)
  return await get_request(`${baseUrl}/agency/${agencyId}`)
}

const update_agency = async (agencyId, data) => {
  return await post_request(`${baseUrl}/agency/${agencyId}`, data)
}

const fetch_site_manufacturers = async (token) => {
  return await get_request(`${baseUrl}/site_manufacturers`, token)
}

const fetch_ar24_sender = async (token) => {
  return await get_request(`${baseUrl}/ar24/sender`, token)
}

const create_ar24_sender = async (data) => {
  return await post_request(`${baseUrl}/ar24/sender`, data)
}

const associate_ar24_sender = async (data) => {
  return await post_request(`${baseUrl}/ar24/sender/associate`, data)
}

const update_ar24_sender = async (userId, data) => {
  return await post_request(`${baseUrl}/ar24/sender/${userId}`, data)
}

const delete_ar24_sender = async (userId) => {
  return await delete_request(`${baseUrl}/ar24/sender/${userId}`)
}

const request_ar24_sender_verification = async (userId, data) => {
  return await post_request(`${baseUrl}/ar24/sender/${userId}/request_verification`, data)
}

const verify_otp_key = async (userId, key) => {
  return await post_request(`${baseUrl}/ar24/sender/${userId}/verify_otp`, { key })
}

const send_ar24_mail = async (data) => {
  return await post_request(`${baseUrl}/ar24/mail`, data)
}

const fetch_ar24_mail = async (token) => {
  return await get_request(`${baseUrl}/ar24/mail`, token)
}

const fetch_ar24_single_mail = async (mailId) => {
  return await get_request(`${baseUrl}/ar24/mail/${mailId}`)
}

const upload_ar24_attachment = async (data) => {
  return await post_request(`${baseUrl}/ar24/attachment`, data)
}

const resend_ar24_sender_confirmation = async (userId) => {
  return await post_request(`${baseUrl}/ar24/sender/${userId}/resend_confirmation`, {})
}

// const resend_ar24_sender_invitation = async (userId) => {
//   return await post_request(`${baseUrl}/ar24/sender/${userId}/resend_invitation`, {})
// }

const renew_ar24_sender_verification = async (userId, data) => {
  return await post_request(`${baseUrl}/ar24/sender/${userId}/renew_verification`, data)
}

const fetch_ar24_sender_events = async (userId, token) => {
  return await get_request(`${baseUrl}/ar24/sender/${userId}/events`, token)
}


const get_request = async (url, token, params) => {
  if(!firebase.auth().currentUser) {
    return
  }
  if(!token) {
    token = await firebase.auth().currentUser.getIdToken(true)
  }
  let query = ''
  if(params) {
    query = `&${params}`
  }
  let response = await axios.get(`${url}?id_token=${token}${query}`)
  if(response.data.error) {
    console.log("firestore error")
    console.log(response.data.error)
    return response.data
  } 
  return response.data.data
}

const get_anonymous = async (url, responseType = 'blob') => {
  try {
    let response = await axios.get(url, { responseType })
    return response.data
  } catch(err) {
    return { error: err }
  }
}

const patch_request = async (url, data) => {
  if(!firebase.auth().currentUser) {
    return
  }
  const token = await firebase.auth().currentUser.getIdToken(true)
  let response = await axios.patch(`${url}?id_token=${token}`, data, {
    headers: {
      "Content-Type": "application/json"
    }
  })
  if(response.data.error) {
    console.log("firestore error")
    console.log(response.data.error)
    return response.data
  } 
  return response.data
}

const post_request = async (url, data) => {
  if(!firebase.auth().currentUser) {
    return
  }
  const token = await firebase.auth().currentUser.getIdToken(true)
  try {
    let response = await axios.post(`${url}?id_token=${token}`, data, {
      headers: {
        "Content-Type": "application/json"
      }
    })
    if(response.data.error) {
      console.log("firestore error")
      console.log(response.data.error)
      return response.data
    } 
    return response.data
  } catch(err) {
    return { error: err.response ? err.response.data.error || err.response.data : err }
  }
}

const delete_request = async (url) => {
  if(!firebase.auth().currentUser) {
    return
  }
  const token = await firebase.auth().currentUser.getIdToken(true)
  let response = await axios.delete(`${url}?id_token=${token}`)
  if(response.data.error) {
    console.log("firestore error")
    console.log(response.data.error)
    return response.data
  } 
  return response.data.data
}

const upload_file = async (data, name, format, contentType, path, timestamped = true) => {
  try {
    let fileRef = firebase.storage().ref().child(`${path}/${name}${timestamped ? `-${Date.now()}`: ''}.${format}`)
    // let fileRef = firebase.storage().ref().child(`/${handle}/${entityId}_logo.png`)
    let parts = data.split(',')
    let base64data
    if(parts.length === 2) {
      base64data = parts[1]
    } else {
      base64data = data
    }
    await fileRef.putString(base64data, 'base64', { contentType: contentType })
    let downloadUrl = await fileRef.getDownloadURL()
    return { url: downloadUrl }
  } catch(err) {
    console.log('image upload error', err)
    return { error: err }
  }
}

const get_file = async (data) => {
  return await post_request(`${baseUrl}/file_base64`, data);
}

const filter_documents = async (data) => {
  return await post_request(`${baseUrl}/filter-documents`, data);
}

const fetch_customers = async (token, keywords = '') => {
  return await get_request(`${baseUrl}/customers`, token, `keywords=${keywords}`)
}

const fetch_admins = async (token) => {
  return await get_request(`${baseUrl}/admins`, token)
}

const fetch_customer_by_id = async (token, id, useTelephoneCode = false) => {
  let params = null
  if(useTelephoneCode) {
    params = 'telephone_code=true'
  }
  return await get_request(`${baseUrl}/customers/${id}`, token, params)
}

const get_signature_credentials = (token) => {
  return get_request(`${baseUrl}/signature_credentials`, token)
}

const fetch_standard_templates = async () => {
  return await get_request(`${baseUrl}/standard_templates`)
}

const create_standard_template = async (data) => {
  data.meta = {
    created: Date.now(),
    updated: Date.now()
  }
  return await post_request(`${baseUrl}/standard_templates`, data)
}

const update_standard_template = async (id, data) => {
  return await patch_request(`${baseUrl}/standard_templates/${id}`, data)
}

const delete_standard_template = async (id) => {
  return await delete_request(`${baseUrl}/standard_templates/${id}`)
}

const fetch_standard_templates_folders = async () => {
  return await get_request(`${baseUrl}/standard_templates_folders`)
}

const create_standard_templates_folder = async (data) => {
  data.meta = {
    created: Date.now(),
    updated: Date.now()
  }
  return await post_request(`${baseUrl}/standard_templates_folders`, data)
}

const update_standard_templates_folder = async (data, id) => {
  data[`meta.updated`] = Date.now();
  return await patch_request(`${baseUrl}/standard_templates_folders/${id}`, data);
}

const delete_standard_templates_folder = async (id) => {
  return await delete_request(`${baseUrl}/standard_templates_folders/${id}`);
}

const firebaseTimeStamp = firebase.firestore.FieldValue.serverTimestamp();

export {
  baseUrl,
  fetch_templates,
  fetch_single_template,
  fetch_documents,
  fetch_single_document,
  fetch_document_history,
  update_document,
  add_document_event,
  fetch_document_events,
  create_document,
  delete_document,
  filter_documents,
  update_template,
  delete_template,
  fetch_folders_order,
  update_folders_order,
  fetch_folders,
  create_folder,
  update_folder,
  delete_folder,
  firebaseTimeStamp,
  upload_file,
  post_request,
  fetch_actions,
  create_signature,
  fetch_signatures,
  get_signature_status,
  delete_signature,
  get_signature_document,
  place_signature,
  get_signature_placement_interface,
  fetch_documents_folders,
  create_documents_folder,
  update_documents_folder,
  delete_documents_folder,
  fetch_customers,
  fetch_admins,
  get_file,
  update_signature,
  get_signature_position,
  fetch_document_data,
  get_site_configs,
  push_to_immocloud,
  fetch_tag_matchings,
  fetch_sections,
  create_section,
  update_section,
  delete_section,
  fetch_customer_by_id,
  fetch_signature_templates,
  create_signature_template,
  update_signature_template,
  delete_signature_template,
  get_signature_credentials,
  get_signatures_credits,
  update_agency,
  fetch_agency,
  fetch_email_user,
  get_request,
  patch_request,
  fetch_site_manufacturers,
  fetch_ar24_sender,
  create_ar24_sender,
  update_ar24_sender,
  delete_ar24_sender,
  get_anonymous,
  send_ar24_mail,
  fetch_ar24_mail,
  upload_ar24_attachment,
  request_ar24_sender_verification,
  verify_otp_key,
  associate_ar24_sender,
  resend_ar24_sender_confirmation,
  // resend_ar24_sender_invitation,
  renew_ar24_sender_verification,
  fetch_ar24_single_mail,
  fetch_ar24_sender_events,
  fetch_standard_templates,
  create_standard_template,
  update_standard_template,
  delete_standard_template,
  fetch_standard_templates_folders,
  create_standard_templates_folder,
  update_standard_templates_folder,
  delete_standard_templates_folder
}