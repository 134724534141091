const Search2 = () => {
  return (
    <span className="svg-icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="22.296" height="22.224" viewBox="0 0 22.296 22.224">
        <path id="Path_1472" data-name="Path 1472" d="M8.109,16.218a8.109,8.109,0,1,1,8.109-8.109A8.118,8.118,0,0,1,8.109,16.218Zm0-14.416a6.307,6.307,0,1,0,6.307,6.307A6.314,6.314,0,0,0,8.109,1.8ZM20.723,21.624a.9.9,0,0,1-.637-.264l-7.208-7.208a.9.9,0,0,1,1.274-1.274l7.208,7.208a.9.9,0,0,1-.637,1.538Z" transform="translate(0.3 0.3)" fill="#1e548a" stroke="#1e548a" strokeWidth="0.6"/>
      </svg>
    </span>
  )
}

export default Search2