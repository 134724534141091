import React from 'react'

const SenderModalContentIdentityVerificationSuccess = ({ onClose }) => {

  return (
    <div className=''>
      <h2 className='modal-title'>Associer un compte expéditeur AR24</h2>
      <ul className='modal-list'>
        <li className='modal-list-item'>
          <p className='text-success'>
            Votre demande a bien été transmise
          </p>
        </li>
        <li className='modal-list-item'>
          <p>
            Vous recevrez sous 3 jours ouvrés votre <b>identifiant LRE</b> contenant votre <b>clé OTP</b>.
          </p>
        </li>
      </ul>
      <ul className='flex flex-row justify-end gap-10'>
        <li>
          <button
            className='btn btn--large btn--transparent btn--border-primary'
            onClick={onClose}
          >
            Fermer
          </button>
        </li>
      </ul>
    </div>
  )
}

export default SenderModalContentIdentityVerificationSuccess