const SignaturesNotAvailableVialink = (/*{ store = null }*/) => {

  // const renderNoStoreContent = () => {
  //   return <>
  //     <h2>Signatures</h2>
  //     <p>BIENTÔT DISPONIBLE - ESIGN Connect</p>
  //     <p>Envoyez vos documents en signature électronique directement depuis Immo Docs !</p>
  //   </>
  // }

  // const renderStoreContent = () => {
  //   return <>
  //     <p>Connectez-vous au Store Immofacile pour souscrire à Esign Connect :</p>
  //     <ul>
  //       <li>Achetez un pack de 100, 200 ou 500 signatures</li>
  //       <li>Envoyez vos documents juridiques directement depuis Immo Docs</li>
  //       <li>Spécifiez un ordre dans l’envoi des signatures</li>
  //       <li>Suivez l’état des envois depuis Immo Docs</li>
  //       <li>Téléchargez les documents signés depuis Immo Docs</li>
  //     </ul>
  //     <a href={store} target="__blank" className="button button--primary">Connectez</a>
  //   </>
  // }

  return (
    <div className="signatures-not-available-v2">
      <h2>Indisponible</h2>
      <p>La signature électronique n'est pas disponible pour ce modèle de document</p>
    </div>
  )
}

export default SignaturesNotAvailableVialink