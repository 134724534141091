export const FUNCTIONS_BASE_URL = 'https://europe-west1-jurisur-be.cloudfunctions.net'
export const FUNCTIONS_BASE_URL_LOCAL = 'http://localhost:5001/jurisur-be/europe-west1'

export const STATUS_LABELS = {
  'ready': "Prêt pour envoi",
  'draft': "Brouillon",
  'in-progress': "Cérémonie en cours",
  'closed': "Cérémonie clôturée",
  'expired': "Cérémonie expirée",
  'cancelled': "Cérémonie annulée"
}

export const STATUS_LABELS_SHORT = {
  'ready': "Prêt pour envoi",
  'draft': "Brouillon",
}

export const AR24_STATUS_LABELS = {
  // 'to_valid': 'Brouillon (eIDAS)',
  'waiting': 'Envoyées',
  // 'dp': 'Livré (dp)',
  'ev': 'Livrées',
  'AR': 'Acceptées',
  'refused': 'Refusées',
  'negligence': 'Négligées',
//   - Envoyées
// - Reçues
// - Acceptées
// - Refusées
// - Négligées
}
 
export const AR24_SENDER_STATUS = {
  'particulier': 'Particulier',
  'professionnel': 'Professionnel'
}
 
export const AR24_SENDER_STATUS_LABEL = {
  'api-access-pending': 'En attente de validation',
  // 'invitation-pending': 'En attente',
  'confirmation-pending': 'En attente de validation',
  'verification-pending': 'Non vérifié',
  'non-verified': 'Non vérifié',
  'verified': 'Vérifié',
}

export const AR24_SENDER_STATUS_LABEL_FULL = {
  'api-access-pending': 'En attente  de validation',
  // 'invitation-pending': 'En attente',
  'confirmation-pending': 'En attente  de validation',
  'verification-pending': 'Non vérifié',
  'non-verified': 'Non vérifié',
  'verified': 'Vérifié',
}

export const ACTION_LABELS = {
  'document_update': 'mis à jour le document'
}

export const SIGNATURE_STATUSES = {
  'ok': "Signé",
  'attente': "En attente"
}

export const SIGNATURE_STATUS_TRANLSATIONS = {
  'COMPLETED': "Signé",
  'PASS': "Signé",
  'SIGNING_COMPLETED': "Signé",
  'SIGNING_PENDING': "En attente",
  'IN_PROGRESS': "En cours",
  'ARCHIVED': "Archivé",
  'DECLINED': "Refusé",
  'ACTION_NEEDED': "Refusé",
  'EXPIRED': "Expiré",
  'OPTED_OUT': "Exclusion",
  'CANCELLED': "Annulé",
  'INACTIVE': "Inactif",
  'DRAFT': "Brouillon",
  'SENT': "Envoyé",
  'DELETED': "Supprimé"
}

export const VIALINK_SIGNATURE_STATUS_FILTER_OPTIONS = [
  'IN_PROGRESS',
  'CANCELLED',
  'EXPIRED',
  'COMPLETED',
  'ACTION_NEEDED',
]

export const ESIGN_STATUS_FILTER_OPTIONS = [
  'SIGNING_PENDING',
  'COMPLETED',
  'DECLINED',
  'EXPIRED',
  'CANCELLED'
]

export const CONTACT_ID_VARIABLES = [
  'do_co_seller_customers_id',
  'do_co_customers_id',
  'co_customers_id'
]

export const CONTACT_TYPE_FOR_ID_VARIABLE = {
  'do_co_seller_customers_id': 'compromis_seller_associated',
  'do_co_customers_id': 'compromis_buyer_associated',
  'co_customers_id': 'mandat_seller_associated'
}

export const CONTACT_VARIABLE_PREFIX_FOR_TYPE = {
  'compromis_seller_associated': 'do_co_seller_customers_',
  'compromis_buyer_associated': 'do_co_customers_',
  'mandat_seller_associated': 'co_customers_'
}

export const CONTACT_DATA_KEYS = {
  compromis_seller_associated: [
    'do_co_seller_customers_id',
    'do_co_seller_customers_statut',
    'do_co_seller_customers_gender',
    'do_co_seller_customers_lastname',
    'do_co_seller_customers_firstname',
    'do_co_seller_entry_street_address',
    'do_co_seller_customers_entry_residence',
    'do_co_seller_entry_postcode',
    'do_co_seller_entry_city',
    'do_co_seller_entry_state',
    'do_co_seller_customers_telephone',
    'do_co_seller_customers_email_address',
    'do_co_seller_customers_fax',
    'do_co_seller_na_customers_lastname',
    'do_co_seller_profession',
    'do_co_seller_secteur_activite',
    'do_co_seller_profession_type_contrat',
    'do_co_seller_lieu_travail',
    'do_co_seller_anciennete',
    'do_co_seller_date_naissance',
    'do_co_seller_lieu_naissance',
    'do_co_seller_type_contrat',
    'do_co_seller_lieu_mariage',
    'do_co_seller_date_mariage',
    'do_co_seller_nom_jeune_fille',
    'do_co_seller_customers_nombre_enfants',
    'do_co_seller_situation_maritale',
    'do_co_seller_customers_raison_sociale',
    'do_co_seller_customers_forme_juridique',
    'do_co_seller_responsable_gender',
    'do_co_seller_customers_fonction',
    'do_co_seller_acq_suivi_par',
    'do_co_seller_customers_manufacturers_id_to',
    'do_co_seller_customers_creation_date',
    'do_co_seller_customer_date_maj',
    'do_co_seller_customers_next_contact',
    'do_co_seller_origine',
    'do_co_seller_total_amount',
    'do_co_seller_apport',
    'do_co_seller_montant_a_emprunter',
    'do_co_seller_duree',
    'do_co_seller_taux',
    'do_co_seller_mensualites',
    'do_co_seller_revenus',
    'do_co_seller_credits_en_cours',
    'do_co_seller_banque',
    'do_co_seller_primo_accedant',
    'do_co_seller_type_projet',
    'do_co_seller_customers_date_butoir',
    'do_co_seller_no_mandat',
    'co_seller_manufacturer_historique_list_input',
    'co_seller_revente_bien_actuel',
    'co_seller_customers_type',
    'co_seller_customers_motif_achat',
    'co_seller_customers_project_delay',
    'do_co_seller_proof_address',
    'do_co_seller_nature_document',
    'do_co_seller_expiration_date_document',
    'do_co_seller_native_country',
    'do_co_seller_nationality',
    'do_co_seller_double_nationality',
    'do_co_seller_second_nationality',
    'do_co_seller_us_person',
    'do_co_seller_procuration',
    'do_co_seller_proof_procuration',
    'do_co_seller_conformity_label',
    'do_co_seller_socio_professional',
    'do_co_seller_monthly_net_income',
    'do_co_seller_payday_savings',
    'do_co_seller_joint_payday_saving',
    'do_co_seller_joint_owner',
    'do_co_seller_joint_owner_2',
    'do_co_seller_sale_real_estate',
    'do_co_seller_inheritance_amount',
    'do_co_seller_donation_amount',
    'do_co_seller_life_insurance_contract',
    'do_co_seller_real_estate_income',
    'do_co_seller_gain_play',
    'do_co_seller_other_amount',
    'do_co_seller_type_proof_income',
    'do_co_seller_proof_salary',
    'do_co_seller_real_estate_loan_amount',
    'do_co_seller_joint_mortgage',
    'do_co_seller_borrowers',
    'do_co_seller_borrowers_2',
    'do_co_seller_customers_no_rcs',
    'do_co_seller_customers_city_rcs',
    'do_co_seller_entity_regulated',
    'do_co_seller_society_regulated_market',
    'do_co_seller_type_incapacite',
    'do_co_seller_incapacity_represent_by',
    'do_co_seller_incapacity_role',
    'do_co_seller_represent_name',
    'do_co_seller_incapacity_date',
    'do_co_seller_incapacity_tgi',
    'do_co_seller_society_type',
    'do_co_seller_society_creation_date',
    'do_co_seller_registration_address',
    'do_co_seller_registration_city',
    'do_co_seller_registration_country',
    'do_co_seller_registration_type',
    'do_co_seller_registration_number',
    'do_co_seller_code_ape_naf',
    'do_co_seller_nb_shareholders',
    'do_co_seller_nb_beneficiaries',
    'do_co_seller_nb_associate',
    'do_co_seller_associate_category',
    'do_co_seller_turnover',
    'do_co_seller_own_capital',
    'do_co_seller_bank_loan',
    'do_co_seller_equity_contribution',
    'do_co_seller_other_amount_pm',
    'do_co_seller_type_relationship',
    'do_co_seller_other_entity_group',
    "do_co_seller_manufacturer_historique_list",
    "do_co_seller_revente_bien_actuel",
    "do_co_seller_customers_type",
    "do_co_seller_customers_motif_achat",
    "do_co_seller_customers_project_delay",
    "do_co_seller_entry_language_id"
  ],
  compromis_buyer_associated: [
    'do_co_customers_id',
    'do_co_customers_statut',
    'do_co_customers_gender',
    'do_co_customers_lastname',
    'do_co_customers_firstname',
    'do_co_entry_street_address',
    'do_co_customers_entry_residence',
    'do_co_entry_postcode',
    'do_co_entry_city',
    'do_co_entry_state',
    'do_co_customers_telephone',
    'do_co_customers_email_address',
    'do_co_customers_fax',
    'do_co_na_customers_lastname',
    'do_co_profession',
    'do_co_secteur_activite',
    'do_co_profession_type_contrat',
    'do_co_lieu_travail',
    'do_co_anciennete',
    'do_co_date_naissance',
    'do_co_lieu_naissance',
    'do_co_type_contrat',
    'do_co_lieu_mariage',
    'do_co_date_mariage',
    'do_co_nom_jeune_fille',
    'do_co_customers_nombre_enfants',
    'do_co_situation_maritale',
    'do_co_customers_raison_sociale',
    'do_co_customers_forme_juridique',
    'do_co_responsable_gender',
    'do_co_customers_fonction',
    'do_co_acq_suivi_par',
    'do_co_customers_manufacturers_id_to',
    'do_co_customers_creation_date',
    'do_co_customer_date_maj',
    'do_co_customers_next_contact',
    'do_co_origine',
    'do_co_total_amount',
    'do_co_apport',
    'do_co_montant_a_emprunter',
    'do_co_duree',
    'do_co_taux',
    'do_co_mensualites',
    'do_co_revenus',
    'do_co_credits_en_cours',
    'do_co_banque',
    'do_co_primo_accedant',
    'do_co_type_projet',
    'do_co_customers_date_butoir',
    'do_co_no_mandat',
    'co_manufacturer_historique_list_input',
    'co_customers_project_delay',
    'co_revente_bien_actuel',
    'co_customers_type',
    'co_customers_motif_achat',
    "do_co_manufacturer_historique_list",
    "do_co_customers_project_delay",
    "do_co_revente_bien_actuel",
    "do_co_customers_type",
    "do_co_customers_motif_achat",
    "do_co_proof_address",
    "do_co_nature_document",
    "do_co_expiration_date_document",
    "do_co_native_country",
    "do_co_nationality",
    "do_co_double_nationality",
    "do_co_second_nationality",
    "do_co_us_person",
    "do_co_procuration",
    "do_co_conformity_label",
    "do_co_socio_professional",
    "do_co_monthly_net_income",
    "do_co_payday_savings",
    "do_co_joint_payday_saving",
    "do_co_joint_owner",
    "do_co_joint_owner_2",
    "do_co_sale_real_estate",
    "do_co_inheritance_amount",
    "do_co_donation_amount",
    "do_co_life_insurance_contract",
    "do_co_real_estate_income",
    "do_co_gain_play",
    "do_co_other_amount",
    "do_co_real_estate_loan_amount",
    "do_co_joint_mortgage",
    "do_co_customers_no_rcs",
    "do_co_customers_city_rcs",
    "do_co_entity_regulated",
    "do_co_society_regulated_market",
    "do_co_type_incapacite",
    "do_co_incapacity_represent_by",
    "do_co_incapacity_role",
    "do_co_represent_name",
    "do_co_incapacity_date",
    "do_co_incapacity_tgi",
    "do_co_society_type",
    "do_co_society_creation_date",
    "do_co_registration_address",
    "do_co_registration_city",
    "do_co_registration_country",
    "do_co_registration_type",
    "do_co_registration_number",
    "do_co_code_ape_naf",
    "do_co_nb_shareholders",
    "do_co_nb_beneficiaries",
    "do_co_nb_associate",
    "do_co_turnover",
    "do_co_own_capital",
    "do_co_bank_loan",
    "do_co_equity_contribution",
    "do_co_other_amount_pm",
    "do_co_type_relationship",
    "do_co_other_entity_group",
    "do_co_entry_language_id"
  ],
  mandat_seller_associated: [
    'co_customers_id',
    'co_customers_statut',
    'co_customers_gender',
    'co_customers_lastname',
    'co_customers_firstname',
    'co_entry_street_address',
    'co_customers_entry_residence',
    'co_entry_postcode',
    'co_entry_city',
    'co_entry_state',
    'co_customers_telephone',
    'co_customers_email_address',
    'co_customers_fax',
    'co_na_customers_lastname',
    'co_profession',
    'co_secteur_activite',
    'co_profession_type_contrat',
    'co_lieu_travail',
    'co_anciennete',
    'co_date_naissance',
    'co_lieu_naissance',
    'co_type_contrat',
    'co_lieu_mariage',
    'co_date_mariage',
    'co_nom_jeune_fille',
    'co_customers_nombre_enfants',
    'co_situation_maritale',
    'co_customers_raison_sociale',
    'co_customers_forme_juridique',
    'co_responsable_gender',
    'co_customers_fonction',
    'co_acq_suivi_par',
    'co_customers_manufacturers_id_to',
    'co_customers_creation_date',
    'co_customer_date_maj',
    'co_customers_next_contact',
    'co_origine',
    'co_total_amount',
    'co_apport',
    'co_montant_a_emprunter',
    'co_duree',
    'co_taux',
    'co_mensualites',
    'co_revenus',
    'co_credits_en_cours',
    'co_banque',
    'co_primo_accedant',
    'co_type_projet',
    'co_customers_date_butoir',
    'co_no_mandat',
    'co_proof_address',
    'co_nature_document',
    'co_expiration_date_document',
    'co_native_country',
    'co_nationality',
    'co_double_nationality',
    'co_second_nationality',
    'co_us_person',
    'co_procuration',
    'co_proof_procuration',
    'co_conformity_label',
    'co_socio_professional',
    'co_monthly_net_income',
    'co_payday_savings',
    'co_joint_payday_saving',
    'co_joint_owner',
    'co_joint_owner_2',
    'co_sale_real_estate',
    'co_inheritance_amount',
    'co_donation_amount',
    'co_life_insurance_contract',
    'co_real_estate_income',
    'co_gain_play',
    'co_other_amount',
    'co_type_proof_income',
    'co_proof_salary',
    'co_real_estate_loan_amount',
    'co_joint_mortgage',
    'co_co_borrowers',
    'co_co_borrowers_2',
    'co_customers_no_rcs',
    'co_customers_city_rcs',
    'co_entity_regulated',
    'co_society_regulated_market',
    'co_type_incapacite',
    'co_incapacity_represent_by',
    'co_incapacity_role',
    'co_represent_name',
    'co_incapacity_date',
    'co_incapacity_tgi',
    'co_society_type',
    'co_society_creation_date',
    'co_registration_address',
    'co_registration_city',
    'co_registration_country',
    'co_registration_type',
    'co_registration_number',
    'co_code_ape_naf',
    'co_nb_shareholders',
    'co_nb_beneficiaries',
    'co_nb_associate',
    'co_associate_category',
    'co_turnover',
    'co_own_capital',
    'co_bank_loan',
    'co_equity_contribution',
    'co_other_amount_pm',
    'co_type_relationship',
    'co_other_entity_group',
    'co_entry_language_id'
  ]
}

export const DOC_CONTACTS_KEYS = [
  'customers_lastname',
  'customers_firstname',
  'customers_telephone',
  'customers_email_address',
  'co_customers_lastname',
  'co_customers_firstname',
  'co_customers_telephone',
  'co_customers_email_address',
  'do_sellers_customers_lastname',
  'do_sellers_customers_firstname',
  'do_sellers_customers_email_address',
  'do_sellers_customers_telephone',
  'do_co_seller_customers_lastname',
  'do_co_seller_customers_firstname',
  'do_co_seller_customers_telephone',
  'do_co_seller_customers_email_address',
  'do_customers_lastname',
  'do_customers_firstname',
  'do_customers_email_address',
  'do_customers_telephone',
  'do_co_customers_lastname',
  'do_co_customers_firstname',
  'do_co_customers_telephone',
  'do_co_customers_email_address'
]

export const SEARCH_HISTORY_LS_KEY = 'jurisur_search_history';
export const JURISUR_SORT_LS = 'jurisur_sort' // LS - local storage
export const JURISUR_PAGINATION_LS = 'jurisur_pagination' 

export const DOCUMENT_LIFESPAN_MILLISECONDS = 30 * 24 * 60 * 60 * 1000 // 30 days

export const perPageOptions = [5, 10, 15, 20, 25, 50]

export const TEXT_LINES = '______________________________'
export const TEXTAREA_LINES = `__________________________________________________________________________
__________________________________________________________________________
__________________________________________________________________________
__________________________________________________________________________
__________________________________________________________________________`
export const DATE_LINES = '____/____/______'
export const NUMBER_LINES = '_______________'

export const AR24_DATE_FORMAT = 'YYYY-MM-DD hh:mm:ss'