import React from 'react'
import { Check } from "@material-ui/icons";

const MailModalContentSummary = ({ onReturn, onSubmit, recipients }) => {

  const labelForRecipientType = (type) => {
    switch (type) {
      case 'particulier':
        return 'Particulier'
      case 'professionnel':
        return 'Professionnel'
      default:
        return 'Non renseigné'
    }
  }

  const defaultPricePerRecipient = 3.49 // HT

  const totalPrice = (recipients?.length || 0) * defaultPricePerRecipient

  return (
    <div className='registered-mail-send-modal-envoi-container'>
      <div className='flex flex-row modal-section-title mb-20'>
        <Check className="modal-section-title--icon" />
        Vérifier les informations avant envoi
      </div>
      <div className="mb-20">
        <p>Chaque destinaire renseigné ci-dessous va recevoir une lettre recommandée électronique. Vérifiez bien les informations avant envoi.</p>
      </div>
      <table className='registered-mail-send-modal-list-table'>
        <thead>
          <tr>
            <th>Prénom</th>
            <th>Nom</th>
            <th>Email</th>
            <th>Statut</th>
            <th>Prix (€ HT)</th>
          </tr>
        </thead>
        <tbody>
          {recipients?.map((recipient, index) => (
            <tr key={index}>
              <td>{recipient.firstName}</td>
              <td>{recipient.lastName}</td>
              <td>{recipient.email}</td>
              <td>{labelForRecipientType(recipient.type)}</td>
              <td>{defaultPricePerRecipient}€</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="flex flex-row justify-end align-center mt-10 mb-40">
        <p><b>Coût total: {totalPrice}€ HT</b></p>
      </div>

      <div className='modal-btn-box mt-20'>
        <button
          className='btn btn--large btn--transparent btn--border-primary'
          onClick={onReturn}
        >
          Précédent
        </button>
        <button
          className='btn btn--large btn--success'
          onClick={onSubmit}
        >
          Envoyer
        </button>
      </div>
    </div>
  )
}

export default MailModalContentSummary