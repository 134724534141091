
import { useState } from 'react'
import { Document, Page } from 'react-pdf'
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const PdfPreview = ({ src, filename }) => {

  const [pages, setPages] = useState([])

  // On document load success
  const handleDocumentLoadSuccess = (numPages) => {
    let arr = []
    for(let i = 1; i < numPages + 1; i++) {
      arr.push(<Page key={`preview_page_${i}`} pageNumber={i} width={774} height={1094} className={`page-${i}`} renderTextLayer={true} loading={'Chargement...'} />)
    }
    setPages(arr)
  }


  const handleDownload = () => {
    // window.open(src, '_blank')
    var link = document.createElement("a");
    link.download = filename || 'preview'
    link.href = src
    // document.body.appendChild(link)
    link.click()
    // document.body.removeChild(link)
  }

  return (
    <div className='pdf-preview'>
      <div className='pdf-preview-toolbar'>
        <div className='pdf-preview-toolbar-button' onClick={handleDownload}>
          <FileDownloadIcon />
        </div>
      </div>
      <div className='pdf-preview-content'>
        <Document 
            file={src} 
            onLoadSuccess={({ numPages }) => handleDocumentLoadSuccess(numPages)} 
            renderMode="canvas" 
            loading={'Chargement...'}
          >
            { pages.map(p => p)}
        </Document>
        </div>
      </div>
  )
}

export default PdfPreview