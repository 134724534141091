import React, { useContext } from 'react'
import { RegisteredMailContext } from '../../../context'

const SenderModalContentPromptIdentityVerification = ({ onReturn }) => {

  const { renewIdentityVerification } = useContext(RegisteredMailContext)

  const handleConfirm = async () => {
    await renewIdentityVerification()
  }

  return (
    <div className=''>
      <h2 className='modal-title'>Modifier le compte expéditeur</h2>
      <ul className='modal-list'>
        <li className='modal-list-item'>
          <p>
            L'expéditeur recevra par voie postale sous 3 jours ouvrés une <b>clé OTP</b> inscrite sur l’<b>identifiant LRE</b>.<br/>
            Pour <b>valider</b> le compte, il sera nécessaire de renseigner la clé OTP reçue sur Immo Docs (Lettre recommandée &gt; Gestion du compte expéditeur).
          </p>
        </li>
        <li className='modal-list-item'>
          <p>
            Vous serez facturés <b>7,49€ HT</b> pour la commande d'un identifiant LRE. 
          </p>
        </li>
        <li className='modal-list-item'>
          <p>
            Dès <b>soumission</b>, vous <b>ne pourrez plus envoyer de LRE</b> tant que votre compte n'aura pas été <b>vérifié</b>.
          </p>
        </li>
      </ul>
      <ul className='flex flex-row justify-end gap-10'>
        <li>
          <button
            className='btn btn--large btn--transparent btn--border-primary'
            onClick={onReturn}
          >
            Précedent
          </button>          
        </li>
        <li>
          <button
            className='btn btn--large btn--success'
            onClick={handleConfirm}
          >
            Soumettre
          </button>
        </li>
      </ul>
    </div>
  )
}

export default SenderModalContentPromptIdentityVerification