import { useState, useEffect, useRef } from 'react'

import { EllipsisH } from '../../assets/icons'
import { CustomTooltip, Radio } from '../ui_new'
import { useVariablesActions } from '../../hooks'

const SingleDocumentVariablesInner = ({ title, choices, fields, choicesName, onValueChange, documentValues, multiselect, tooltip, variable, select, id, idx, disabled = false, repeatableIds, repetitionIndices }) => {
  const [isOpenedInner, setIsOpenedInner] = useState(false)
  const [isSelectedInner, setIsSelectedInner] = useState(false)
  const [isSelectedInner2, setIsSelectedInner2] = useState(false)
  const [selectedChoice, setSelectedChoice] = useState(documentValues[choicesName] ? documentValues[choicesName] : '')
  const [selectedText, setSelectedText] = useState('')
  const [isFieldsOpened, setIsFieldsOpened] = useState(false)
  const [isChoicesHidden, setIsChoicesHidden] = useState(false)
  const variablesWrapEl = useRef()
  let innerClasses = 'variables-v2 doc-variable-el question-el'

  const { value } = useVariablesActions({ values: documentValues })

  // On values change - 
  useEffect(() => {
    let val = value(choicesName, repeatableIds, repetitionIndices)
    // if(repId && repIndex !== undefined && repIndex > -1) {
    //   val = documentValues[repId]?.[repIndex]?.[choicesName]
    // } else {
    //   val = documentValues[choicesName]
    // }
    // if(repeatableIds && repeatableIds.length > 0) {
    //   val = duplicatableValuesArray(repeatableIds, repetitionIndices)?.[variable]
    // } else {
    //   val = documentValues[choicesName]
    // }
    if(val) {
      const choice = [...choices].filter(choice => choice.value === val)
      if(choice.length > 0) {
        setSelectedText(choice[0].label)
      }
      setIsOpenedInner(true)
      setIsSelectedInner(true)
      if(val !== 'non') {
        setIsFieldsOpened(true)
      }
    }
  }, [choices, choicesName, repeatableIds, repetitionIndices, value])

  // On head paragraph click
  const handleHeadParagraphClick = () => {
    if (isSelectedInner) {
      setIsSelectedInner2(!isSelectedInner2)
    } else {
      setIsOpenedInner(!isOpenedInner)
    }
  }

  // On choose button click
  const handleChooseButtonClick = () => {
    setIsOpenedInner(!isOpenedInner)
  }

  // On reset click
  const handleResetClick = (e) => {
    setIsOpenedInner(false)
    setIsSelectedInner(false)
    setIsSelectedInner2(false)
    setIsFieldsOpened(false)
    setIsChoicesHidden(false)
    setSelectedChoice('')
    onValueChange('')
    setSelectedText('')
  }

  // On radio button change
  const handleRadioChange = (e) => {
    setSelectedChoice(e.target.value)
    setSelectedText(e.target.parentElement.querySelector('p').textContent)
    setIsChoicesHidden(true)
    setIsSelectedInner(true)
    onValueChange(e.target.value)
    if (e.target.value !== 'non') {
      setIsFieldsOpened(true)
    } else {
      setIsFieldsOpened(false)
    }
    if (isSelectedInner2) {
      setIsSelectedInner2(false)
    }
  }

  // On choice button click
  const handleChoiceButtonClick = (choice) => {
    setSelectedChoice(choice.value)
    setSelectedText(choice.label)
    onValueChange(choice.value)
    setIsSelectedInner(true)
  }

  // Set classes based on conditions
  if (isOpenedInner) {
    innerClasses += ' opened-2'
  }
  if (isSelectedInner) {
    innerClasses += ' selected'
  }
  if (isSelectedInner2) {
    innerClasses += ' selected-2'
  }

  return (
    <div className={innerClasses} ref={variablesWrapEl} id={id} data-id={idx}>
      <div className={select ? "variables-v2__head select" : "variables-v2__head"}>
        <div className={choices.length > 3 ? "variables-v2__head_text tooltip-wrapper" : "variables-v2__head_text tooltip-wrapper cursor-default"} onClick={choices.length > 3 ? handleHeadParagraphClick : null}>
          {tooltip ? (
            <CustomTooltip content={tooltip}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <span className="radio"></span>
                <p>{title}</p>
                <p className="selected">{selectedText}</p>
              </div>
            </CustomTooltip>
          ) : (
            <>
              <span className="radio"></span>
              <p>{title}</p>
              <p className="selected">{selectedText}</p>
            </>
          )}
        </div>
        <div className="variables-v2__head_buttons">
          {choices.length < 4
            ? ( <> 
              { choices.map((c, i) => (
                <button className="variables-v2__btn" onClick={() => handleChoiceButtonClick(c)} disabled={disabled} key={i}>{c.label}</button>
              )) }
            </> 
            )
            : <button className="variables-v2__btn" onClick={handleChooseButtonClick} disabled={disabled}>Choisir...</button>
          }
        </div>
        <div className="variables-v2__head_selected">
          <CustomTooltip content="Réinitialiser">
            <button className="variables-v2__btn" onClick={handleResetClick}><EllipsisH /></button>
          </CustomTooltip>
        </div>
      </div>
      <div className={isFieldsOpened ? "variables-v2__body fields-opened" : "variables-v2__body"}>
        <div className={isChoicesHidden ? "choices hide" : "choices"}>
          <div className="choices__inner">
              <Radio
                choices={choices.map((i) => { return {...i, disabled: disabled} })}
                name={choicesName}
                selectedValue={selectedChoice}
                onChange={handleRadioChange}
              />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleDocumentVariablesInner