import { useEffect, useState, useContext } from 'react'

import { Modal, Input } from '../ui_new'
import { useFoldersActions } from '../../hooks'
import { DocumentsFoldersContext, FolderContext } from '../../context'

const CreateEditFolderModal = ({ onClose, mode = 'create', view = 'documents', current, onSetNewFolderCreated }) => {
  const { createFolder, editFolder } = useFoldersActions()
  const { docFoldersLoading } = useContext(DocumentsFoldersContext)
  const { foldersLoading, standardTemplatesFoldersLoading } = useContext(FolderContext)
  const [name, setName] = useState('')

  // Set name if in edit mode
  useEffect(() => {
    if(mode === 'edit' && current) {
      setName(current.name)
    }
  }, [current, mode]);

  // On modal close
  const handleClose = () => {
    onClose()
  }

  // On name change
  const handleChange = (e) => {
    setName(e.target.value)
  }

  // On submit 
  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      let data = {
        name: name.trim()
      }
      if(mode === 'create') {
        data.parentFolder = current ? current.id : null
        await createFolder(data, view)
        onSetNewFolderCreated && onSetNewFolderCreated(true)
        onClose()
      }else if(mode === 'edit') {
        await editFolder(data, current.id, view)
        onClose()
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Modal onClose={handleClose} className="modal-v2--small">
      <div className="folder-modal-v2">
        <h2>{mode === 'create' ? "Créer un dossier" : "Modifier le dossier"}</h2>
        <form onSubmit={handleSubmit} className="folder-modal-v2__form">
          <Input 
            name="name"
            value={name}
            onChange={handleChange}
            placeholder="Indiquez le nom du dossier"
          />
          <div className="folder-modal-v2__form_actions">
            <button className="btn btn--transparent" onClick={handleClose}>Annuler</button>
            <button className="btn" onClick={handleSubmit} disabled={view === 'documents' ? docFoldersLoading : foldersLoading}>
              {view === 'documents' && (docFoldersLoading ? mode === 'create' ? "Création..." : "Mise à jour..." : "Sauvegarder")}
              {view === 'templates' && (foldersLoading ? mode === 'create' ? "Création..." : "Mise à jour..." : "Sauvegarder")}
              {view === 'standard-templates' && (standardTemplatesFoldersLoading ? mode === 'create' ? "Création..." : "Mise à jour..." : "Sauvegarder")}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default CreateEditFolderModal