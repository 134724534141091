const Building = () => {
  return (
    <span className="svg-icon">
      <svg id="Group_1185" data-name="Group 1185" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 15.987 20.949">
        <g id="Group_1184" data-name="Group 1184">
          <path id="Path_1639" data-name="Path 1639" d="M15.416,0H.571A.56.56,0,0,0,0,.551V20.4a.56.56,0,0,0,.571.551H4.962V18.744a.55.55,0,0,1,.551-.551h4.962a.55.55,0,0,1,.551.551V20.4s0,0,0,0v.548h4.391a.56.56,0,0,0,.571-.551V.551A.56.56,0,0,0,15.416,0M4.961,13.782a.55.55,0,0,1-.551.551H2.756a.55.55,0,0,1-.551-.551V12.128a.55.55,0,0,1,.551-.551H4.41a.55.55,0,0,1,.551.551Zm0-4.41a.55.55,0,0,1-.551.551H2.756A.55.55,0,0,1,2.2,9.372V7.718a.55.55,0,0,1,.551-.551H4.41a.55.55,0,0,1,.551.551Zm0-4.41a.55.55,0,0,1-.551.551H2.756a.55.55,0,0,1-.551-.551V3.308a.55.55,0,0,1,.551-.551H4.41a.55.55,0,0,1,.551.551Zm4.41,8.821a.55.55,0,0,1-.551.551H7.166a.55.55,0,0,1-.551-.551V12.128a.55.55,0,0,1,.551-.551H8.82a.55.55,0,0,1,.551.551Zm0-4.41a.55.55,0,0,1-.551.551H7.166a.55.55,0,0,1-.551-.551V7.718a.55.55,0,0,1,.551-.551H8.82a.55.55,0,0,1,.551.551Zm0-4.41a.55.55,0,0,1-.551.551H7.167a.55.55,0,0,1-.551-.551V3.308a.55.55,0,0,1,.551-.551H8.82a.55.55,0,0,1,.551.551Zm4.41,8.821a.55.55,0,0,1-.551.551H11.577a.55.55,0,0,1-.551-.551V12.128a.55.55,0,0,1,.551-.551h1.654a.55.55,0,0,1,.551.551Zm0-4.41a.55.55,0,0,1-.551.551H11.577a.55.55,0,0,1-.551-.551V7.718a.55.55,0,0,1,.551-.551h1.654a.55.55,0,0,1,.551.551Zm0-4.41a.55.55,0,0,1-.551.551H11.577a.55.55,0,0,1-.551-.551V3.308a.55.55,0,0,1,.551-.551h1.654a.55.55,0,0,1,.551.551Z" />
        </g>
      </svg>
    </span>
  )
}

export default Building