import { useContext, useEffect, useRef, useState } from 'react'

import { FolderPlus } from '../../assets/icons'
import { FolderListItem, CreateEditFolderModal } from '.'
import { useTemplatesActions, useFoldersActions } from '../../hooks'
import { areFolderActionsAuthorized } from '../../utils'
import { FolderContext, UserContext } from '../../context'

const StandardTemplatesContentSidebar = ({ onSetSelectedFilters, selectedFilters, rootFolders, standard  }) => {
  const { moveTemplateToFolder } = useTemplatesActions()
  const { moveToRootFolder } = useFoldersActions()
  const [activeFolder, setActiveFolder] = useState(selectedFilters.folder)
  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false)
  const { setCurrentlyActiveFolder, foldersOrderMap, updateFoldersOrder } = useContext(FolderContext)
  const { userClaims } = useContext(UserContext)

  // On folders filter
  const handleFilterFolders = (id) => {
    setActiveFolder(id)
    onSetSelectedFilters(prev => ({ ...prev, folder: id }))
  }

  // On all folders filter
  const handleFilterAllFolders = () => {
    setActiveFolder('all');
    setCurrentlyActiveFolder('root');
    onSetSelectedFilters(prev => ({ ...prev, folder: 'all' }))
  }

  // Drag over - all
  const handleDragOver = (e) => {
    e.preventDefault()
    const folderBox = e.target.closest('.folder-list-item__main')
    if(!folderBox.classList.contains('active')) {
      folderBox.classList.add('active')
    }
  }

  // Drag leave - all
  const handleDragLeave = (e) => {
    e.preventDefault()
    const folderBox = e.target.closest('.folder-list-item__main')
    if(folderBox.classList.contains('active')) {
      folderBox.classList.remove('active')
    }
  }

  // On drop
  const handleDrop = async (e) => {
    const folderBox = e.target.closest('.folder-list-item__main')
    if(folderBox.classList.contains('active')) {
      folderBox.classList.remove('active')
    }

    const data = e.dataTransfer.getData('folder') ? JSON.parse(e.dataTransfer.getData('folder')) : null
    
    if(data) {
      moveToRootFolder(data, 'standard-templates')
      return
    }

    const templateData = e.dataTransfer.getData('template') ? JSON.parse(e.dataTransfer.getData('template')) : null

    if(templateData) {
      moveTemplateToFolder({ folder: null, template: templateData, view: 'standard-templates' })
    }
  }

  // On create folder icon click
  const handleOpenCreateFolderModal = () => {
    setShowCreateFolderModal(true)
  }

  // On close create folder modal
  const handleCloseCreateFolderModal = () => {
    setShowCreateFolderModal(false)
  }

  // drag and drop to reorder folders
  const [orderedFolders, setOrderedFolders] = useState([]);
  const [draggedOverItem, setDraggedOverItem] = useState('');
  const [parent, setParent] = useState('');

  useEffect(() => {
    const rootOrder = foldersOrderMap?.['standard_templates']?.root || [];
    let orderedFoldersCopy = rootOrder.map(id => rootFolders.find(folder => folder.id === id));
    // filter out undefined folders & add folders that are not in the order
    rootFolders.forEach(folder => {
      if(!orderedFoldersCopy.includes(folder)) {
        orderedFoldersCopy.push(folder);
      }
    });
    orderedFoldersCopy = orderedFoldersCopy.filter(folder => folder);
    setOrderedFolders(orderedFoldersCopy);
  }, [rootFolders, foldersOrderMap]);

  const dragFolder = useRef(0);
  const draggedOverFolder = useRef(0);

  function reorder() {
    const orderedFoldersClone = [...orderedFolders];
    const movedFolder = orderedFoldersClone.splice(dragFolder.current, 1)[0];
    orderedFoldersClone.splice(draggedOverFolder.current, 0, movedFolder);
    setOrderedFolders(orderedFoldersClone);

    const newFullOrder = {...(foldersOrderMap?.['standard_templates'] || {})}
    newFullOrder['root'] = orderedFoldersClone.map(folder => folder.id)
    updateFoldersOrder('standard_templates', newFullOrder)

    setDraggedOverItem();
    setParent();
  }

  return (
    <div className="templates-content__sidebar u-custom-scrollbar">
      <div className="templates-content__sidebar_head">
        <h1><span>{standard ? 'Mes modèles types' : 'Mes modèles'}</span></h1>
      </div>
      <section className="sidebar-folders">
        <h3>Parcourir par catégorie</h3>
        <ul>
          <li>
            <div className="folder-list-item">
              <div 
                className={`folder-list-item__main root ${activeFolder === 'all' ? 'active' : ''}`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                draggable={areFolderActionsAuthorized('standard-templates', userClaims)}
              >
                <p onClick={handleFilterAllFolders}><span className="text">Dossier racine</span></p>
                {areFolderActionsAuthorized('standard-templates', userClaims) && <span className="icon-right icon-right--2" onClick={handleOpenCreateFolderModal}><FolderPlus /></span>}
              </div>
              <div className="folder-list-item__body"></div>
            </div>
          </li>
          {orderedFolders.map((folder, idx) => {
            return (
              <li key={`folder_root_${idx}`}
                className={`${idx === draggedOverItem ? 'dragged-over-item ' : ' '}
                 ${(parent === orderedFolders[draggedOverFolder.current]?.parentFolder && dragFolder.current > draggedOverFolder.current ) ? 'top ' : ' '} 
                 ${(parent === orderedFolders[draggedOverFolder.current]?.parentFolder && dragFolder.current < draggedOverFolder.current) ? 'bottom ' : ' '}`}>
                <div className='flex'>
                  <FolderListItem 
                    folder={folder} 
                    onFilter={handleFilterFolders} 
                    activeFolder={activeFolder} 
                    view="standard-templates"

                      reorderDraggable={areFolderActionsAuthorized('standard-templates', userClaims)}
                      reorderDragStart={() => { dragFolder.current = idx; setParent(folder.parentFolder) }}
                      reorderDragEnter={() => (draggedOverFolder.current = idx)}
                      reorderDragEnd={reorder}
                      reorderDragOver={(e) => { e.preventDefault(); setDraggedOverItem(idx) }}
                  />
                </div>
              </li>
            )
          })}
        </ul>
      </section>

      {showCreateFolderModal && (
        <CreateEditFolderModal 
          onClose={handleCloseCreateFolderModal}
          view="standard-templates"
        />
      )}
    </div>
  )
}

export default StandardTemplatesContentSidebar