import { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { ClickAwayListener } from '@material-ui/core'

import {
    Eye,
    Save,
    Download,
    GripLines,
    Share,
    Pen,
    History,
    FileAlt,
    CloudUpload,
    Sync,
    FileDownload,
    Delete,
    Duplicate,
    EllipsisV,
    Signature,
    RegisteredMail
} from '../../assets/icons'
import {
    SingleDocumentVariables,
    SingleDocumentShare,
    SingleDocumentSign,
    SingleDocumentHistory,
    SingleDocumentSynthesis,
    SingleDocumentInject,
    SingleDocumentAttachments,
} from './'
import { CustomTooltip, Alert } from '../ui_new'
import { useSingleDocumentActions } from '../../hooks'
import { UserContext, LoaderContext, RegisteredMailContext, DocumentsContext } from '../../context'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import { isMobile } from 'react-device-detect'
import { availableOn } from '../../utils'
import SingleDocumentSignVialink from './SingleDocumentSignVialink'
import { FEATURE, isFeatureEnabled } from '../../utils'
import { Star } from '@material-ui/icons'

const SingleDocumentMain = ({
    singleDoc,
    templateData,
    documentValues,
    onValuesChange,
    documentCheckboxValues,
    onCheckboxValueChange,
    onCustomCoverChange,
    useCustomCover,
    onAgencyModalOpen,
    onNotariesModalOpen,
    linesAddedTo,
    onRemoveLines,
    onSetLinesAddedTo,
    onSave,
    onDocumentPreview,
    linesAdded,
    onAddLines,
    attachments,
    onAttachmentsShareUpdate,
    onAttachmentsShareSave,
    documentId,
    documentName,
    template,
    docContacts,
    docSignatureRecipients,
    onSetDocumentValues,
    docHistory,
    docEvents,
    onVersionRestore,
    onFetchHistory,
    onResetSetup,
    view,
    onSetView,
    onSetDocumentAttachments,
    onSetTab,
    onToggleSidePanel,
    isSidePanelOpen,
    isContentEditable,
    onToggleContentEditable,
    onSectionContentChanged,
    onDocumentDownload,
    previewSettings,
    templateMetaSections
}) => {
  const { downloadDocument, moveDocumentToDeleteDocuments, duplicateDocument,  downloadDocumentData, immocloudUploadAvailable, uploadToCloud } = useSingleDocumentActions()
  const { immocloudConfig, hideActionBar, agency, user, partner, siteConfigs } = useContext(UserContext)
  const { setShowGlobalResponseLoader, setLoading, setGlobalResponseLoaderText } = useContext(LoaderContext)
  const { createAr24MailRequestFromDocument } = useContext(RegisteredMailContext)
  const { createStandardTemplate } = useContext(DocumentsContext)
  const [showShareModal, setShowShareModal] = useState(false)
  const [showSignModal, setShowSignModal] = useState(false)
  const [showHistoryModal, setShowHistoryModal] = useState(false)
  const [showSynthesisModal, setShowSynthesisModal] = useState(false)
  const [showInjectModal, setShowInjectModal] = useState(false)
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  const [showDocxDownloadAlert, setShowDocxDownloadAlert] = useState(false)
  const [showMoreActions, setShowMoreActions] = useState(false)
  const history = useHistory()

    // On document save
    const handleSave = () => {
        onSave()
    }

    // On document preview
    const handleDocumentPreview = () => {
        if (!onDocumentPreview) return
        onDocumentPreview()
    }

    const agencyFontConfig = () => {
        if(!agency) {
            return {}
        }
        const font_config = {
            heading_2: {
                color: agency.cover_color,
                borderColor: agency.cover_color,
            },
            heading_3: {
                color: "#ffffff",
                backgroundColor: agency.cover_color,
            },
            footer_title: {
                color: agency.cover_color,
            },
            cover_title: {
                color: agency.cover_color,
            }
        }
        return font_config
    }

    const agencyConfig = () => {
        if(!agency) {
            return {}
        }
        const config = {
            cover_color: agency.cover_color,
        }
        return config
    }

    // On pdf download
    const handlePdfDownload = async () => {
        setLoading(true)
        await downloadDocument('pdf', {
            ...singleDoc,
            custom_cover: useCustomCover,
        }, { font_config: { font_size: previewSettings.size, ...agencyFontConfig() }, use_watermark: previewSettings.watermark, ...agencyConfig() })
        setLoading(false)
    }

    // On open docx download alert
    const handleOpenDocxDownloadAlert = () => {
        setShowDocxDownloadAlert(true)
    }

    // On close docx download alert
    const handleCloseDocxDownloadAlert = () => {
        setShowDocxDownloadAlert(false)
    }

    // // On docx download
    const handleDocxDownload = async () => {
        setLoading(true)
        await downloadDocument('docx', {
            ...singleDoc,
            custom_cover: useCustomCover,
        }, { font_config: { font_size: previewSettings.size, ...agencyFontConfig() }, use_watermark: previewSettings.watermark, ...agencyConfig() })
        setShowDocxDownloadAlert(false)
        setLoading(false)
    }


    // On add lines
    const handleAddLines = () => {
        onAddLines()
    }


    // On open sendLREModal
    // const handleOpenSendModalModal = async () => {
    //     setLoading(true)
    //     const previewData = await getPreviewData({...singleDoc, values: documentValues, checkboxValues: documentCheckboxValues})
    //     const buffer = Buffer.from(previewData, 'base64')
    //     console.log('setSendModalDefaultAttachments', {...singleDoc, base64: previewData.split(',')[1], size: buffer.byteLength })
    //     setSendModalDefaultAttachments([{...singleDoc, base64: previewData.split(',')[1], size: buffer.byteLength }])
    //     history.push("/registered-mail/tracking");
    //     setShowRegisteredMailSendModal(true)
    //     setLoading(false)
    // }

    const handleOpenRegisteredMailModal = () => {
        createAr24MailRequestFromDocument({ document: singleDoc, documentValues, documentCheckboxValues, documentName, documentContacts: docContacts })
    }

    // On open share modal
    const handleOpenShareModal = () => {
        setShowShareModal(true)
    }

    // On close share modal
    const handleCloseShareModal = () => {
        setShowShareModal(false)
    }

    // On open sign modal
    const handleOpenSignModal = () => {
        setShowSignModal(true)
    }

    // On close sign modal
    const handleCloseSignModal = () => {
        setShowSignModal(false)
    }

    // On open history modal
    const handleOpenHistoryModal = () => {
        onFetchHistory()
        setShowHistoryModal(true)
    }

    // On close history modal
    const handleCloseHistoryModal = () => {
        setShowHistoryModal(false)
    }

    // On open synthesis modal
    const handleOpenSynthesisModal = async () => {
        setShowSynthesisModal(true)
    }

    // On close synthesis modal
    const handleCloseSynthesisModal = () => {
        setShowSynthesisModal(false)
    }

    // On open inject modal
    const handleOpenInjectModal = () => {
        setShowInjectModal(true)
    }

    // On close inject modal
    const handleCloseInjectModal = () => {
        setShowInjectModal(false)
    }

    // On open delete alert
    const handleOpenDeleteAlert = () => {
        setShowDeleteAlert(true)
    }

    // On close delete alert
    const handleCloseDeleteAlert = () => {
        setShowDeleteAlert(false)
    }

    // On delete document
    const handleDeleteDocument = async () => {
        setShowGlobalResponseLoader(true)
        try {
            await moveDocumentToDeleteDocuments({
                ...singleDoc,
                id: documentId,
            })
            history.push('/documents')
        } catch (err) {
        } finally {
            setShowGlobalResponseLoader(false)
        }
    }

    // On duplicate
    const handleDuplicate = async () => {
        setShowGlobalResponseLoader(true)
        try {
            const id = await duplicateDocument({
                doc: singleDoc,
                linesAddedTo,
                template,
            })
            history.replace(`/documents/${id}`)
            onResetSetup()
        } catch (err) {
            console.log(err)
        } finally {
            setShowGlobalResponseLoader(false)
        }
    }

    // On data download
    const handleDownloadData = () => {
        downloadDocumentData({
            documentValues,
            documentCheckboxValues,
            documentName,
            documentId,
        })
    }

    // On upload to upload
    const handleCloudUpload = async () => {
        setShowGlobalResponseLoader(true)
        try {
            await uploadToCloud({
                template,
                doc: singleDoc,
                immocloudConfig,
            })
        } catch (err) {
            console.log(err)
        } finally {
            setShowGlobalResponseLoader(false)
        }
    }

    // On more actions click away
    const handleMoreActionsClickAway = () => {
        setShowMoreActions(false)
    }

    // On toggle more actions
    const handleToggleMoreActions = () => {
        setShowMoreActions(!showMoreActions)
    }

    const handleCreateStandardTemplate = async () => {
        // TODO create standart template
        setShowGlobalResponseLoader(true, 2000)
        setGlobalResponseLoaderText('Ouverture du modèle type créé')
        // history.push(`/documents/create-standard-template/${documentId}`)
        const standardTemplateResponse = await createStandardTemplate({ document: singleDoc, template, values: documentValues, checkboxValues: documentCheckboxValues, name: documentName, customCover: useCustomCover, attachments: attachments })
        if(standardTemplateResponse.error) {
            return
        }
        history.push(`/standard-templates/${standardTemplateResponse.id}`)
        setShowGlobalResponseLoader(false)
    }

    return (
        <div>
            <div
                className="document-main__actions"
                style={{
                    display: isMobile ? (hideActionBar ? 'none' : '') : '',
                }}
            >
                <CustomTooltip content="Sauvegarder">
                    <button
                        className="icon-btn icon-btn--md"
                        onClick={handleSave}
                    >
                        <Save />
                    </button>
                </CustomTooltip>
                <CustomTooltip content="Aperçu">
                    <button
                        className="icon-btn icon-btn--md"
                        onClick={handleDocumentPreview}
                    >
                        <Eye />
                    </button>
                </CustomTooltip>
                <CustomTooltip
                    content={
                        !linesAdded
                            ? 'Ajouter des lignes dans les champs vides'
                            : 'Enlever les lignes des champs vides'
                    }
                >
                    <button
                        className={`icon-btn icon-btn--md hide-on-800 ${
                            linesAdded ? 'icon-btn--active' : ''
                        }`}
                        onClick={handleAddLines}
                    >
                        <GripLines />
                    </button>
                </CustomTooltip>
                <CustomTooltip content="Télécharger">
                    <div className="icon-btn icon-btn--md icon-btn--not-clickable">
                        <Download />
                        <div className="icon-btn__popover">
                            <button onClick={handlePdfDownload}>pdf</button>
                            <button onClick={handleOpenDocxDownloadAlert}>
                                docx
                            </button>
                        </div>
                    </div>
                </CustomTooltip>
                {immocloudUploadAvailable() && (
                    <CustomTooltip
                        content={
                            immocloudConfig.handle === 'customer_id'
                                ? "Envoyer vers l'Immocloud du contact"
                                : "Envoyer vers l'Immocloud du bien"
                        }
                    >
                        <button
                            className="icon-btn icon-btn--md hide-on-800"
                            onClick={handleCloudUpload}
                        >
                            <CloudUpload />
                        </button>
                    </CustomTooltip>
                )}
                <CustomTooltip content="Partager">
                    <button
                        className="icon-btn icon-btn--md icon-btn--svg-sm"
                        onClick={handleOpenShareModal}
                    >
                        <Share />
                    </button>
                </CustomTooltip>
                <CustomTooltip content="Injecter">
                    <button
                        className="icon-btn icon-btn--md icon-btn--svg-sm hide-on-800"
                        onClick={handleOpenInjectModal}
                    >
                        <Sync />
                    </button>
                </CustomTooltip>
                <CustomTooltip content="Signer">
                    <button
                        className="icon-btn icon-btn--md icon-btn--svg-sm"
                        onClick={handleOpenSignModal}
                    >
                        <Pen />
                    </button>
                </CustomTooltip>
                {/* <CustomTooltip content="Signatures">
                    <button className="icon-btn icon-btn--md icon-btn--svg-sm" onClick={handleOpenSendModalModal}><Signature /></button>
                </CustomTooltip> */}
                {isFeatureEnabled(FEATURE.AR24, siteConfigs) && <CustomTooltip content="Envoyer le document par LRE">
                    <button className="icon-btn icon-btn--md icon-btn--svg-sm" onClick={handleOpenRegisteredMailModal}><RegisteredMail /></button>
                </CustomTooltip>}
                {/* <CustomTooltip content="Synthèse">
          <button className="icon-btn icon-btn--md icon-btn--svg-sm" onClick={handleOpenSynthesisModal}><FileAlt /></button>
        </CustomTooltip>
        <CustomTooltip content="Historique">
          <button className="icon-btn icon-btn--md icon-btn--svg-sm" onClick={handleOpenHistoryModal}><History /></button>
        </CustomTooltip>
        <CustomTooltip content="Dupliquer">
          <button className="icon-btn icon-btn--md icon-btn--svg-sm" onClick={handleDuplicate}><Duplicate /></button>
        </CustomTooltip>
        <CustomTooltip content="Télécharger les données">
          <button className="icon-btn icon-btn--md icon-btn--svg-sm" onClick={handleDownloadData}><FileDownload /></button>
        </CustomTooltip>
        <CustomTooltip content="Supprimer">
          <button className="icon-btn icon-btn--md icon-btn--svg-sm" onClick={handleOpenDeleteAlert}><Delete /></button>
        </CustomTooltip> */}
                <ClickAwayListener onClickAway={handleMoreActionsClickAway}>
                    <div className="document-main__actions_more">
                        <CustomTooltip content="Plus d'actions">
                            <button
                                className="icon-btn icon-btn--md"
                                onClick={handleToggleMoreActions}
                            >
                                <EllipsisV />
                            </button>
                        </CustomTooltip>
                        {showMoreActions && (
                            <div className="more-dropdown">
                                <ul>
                                    <li className="show-on-800">
                                        <button onClick={handleAddLines}>
                                            <GripLines />{' '}
                                            <span className="text">
                                                {!linesAdded
                                                    ? 'Ajouter des lignes dans les champs vides'
                                                    : 'Enlever les lignes des champs vides'}
                                            </span>
                                        </button>
                                    </li>
                                    <li className="show-on-800">
                                        <button onClick={handleCloudUpload}>
                                            <CloudUpload />{' '}
                                            <span className="text">
                                                {immocloudConfig.handle ===
                                                'customer_id'
                                                    ? "Envoyer vers l'Immocloud du contact"
                                                    : "Envoyer vers l'Immocloud du bien"}
                                            </span>
                                        </button>
                                    </li>
                                    {availableOn(['development', 'staging']) && <li className="show-on-800">
                                        <button
                                            onClick={handleOpenInjectModal}
                                        >
                                            <Sync />{' '}
                                            <span className="text">
                                                Injecter
                                            </span>
                                        </button>
                                    </li> }
                                    <li>
                                        <button
                                            onClick={
                                                handleOpenSynthesisModal
                                            }
                                        >
                                            <FileAlt />{' '}
                                            <span className="text">
                                                Synthèse
                                            </span>
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            onClick={handleOpenHistoryModal}
                                        >
                                            <History />{' '}
                                            <span className="text">
                                                Historique
                                            </span>
                                        </button>
                                    </li>
                                    <li>
                                        <button onClick={handleDuplicate}>
                                            <Duplicate />{' '}
                                            <span className="text">
                                                Dupliquer
                                            </span>
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            onClick={handleDownloadData}
                                        >
                                            <FileDownload />{' '}
                                            <span className="text">
                                                Télécharger les données
                                            </span>
                                        </button>
                                    </li>
                                    {isFeatureEnabled(FEATURE.STANDARD_TEMPLATES, siteConfigs) && <li>
                                        <button
                                            onClick={handleCreateStandardTemplate}
                                        >
                                            <Star />{' '}
                                            <span className="text">
                                                Créer un modèle type
                                            </span>
                                        </button>
                                    </li>}
                                    <li>
                                        <button
                                            onClick={handleOpenDeleteAlert}
                                        >
                                            <Delete />{' '}
                                            <span className="text">
                                                Supprimer
                                            </span>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>
                </ClickAwayListener>
            </div>
            <div className="document-main__body">
                {view === 'variables' && (
                    <SingleDocumentVariables
                        templateData={templateData}
                        documentValues={documentValues}
                        onValuesChange={onValuesChange}
                        documentCheckboxValues={documentCheckboxValues}
                        onCheckboxValueChange={onCheckboxValueChange}
                        onCustomCoverChange={onCustomCoverChange}
                        useCustomCover={useCustomCover}
                        onAgencyModalOpen={onAgencyModalOpen}
                        onNotariesModalOpen={onNotariesModalOpen}
                        linesAddedTo={linesAddedTo}
                        onRemoveLines={onRemoveLines}
                        onSetLinesAddedTo={onSetLinesAddedTo}
                        isContentEditable={isContentEditable}
                        onSectionContentChanged={onSectionContentChanged}
                        templateMetaSections={templateMetaSections}
                    />
                )}
                {view === 'attachments' && (
                    <SingleDocumentAttachments
                        attachments={attachments}
                        docId={documentId}
                        onSetDocumentAttachments={onSetDocumentAttachments}
                        onSetView={onSetView}
                        onSetTab={onSetTab}
                        onToggleSidePanel={onToggleSidePanel}
                        isSidePanelOpen={isSidePanelOpen}
                    />
                )}
            </div>

            {showShareModal && (
                <SingleDocumentShare
                    onClose={handleCloseShareModal}
                    attachments={attachments}
                    onAttachmentShareUpdate={onAttachmentsShareUpdate}
                    onAttachmentsShareSave={onAttachmentsShareSave}
                    documentName={documentName}
                    documentId={documentId}
                    singleDoc={singleDoc}
                    template={template}
                    documentValues={documentValues}
                    useCustomCover={useCustomCover}
                />
            )}

            {showSignModal && (
                <SingleDocumentSignVialink
                    onClose={handleCloseSignModal}
                    documentName={documentName}
                    docId={documentId}
                    docAttachments={attachments}
                    docSignatureRecipients={docSignatureRecipients}
                    singleDoc={{
                        ...singleDoc,
                        name: documentName,
                        values: documentValues,
                        custom_cover: useCustomCover,
                        id: documentId,
                    }}
                    docContacts={docContacts}
                    template={template}
                />
            )}

            {showHistoryModal && (
                <SingleDocumentHistory
                    onClose={handleCloseHistoryModal}
                    docHistory={docHistory}
                    docEvents={docEvents}
                    onRestore={onVersionRestore}
                />
            )}

            {showSynthesisModal && (
                <SingleDocumentSynthesis
                    onClose={handleCloseSynthesisModal}
                    documentValues={documentValues}
                    onSetDocumentValues={onSetDocumentValues}
                    data={template}
                />
            )}

            {showInjectModal && (
                <SingleDocumentInject
                    onClose={handleCloseInjectModal}
                    template={template}
                    doc={{
                        ...singleDoc,
                        name: documentName,
                        values: documentValues,
                        custom_cover: useCustomCover,
                        id: documentId,
                    }}
                    documentValues={documentValues}
                />
            )}

            {showDeleteAlert && (
                <Alert
                    onClose={handleCloseDeleteAlert}
                    text="Êtes-vous sûr de vouloir supprimer ce document ?"
                    onSubmit={handleDeleteDocument}
                    deleteAlert={true}
                />
            )}

            {showDocxDownloadAlert && (
                <Alert
                    onClose={handleCloseDocxDownloadAlert}
                    onOkClose={handleDocxDownload}
                    text="Note importante"
                    bodyText="Immo Docs ne pourra être tenu pour responsable des modifications apportées au .docx"
                    showOk
                    okBtnText="Télécharger le .docx"
                />
            )}
        </div>
    )
}

export default SingleDocumentMain
