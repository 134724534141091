import { useState, useContext, useEffect } from 'react' 
import { useHistory } from 'react-router-dom'
import { ClickAwayListener } from '@material-ui/core'

import { Plus, FileAlt } from '../../assets/icons'
import { DocumentsContext, FolderContext, LoaderContext } from '../../context'
import { folderHasTemplates } from '../../utils'
import { useFoldersActions, useSingleDocumentActions } from '../../hooks'

const AddNewDocument = () => {
  const { getFolderTemplates } = useFoldersActions()
  const { createDocumentFromTemplate } = useSingleDocumentActions()
  const { folders, foldersFetched } = useContext(FolderContext)
  const { templates, templatesLoaded } = useContext(DocumentsContext)
  const { setShowGlobalResponseLoader, setGlobalResponseLoaderText } = useContext(LoaderContext)
  const [isActive, setIsActive] = useState(false)
  const [showDropdown, setShowDropdown] = useState(false)
  const [rootFolderTemplates, setRootFolderTemplates] = useState([])
  const [formattedFolders, setFormattedFolders] = useState([])
  const history = useHistory()

  // Set root folder templates
  useEffect(() => {
    if(templatesLoaded && foldersFetched) {
      const copyOfTemplates = {...templates}
      let templatesInRootFolder = []
      for(let key in copyOfTemplates) {
        if(!copyOfTemplates[key].deleted) {
          const template = copyOfTemplates[key]
          if(template.folderId && Array.isArray(template.folderId) && template.folderId[0]) {

          }else {
            templatesInRootFolder.push({...template, id: key})
          }
        }
      }
      setRootFolderTemplates(templatesInRootFolder.filter(t => (!t.hidden && !t.deleted)))
    }
  }, [templates, templatesLoaded, foldersFetched])

  useEffect(() => {
    let foldersArr = [...folders]
    let procFolders = {}
    for(let i in foldersArr) {
      let folder = foldersArr[i]
      while(folder.parentFolder) {
        let parent = foldersArr.find(f => f.id === folder.parentFolder)
        if(!parent) break
        if(!parent.subfolders) {
          parent.subfolders = []
        }
        if(!parent.subfolders.find(f => f.id === folder.id)) {
          parent.subfolders.push(folder)
        }
        folder = parent
      }
      if(!folder.parentFolder) {
        procFolders[folder.id] = folder
      }
    }
    let procFoldersArray = Object.values(procFolders)
    setFormattedFolders(procFoldersArray)
  }, [folders])

  // On mouse over
  const handleMouseOver = () => {
    if(!isActive) setIsActive(true)
  }

  // On click away 
  const handleClickAway = () => {
    if(isActive) setIsActive(false)
    if(showDropdown) setShowDropdown(false)
  }

  // On dropdown toggle
  const handleToggleDropdown = () => {
    setShowDropdown(!showDropdown)
  }

  // On create document
  const handleCreateDocument = async (e, tmplt) => {
    e.preventDefault()
    setShowGlobalResponseLoader(true)
    setGlobalResponseLoaderText('Création du document')
    try {
      const id = await createDocumentFromTemplate(tmplt)
      history.push(`/documents/${id}`)
    } catch (err) {

    } finally {
      setShowGlobalResponseLoader(false)
      setGlobalResponseLoaderText('')
    }
  }

  // On folder click
  const handleFolderClick = (e) => {
    e.preventDefault()
  }

  // TODO test on mobile and fix opening doc when opening folder

  const rowNestLevelOffset = 10
  const renderFoldersRow = (folders, level) => {
    return folders.map((f) => {
      return (
        <>
          <li key={f.id}>
            <a href="/" onClick={e => e.preventDefault()} onMouseDown={handleFolderClick}>
              { level > 0 && <span style={{ marginLeft: level * rowNestLevelOffset }}></span>}
              <span className="icon"><FileAlt /></span> 
              <span className="text">{f.name}</span>
            </a>

            {folderHasTemplates(templates, f) && (
              <ul className="u-custom-scrollbar">
                {getFolderTemplates(f).map((tmplt) => {
                  return (
                    <li key={tmplt.id}><a href="/" onClick={e => e.preventDefault()} onMouseDown={(e) => handleCreateDocument(e, tmplt)}>{tmplt.name}</a></li>
                  )
                })}
              </ul>
            )}
          </li>
          { f.subfolders && renderFoldersRow(f.subfolders, level + 1)}
        </>
      )
    })
  }

  const renderDropdown = (mobile) => {
    if(!showDropdown) return null
    return (
      <div className={`add-new-document__dropdown${mobile ? ' mobile' : ''}`}>
        <ul>
          <li key={'root-folder'}>
            <a href="/" onClick={e => e.preventDefault()} onMouseDown={handleFolderClick}>
              <span className="icon"><FileAlt /></span>
              <span className="text">Dossier racine</span>
            </a>

            {rootFolderTemplates.length > 0 && (
              <ul className="u-custom-scrollbar">
                {rootFolderTemplates.map((tmplt, idx) => {
                  return (
                    <li key={idx}><a href="/" onClick={e => e.preventDefault()} onMouseDown={(e) => {
                      e.preventDefault()
                      handleCreateDocument(e, tmplt)
                    }}>{tmplt.name}</a></li>
                  )
                })}
              </ul>
            )}
          </li>
          { renderFoldersRow(formattedFolders, 0)}
          {/* {formattedFolders.map((f) => {
            return (
              <li key={f.id}>
                <a href="/" onClick={e => e.preventDefault()} onMouseDown={handleFolderClick}>
                  <span className="icon"><FileAlt /></span> 
                  <span className="text">{f.name}</span>
                </a>

                {folderHasTemplates(templates, f) && (
                  <ul className="u-custom-scrollbar">
                    {getFolderTemplates(f).map((tmplt) => {
                      return (
                        <li key={tmplt.id}><a href="/" onClick={e => e.preventDefault()} onMouseDown={(e) => handleCreateDocument(e, tmplt)}>{tmplt.name}</a></li>
                      )
                    })}
                  </ul>
                )}
              </li>
            )
          })} */}
        </ul>
      </div>
    )
  }
  

  return (
    <div className="add-new-document">
      <ClickAwayListener onClickAway={handleClickAway}>
        <>
          <div className="add-new-document__mobile">
            <button className="link" onClick={handleToggleDropdown}><Plus /></button>
            {renderDropdown(true)}
          </div>
          <div className="add-new-document__body-wrapper">
            <div className={`add-new-document__body ${isActive ? showDropdown ? 'active no-bottom-radius' : 'active' : ''}`} onMouseOver={handleMouseOver}>
              <button className="link"><Plus /></button>
              <div className="add-new-document__body_main" onClick={handleToggleDropdown}>
                <p>Nouveau document</p>
              </div>
              {renderDropdown()}
            </div>
          </div>
        </>
      </ClickAwayListener>
    </div>
  )
}

export default AddNewDocument