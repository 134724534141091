import { memo } from 'react'
import moment from 'moment'

import { dateValueFormat } from '../../utils'
import { DATE_LINES } from '../../constants'
import { DateInput } from '../ui_new'

const VariableDateInput = ({fieldVariable, handle, val, repeatableIds, repetitionIndices, linesAddedTo = [], isHandleLocked, onInputChange, onRemoveLines}) => {
  const { variable, tooltip, idx} = fieldVariable

  let linesAdded
  // TODO: fix lines added with nested repetitions
  // if(repId && repIndex !== undefined && repIndex > -1) {
  //   linesAdded = linesAddedTo.find(v => v.variable === variable && v.belongs_to === repId && v.index === repIndex)
  //   if(!linesAdded && linesAddedTo.find(v => v.belongs_to === repId) && val === DATE_LINES) {
  //     val = ''
  //   }
  // }else {
  //   linesAdded = linesAddedTo.find(v => v.variable === variable)
  // }

  const disabled = linesAdded || isHandleLocked(handle)
  
  return (
    <div className="tooltip-wrapper doc-variable-el" data-id={idx}>
      <div className={`${val ? 'date_picker-v2 not-empty' : 'date_picker-v2'}${disabled ? ' disabled' : ''}`}>
        <DateInput 
          selected={!val || linesAdded || val === DATE_LINES ? null : new Date(moment(val, dateValueFormat).valueOf())}
          onChange={date => date ? onInputChange(variable, moment(date.valueOf()).format(dateValueFormat), repeatableIds, repetitionIndices) : ''}
          peekNextMonth={true}
          showMonthDropdown={true}
          showYearDropdown={true}
          dropdownMode="select"
          disabled={disabled}
          tooltip={tooltip}
          isClearable={val}
          onClearableClick={() => onInputChange(variable, '', repeatableIds, repetitionIndices)}
        />
        {linesAdded && (
          <div className="date-lines" onClick={() => onRemoveLines(variable, repeatableIds, repetitionIndices)}>{DATE_LINES}</div>
        )}
      </div>
    </div>
  )
}

export default memo(VariableDateInput)