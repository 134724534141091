import { useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Page } from 'react-pdf'

import { UserSolid, Building, BgImage } from '../assets/icons'
import { SettingsLayout } from '../layouts'
import { CoverPagePreview } from '../components/misc'
import { Select, Option, Checkbox, RadioGroup, RadioEl, FileUploadDnD } from '../components/ui_new'
import { NotificationContext, UserContext } from '../context'
import { useCoverPageActions } from '../hooks'
import ColorPicker from "../components/UI/ColorPicker"
import { FEATURE, agencyLogoUrl, isFeatureEnabled } from '../utils'

const SettingsCoverPage = () => {
  const { fetchDocumentData, saveAgency } = useCoverPageActions()
  const { agency, user, partner, siteConfigs } = useContext(UserContext)
  const { setNotification } = useContext(NotificationContext)
  const [color, setColor] = useState('')
  const [font, setFont] = useState('')
  const [useAgencyInfo, setUseAgencyInfo] = useState(false)
  const [useAdminInfo, setUseAdminInfo] = useState(false)
  const [useAdminPhoto, setUseAdminPhoto] = useState(false)
  const [useLogo, setUseLogo] = useState(false)
  const [coverImageInput, setCoverImageInput] = useState('default')
  const [logoInput, setLogoInput] = useState('agency')
  const [colorInput, setColorInput] = useState('agency')
  const [showPreview, setShowPreview] = useState(false)
  const [agencyImages, setAgencyImages] = useState({
    logo: { url: '', data: '', name: '', type: '', extension: '' },
    cover: { url: '', data: '', name: '', type: '', extension: '' }
  })
  const [pdfPreviewData, setPdfPreviewData] = useState(null)
  const [previewPage, setPreviewPage] = useState(null)
  const [loadingPreview, setLoadingPreview] = useState(false)
  const fontOptions = [
    { value: 'Inter', label: 'Inter' },
    { value: 'Open Sans', label: 'Open Sans' },
    { value: 'Caladea', label: 'Caladea' },
    { value: 'Carlito', label: 'Carlito' },
    { value: 'DejaVu Sans', label: 'DejaVu Sans' },
    { value: 'Gentium Basic', label: 'Gentium Basic' },
    { value: 'Liberation Sans', label: 'Liberation Sans' },
    { value: 'Linux Biolinum G', label: 'Linux Biolinum G' },
    { value: 'Rubik', label: 'Rubik' },
    // { value: 'CroissantOne', label: 'CroissantOne' },
    // { value: 'Croissant One', label: 'Croissant One' }
  ]

  // Set values after agency is fetched
  useEffect(() => {
    if(!agency) {
      return
    }
    setAgencyImages({
      logo: { url: agency.logo || ''},
      cover: { url: agency.cover || ''}
    })
    setColor(agency.cover_color || '')
    setFont(agency.font_family || 'Inter')
    setUseAgencyInfo(agency.cover_agency_info || false)
    setUseAdminInfo(agency.cover_admin_info || false)
    setUseAdminPhoto(agency.cover_admin_photo || false)
    setCoverImageInput((agency.cover_image_input !== 'none' ? agency.cover_image_input : 'default') || 'default')
    setLogoInput(agency.cover_logo_input || 'agency')
    setColorInput(agency.cover_color_input || 'agency')
    setUseLogo(agency.cover_use_logo || false)
  }, [agency])

  // On color change 
  const handleColorChange = (value) => {
    setColor(value.replace('#', ''))
  }

  // On font change
  const handleFontChange = (value) => {
    setFont(value)
  }

  // To base 64
  const toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

  // On file change
  const handleFileChange = async (files, name) => {
    if(files && files.length > 0) {
      const file = files[0]
      if (!file.type.startsWith('image/')) { return }

      const data = await toBase64(file)
      let components = file.name.split('.')
      let extension = components.splice(components.length - 1, 1)[0]
      setAgencyImages({
        ...agencyImages,
        [name]: {
          url: agencyImages[name].url,
          data: data,
          name: file.name,
          type: file.type,
          extension
        }
      })
    }
  }

  // On image remove 
  const handleImageRemove = (name) => {
    setAgencyImages({
      ...agencyImages,
      [name]: {
        url: '',
        data: '',
        name: '',
        type: '',
        extension: ''
      }
    })
  }

  // Check if custom cover image is disabled
  const isCustomCoverImageDisabled = () => {
    return !agencyImages.cover.url && !agencyImages.cover.data
  }

  // On document load success
  const onDocumentLoadSuccess = ({ numPages }) => {
    const arr = []
    for(let i = 1; i < numPages + 1; i++) {
      arr.push(<Page pageNumber={i} width={300} loading="" />)
    }
    setPreviewPage(arr[0])
  }

  const agencyDefaultColor = () => {
    let colorSiteConfig = siteConfigs.find(c => c.key === 'DEFAULT_EMAIL_COLOR')
      if(colorSiteConfig) {
        return colorSiteConfig.value
      }
      return ''
  }

  // On preview
  const handlePreview = async () => {
    setLoadingPreview(true)
    setPdfPreviewData(null)

    let coverColor = color
    if(colorInput === 'agency') {
      let colorSiteConfig = siteConfigs.find(c => c.key === 'DEFAULT_EMAIL_COLOR')
      if(colorSiteConfig) {
        coverColor = colorSiteConfig.value
      }
    }

    let documentData = await fetchDocumentData({ useLogo, agencyImages, coverImageInput, color: coverColor, font, useAdminInfo, useAdminPhoto, useAgencyInfo, logoInput, agency: user?.manufacturer })
    setLoadingPreview(false)
    if(!documentData) {
      setNotification({ msg: "Une erreur s'est produite lors de la création de l'aperçu", type: 'danger' })
      return
    }
   
    setPdfPreviewData(`data:application/pdf;base64,${documentData}`)
    // const a = document.createElement("a")
    // a.href = `data:application/pdf;base64,${documentData}`
    // a.download = "preview.pdf"
    // a.click()
  }

  // On toggle preview
  const handleTogglePreview = () => {
    setShowPreview(!showPreview)
    if(!showPreview) {
      handlePreview()
    }
  }

  // On close preview
  const handleClosePreview = () => {
    setShowPreview(false)
  }

  // On open preview
  const handleOpenPreview = () => {
    setShowPreview(true)
    handlePreview()
  }

  // On save
  const handleSave = async () => {
    let coverColor = color
    if(colorInput === 'agency') {
      let colorSiteConfig = siteConfigs.find(c => c.key === 'DEFAULT_EMAIL_COLOR')
      if(colorSiteConfig) {
        coverColor = colorSiteConfig.value.replace('#', '')
      }
    }
    const images = agencyImages
    let coverLogo = agencyImages.logo
    if(logoInput === 'agency') {
      coverLogo = { url: agencyLogoUrl(user.manufacturer) }
    }
    if(!coverLogo) {
      coverLogo = ''
    }
    images.logo = coverLogo  

    await saveAgency({ agencyImages: images, color: coverColor, font, useAgencyInfo, useAdminInfo, useAdminPhoto, coverImageInput, useLogo, logoInput, colorInput })
  }

  return (
    <SettingsLayout>
      <div className="settings-profile">
        <div className="settings-profile__mobile-nav-top">
          <Link to="/settings/profile" className="with-line"><UserSolid /> Profil</Link>
          <Link to="/settings/agency" className="with-line"><Building /> Agences</Link>
          { isFeatureEnabled(FEATURE.COVER_PAGE_SETTINGS, [], partner, user) && <Link to="/settings/cover-page"><BgImage /> Page de couverture</Link> }
        </div>
        <div className="cover-page-v2">
          <h2>Sélectionnez les éléments à appliquer sur les documents</h2>

          <section className="cover-page-v2__section">

            <div className={`color-wrapper ${colorInput !== 'custom' ? "disabled" : ''}`}>
              <label>Couleur de la page de garde et des titres</label>
              <ColorPicker color={colorInput === 'custom' ? `#${color}` : agencyDefaultColor()} onChange={handleColorChange} disabled={colorInput !== 'custom'} />
            </div>
            <RadioGroup 
              name="color" 
              selected={colorInput} 
              onChange={(e) => setColorInput(e.target.value)} 
            >
              <RadioEl value="agency">Utiliser la couleur de l'agence</RadioEl>
              <RadioEl value="custom">Couleur personnalisée</RadioEl>
            </RadioGroup>

            <div className="color-wrapper">
              <label>Police de caractères</label>
              <Select onChange={handleFontChange} selected={font}>
                {fontOptions.map((font, idx) => {
                  return (
                    <Option key={idx} value={font.value}>{font.label}</Option>
                  )
                })}
              </Select>
            </div>
          </section>
          <h2>Modifier les informations affichées sur la page de couverture des documents</h2>
          <section className="cover-page-v2__section">
            <RadioGroup 
              name="logo" 
              selected={logoInput} 
              onChange={(e) => setLogoInput(e.target.value)} 
              label="Logo"
            >
              <RadioEl value="agency">Utiliser le logo de l'agence</RadioEl>
              <RadioEl value="custom">Logo personnalisé</RadioEl>
            </RadioGroup>
            <Checkbox 
              label="Afficher les informations de l’agence" 
              checked={useAgencyInfo} 
              onChange={(e) => setUseAgencyInfo(e.target.checked)} 
            />
            <Checkbox 
              label="Afficher les informations du négociateur" 
              checked={useAdminInfo}
              onChange={(e) => setUseAdminInfo(e.target.checked)}
            />
            <Checkbox 
              label="Afficher la photo du négociateur" 
              checked={useAdminPhoto}
              onChange={(e) => setUseAdminPhoto(e.target.checked)}
            />
            <Checkbox 
              label="Afficher le logo" 
              checked={useLogo}
              onChange={(e) => setUseLogo(e.target.checked)}
            />
          </section>
          <section className="cover-page-v2__section">
            <RadioGroup 
              name="cover_image" 
              selected={coverImageInput} 
              onChange={(e) => setCoverImageInput(e.target.value)} 
            >
              <RadioEl value="default">Image de couverture par défaut</RadioEl>
              <RadioEl value="custom" disabled={isCustomCoverImageDisabled()}>Image de couverture personnalisée</RadioEl>
            </RadioGroup>

            <div className="dnd-wrapper">
              <FileUploadDnD
                disabled={logoInput === 'agency'}
                btnText="Ajouter un logo" 
                accept="image/png,image/jpg,image/jpeg" 
                name="logo"
                imagePreview={logoInput === 'agency' ? agencyLogoUrl(user.manufacturer) : agencyImages.logo.data || agencyImages.logo.url}
                onChange={handleFileChange}
                onImageRemove={handleImageRemove}
                // minimumImageDimensions={{ width: 250, height: 250 }}
                note={"Largeur minimale recommandée: 250px. Si l'image du logo n'est pas carrée, elle peut avoir une bordure blanche"}
              />
              <FileUploadDnD 
                btnText="Ajouter une image de couverture personnalisée" 
                accept="image/png,image/jpg,image/jpeg" 
                name="cover"
                imagePreview={agencyImages.cover.data || agencyImages.cover.url}
                onChange={handleFileChange}
                onImageRemove={handleImageRemove}
                // minimumImageDimensions={{ width: 1000, height: 1000 }}
                note={"Largeur minimale recommandée: 1000px"}
              />
            </div> 
          </section>
          <div className="cover-page-v2__foot">
            <button className="btn preview-btn" onClick={handleOpenPreview}>Aperçu</button>
            <button className="btn" onClick={handleSave}>Sauvegarder</button>
          </div>
        </div>
      </div>

      <CoverPagePreview 
        opened={showPreview} 
        onToggle={handleTogglePreview} 
        data={pdfPreviewData}
        previewPage={previewPage}
        loading={loadingPreview}
        onDocumentLoadSuccess={onDocumentLoadSuccess}
        onPreviewRefresh={handlePreview}
        onClose={handleClosePreview}
      />
    </SettingsLayout>
  )
}

export default SettingsCoverPage