import { useState, useEffect, useContext, useCallback, useMemo, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { ReactSortable } from 'react-sortablejs'

import { Modal, Input, Switch, Alert, CustomTooltip } from '../ui_new'
import { SignaturesNotAvailableVialink, SingleDocumentSignContacts } from '.'
import { UserContext, NotificationContext, LoaderContext } from '../../context'
import { useDocumentSignActions } from '../../hooks'
import { sortArrayOfObjects, base64toBlob, availableOn, ENVIRONMENT } from '../../utils'
import { fetch_customer_by_id, fetch_customers } from '../../services/firestore'
import { convert_attachment, generate_document } from '../../services/lawstudioApi';
import config from '../../config.json'
import { get_file_data } from '../../services/storage'
import { UserPlus, Chat, CalendarAlt, InfoCircle, ChevronLeft, Search, Plus, Drag } from '../../assets/icons'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import { Document, Page, pdfjs } from 'react-pdf'
import useVialinkActions from '../../hooks/useVialinkActions'
import Button from '../UI/Button'
import { fetch_document_data } from '../../helpers/documents'
import { ClickAwayListener } from '@material-ui/core'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { Delete } from '@material-ui/icons'
import { getAttachmentPreview } from '../../services/functions'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const MAX_ATTACHMENT_SIZE = 52428800

const MAX_ATTACHMENTS_COUNT = 50

const SingleDocumentSignVialink = ({ onClose, documentName, docAttachments, docSignatureRecipients, singleDoc, docContacts, template, signatureObject }) => {
  const { setShowGlobalResponseLoader } = useContext(LoaderContext)
  const [requestSent, setRequestSent] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [signatureErrors, setSignatureErrors] = useState([])
  const [requestFailed, setRequestFailed] = useState(false)
  const [showSendRequestAlert, setShowSendRequestAlert] = useState(false)
  const [currentDocLoaded, setCurrentDocLoaded] = useState(false)
  const [attachmentPreviewsLoaded, setAttachmentPreviewsLoaded] = useState(false)
  const [verifiedCustomers, setVerifiedCustomers] = useState([])
  const [loadingVerifiedCustomers, setLoadingVerifiedCustomers] = useState(false)
  const history = useHistory()

  const { submitForm, cancelSignature, submitSignatureUpdate } = useVialinkActions(template, { ...singleDoc, attachments: docAttachments })
  const { canUseSignatures, siteConfigsLoaded, agency, user, partner, admins, isNotSQHorCAIpartner, authenticationToken } = useContext(UserContext)
  const { setNotification } = useContext(NotificationContext)

  // Disabled per request - MM-1129 Vialink: SQH partner
  // For signature contacts : we can remove the specific SQH ‘certified contacts’ fields and use the same fields as for the generic for the signataires
  const isSQHorCAI = false // !isNotSQHorCAIpartner

  const [activeTab, setActiveTab] = useState('SIGNATORIES'); //'SIGNATORIES' 'DOCUMENTS'
  const [showSection, setShowSection] = useState({ first: true, second: true, third: true, forth: true, });
  const emptyTableRowData = { id: "1", category: '', firstName: '', lastName: '', email: '', phone: '' };
  const [tableList, setTableList] = useState({ items: isSQHorCAI ? [] : [emptyTableRowData] });
  const emptyPeopleRowData = {
    firstName: '', lastName: '', email: '', valid: {
      firstName: undefined,
      lastName: undefined,
      email: undefined,
    }
  };
  const [peopleCopyReceiverList, setPeopleCopyReceiverList] = useState([emptyPeopleRowData]);
  const [signaturesOrder, setSignaturesOrder] = useState('unordered')
  const [pdfFiles, setPdfFiles] = useState([
    { name: singleDoc.name, type: 'application/pdf', file_type: 'document' },
    ...(singleDoc.attachments || []).map((attachment) => ({ ...attachment, selected: false, file_type: 'attachment' }))
  ]);
  const [maxAge, setMaxAge] = useState(10);
  const [reminder, setReminder] = useState('daily');
  const [emailData, setEmailData] = useState({ subject: '', message: '' })
  const [formValidation, setFormValidation] = useState({ showValidations: false })
  const [displayError, setDisplayError] = useState(false)
  const fileUploadRef = useRef();
  const errorMessage = useRef();
  const verifiedCustomersTimeout = useRef()

  useEffect(() => {

    const fetchDocumentData = async () => {

      if(!template || !singleDoc || !agency || !user) return

      let documentBase64 = await fetch_document_data(template, singleDoc, 'pdf', agency, user, {}, partner)
      const size = Buffer.from(documentBase64, 'base64').length
      setPdfFiles(files => {
        const newPdfFiles = [...files]
        newPdfFiles[0] = {
          ...newPdfFiles[0],
          data: documentBase64,
          size: size
        }
        return newPdfFiles
      })
      setCurrentDocLoaded(true)
    }
    if(currentDocLoaded) return 
    fetchDocumentData()
    return () => {
      setCurrentDocLoaded(false)
    }
  }, [template, singleDoc, agency, user, currentDocLoaded, partner])

  useEffect(() => {
    if(attachmentPreviewsLoaded) return
    const attachmentPreviewPromises = (docAttachments || []).map(async (attachment) => {
      const url = attachment.url
      if(attachment.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        try {
          const convertedData = await convert_attachment(attachment.url)

          const size = Buffer.from(convertedData.data, 'base64').length
          return {
            ...attachment,
            size: size,
            data: convertedData.data,
          }
        } catch(e) {
          return {
            ...attachment,
            size: NaN,
            data: '',
            error: "Une erreur s'est produite lors de la conversion de cette pièce jointe au format PDF"
          }
        }
      } else if(attachment.type !== 'application/pdf') {
        return {
          ...attachment,
          size: NaN,
          data: '',
          error: `La pièce jointe a un format invalide (${attachment.format || attachment.type})`
        }
      }
      try {
        const response = await getAttachmentPreview({ attachment_url: url })
        return {
          ...attachment,
          size: response.size,
          data: response.preview
        }
      } catch(e) {
        return {
          ...attachment,
          size: NaN,
          data: ''
        }
      }
    })

    Promise.all(attachmentPreviewPromises).then((attachments) => {
      setPdfFiles(files => {
        const newPdfFiles = [...files]
        attachments.forEach((attachment, index) => {
          newPdfFiles[index + 1] = {
            ...newPdfFiles[index + 1],
            size: attachment.size,
            data: attachment.data,
            error: attachment.error
          }
        })
        return newPdfFiles
      })
      setAttachmentPreviewsLoaded(true)
    })
  }, [singleDoc, attachmentPreviewsLoaded])

  useEffect(() => {
    if(!signatureObject) return
    setTableList({ items: signatureObject.recipients.map((recipient) => ({
      firstName: recipient.firstName,
      lastName: recipient.lastName,
      email: recipient.email,
      phone: recipient.phone,
      category: recipient.category || '',
      status: recipient.status,
      participantId: recipient.participantId,
      contactId: recipient.contactId,
      valid: {
        firstName: true,
        lastName: true,
        email: true,
        phone: true,
        category: true
      }
    }))})
    setSignaturesOrder(signatureObject.order)
    setEmailData({ subject: signatureObject.email_data?.subject || '', message: signatureObject.email_data?.message || '' })
    setPeopleCopyReceiverList(signatureObject.completion_recipients.map((recipient) => ({
      firstName: recipient.firstName,
      lastName: recipient.lastName,
      email: recipient.email,
      contactId: recipient.contactId,
    })))
  }, [signatureObject])

  const onDragEnd = (e) => {
    if (!e.destination) {
      return;
    }
    const { items } = tableList;
    const sorted = reorder(items, e.source.index, e.destination.index);
    setTableList({ items: sorted });
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onFileInputChange = (e) => {
    const inputFiles = e.target.files;
    if (inputFiles.length === 0) return;
    const newFiles = []
    for(let i = 0; i < inputFiles.length; i++) {
      const file = inputFiles[i];
      if(file.type !== 'application/pdf') {
        setNotification({ type: 'danger', msg: `Seules les pièces jointes au format PDF sont autorisées lors de l’envoi en signature. (${file.name})`, duration: 7000 })
        // e.target.value = ""
        continue;
      }
      if (file.size > MAX_ATTACHMENT_SIZE) {
        setNotification({ type: 'danger', msg: `La taille du fichier ne doit pas dépasser 50 Mo. (${file.name})` })
        // e.target.value = ""
        continue;
      }
      const fileName = file.name.split('.').slice(0, -1).join('.')
      newFiles.push({ file: file, name: fileName, type: file.type, size: file.size, selected: false, file_type: 'upload' })
    }
    setPdfFiles((pdfFiles) => {
      e.target.value = "";
      return [...pdfFiles, ...newFiles]
    });
  };

  const createObjectURLFn = (file) => {
    return URL.createObjectURL(file);
  }

  const isOverSize = (file) => {
    return file.size >= MAX_ATTACHMENT_SIZE
  };

  const isAttachmentSizeValid = useMemo(() => {
    return pdfFiles.every(file => file.size < MAX_ATTACHMENT_SIZE)
  }, [pdfFiles])

  const deletePdf = (index) => {
    setPdfFiles(files => {
      const newPdfFiles = [...files]
      newPdfFiles.splice(index, 1)
      return newPdfFiles
    });
  };

  const selectPdf = (index) => {
    setPdfFiles(files => {
      const newPdfFiles = [...files.map((file, i) => ({...file, selected: i === index ? !file.selected : false}))]
      return newPdfFiles
    })
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 MB';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  const printSizeMegabytes = (sizeBytes, decimals = 2) => {
    return `${(sizeBytes / 1024 / 1024).toFixed(decimals)} MB`
  }

  const storeTableData = (e, index) => {
    const newVal = tableList.items.map((item, i) => {
      if (i === index) {
        item[e.target.name] = e.target.value
        return item
      }
      return item
    })
    setTableList({ items: newVal })
  }

  const storePeopleData = (e, index) => {
    const newVal = peopleCopyReceiverList.map((item, i) => {
      if (i === index) {
        item[e.target.name] = e.target.value
        return item
      }
      return item
    })
    setPeopleCopyReceiverList(newVal)
  }

  const addNewRow = () => {
    const newEmptyRow = { ...emptyTableRowData, id: String(tableList.items.length + 1) }
    setTableList({ items: [...tableList.items, newEmptyRow] });
  }

  const handleAddContact = (contact) => {
    let phone = contact.phone || contact.telephone || ''
    while(phone[0] === '0') {
      phone = phone.slice(1)
    }
    if(phone && phone[0] !== '+') {
      phone = `+33 ${phone}`
    }
    const email = contact.email || contact.email_address || ''
    const newEmptyRow = { ...emptyTableRowData, id: String(tableList.items.length + 1), firstName: contact.firstName, lastName: contact.lastName, email, phone }
    setTableList({ items: [...tableList.items, newEmptyRow] });
  }

  const addNewPeopleRow = () => {
    setPeopleCopyReceiverList([...peopleCopyReceiverList, emptyPeopleRowData]);
  }

  const deleteTableItem = (e, index) => {
    e.preventDefault()
    setTableList(prev => ({ items: prev.items.filter((elem, i) => i !== index) }));
  }

  const deletePeople = (e, index) => {
    e.preventDefault()
    setPeopleCopyReceiverList(prev => prev.filter((elem, i) => i !== index));
  }

  const validSignatureModes = ['automatic', 'manual']
  const signaturesAvailable = canUseSignatures && (!template || validSignatureModes.includes(template?.signature_mode))

  const checkValidation = (obj, useCategory = true) => {
    const emailCondition = obj.email && String(obj.email.toLowerCase())
      .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    const phoneCondition = obj.phone && String(obj.phone).toLowerCase().replace(/[^0-9]/g, '').match(/\d{6,16}/)
    // const nameCondition = obj.name
    const firstnameCondition = obj.firstName
    const lastNameCondition = obj.lastName
    const valdicationObj = {
      firstName: !!firstnameCondition,
      lastName: !!lastNameCondition,
      email: !!emailCondition,
    }
    if (obj.phone !== undefined) {
      valdicationObj.phone = !!phoneCondition
    }
    if(template?.signature_mode === "manual" && useCategory) {
      valdicationObj.category = !!obj.category
    }
    return valdicationObj
  };

  useEffect(() => {
    // if(signatureObject) return // dont run this if signature object is loaded
    if (tableList.items && tableList.items !== formValidation.tableList) {
      
      setTableList(list => {
        let newItems = list.items.map((item, index) => {
          item.valid = checkValidation(item);
          return item;
        });
        setFormValidation(prev => ({ ...prev, tableList: newItems }))
        return { items: newItems }
      });
      
    }
  }, [tableList.items, formValidation.tableList, signatureObject])

  useEffect(() => {
    // if(signatureObject) return // dont run this if signature object is loaded
    if (peopleCopyReceiverList !== formValidation.peopleList) {
      
      setPeopleCopyReceiverList(list => {
        let newItems = list.map((item, index) => {
          if (item.firstName?.length > 0 || item.lastName?.length > 0 || item.email?.length > 0) {
            item.valid = checkValidation(item, false);
          }
          else {
            item.valid = {
              firstName: true,
              lastName: true,
              email: true,
            };
          }
          return item;
        });
        setFormValidation(prev => ({ ...prev, peopleList: newItems }))
        return newItems
      });
    }
  }, [peopleCopyReceiverList, formValidation.peopleList, signatureObject])

  const handleSubmitUpdate = async () => {
    if (Boolean(signatureObject) && !isFormValid()) {
      return
    }
    submitSignatureUpdate({
      signers: tableList.items.map(item => { 
        const signerData = {
          firstName: item.firstName,
          lastName: item.lastName,
          email: item.email,
          phone: item.phone?.replace(/\s/g, ''),
          contactId: item.contactId,
        }
        if(item.category) {
          signerData.category = item.category
        }
        return signerData
      }),
      completionRecipients: peopleCopyReceiverList.map(item => ({ firstName: item.firstName, lastName: item.lastName, email: item.email, contactId: item.contactId })),
      reminder,
      signature: signatureObject,
      setShowSendRequestAlert,
      setShowResponseLoader: setShowGlobalResponseLoader,
      setRequestSent, setSuccessMessage,
      setRequestFailed,
      setSignatureErrors
    })
  }

  const handleSubmitContacts = (e) => {
    e.preventDefault()
    setFormValidation(prev => ({ ...prev, showValidations: true }))
    if (Boolean(signatureObject) && isFormValid()) {
      handleSubmitUpdate()
      return
    }
    if(!Boolean(signatureObject) && isFormValid()) {
      setActiveTab('DOCUMENTS')
    } else {
      displayTabChangeError()
    }
  }

  const isFormValid = () => {
    function hasFalseValue(obj) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key) && obj[key] === false) {
          return true;
        }
      }
      return false;
    }
    return !tableList.items.some(item => hasFalseValue(item.valid)) && !peopleCopyReceiverList.some(item => hasFalseValue(item.valid));
  }
  // On submit
  const handleSubmit = (e) => {
    e.preventDefault()
    submitForm({
      attachments: pdfFiles,
      signers: tableList.items.map(item => { 
        const signerData = {
          firstName: item.firstName,
          lastName: item.lastName,
          email: item.email,
          phone: item.phone?.replace(/\s/g, '')
        }
        if(item.category) {
          signerData.category = item.category
        }
        return signerData
        }),
      completionRecipients: peopleCopyReceiverList.map(item => ({ firstName: item.firstName, lastName: item.lastName, email: item.email })),
      emailData,
      maxAge,
      reminder,
      signaturesOrder,
      documentPages: 1, // currentDocNumOfPages,
      setShowSendRequestAlert,
      setShowResponseLoader: setShowGlobalResponseLoader,
      setRequestSent, setSuccessMessage,
      setRequestFailed,
      setSignatureErrors
    })
  }

  const displayTabChangeError = () => {
    setDisplayError(true)
    setTimeout(() => {
      document.querySelector('.signature-modal-v2').scroll({ top: 1000, left: 0, behavior: "smooth", })
    }, 0)
  }


  const handleCancelSignature = (e) => {
    e.preventDefault()
    if(window.confirm("Êtes-vous sûr de vouloir annuler cette cérémonie de signature?")) {
      cancelSignature({
        folderId: signatureObject.folder_id,
        signatureId: signatureObject.id,
        setShowResponseLoader: setShowGlobalResponseLoader,
        setSuccessMessage
      })
    }
  }

  const onCloseWithExtentedFeature = (e) => {
    if (signatureObject || requestSent || tableList.items.length === 0) {
      e.preventDefault()
      onClose()
      return
    }
    if (tableList.items[0].firstName || tableList.items[0].lastName || tableList.items[0].email || tableList.items[0].phone || peopleCopyReceiverList[0].firstName || peopleCopyReceiverList[0].lastName || peopleCopyReceiverList[0].email) {
      let text = "Souhaitez-vous abandonner cet envoi ? Les données saisies ne seront pas conservées.";
      if (window.confirm(text) === true) {
        e.preventDefault()
        onClose()
      } else {
        e.preventDefault()
      }
    } else {
      e.preventDefault()
      onClose()
    }
  }
  const goToDocumentsTab = () => {
    setDisplayError(false)
    setActiveTab('DOCUMENTS')
  }

  const handleVerifyDocument = async () => {
    onClose()
    history.push('/signatures')
  }
    
  const totalPrice = useMemo(() => {
    const basePrice = 0.9
    const additionalPrice = 0.46
    const additionalSigners = tableList.items.length - 6
    return (basePrice + (additionalSigners > 0 ? additionalSigners * additionalPrice : 0)).toFixed(2)
  }, [tableList.items])

  const fileNameWithExtension = (file) => {
    if(!file) return ''
    let fileExtension
    if(!file.type) {
      fileExtension = 'pdf'
    } else {
      fileExtension = file.type.split('/')[1].split('+')[0]
    }
    return `${file.name}.${fileExtension}`
  }

  const isParticipantLocked = (participant) => {
    return Boolean(signatureObject) && (participant.status !== "IN_PROGRESS" || !Boolean(participant.contactId))
  }

  const isContactLocked = (contact) => {
    return Boolean(signatureObject) && !Boolean(contact.contactId)
  }

  const isSignatureLocked = () => {
    return Boolean(signatureObject) && signatureObject.status !== "IN_PROGRESS"
  }

  const processedPhoneNumber = (number) => {
    if(!number) {
      return ''
    }
    return number.replace(/[^0-9+]/g, '')
  }

  // Search customers change handler
  const searchVerifiedCustomersChangeHandler = (value) => {
    if(verifiedCustomersTimeout.current) {
      clearTimeout(verifiedCustomersTimeout.current)
      verifiedCustomersTimeout.current = null
    }
    if(value === '') {
      setVerifiedCustomers([])
      setLoadingVerifiedCustomers(false)
    }else if (value.length > 2) {
      setLoadingVerifiedCustomers(true)
      verifiedCustomersTimeout.current = setTimeout(async () => {
        const res = await fetch_customers(authenticationToken, value)
        if(res && Array.isArray(res)) {
          const customersArr = []
          res.forEach(customer => {
            if(customer.email && customer.firstname && customer.lastname && customer.certification === 1) {
              customersArr.push({
                email: customer.email,
                firstName: customer.firstname,
                lastName: customer.lastname,
                phone: processedPhoneNumber(customer.mobile_phone || customer.phone || ''),
              })
            }
          })
          const sortedArr = sortArrayOfObjects(customersArr, 'lastName', 'asc')
          setVerifiedCustomers(sortedArr)
        }
        setLoadingVerifiedCustomers(false)
      }, 1000)
    }
  }

  const isSubmitEnabled = useMemo(() => {    
    // Boolean(signatureObject) || !isAttachmentSizeValid
    return !Boolean(signatureObject) && isAttachmentSizeValid && pdfFiles.length > 0 && pdfFiles.length <= MAX_ATTACHMENTS_COUNT
  }, [isAttachmentSizeValid, pdfFiles, signatureObject])

  return (
    <Modal onClose={onCloseWithExtentedFeature} className="signature-modal-v2">

      {(!requestSent && signaturesAvailable && !signatureErrors.length > 0) ? (
        <div className="signature-modal-v2-inner">
          <h2>{documentName}</h2>
          <div className="signature-modal-v2-inner__head">
            <ul>
              <li className={`${activeTab === 'SIGNATORIES' ? "active no-cursor" : ""}`} onClick={() => setActiveTab('SIGNATORIES')}>SÉLECTION DES SIGNATAIRES</li>
              <li className={`${activeTab === 'DOCUMENTS' ? "active no-cursor" : ""}`} onClick={() => (isFormValid() || Boolean(signatureObject)) ? goToDocumentsTab() : displayTabChangeError()}>SÉLECTION DES DOCUMENTS</li>
            </ul>
          </div>
          {activeTab === 'SIGNATORIES' && <div className="tab-content">
            <p className="top-text">Le prix de la cérémonie comprenant 6 signataires est de 0,90€ HT. A partir de 7 signataires, un supplément de 0,46€ HT par signataire supplémentaire sera appliqué.</p>

            <div className="form-parent">
              <form>
                <div className='heading-parent'>
                  <div className='heading' onClick={() => setShowSection(prev => ({ ...prev, first: !prev.first }))}>
                    <div className='icon'>
                      <UserPlus />
                    </div>
                    <span className="text">Signataires</span>
                    <span className={`chevron ${showSection.first ? "open" : ""}`}><ChevronLeft /></span>
                  </div>
                </div>
                <SlideDown>
                  {showSection.first && <>
                    <div className='custom-radio'>
                      <div className="" onClick={isSignatureLocked() ? () => {} : () => setSignaturesOrder('unordered')}>
                        <input type="radio" id="first" name="radio-group" checked={signaturesOrder === 'unordered'} disabled={isSignatureLocked()} />
                        <label htmlFor="first"> Envoi en simultané</label>
                      </div>
                      <div className="" onClick={isSignatureLocked() ? () => {} : () => setSignaturesOrder('ordered')}>
                        <input type="radio" id="second" name="radio-group" checked={signaturesOrder === 'ordered'} disabled={isSignatureLocked()} />
                        <label htmlFor="second"> Envoi par ordre de signature</label>
                      </div>
                      <CustomTooltip content="Si vous choisissez l’envoi par ordre de signature, les signataires ne recevront pas en même temps la demande de signature, mais signeront les uns après les autres, selon l’ordre que vous aurez déterminé.">
                        <div className="info"> <InfoCircle /> </div>
                      </CustomTooltip>
                    </div>
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="Table">
                        {(provided) => (
                          <table {...provided.droppableProps} ref={provided.innerRef}>
                            <thead>
                              <tr>
                                {signaturesOrder === 'ordered' && <th>
                                  Ordre
                                </th>}
                                { template?.signature_mode === "manual" && <th>
                                  Catégorie
                                </th> }
                                <th>
                                  Prénom
                                </th>
                                <th>
                                  Nom
                                </th>
                                <th>
                                  <div>
                                    E-mail
                                    <CustomTooltip
                                      content="L’email est obligatoire car la demande de signature sera envoyée aux signataires à l’adresse email indiquée.">
                                      <div className="info"> <InfoCircle /> </div>
                                    </CustomTooltip>
                                  </div>
                                </th>
                                <th>
                                  <div>
                                    Numéro téléphone mobile
                                    <CustomTooltip content="Le numéro de téléphone est obligatoire car chaque signataire recevra un code pour l'autoriser à accéder aux documents à signer.">
                                      <div className="info"> <InfoCircle /> </div>
                                    </CustomTooltip>
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {tableList?.items.map((item, index) => (
                                <Draggable
                                  isDragDisabled={signaturesOrder === 'unordered'}
                                  key={`draggable_${item.id || index}`}
                                  draggableId={`${item.id || index}`}
                                  index={index}
                                >
                                  {(provided) => (
                                    <tr
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      {signaturesOrder === 'ordered' && <td>
                                        <div className="order-column">
                                          <b>{index + 1}</b>
                                          <Drag />
                                        </div>
                                      </td>}
                                      { template?.signature_mode === "manual" && <td>
                                        <select className={`custom-select ${formValidation.showValidations && !item?.valid?.category ? 'invalid' : ''}`} name="category" value={item.category || ''} onChange={(e) => storeTableData(e, index)} disabled={isParticipantLocked(item)}>
                                          <option value="" disabled>Sélectionner</option>
                                          <option value="seller">Vendeur</option>
                                          <option value="owner">Propriétaire</option>
                                          <option value="acquirer">Acquéreur</option>
                                          <option value="lessor">Bailleur</option>
                                          <option value="tenant">Locataire</option>
                                          <option value="representative">Mandataire</option>
                                          <option value="surety">Cautionnaire</option>
                                        </select>
                                        {formValidation.showValidations && !item?.valid?.category && <span className='not-valid'>Non renseigné</span>}
                                      </td> }
                                      <td>
                                        <input className={`custom-input ${formValidation.showValidations && !item?.valid?.firstName ? 'invalid' : ''}`} value={item.firstName} type="text" name="firstName" onChange={(e) => storeTableData(e, index)} disabled={isParticipantLocked(item) || isSQHorCAI} />
                                        {formValidation.showValidations && !item?.valid?.firstName && <span className='not-valid'>Non renseigné</span>}
                                      </td>
                                      <td>
                                        <input className={`custom-input ${formValidation.showValidations && !item?.valid?.lastName ? 'invalid' : ''}`} value={item.lastName} type="text" name="lastName" onChange={(e) => storeTableData(e, index)} disabled={isParticipantLocked(item) || isSQHorCAI} />
                                        {formValidation.showValidations && !item?.valid?.lastName && <span className='not-valid'>Non renseigné</span>}
                                      </td>
                                      <td>
                                        <input className={`custom-input ${formValidation.showValidations && !item?.valid?.email ? 'invalid' : ''}`} value={item.email} type="email" name="email" onChange={(e) => storeTableData(e, index)} disabled={isParticipantLocked(item) || isSQHorCAI} />
                                        {formValidation.showValidations && !item?.valid?.email && <span className='not-valid'>Non valide</span>}
                                      </td>
                                      <td>
                                        <div className={`phone-column ${formValidation.showValidations && !item?.valid?.phone ? 'invalid' : ''}`}>
                                          <PhoneInput
                                            inputProps={{ name: 'phone' }}
                                            country={"fr"}
                                            enableSearch={true}
                                            value={item.phone}
                                            onChange={(phone) => storeTableData({ target: { name: 'phone', value: phone } }, index)}
                                            disabled={isParticipantLocked(item) || isSQHorCAI}
                                          />
                                          {tableList.items.length > 1 && !signatureObject && <button className="delete" onClick={(e) => deleteTableItem(e, index)}>
                                            <Delete />
                                          </button>
                                          }
                                        </div>
                                        {formValidation.showValidations && !item?.valid?.phone && <span className='not-valid'>Non valide</span>}
                                      </td>
                                    </tr>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </tbody>
                          </table>
                        )}
                      </Droppable>
                    </DragDropContext>
                    {!signatureObject && <ul className='search'>
                      {!isSQHorCAI && <li>
                        <ContactSearch
                          title="Rechercher un signataire dans le document"
                          noDataText="Pas de contacts"
                          contacts={docContacts.map(c => ({ ...c, firstName: c.firstname, lastName: c.lastname }))}
                          onAddContact={handleAddContact}
                        />
                      </li> }
                      {!isSQHorCAI && <li>
                        <div>
                          <span onClick={addNewRow}>
                            <Plus />Ajouter un nouveau signataire (ligne vide)
                          </span>
                        </div>
                      </li>}
                      {isSQHorCAI && <li>
                        <ContactSearch
                          title="Rechercher un contact certifié"
                          noDataText="Pas de contacts"
                          contacts={verifiedCustomers}
                          onAddContact={handleAddContact}
                          onSearchChange={searchVerifiedCustomersChangeHandler}
                          loading={loadingVerifiedCustomers}
                          searchDisabled={true}
                          minSearchLength={3}
                        />
                      </li> }
                      <li>
                        <ContactSearch
                          title="Sélectionner un signataire représentant l'agence"
                          noDataText="Pas de contacts"
                          contacts={(admins || []).filter(a => ["1", "2"].includes(`${a.status}`)).map(c => ({ ...c, firstName: c.firstname, lastName: c.lastname }))}
                          onAddContact={handleAddContact}
                        />
                      </li>
                    </ul> }
                    <br /><br />
                  </>}
                </SlideDown>
                {/* <div className='heading-parent'>
                  <div className='heading' onClick={() => setShowSection(prev => ({ ...prev, second: !prev.second }))}>
                    <div className='icon'>
                      <Chat />
                    </div>
                    <span className="text">Ecrire un message pour les destinataires</span>
                    <span className={`chevron ${showSection.second ? "open" : ""}`}><ChevronLeft /></span>
                  </div>
                </div>
                <SlideDown>
                  {showSection.second && <>
                    <div className="form-group">
                      <label>Objet</label>
                      <input className="custom-input" placeholder="[Immobilière d'exception] Documents à signer" value={emailData.subject} onChange={e => setEmailData({ ...emailData, subject: e.target.value })} disabled={Boolean(signatureObject)} />
                    </div>
                    <div className="form-group">
                      <label>Message
                        <CustomTooltip content="Ajouter un message dans l'e-mail envoyé au(x) signataire(s).">
                          <div className="info"> <InfoCircle /> </div>
                        </CustomTooltip>
                      </label>
                      <textarea className="custom-input" placeholder="Facultatif" value={emailData.message} onChange={e => setEmailData({ ...emailData, message: e.target.value })} disabled={Boolean(signatureObject)}></textarea>
                    </div>
                    <br /><br />
                  </>}
                </SlideDown> */}
                <div className='heading' onClick={() => setShowSection(prev => ({ ...prev, third: !prev.third }))}>
                  <div className='icon'>
                    <CalendarAlt />
                  </div>
                  <span className="text">Ajouter des personnes à notifier lorsque les documents seront signés</span>
                  <span className={`chevron ${showSection.third ? "open" : ""}`}><ChevronLeft /></span>
                </div>
                <SlideDown>
                  {showSection.third && <>
                    <table>
                      <thead>
                        <tr>
                          <th>
                            Prénom du destinataire
                          </th>
                          <th>
                            Nom du destinataire
                          </th>
                          <th>
                            E-mail du destinataire
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {peopleCopyReceiverList?.map((item, index) => (
                          <tr key={`external_contact_${index}`}>
                            <td>
                              <input className={`custom-input ${formValidation.showValidations && !item?.valid?.firstName ? 'invalid' : ''}`} value={item.firstName}
                                type="text" name="firstName" placeholder="Prénom" onChange={(e) => storePeopleData(e, index)} disabled={isContactLocked(item)} />
                              {formValidation.showValidations && !item?.valid?.firstName && <span className='not-valid'>Non renseigné</span>}
                            </td>
                            <td>
                              <input className={`custom-input ${formValidation.showValidations && !item?.valid?.lastName ? 'invalid' : ''}`} value={item.lastName}
                                type="text" name="lastName" placeholder="Nom" onChange={(e) => storePeopleData(e, index)} disabled={isContactLocked(item)} />
                              {formValidation.showValidations && !item?.valid?.lastName && <span className='not-valid'>Non renseigné</span>}
                            </td>
                            <td>
                              <div className='email-parent'>
                                <div>
                                  <input className={`custom-input ${formValidation.showValidations && !item?.valid?.email ? 'invalid' : ''}`} value={item.email}
                                    type="email" name="email" placeholder="E-mail" onChange={(e) => storePeopleData(e, index)} disabled={isContactLocked(item)} />
                                  {formValidation.showValidations && !item?.valid?.email && <span className='not-valid'>{item?.valid?.email}</span>}
                                </div>
                                {peopleCopyReceiverList.length > 1 && !Boolean(signatureObject) && <button className="delete" onClick={(e) => deletePeople(e, index)}>
                                  <Delete />
                                </button>}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {!Boolean(signatureObject) && <ul className='search'>
                      <li>
                        <div>
                          <span onClick={addNewPeopleRow}>
                            <Plus />  Ajouter
                          </span>
                        </div>
                      </li>
                    </ul> }
                  </>}
                  <br />
                </SlideDown>
                <div className='heading' onClick={() => setShowSection(prev => ({ ...prev, forth: !prev.forth }))}>
                  <div className='icon'>
                    <UserPlus />
                  </div>
                  <span className="text">Rappels et délai d'expiration</span>
                  <span className={`chevron ${showSection.forth ? "open" : ""}`}><ChevronLeft /></span>

                </div>
                <SlideDown>
                  {showSection.forth && <>
                    <div>
                      <label>Envoi d'un e-mail de rappel</label>
                      <br />
                      <select className="custom-select" disabled={isSignatureLocked()} value={reminder} onChange={e => setReminder(e.target.value)}>
                        <option value="daily">Quotidien</option>
                        <option value="semidaily" >Tous les 2 jours</option>
                        <option value="none">Ne pas envoyer de rappel</option>
                      </select>
                    </div>
                    <br />
                    <div>
                      <label>Expiration</label>
                      <p>
                        La demande de signature va expirer après
                        <input type="number" value={maxAge} onChange={e => setMaxAge(e.target.value)} className="custom-input number" disabled={Boolean(signatureObject)} />
                        jour(s).
                      </p>
                    </div>
                  </>}
                </SlideDown>
                {(displayError || (!isFormValid() && formValidation.showValidations)) && <div className='error' ref={errorMessage}>
                  Des informations sont manquantes ou mal renseignées.
                </div>}
                <div className='buttons-row'>
                  {signatureObject && <button className='btn btn--large btn--transparent btn--border-primary' onClick={handleCancelSignature}>Annuler l'envoi</button>}
                  <button className='btn btn--large btn--transparent btn--border-primary' onClick={signatureObject ? onClose : onCloseWithExtentedFeature}>{signatureObject ? 'Retour' : 'Annuler'}</button>
                  <button className='btn btn--large' onClick={handleSubmitContacts}>{ Boolean(signatureObject) ? 'Enregistrer' : 'Suivant'}</button>
                </div>
              </form>
            </div>
          </div>}
          {activeTab === 'DOCUMENTS' && <div className="tab-content">
            <b className='title'>
              <span className="text">Vérifiez les documents à envoyer</span>
              {!Boolean(signatureObject) && <div className="browse" onClick={() => fileUploadRef.current.click()}> <Plus /> </div> }
              <input type="file" multiple onChange={onFileInputChange} hidden ref={fileUploadRef} accept="application/pdf" />
            </b>
            <div className="preview">
              {pdfFiles?.map((pdfFile, index) => (
                <div key={`pdfFile_${index}`} onClick={() => selectPdf(index)} className={`${pdfFile.selected ? 'selected' : ''}`}>
                  <span className="index">{index + 1}</span>
                  <div className='pdf-preview-wrapper'>
                    {(pdfFile.file_type === "document" || pdfFile.file_type === "attachment") && !pdfFile.data ? 
                      pdfFile.error ? <div className='document-preview-dummy'><div className='ml-5 mt-5'>{pdfFile.error}</div></div> : <div className='document-preview-dummy'>Chargement...</div> :
                      pdfFile.file ? <div className='document-preview-dummy'><div className='ml-10 mt-5'>{pdfFile.name}</div></div> : <Document
                        file={pdfFile.data ? `data:application/pdf;base64,${pdfFile.data}` : pdfFile.file ? createObjectURLFn(pdfFile.file) : '' }
                        options={{}}
                        renderMode="canvas"
                        loading="Chargement...">
                        <Page pageNumber={1} width={100} loading="" />
                      </Document>
                    }
                  </div>
                  <footer>
                    <span className='file-name'>{fileNameWithExtension(pdfFile).slice(0, 14)} {fileNameWithExtension(pdfFile).length > 14 && '...'}</span>
                    { pdfFile.file_type !== "document" && <button onClick={() => deletePdf(index)} className="delete"><Delete /></button> }
                  </footer>
                </div>
              ))}
            </div>
            {pdfFiles.length > MAX_ATTACHMENTS_COUNT && <p className='text-error mb-20'>Vous avez atteint la limite de {MAX_ATTACHMENTS_COUNT} documents maximum à joindre à l'envoi. Vous pouvez néanmoins fusionner des annexes, dans la limite de 50 MB maximum par document.</p>}
            <p>
              {pdfFiles.find(f => f.selected) && <span className={`${isOverSize(pdfFiles.find(f => f.selected)) ? 'over-size' : ''}`}> Taille du document sélectionné : {printSizeMegabytes(pdfFiles.find(f => f.selected).size)}</span> }
              <br />
              <span className={!isAttachmentSizeValid ? 'over-size' : ''}>Taille maximale autorisée par document : 50 MB</span><br />
              <span>Seules les pièces jointes au format PDF sont autorisées lors de l’envoi en signature</span>
            </p><br /><br />
            <p>
              {`Vous serez facturé ${totalPrice}€ HT pour cet envoi (prix de la cérémonie à 0,90€ HT + 0,46€ HT par signataire supplémentaire au delà de 6 signataires).`}
            </p>

            <div className='buttons-row'>
              <button className='btn btn--large btn--transparent btn--border-primary' onClick={() => setActiveTab("SIGNATORIES")}>Précédent</button>
              <button className='btn btn--large btn--success' onClick={handleSubmit} disabled={!isSubmitEnabled}>
                <span className='icon'> <Plus /></span>
                Envoyer
              </button>
            </div>
          </div>}
        </div>) : !siteConfigsLoaded
        ? <div className="loader-wrapper"><div className="loader-v2"></div></div>
        : !signaturesAvailable && <SignaturesNotAvailableVialink />}

      {signatureErrors.length > 0 && 
        <div className="signatures-success-v2">
          <div className="signatures-success-v2__top">
          </div>
          <div className="message-v2 message-v2--error">{signatureErrors.join('')}</div>
          <div className="buttons-wrapper buttons-wrapper--gap-20">
            <button className="btn btn--large btn--transparent btn--border-primary" type="button" onClick={() => setSignatureErrors([])}>Retour</button>
          </div>
        </div>
      }
      {requestSent &&
        <div className="signatures-success-v2">
          <div className="signatures-success-v2__top">
            <h2>{singleDoc.name}</h2>
          </div>
          <div className="message-v2 message-v2--success">{successMessage}</div>
          <div className="buttons-wrapper buttons-wrapper--gap-20">
            <button className="btn btn--large btn--transparent btn--border-primary" type="button" onClick={onClose}>Fermer</button>
            <button className="btn btn--large" type="button" onClick={handleVerifyDocument}>Vérifier le document</button>
          </div>
        </div>
      }
    </Modal>
  )
}

const ContactSearch = ({ title, searchPlaceholder = "", contacts = [], onAddContact = () => {}, noDataText = "Pas de contacts", onSearchChange, loading, listVisible, searchDisabled, minSearchLength }) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const [search, setSearch] = useState('')
  const [filteredContacts, setFilteredContacts] = useState([])
  const inputRef = useRef(null)

  useEffect(() => {
    if(!search || searchDisabled) {
      setFilteredContacts(contacts)
      return
    }
    const filtered = contacts.filter(a => {
      return (a.firstName || '').toLowerCase().includes(search.toLowerCase()) || (a.lastName || '').toLowerCase().includes(search.toLowerCase())
    })
    setFilteredContacts(filtered)
  }, [contacts, search, searchDisabled])

  const searchChangeHandler = (e) => {
    let searchString = (e.target.value || "").trim()

    setSearch(searchString)
    if(onSearchChange) {
      onSearchChange(searchString)
    }
    if(searchString) {
      setShowDropdown(true)
    } else {
      setShowDropdown(false)
    }
  }

  const handleContactSelected = (contact) => {
    setShowDropdown(false)
    if(!searchDisabled) {
      setSearch('')
    }
    onAddContact({ 
      firstName: contact.firstName,
      lastName: contact.lastName,
      email: contact.email || contact.email_address,
      phone: contact.mobile || contact.mobile_phone || contact.phone || contact.phone_1 || contact.telephone || '',
    })
  }

  const handleFocus = (e) => {
    e.preventDefault()
    inputRef.current.focus()
    setShowDropdown(true)
  }

  const handleFocusCapture = (e) => {
    setShowDropdown(true)
    e.preventDefault()
    // inputRef.current.focus()
  }

  return (
    <div className={"search-row-wrapper"}>
      <span>
        <Plus />
        { title }
        <div className='search-input'>
          <button onClick={handleFocus}><Search /></button>
          <input ref={inputRef} className="custom-input" placeholder={searchPlaceholder} onChange={searchChangeHandler} value={search} onFocusCapture={handleFocusCapture} onFocus={handleFocusCapture} />
        </div>
      </span>
      <ClickAwayListener onClickAway={() => setShowDropdown(false)}>
        { loading ? (
          <div className="dropdown-el default-dropdown users-dropdown">
            <div className="loader-v2"></div>
          </div>
        ) : (showDropdown && search.length < minSearchLength) ? (
          <div className="dropdown-el default-dropdown users-dropdown">
            <p className="no-data">{`Tapez au moins ${minSearchLength} caractères`}</p>
          </div>
        ) : (showDropdown || listVisible) ? 
          <div className="dropdown-el default-dropdown users-dropdown">
            {filteredContacts.length > 0 ? filteredContacts.map((a, i) => (
              <p key={`contact_filter_option_${i}`} onClick={() => handleContactSelected(a)}>{a.firstName} {a.lastName}</p>
            )) 
              : <p className="no-data">{noDataText}</p>
            }
          </div>
          : <div></div>
        }
      </ClickAwayListener>
    </div>
  )
}

export default SingleDocumentSignVialink