import React, { useContext } from 'react'
import { useHistory  } from 'react-router'
import moment from 'moment'
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined'

import Loader from '../../UI/Loader'
import SupportPageLayout from '../../layouts/SupportPageLayout'
import { HelpContext } from '../../../context'
import { MainLayout } from '../../../layouts'

const Documentation = ({v2}) => {
  const { documentationArticles, articlesFetched } = useContext(HelpContext)
  const history = useHistory()

  // Category click handler
  const articleClickHandler = (e, id) => {
    e.preventDefault()
    history.push(`/documentation/${id}`)
  }

  return (
    
    <MainLayout className="page-help" pageTitle="Documents utiles">
      <SupportPageLayout title="Documents utiles" v2={v2}>
        <div className="help-category">
          <div className="help-category__body">
            {!articlesFetched 
              ? <div className="loader-wrapper loader-wrapper--2"><Loader normal small primary /></div>
              : documentationArticles.length 
                ? <ul>
                  {documentationArticles.map((article, idx) => (
                    <li key={idx}>
                      <a href="/#" onClick={(e) => articleClickHandler(e, article.id)}><DescriptionIcon /> {article.title.en}</a>
                      {/* <span>{moment(article.date).format('DD MMM YYYY @ HH:mm')}</span> */}
                    </li>
                  ))}
                </ul>
                :
                <div className="support-page__no-data"><p>{`Pas d'articles`}</p></div>
          }
          </div>
        </div>
      </SupportPageLayout>
    </MainLayout>
  )
}

export default Documentation