import React, { useState } from 'react'
import { CustomTooltip } from '../../ui_new'
import { InfoCircle } from '../../../assets/icons'

const SenderModalContentAssociate = ({ onReturn, onSubmit }) => {

  const [associateSenderData, setAssociateSenderData] = useState({
    email: '',
  })

  const [associateSenderDataError, setAssociateSenderDataError] = useState(null)

  const handleChangeAssociateSenderData = (e) => {
    const { name, value } = e.target
    setAssociateSenderData({ ...associateSenderData, [name]: value })
  }

  const validateAssociateSenderData = () => {
    // const generalError = 'Des informations sont manquantes ou mal renseignées'
    if (associateSenderData.email === '') {
      setAssociateSenderDataError('Veuillez renseigner l\'email')
      // setAssociateSenderDataError(generalError)
      return false
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      if (!emailRegex.test(associateSenderData.email)) {
        setAssociateSenderDataError('Veuillez renseigner un email valide')
        // setAssociateSenderDataError(generalError)
        return false
      }
    }
    setAssociateSenderDataError(null)
    return true
  }

  const handleSubmitAssociate = (e) => {
    e.preventDefault()
    const isValid = validateAssociateSenderData()
    if (!isValid) {
      return
    }
    onSubmit(associateSenderData)
  }


  return (
    <div className=''>
      <h2 className='modal-title'>Associer un compte expéditeur AR24</h2>
      <ul className='modal-list'>
        <li className='modal-list-item'>
          <p>
            Pour associer votre compte expéditeur AR24 à Immo Docs, veuillez renseigner l'e-mail lié à votre compte expéditeur dans le champ ci-dessous :
          </p>
        </li>
        <li>
          <div className={`form--group w-half ${associateSenderDataError ? 'invalid' : ''}`}>
            <label className='form-label' htmlFor='email'>Email</label>
            <div className='form-wrapper'>
              <input
                className='form-input'
                type='text'
                placeholder='Email'
                name='email'
                value={associateSenderData.email}
                onChange={handleChangeAssociateSenderData}
              />
              <div className='input-tooltip align-end'>
                <CustomTooltip content={"L'email a renseigner doit être celui qui est associé au compte AR24."}>
                  <div className="info"> <InfoCircle /></div>
                </CustomTooltip>
              </div>
            </div>
          </div>
        </li>
        <li className='modal-list-item'>
          <p>
            Vous recevrez un e-mail vous demandant de valider l'association de votre compte expéditeur AR24 à Immo Docs.
          </p>
        </li>
        {associateSenderDataError && <li className='modal-list-item'>
          <p className='text-error'>
            {associateSenderDataError}
          </p>
        </li>}
      </ul>
      <ul className='flex flex-row justify-end gap-10'>
        <li>
          <button
            className='btn btn--large btn--transparent btn--border-primary'
            onClick={onReturn}
          >
            Précédent
          </button>
        </li>
        <li>
          <button
            className='btn btn--large btn--success'
            onClick={handleSubmitAssociate}
          >
            Soumettre
          </button>
        </li>
      </ul>
    </div>
  )
}

export default SenderModalContentAssociate