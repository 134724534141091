import { Children, cloneElement } from 'react'

import { Check } from '../../assets/icons'

const RadioGroup = ({ children, label, name, onChange, selected }) => {

  // Items
  const items = Children.map(children, (child) => {
    if(child.type !== RadioEl) {
      console.warn('Invalid child element, please use Radio')
    }

    const props = {
      onChange: onChange,
      name: name,
      selected: selected
    }

    return cloneElement(child, props)
  })

  return (
    <div className="radio-group">
      {label && (
        <div className="radio-group__label">{label}</div>
      )}
      <div className="radio-group__items">
        {items}
      </div>
    </div>
  )
}

export const RadioEl = ({ children, ...props }) => {
  return (
    <div className="radio-group__radio">
      <label>
        <input type="radio" name={props.name} onChange={props.onChange} value={props.value} checked={props.selected === props.value} disabled={props.disabled} />
        <div className="radio-group__radio_check"><Check /></div>
        <p>{children}</p>
      </label>
    </div>
  )
}

export default RadioGroup 