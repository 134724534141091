const Archive = () => {
  return (
    <span className="svg-icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="17.925" height="16.4" viewBox="0 0 17.925 16.4">
        <g id="Group_702" data-name="Group 702" transform="translate(0.2 0.2)">
          <path id="Path_1525" data-name="Path 1525" d="M17.525,4.571H16.494V1.714a.546.546,0,0,0-.515-.571H1.546a.546.546,0,0,0-.515.571V4.571H0V1.714A1.639,1.639,0,0,1,1.546,0H15.979a1.639,1.639,0,0,1,1.546,1.714V4.571" transform="translate(0 0)" fill="#1e548a" stroke="#1d5489" strokeWidth="0.4" fillRule="evenodd"/>
          <path id="Path_1526" data-name="Path 1526" d="M15.716,19.429H3.524A1.63,1.63,0,0,1,2,17.714V8H3.016v9.714a.543.543,0,0,0,.508.571H15.716a.543.543,0,0,0,.508-.571V8H17.24v9.714a1.63,1.63,0,0,1-1.524,1.714" transform="translate(-0.857 -3.429)" fill="#1e548a" stroke="#1d5489" strokeWidth="0.4" fillRule="evenodd"/>
          <path id="Path_1527" data-name="Path 1527" d="M17.143,13.143H12.571a.571.571,0,0,1,0-1.143h4.571a.571.571,0,0,1,0,1.143" transform="translate(-6.094 -5.143)" fill="#1e548a" stroke="#1d5489" strokeWidth="0.4" fillRule="evenodd"/>
          <path id="Path_1528" data-name="Path 1528" d="M0,6H17.525V7.143H0Z" transform="translate(0 -2.571)" fill="#1e548a" stroke="#1d5489" strokeWidth="0.4" fillRule="evenodd"/>
        </g>
      </svg>
    </span>
  )
}

export default Archive