import { useState, useContext } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Editor } from '@tinymce/tinymce-react'
import QRCode from 'qrcode.react'

import { Modal, Input, CustomTooltip, Checkbox } from '../ui_new'
import { UserPlus, Delete } from '../../assets/icons'
import { urlSuffixForEnvironment } from '../../utils'
import {
    UserContext,
    NotificationContext,
    LoaderContext,
} from '../../context'
import { useSingleDocumentActions } from '../../hooks'
import { validateEmail } from '../../helpers/validate'
import config from '../../config.json'
import { add_document_event } from '../../services/firestore'

const SingleDocumentShare = ({
    onClose,
    singleDoc,
    documentName,
    documentId,
    attachments,
    onAttachmentShareUpdate,
    onAttachmentsShareSave,
    template,
    documentValues,
    useCustomCover,
}) => {
    const { shareDocument } = useSingleDocumentActions()
    const { partner } = useContext(UserContext)
    const { setShowGlobalResponseLoader, setGlobalResponseLoaderText } =
        useContext(LoaderContext)
    const { setNotification } = useContext(NotificationContext)
    const [view, setView] = useState('email')
    const [sentEmailId] = useState(uuidv4())
    const [email, setEmail] = useState('')
    const [recipients, setRecipients] = useState([
        {
            value: '',
            id: `recipient-${uuidv4()}`,
        },
    ])
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState(
        `
      <p>Bonjour,</p>

      <p>Vous trouverez ci-dessous le lien pour télécharger le document "${documentName}" pour relecture.</p>
      
      <p class="link">https://europe-west1-jurisur-be.cloudfunctions.net/document_v2${urlSuffixForEnvironment(
          config.environment
      )}/${documentId}/${sentEmailId}</p>
      
      <p>Nous restons à votre écoute pour toutes corrections et/ou modifications éventuelles.</p>
      
      <p>Cordialement,</p>
    `
    )

    // On recipient change
    const handleRecipientChange = (e, id) => {
        setRecipients(
            recipients.map((r) =>
                r.id === id ? { ...r, value: e.target.value } : r
            )
        )
    }

    // On add new recipient
    const handleAddNewRecipient = (e) => {
        e.preventDefault()
        const id = `recipient-${uuidv4()}`
        let recipient = {
            value: '',
            id,
        }
        setRecipients([...recipients, recipient])
    }

    // On delete recipient
    const handleDeleteRecipient = (e, id) => {
        e.preventDefault()
        setRecipients(recipients.filter((r) => r.id !== id))
    }

    // On editor change
    const handleEditorChange = (content) => {
        setMessage(content)
    }

    // On copy to clipboard
    const handleCopyToClipboard = () => {
        const value = `https://europe-west1-jurisur-be.cloudfunctions.net/document${urlSuffixForEnvironment(
            config.environment
        )}/${documentId}`

        navigator.clipboard.writeText(value)
        setNotification({ msg: 'Lien copié', type: 'success' })
    }

    // On submit
    const handleSubmit = async (e) => {
        e.preventDefault()
        if (
            email.trim() === '' ||
            subject.trim() === '' ||
            message.trim() === ''
        ) {
            return setNotification({
                msg: 'Les champs ne peuvent pas être vides',
                type: 'danger',
            })
        }

        if (!validateEmail(email.trim())) {
            return setNotification({
                msg: 'Adresse email non valide',
                type: 'danger',
            })
        }

        let recipientsInvalidEmail = false
        for (let i = 0; i < recipients.length; i++) {
            if (!validateEmail(recipients[i].value)) {
                recipientsInvalidEmail = true
                break
            }
        }

        if (recipientsInvalidEmail) {
            return setNotification({
                msg: 'Adresse email non valide',
                type: 'danger',
            })
        }

        try {
            setShowGlobalResponseLoader(true)
            setGlobalResponseLoaderText('Partage du document')
            let data = {
                message,
                email: email.trim(),
                subject: subject.trim(),
                recipients,
                sentEmailId,
                // addWatermark: useWatermark,
            }
            await shareDocument(
                data,
                {
                    ...singleDoc,
                    name: documentName,
                    values: documentValues,
                    custom_cover: useCustomCover,
                    id: documentId,
                },
                template,
                attachments
            )
            await add_document_event(singleDoc.id, {
                type: 'email_share',
                values: singleDoc.values,
                content_changes: singleDoc.content_changes,
            })
            onClose()
        } catch (err) {
            console.log(err)
        } finally {
            setShowGlobalResponseLoader(false)
            setGlobalResponseLoaderText('')
        }
    }

    return (
        <Modal onClose={onClose}>
            <div className="document-share">
                <div className="attachments-wrapper-v2">
                    <p>Sélectionnez les pièces jointes à partager</p>
                    <div className="attachments-wrapper-v2__inner">
                        {attachments.map((att, i) => {
                            return (
                                <Checkbox
                                    key={i}
                                    onChange={(e) => {
                                        onAttachmentShareUpdate(
                                            i,
                                            e.target.checked
                                        )
                                    }}
                                    checked={att.share}
                                    label={`${att.name} (${att.type})`}
                                />
                            )
                        })}
                    </div>
                    <button
                        className="btn"
                        onClick={onAttachmentsShareSave}
                    >
                        Sauvegardez les préférences de partage
                    </button>
                </div>

                <div className="document-share__head">
                    <ul>
                        <li
                            className={view === 'email' ? 'active' : null}
                            onClick={() => setView('email')}
                        >
                            Envoyer par email
                        </li>
                        <li
                            className={view === 'link' ? 'active' : null}
                            onClick={() => setView('link')}
                        >
                            Afficher le lien
                        </li>
                    </ul>
                </div>

                <div className="document-share__body">
                    {view === 'email' && (
                        <form
                            onSubmit={handleSubmit}
                            className="share-form"
                        >
                            <div className="share-form__field-wrapper">
                                <Input
                                    label="Votre adresse mail"
                                    value={email}
                                    onChange={(e) =>
                                        setEmail(e.target.value)
                                    }
                                />
                            </div>
                            {recipients.map((recipient, i) => (
                                <div
                                    className={`share-form__field-wrapper with-icon`}
                                    key={recipient.id}
                                >
                                    <Input
                                        value={recipient.value}
                                        onChange={(e) =>
                                            handleRecipientChange(
                                                e,
                                                recipient.id
                                            )
                                        }
                                        label="Adresse mail destinataire"
                                    />
                                    {i === 0 ? (
                                        <CustomTooltip content="Ajouter un destinataire">
                                            <a
                                                href="/#"
                                                className="icon"
                                                onClick={
                                                    handleAddNewRecipient
                                                }
                                            >
                                                <UserPlus />
                                            </a>
                                        </CustomTooltip>
                                    ) : (
                                        <CustomTooltip content="Supprimer le destinataire">
                                            <a
                                                href="/#"
                                                className="icon"
                                                onClick={(e) =>
                                                    handleDeleteRecipient(
                                                        e,
                                                        recipient.id
                                                    )
                                                }
                                            >
                                                <Delete />
                                            </a>
                                        </CustomTooltip>
                                    )}
                                </div>
                            ))}
                            <div className="share-form__field-wrapper">
                                <Input
                                    label="Objet du mail"
                                    value={subject}
                                    onChange={(e) =>
                                        setSubject(e.target.value)
                                    }
                                />
                            </div>
                            <div className="share-form__field-wrapper">
                                <Editor
                                    initialValue={message}
                                    tagName="editor"
                                    init={{
                                        height: 500,
                                        menubar: false,
                                        plugins: [
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount',
                                        ],
                                        toolbar:'bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent',
                                    }}
                                    onEditorChange={handleEditorChange}
                                />
                            </div>

                            <div className="share-form__actions">
                                <button
                                    className="btn btn--transparent"
                                    onClick={onClose}
                                >
                                    Annuler
                                </button>
                                <button className="btn" type="submit">
                                    Envoyer
                                </button>
                            </div>
                        </form>
                    )}

                    {view === 'link' && (
                        <div className="qr-code-section-v2">
                            <div className="qr-code-section-v2__inner">
                                <div className="qr-code-section-v2__left">
                                    <p>
                                        {`https://europe-west1-jurisur-be.cloudfunctions.net/document${urlSuffixForEnvironment(
                                                  config.environment
                                              )}/${documentId}`}
                                    </p>
                                    <button
                                        className="btn"
                                        onClick={handleCopyToClipboard}
                                    >
                                        Copier le lien dans le presse-papier
                                    </button>
                                </div>
                                <div className="qr-code-section-v2__right">
                                    <p>
                                        Scanner ce QRCode depuis votre
                                        mobile ou tablette pour acceder au
                                        lien.
                                    </p>
                                    <QRCode
                                        value={`https://europe-west1-jurisur-be.cloudfunctions.net/document${urlSuffixForEnvironment(
                                                      config.environment
                                                  )}/${documentId}`}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    )
}

export default SingleDocumentShare
