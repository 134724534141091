import { Document, pdfjs } from 'react-pdf'
import { AngleRight, Redo, AngleLeft } from '../../assets/icons'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const CoverPagePreview = ({ opened = false, onToggle, previewPage, data, onDocumentLoadSuccess, loading, onPreviewRefresh, onClose }) => {
  
  // On refresh click
  const handleRefreshPreview = () => {
    if(!onPreviewRefresh) return 
    onPreviewRefresh()
  }

  return (
    <div className={`cover-page-preview ${opened ? 'opened' : ''}`}>
      <div className="cover-page-preview__toggle" onClick={onToggle}><AngleRight /></div>
      <div className="cover-page-preview__inner u-custom-scrollbar">
        <div className="cover-page-preview__head">
          <h2><span className="text">APERÇU</span> <Redo onClick={handleRefreshPreview} /></h2>
        </div>
        <div className="cover-page-preview__body">
          <div className="cover-page-preview__body_close" onClick={onClose}><AngleLeft />Fermer</div>
          {loading ? (
            <div className="loader-v2"></div>
          ) : (
            <Document file={data} onLoadSuccess={onDocumentLoadSuccess} renderMode="canvas" loading="Chargement...">
              {previewPage}
            </Document>
          )}
        </div>
      </div>
    </div>
  )
}

export default CoverPagePreview