import React, { useEffect } from 'react';

import Modal from '../../UI/Modal';
import Input from '../../UI/Input';
import Button from '../../UI/Button';
import useForm from '../../../hooks/useForm';
import validate from '../../../helpers/validate';

const EditAttachmentModal = ({ onClose, onEdit, attachment, loading, attachmentIndex }) => {
  const initialData = {
    name: {
      required: true,
      value: ''
    }
  };
  const { formData, errors, setErrors, changeHandler, setFormData } = useForm(initialData, validate);

  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      name: {
        ...prev.name,
        value: attachment.name
      }
    }));
    // eslint-disable-next-line
  }, [attachment]);

  const submitHandler = (e) => {
    e.preventDefault();
    
    const newErrors = validate(formData, true);
    setErrors(newErrors);

    if(Object.keys(newErrors).length === 0) {
      onEdit(formData.name.value.trim(), attachmentIndex);
    }
  }

  return(
    <Modal onClose={onClose} small>
      <div className="folder-modal">
        <h2>Modifier la pièce jointe</h2>
        <form onSubmit={submitHandler} className="form">
          <Input 
            name="name"
            value={formData.name.value}
            onChange={changeHandler}
            error={errors.name}
            placeholder="Nom de la pièce jointe"
          />
          <div className="form__actions">
            <Button text="Annuler" transparent onButtonClick={onClose} />
            <Button 
              type="submit" 
              text={loading ? "Mise à jour..." : "Sauvegarder"} 
              primary 
              disabled={loading} 
            />
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default EditAttachmentModal;