import { Modal } from '../../ui_new'
import { useContext, useEffect, useState } from "react";
import SenderModalContentAdd from './SenderModalContentAdd';
import SenderModalContentCreate from './SenderModalContentCreate';
import SenderModalContentAssociate from './SenderModalContentAssociate';
import SenderModalContentModify from './SenderModalContentModify';
import { RegisteredMailContext } from '../../../context';
import SenderModalContentCreateSuccess from './SenderModalContentCreateSuccess';
import SenderModalContentAssociateSuccess from './SenderModalContentAssociateSuccess';
import SenderModalContentResendSuccess from './SenderModalContentResendSuccess';
import SenderModalContentPromptIdentityVerification from './SenderModalContentPromptIdentityVerification';
import SenderModalContentIdentityVerificationSuccess from './SenderModalContentIdentityVerificationSuccess';

const SenderModal = ({ onClose, section, loadingMessage, onCreateSender, onAssociateSender, onUpdateSender, errorMessage, onClearError }) => {

  const [content, setContent] = useState('add')

  // const content = 'prompt-identity-verification'
  // const setContent = () => {}

  const { ar24Sender } = useContext(RegisteredMailContext)

  useEffect(() => {
    if(!section) return
    setContent(section)
  }, [section])

  const handlePromptCreate = () => {
    setContent('create')
  }

  const handlePromptAssociate = () => {
    setContent('associate')
  }

  const handlePromptAdd = () => {
    setContent('add')
  }

  const displayContent = () => {
    return !loadingMessage && !errorMessage
  }

  return (
    <Modal onClose={onClose} className="modal--padding-sm registered-mail-modal">
      {loadingMessage && 
        <div className="response-loader-v2__content">
          <p>{loadingMessage}</p>
          <div className="loader-v2 mt-20"></div>
        </div>
      }
      {errorMessage &&
       <div className=''>
        {/* <h2 className='modal-title'>Modifier le compte expéditeur</h2> */}
          <ul className='modal-list'>
            <li className='modal-list-item'>
              <p className='text-error' >{errorMessage}</p>
            </li>
          </ul>
          <ul className='flex flex-row justify-end gap-10'>
            <li>
              <button
                className='btn btn--large btn--transparent btn--border-primary'
                onClick={onClearError}
              >             
                Retour
              </button>
            </li>
          </ul>
        </div>
      }
        
      {displayContent() && content === 'add' && <SenderModalContentAdd onClose={onClose} onCreate={handlePromptCreate} onAssociate={handlePromptAssociate}  />}
      {displayContent() && content === 'create' && <SenderModalContentCreate onReturn={handlePromptAdd} onSubmit={onCreateSender} />}
      {displayContent() && content === 'associate' && <SenderModalContentAssociate onReturn={handlePromptAdd} onSubmit={onAssociateSender} />}
      {displayContent() && content === 'modify' && <SenderModalContentModify onClose={onClose} onSubmit={(data) => onUpdateSender(ar24Sender.id, data)} onPromptRenewIdentity={() => setContent('prompt-identity-verification')} />}
      {displayContent() && content === 'create-success' && <SenderModalContentCreateSuccess onClose={onClose} />}
      {displayContent() && content === 'associate-success' && <SenderModalContentAssociateSuccess onClose={onClose} />}
      {displayContent() && content === 'resend-success' && <SenderModalContentResendSuccess onClose={onClose} />}
      {displayContent() && content === 'prompt-identity-verification' && <SenderModalContentPromptIdentityVerification onReturn={() => setContent('modify')} />}
      {displayContent() && content === 'identity-verification-success' && <SenderModalContentIdentityVerificationSuccess onClose={onClose} />}
    </Modal>

  )
}

export default SenderModal