import { Select, Option, DateInput, Multiselect } from '../ui_new'
import { AR24_STATUS_LABELS } from '../../constants'
import { NavLink } from 'react-router-dom'
import { useContext } from 'react'
import { RegisteredMailContext } from '../../context'


const RegisteredMailSidebar = ({ sidebarSection, onModifySender }) => {

  const { ar24MailFilter, setAr24MailFilter, ar24Sender } = useContext(RegisteredMailContext)

  const handleFilterChange = (key) => (value) => {
    console.log("handleFilterChange", key, value)
    setAr24MailFilter({
      ...ar24MailFilter,
      [key]: value
    })
  }

  return (
    <div className="registered-mail-content__sidebar u-custom-scrollbar">
      <div className="registered-mail-content__sidebar_head">
        <h1><span>Lettres recommandées électroniques</span></h1>
      </div>
      <section className="registered-mail-content__nav">
        <ul>
          <li><NavLink to="/registered-mail/tracking">Suivi des LRE</NavLink></li>
          <li><NavLink to="/registered-mail/sender">Gestion compte expéditeur</NavLink></li>
          {/* <li><NavLink to="/registered-mail/requests">Historique des commandes d'identifiant</NavLink></li> */}
          {/* <li><NavLink to="/registered-mail/teams">Gestion des équipes</NavLink></li> */}
        </ul>
      </section>
      {sidebarSection === 'tracking' && (
        <section className="sidebar-filters-section">
          <h3>Filtres</h3>
          <div className="sidebar-filters-section__item">
            <Multiselect label="Statut" valueToClear='all' onChange={handleFilterChange('status')} placeholder="Tous" values={ar24MailFilter.status} options={[
              { value: 'all', label: 'Tous' },
              ...Object.entries(AR24_STATUS_LABELS).map(status => ({ value: status[0], label: status[1] }))              
            ]} />
          </div>
          {/* TODO add recipient filter field */}
          <div className="sidebar-filters-section__item">
            <Select label="Agence" onChange={handleFilterChange('agence')} selected={ar24MailFilter.agence}>
              <Option value="all">Tous</Option>
              {/* {Object.entries(recipients).map((status, index) => (
                <Option key={index} value={status[0]}>{status[1]}</Option>
              ))} */}
            </Select>
          </div>
          <div className="sidebar-filters-section__item">
            <DateInput
              label="Créé avant"
              onChange={handleFilterChange('dateBefore')}
              onChangeRaw={(e) => e.preventDefault()}
              isClearable={ar24MailFilter.dateBefore}
              onClearableClick={() => handleFilterChange('dateBefore')('')}
              selected={ar24MailFilter.dateBefore}
              minDate={ar24MailFilter.dateAfter}
            />
          </div>
          <div className="sidebar-filters-section__item">
            <DateInput
              label="Créé après"
              onChange={handleFilterChange('dateAfter')}
              onChangeRaw={(e) => e.preventDefault()}
              isClearable={ar24MailFilter.dateAfter}
              onClearableClick={() => handleFilterChange('dateAfter')('')}
              selected={ar24MailFilter.dateAfter}
              maxDate={ar24MailFilter.dateBefore}
            />
          </div>
        </section>
      )}

      {sidebarSection === 'sender' && (
        <section className='sidebar-filters-section'>
          <h3>Filtres</h3>
          <div className="sidebar-filters-section__item separator-bottom">
            <Select label="Agence" onChange={() => {}}>
              <Option value="all">Tous</Option>
            </Select>
          </div>
          {ar24Sender && <div className='flex flex-row justify-center'>
            <button className='btn btn--primary mt-20' onClick={onModifySender}>Modifier le compte</button>
          </div>}
        </section>        
      )}

      {sidebarSection === 'verificationRequests' && (
        <section className="sidebar-filters-section">
            {/* <h3>Filtres</h3>
            <div className="sidebar-filters-section__item">
                <Select label="Statut" onChange={handleStatusChange} selected={selectedFilters.status}>
                    <Option value="all">Tous</Option>
                    {Object.entries(STATUS_LABELS_SHORT).map((status, index) => (
                        <Option key={index} value={status[0]}>{status[1]}</Option>
                    ))}
                </Select>
            </div> */}
        </section>
      )}
    </div>
  )
}

export default RegisteredMailSidebar