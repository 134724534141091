const Download = () => {
  return (
    <span className="svg-icon">
      <svg width="100%" height="100%" viewBox="0 0 17 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" xmlns-serif="http://www.serif.com/" >
        <g id="Group_968">
          <g id="Group_967">
            <g id="Path_1601" transform="matrix(1,0,0,1,-81.39,0)">
              <path d="M89.117,11.979C89.409,12.269 89.889,12.269 90.181,11.979L94.891,7.266C95.036,7.121 95.118,6.924 95.118,6.719C95.118,6.295 94.769,5.946 94.345,5.946L91.784,5.946L91.784,0.9C91.784,0.406 91.378,0 90.884,0L88.409,0C87.915,0 87.509,0.406 87.509,0.9L87.509,5.945L84.947,5.945C84.523,5.945 84.174,6.294 84.174,6.718C84.174,6.923 84.256,7.12 84.401,7.265L89.117,11.979ZM93.072,7.246L89.649,10.672L86.221,7.246L88.811,7.246L88.811,1.3L90.483,1.3L90.483,7.246L93.072,7.246Z" />
            </g>
            <g id="Path_1602" transform="matrix(1,0,0,1,0.175,-367.264)">
              <path d="M15.266,379L10.723,379C10.682,379 10.643,379.016 10.614,379.045L9.1,380.563C8.831,380.833 8.466,380.984 8.085,380.984C7.705,380.984 7.34,380.833 7.071,380.563L5.55,379.045C5.521,379.016 5.482,379 5.441,379L0.9,379C0.406,379 0,379.406 0,379.9L0,383.368C0,383.862 0.406,384.268 0.9,384.268L15.266,384.268C15.76,384.268 16.166,383.862 16.166,383.368L16.166,379.9C16.166,379.406 15.76,379 15.266,379M14.866,380.3L14.866,382.963L1.3,382.963L1.3,380.3L4.966,380.3L6.148,381.482C6.66,381.995 7.357,382.284 8.082,382.284C8.807,382.284 9.504,381.995 10.016,381.482L11.2,380.3L14.866,380.3Z" />
            </g>
            <g id="Path_1603" transform="matrix(1,0,0,1,-345.77,-431.22)">
              <path d="M357.619,446.239C357.959,446.239 358.238,445.96 358.238,445.62C358.238,445.28 357.959,445.001 357.619,445.001C357.279,445.001 357,445.28 357,445.62C357.001,445.959 357.28,446.238 357.619,446.239" />
            </g>
            <g id="Path_1604" transform="matrix(1,0,0,1,-407.788,-431.22)">
              <path d="M421.619,446.239C421.959,446.239 422.238,445.96 422.238,445.62C422.238,445.28 421.959,445.001 421.619,445.001C421.279,445.001 421,445.28 421,445.62C421.001,445.959 421.28,446.238 421.619,446.239" />
            </g>
          </g>
        </g>
      </svg>
    </span>
  )
}

export default Download