import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import { Folder, Check } from '../../assets/icons'
import { dateDisplayFormat } from '../../utils'

const DashboardDocumentBox = ({ id, doc, templateExists, folder = null, onSetSelectedDocuments, selectedDocuments, type }) => {
  const [checked, setChecked] = useState(false)
  const [createdAt, setCreatedAt] = useState('')
  const history = useHistory()

  // Set checked to false if selectedDocuments length is zero
  useEffect(() => {
    if(checked && selectedDocuments.length === 0) {
      setChecked(false)
    }else if(selectedDocuments.length > 0  && selectedDocuments.find(d => d.id === id)) {
      setChecked(true)
    }else if(selectedDocuments.length > 0 && checked && !selectedDocuments.find(d => d.id === id)) {
      setChecked(false)
    }
  }, [selectedDocuments, checked, id])

  // Set createdAt
  useEffect(() => {
    if(doc?.meta?.created) {
      setCreatedAt(moment(doc.meta.created).format(dateDisplayFormat))
    }else if(doc.createdAt) {
      setCreatedAt(moment(doc.createdAt).format(dateDisplayFormat))
    }
  }, [doc])

  // On document select
  const handleSelectDocument = () => {
    setChecked(!checked)
    if(checked) {
      onSetSelectedDocuments(prev => prev.filter(d => d.id !== id))
    }else {
      onSetSelectedDocuments(prev => [...prev, doc])
    }
  }

  // On go to div click
  const handleGoTo = () => {
    if(type === 'documents') {
      history.push(`/documents/${id}`)
    } else if(type === 'signatures') {
      history.push(`/signatures?detail=${id}`)
    } else if(type === 'registered_mail') {
      history.push('/registered-mail/tracking')
    }
  }

  return (
    <div className={`dashboard-document-box ${type} ${checked ? 'active' : ''}`}>
      {type === 'documents' && <div className={`dashboard-document-box__check ${checked ? 'checked' : ''}`} onClick={handleSelectDocument}>
        <Check />
      </div>}
      { type === 'registered_mail' && 
        <div>
          <p className='mail-box-title'>{`${doc.to_firstname} ${doc.to_lastname.toUpperCase()}`}</p>
          { doc.document_name && <p className="mail-box-label">{ doc.document_name }</p>}
        </div> }
      <div className="dashboard-document-box__go-to" onClick={handleGoTo}></div>
      <h3>{type === 'documents' ? doc.name : doc.title}</h3>
      <div className="dashboard-document-box__meta">
        <div className="date">{createdAt}</div>
        {folder && <div className="folder"><Folder /> {folder.name}</div>}
      </div>
      {type === 'documents' && (
        <div className="template-exists">
          {/* <span className={templateExists ? 'on' : 'off'}></span> */}
          <span className={doc.status === 'draft' ? 'off' : 'on'}></span>
        </div>
      )}
    </div>
  )
}

export default DashboardDocumentBox