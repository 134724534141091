import React, { useContext } from 'react'

import { UserContext } from '../../context'

const Footer = () => {
    const { user, isScroll } = useContext(UserContext)

    return (
        !isScroll && (
            <footer className="footer-v2">
                <p>
                    &copy; 2005-{new Date().getFullYear()}{' '}
                    <a
                        href="https://www.ac3-groupe.com/"
                        target="_blank"
                        rel="noreferrer"
                        className="footer--link"
                    >
                        AC3 Groupe
                    </a>
                </p>
                <p>
                    {user.firstname
                        ? `${user.firstname} ${user.lastname}`
                        : ''}
                </p>
                <p>
                    <a
                        href="https://www.ac3-groupe.com/mentions-legales/"
                        target="_blank"
                        rel="noreferrer"
                        className="policy"
                    >
                        Mentions légales
                    </a>
                    {' - '}
                    <a
                        href="https://realestate.orisha.com/app/uploads/2024/01/Conditions-generales-de-vente.pdf"
                        target="_blank"
                        rel="noreferrer"
                        className="policy"
                    >
                        Conditions générales de ventes
                    </a>
                </p>
            </footer>
        )
    )
}

export default Footer
