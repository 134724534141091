import { useState, useContext, useEffect } from 'react';
import { Times } from '../../assets/icons';

import { Close, Search, Search2 } from '../../assets/icons';
import { SEARCH_HISTORY_LS_KEY } from '../../constants';
import { DocumentsContext, SignatureContext } from '../../context';

const SearchBar = ({ searchIn }) => {
  const {
    setDocsSelectedFilters,
    docsSelectedFilters,
    setTemplatesSelectedFilters,
    templatesSelectedFilters,
  } = useContext(DocumentsContext);
  const { setSignaturesSelectedFilters, signaturesSelectedFilters } =
    useContext(SignatureContext);
  const [search, setSearch] = useState('');

  const [searchBarOpen, setSearchBarOpen] = useState(false);
  const [removeSearchClose, setRemoveSearchClose] = useState(false);
  const [readyDocs, setReadyDocs] = useState([]);
  const [draftDocs, setDraftDocs] = useState([]);
  const [inProgressDocs, setInProgressDocs] = useState([]);
  const [closedDocs, setClosedDocs] = useState([]);
  const [cancelledDocs, setCancelledDocs] = useState([]);
  const [expiredDocs, setExpiredDocs] = useState([]);
  const [data, setData] = useState([]);
  const [activeStatus, setActiveStatus] = useState('');
  const [searchHistory, setSearchHistory] = useState(
    localStorage.getItem(SEARCH_HISTORY_LS_KEY)
      ? JSON.parse(localStorage.getItem(SEARCH_HISTORY_LS_KEY))
      : []
  );
  const [searchInputFocused, setSearchInputFocused] = useState(false);

  // Set search
  useEffect(() => {
    if (searchIn === 'documents') {
      setSearch(docsSelectedFilters.search);
    } else if (searchIn === 'templates') {
      setSearch(templatesSelectedFilters.search);
    } else if (searchIn === 'signatures') {
      setSearch(signaturesSelectedFilters.search);
    }
    // eslint-disable-next-line
  }, [searchIn, docsSelectedFilters]);

  // On change
  const handleChange = (e) => {
    handleSearchValueChange(e.target.value);
  };

  const handleSearchValueChange = (value) => {
    setSearch(value);
    if (searchIn === 'documents') {
      setDocsSelectedFilters((prev) => ({
        ...prev,
        search: value,
      }));
    } else if (searchIn === 'templates') {
      setTemplatesSelectedFilters((prev) => ({
        ...prev,
        search: value,
      }));
    } else if (searchIn === 'signatures') {
      setSignaturesSelectedFilters((prev) => ({
        ...prev,
        search: value,
      }));
    }
  };

  // Search documents inside table
  const searchHandler = (e) => {
    e.preventDefault();
    let docs = [];
    if (activeStatus === 'in-progress') {
      docs = inProgressDocs;
    } else if (activeStatus === 'closed') {
      docs = closedDocs;
    } else if (activeStatus === 'ready') {
      docs = readyDocs;
    } else if (activeStatus === 'draft') {
      docs = draftDocs;
    } else if (activeStatus === 'cancelled') {
      docs = cancelledDocs;
    } else if (activeStatus === 'expired') {
      docs = expiredDocs;
    }
    const filteredArr = [];
    docs.forEach((doc) => {
      if (activeStatus === 'ready' || activeStatus === 'draft') {
        if (
          doc.name.toLowerCase().includes(search.trim().toLowerCase()) &&
          !doc.archived &&
          !doc.deleted
        ) {
          filteredArr.push(doc);
        }
      } else if (activeStatus === 'in-progress' || activeStatus === 'closed') {
        if (doc.title.toLowerCase().includes(search.trim().toLowerCase())) {
          filteredArr.push(doc);
        }
      }
    });
    setData(filteredArr);
  };

  const handleRemoveSearchHistoryItem = (index) => (e) => {
    e.preventDefault();
    let sHistory = searchHistory;
    sHistory.splice(index, 1);
    setSearchHistory([...sHistory]);
    // localStorage.removeItem(SEARCH_HISTORY_LS_KEY)
    localStorage.setItem(
      SEARCH_HISTORY_LS_KEY,
      JSON.stringify(sHistory.slice(0, 6))
    );
  };

  const handleSearchHistoryItemClick = (item) => (e) => {
    handleSearchValueChange(item);
  };

  const toggleSearchBar = () => {
    if (searchBarOpen) {
      handleChange({ target: { value: '' } });
    }
    setSearchBarOpen(!searchBarOpen);
  };

  const SearchHistoryRow = ({ item, index }) => {
    return (
      <div className="search-bar__history-wrapper_row">
        <span onMouseDown={handleSearchHistoryItemClick(item)}>{item}</span>
        <div onMouseDown={handleRemoveSearchHistoryItem(index)}>
          <Close />
        </div>
      </div>
    );
  };

  const SearchHistoryDropdown = () => {
    return (
      <div className="search-bar__history-wrapper">
        {searchHistory.map((hItem, hIndex) => (
          <SearchHistoryRow
            key={`search_history_item_${hIndex}`}
            item={hItem}
            index={hIndex}
          />
        ))}
      </div>
    );
  };

  const handleSearchBarBlur = (e) => {
    let searchValue = e.target.value;
    const suggestions = localStorage.getItem(SEARCH_HISTORY_LS_KEY)
      ? JSON.parse(localStorage.getItem(SEARCH_HISTORY_LS_KEY))
      : [];
    if (searchValue !== '') {
      if (!suggestions.includes(searchValue)) {
        suggestions.unshift(searchValue);
      } else {
        const index = suggestions.indexOf(searchValue);
        suggestions.splice(index, 1);
        suggestions.unshift(searchValue);
      }
      localStorage.setItem(
        SEARCH_HISTORY_LS_KEY,
        JSON.stringify(suggestions.slice(0, 6))
      );
    }
    setSearchHistory([...suggestions]);
    // setTimeout(() => {
    setSearchInputFocused(false);
    // }, 250)
  };

  const handleSearchBarFocus = (e) => {
    setSearchInputFocused(true);
  };

  return (
    <>
      <div className="search-bar">
        <div className="search-bar__mobile">
          <button className="link" onClick={toggleSearchBar}>
            <Search2 />
          </button>
          {searchBarOpen && (
            <>
              <input
                type="text"
                placeholder="Rechercher…"
                value={search}
                onChange={handleChange}
              />
              <div className="close-btn" onClick={toggleSearchBar}>
                <Times />
              </div>
            </>
          )}
        </div>
        <div className="search-bar__body">
          <div className="search-bar__body_icon">
            <Search />
          </div>
          <input
            type="text"
            placeholder="Rechercher…"
            onBlur={handleSearchBarBlur}
            onFocus={handleSearchBarFocus}
            value={search}
            onChange={handleChange}
          />
          {searchHistory.length > 0 && searchInputFocused && (
            <SearchHistoryDropdown />
          )}
        </div>
      </div>
    </>
  );
};

export default SearchBar;
