import { Cog } from '../assets/icons'
import { MainLayout } from './'
import { SettingsContentSidebar } from '../components/misc'

const SettingsLayout = ({ children }) => {
  return (
    <MainLayout className="page-settings" pageTitle="Paramètres" titleIcon={<Cog />}>
      <div className="settings-content">
        <SettingsContentSidebar />
        <div className="settings-content__main">
          {children}
        </div>
      </div>
    </MainLayout>
  )
}

export default SettingsLayout