import { useState } from 'react'
import moment from 'moment'

import { Eye2, Folder } from '../../assets/icons'

const TemplateFolderBox = ({ folder, folderTemplates, onPreview }) => {
  const [showTemplates, setShowTemplates] = useState(false)

  // On toggle templates
  const handleToggleTemplates = () => {
    setShowTemplates(!showTemplates)
  }

  return (
    <div key={folder.id} className="template-folder-box">
      <div className="template-folder-box__head" onClick={handleToggleTemplates}>
        <p><Folder /> {folder.name}</p>
      </div>
      {showTemplates && (
        <div className="template-folder-box__body">
          {folderTemplates.map((tmplt, idx) => {
            return (
              <TemplateBoxMobile key={idx} tmplt={tmplt} onPreview={onPreview} />
            )
          })}
        </div>
      )}
    </div>
  )
}

const TemplateBoxMobile = ({ tmplt, onPreview }) => {
  let updatedAt = ''
  if(tmplt.meta?.updated) {
    updatedAt = moment(tmplt.meta.updated).format("DD MMMM YYYY")
  }

  // On preview click
  const handlePreview = () => {
    onPreview(tmplt)
  }

  return (
    <div className="template-box-mobile">
      <h3>{tmplt.name}</h3>
      <p>Mis à jour le {updatedAt}</p>
      <div className="preview" onClick={handlePreview}><Eye2 /></div>
    </div>
  )
}

export default TemplateFolderBox