import React, { useState, Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import { ChatOutlined, ThumbUpAltOutlined } from '@material-ui/icons'
import { MdOutlineStarOutline, MdOutlineSupport, MdOutlineFileDownload, MdOutlineCampaign } from 'react-icons/md'

import GiveFeedbackModal from './GiveFeedbackModal'
import VoteUpcomingFeaturesModal from './VoteUpcomingFeaturesModal'
import ActionsDropdown from '../../UI/ActionsDropdown'
import { availableOn } from '../../../utils'

const SupportMenu = () => {
  const [showGiveFeedbackModal, setShowGiveFeedbackModal] = useState(false)
  const [showVoteModal, setShowVoteModal] = useState(false)
  const history = useHistory()

  // Support menu item click
  const supportMenuItemClickHandler = (e, route) => {
    e.preventDefault()
    history.push(`/${route}`)
    document.body.click()
  }

  const renderContent = () => {
    return (
      <Fragment>
        <ActionsDropdown
          trigger={<button className="icon-button support-menu-btn"><ChatOutlined /></button>}
          headTitle={'Information'}
        >
          <ul>
            <li>
              <div className="link">
                <a href="/#" onClick={(e) => supportMenuItemClickHandler(e, 'what-is-new')}><MdOutlineCampaign /> {`Nouveautés`}</a>
              </div>
            </li>
            { availableOn(['development', 'staging']) && <li>
              <div className="link">
                <a href="/#" onClick={(e) => supportMenuItemClickHandler(e, 'help-center')}><MdOutlineSupport /> {`Centre d'aide`}</a>
              </div>
            </li> }
            <li>
              <div className="link">
                <a href="/#" onClick={(e) => supportMenuItemClickHandler(e, 'documentation')}><MdOutlineFileDownload /> {`Documents utiles`}</a>
              </div>
            </li>
            <li>
              <div className="link">
                <a href="/#" onClick={(e) => {e.preventDefault(); setShowGiveFeedbackModal(true)}}><MdOutlineStarOutline /> {`Donnez votre avis`}</a>
              </div>
            </li>
            { availableOn(['development', 'staging']) && <li>
              <div className="link">
                <a href="/#" onClick={(e) => {e.preventDefault(); setShowVoteModal(true)}}><ThumbUpAltOutlined /> {`Votez pour les fonctionnalités à venir`}</a>
              </div>
            </li> }
          </ul>
        </ActionsDropdown>

        {showGiveFeedbackModal && <GiveFeedbackModal onClose={() => setShowGiveFeedbackModal(false)} />}
        {showVoteModal && <VoteUpcomingFeaturesModal onClose={() => setShowVoteModal(false)} />}
      </Fragment>
    )

  }
  
  // return availableOn(['development', 'staging']) ? renderContent() : null
  return renderContent()
}

export default SupportMenu