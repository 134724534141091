import MailModalMenu from "./MailModalMenu";
import { useContext, useEffect, useState } from "react";
import { RegisteredMailContext } from "../../../context";
import React from 'react'
import { useHistory } from "react-router-dom";
import { Checkbox, CustomTooltip } from "../../ui_new";
import { statusLabelForAr24User } from "../../../utils";
import { InfoCircle } from "../../../assets/icons";
import { Delete, PersonAdd } from "@material-ui/icons";

const MailModalContentRecipients = ({ onClose, recipients, setRecipients, onContinue, onReturn }) => {


  const [showValidations, setShowValidations] = useState(false)
  const [formErrors, setFormErrors] = useState({})


  const updateRecipient = (e, index) => {
    const { name, value } = e.target
    const newRecipients = [...recipients]
    newRecipients[index][name] = value
    setRecipients(newRecipients)
  }

  useEffect(() => {
    if (showValidations) {
      validateForm()
    }
  }, [showValidations, recipients])

  const handleAddRecipient = () => {
    setShowValidations(false)
    setRecipients([...recipients, { firstName: '', lastName: '', email: '', type: '', consent: false }])
  }

  const validateForm = () => {
    const newFormErrors = {}
    let valid = true
    recipients.forEach((item, index) => {
      const newValid = {}
      if (!item.firstName) {
        newValid.firstName = true
        valid = false
      }
      if (!item.lastName) {
        newValid.lastName = true
        valid = false
      }
      if (!item.email || !item.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
        newValid.email = true
        valid = false
      }
      if (!item.type) {
        newValid.type = true
        valid = false
      }
      if (item.type === 'particulier' && !item.consent) {
        newValid.consent = true
        valid = false
      }
      newFormErrors[index] = newValid
    })
    setFormErrors(newFormErrors)
    setShowValidations(true)
    return valid
  }

  const handleContinue = () => {
    const valid = validateForm()
    if (valid) {
      onContinue()
    }    
  }

  return (
    <div className='registered-mail-send-modal-container'>
      <div className='registered-mail-send-modal-container__header'>
        <ul className='modal-list'>
          <li className='modal-list-item'>
            <p>Complétez les informations de chaque destinaire. Les <b>prénoms</b> et <b>noms</b> doivent être renseignés <b>selon les informations visibles sur la carte d'identité</b> afin que chaque destinaire puisse <b>valider la réception de la lettre recommandée électronique</b>.</p>
          </li>
          <li className='modal-list-item'>
            <p>
              Chaque destinataire renseigné fera l'objet d'un envoi de LRE, avec des certifications et preuves individuelles.
            </p>
          </li>
        </ul>

        <div className='flex flex-row modal-section-title mb-10'>
          <PersonAdd className="modal-section-title--icon" />
          Compléter les destinataires
        </div>
        <table className="mb-20">
          <thead>
            <tr>
              <th>
                Prénom
              </th>
              <th>
                Nom
              </th>
              <th>
                E-mail
              </th>
              <th>
                Type de destinaire
              </th>
              <th>
                <div>
                  Consentement
                  <CustomTooltip content="Avant d'envoyer une lettre recommandée électronique à un particulier, vous devez avoir requis au préalable son consentement à recevoir une lettre recommandée électronique par e-mail de votre part (Article L100 du Code des postes et des communications électroniques). Pour cela, un modèle de lettre de consentement est disponible dans votre bibliothèque Immo Docs.">
                    <div className="info"> <InfoCircle /> </div>
                  </CustomTooltip>
                </div>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {recipients.map((item, index) => (
              <tr>
                <td>
                  <input className={`custom-input ${showValidations && formErrors[index]?.firstName ? 'invalid' : ''}`} value={item.firstName} type="text" name="firstName" onChange={(e) => updateRecipient(e, index)} />
                  {showValidations && formErrors[index]?.firstName && <span className='not-valid'>Non renseigné</span>}
                </td>
                <td>
                  <input className={`custom-input ${showValidations && formErrors[index]?.lastName ? 'invalid' : ''}`} value={item.lastName} type="text" name="lastName" onChange={(e) => updateRecipient(e, index)} />
                  {showValidations && formErrors[index]?.lastName && <span className='not-valid'>Non renseigné</span>}
                </td>
                <td>
                  <input className={`custom-input ${showValidations && formErrors[index]?.email ? 'invalid' : ''}`} value={item.email} type="email" name="email" onChange={(e) => updateRecipient(e, index)} />
                  {showValidations && formErrors[index]?.email && <span className='not-valid'>E-mail invalide</span>}
                </td>
                <td>
                  <select className={`custom-select ${showValidations && formErrors[index]?.type ? 'invalid' : ''}`} name="type" value={item.type || ''} onChange={(e) => updateRecipient(e, index)}>
                    <option value="" disabled>Sélectionner</option>
                    <option value="particulier">Particulier</option>
                    <option value="professionnel">Professionnel</option>
                  </select>
                  {showValidations && formErrors[index]?.type && <span className='not-valid'>Non renseigné</span>}
                </td>
                <td>
                  <div className="custom-select border-none">
                    <Checkbox
                      checked={item.consent}
                      label={item.type !== 'particulier' ? 'Non requis' : 'Reçu'}
                      disabled={item.type !== 'particulier'}
                      onChange={(e) => updateRecipient({ target: { name: 'consent', value: e.target.checked } }, index)}
                    />
                  </div>
                  {showValidations && item.type === 'particulier' && !item.consent && <span className='not-valid'>Non renseigné</span>}
                </td>
                <td>
                  <button
                    className='btn btn--transparent'
                    onClick={() => setRecipients(recipients.filter((_, i) => i !== index))}
                  >
                    <Delete color="error" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div>
          <button
            className='btn'
            onClick={handleAddRecipient}
          >
            Ajouter un destinataire
          </button>
        </div>
        <div className="mt-20 mb-20">
          <p>Pour envoyer une Lettre Recommandée Electronique à un <b>particulier</b>, vous devez avoir <b>requis au préalable le consentement</b> de celui-ci à <b>recevoir les LRE à l'adresse e-mail renseignée ici</b> (Article L100 du Code des postes et des communications électroniques).</p>
        </div>
      </div>
      {Object.values(formErrors).find(e => Object.keys(e).length > 0) && (
        <div className="mb-20">
          <p className="text-error">Des informations sont manquantes ou mal renseignées.</p>
        </div>
      )}
      <div className='modal-btn-box'>
        <button
          className='btn btn--large btn--transparent btn--border-primary'
          onClick={onReturn}
        >
          Précédent
        </button>
        <button
          disabled={recipients.length === 0}
          className='btn btn--large btn--primary'
          onClick={handleContinue}
        >
          Suivant
        </button>
      </div>
    </div>
  )
}

export default MailModalContentRecipients