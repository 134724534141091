import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import { Cog, FileAlt2, FolderOpen, RegisteredMail, Signature } from '../../assets/icons'
import { FEATURE, isFeatureEnabled, availableOn } from '../../utils'
import { UserContext } from '../../context'

const MobileMenu = () => {
  const { siteConfigs } = useContext(UserContext)
  return (
    <div className="mobile-menu u-custom-scrollbar">
      <ul>
        <li>
          <Link to="/documents"><span className="icon-documents"><FileAlt2 /></span> Mes documents</Link>
        </li>
        <li>
          <Link to="/templates"><span className="icon-templates"><FolderOpen /></span> Modèles</Link>
        </li>
        <li>
          <Link to="/signatures"><span className="icon-signatures"><Signature /></span> Signatures</Link>
        </li>
        {isFeatureEnabled(FEATURE.AR24, siteConfigs) && <li>
          <Link to="/registered-mail/tracking"><span className="icon-ar24"><RegisteredMail /></span> Lettres recommandées</Link>
        </li> }
        <li>
          <Link to="/settings/profile"><span className="icon-settings"><Cog /></span> Paramètres</Link>
        </li>
        { availableOn(['development', 'staging']) && <li>
          <Link to="/help"><span><span className="icon-wrapper">?</span></span> Aide</Link>
        </li> }
      </ul>
    </div>
  )
}

export default MobileMenu