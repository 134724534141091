import { useState, useEffect, useContext, useRef, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import qs from 'qs'

import { MainLayout } from '../layouts'
import {
  SingleDocumentSidebar,
  SingleDocumentMain,
  SingleDocumentSidePanel,
  AgencyModal,
  NotariesModal,
  AddEditNotaryModal,
  StandardTemplateSidebar,
  StandardTemplateMain,
} from '../components/misc'
import { ResponseLoader, ResourceNotFound } from '../components/ui_new'
import {
  FileAlt2,
  List,
  Eye2,
  Pen,
  Paperclip,
  EllipsisV,
} from '../assets/icons'
import {
  DocumentsContext,
  NotificationContext,
  UserContext,
  LoaderContext,
} from '../context'
import {
  fetch_document_history,
  fetch_document_data,
} from '../services/firestore'
import { useSingleDocumentActions, useVariablesActions } from '../hooks'
import { areArraysEqual, areSectionConditionsMet, convertToTemplateObjWithUniqueVarIndexes } from '../utils'
import {
  TEXT_LINES,
  TEXTAREA_LINES,
  DATE_LINES,
  NUMBER_LINES,
  CONTACT_ID_VARIABLES,
} from '../constants'
import config from '../config.json'
import { isMobile } from 'react-device-detect'

let scrollToItemTimeout = null
const arrayStack = [-Infinity]

const excludedProgressVars = [...CONTACT_ID_VARIABLES]

const StandardTemplateDetail = ({ token }) => {
  const {
    standardTemplates,
    standardTemplatesLoaded,
    templates,
    templatesLoaded,
    getSingleTemplate,
    updateDocument,
    createDocument,
    updateStandardTemplate,
    deleteStandardTemplate,
  } = useContext(DocumentsContext)
  const { setNotification } = useContext(NotificationContext)
  const { partner, user, agency, signInResult } = useContext(UserContext)
  const { setShowGlobalResponseLoader, setGlobalResponseLoaderText } =
    useContext(LoaderContext)
  const {
    getDocumentHistory,
    processedQueryVariables,
    joinValues,
    getPreviewData,
    clearLinesFromValues,
    allVariables,
    getCustomersFromData,
    extractCustomerData,
  } = useSingleDocumentActions()
  const [documentId, setDocumentId] = useState('')
  const [singleDoc, setSingleDoc] = useState(null)
  const [singleDocReady, setSingleDocReady] = useState(false)
  const [singleDocNotFound, setSingleDocNotFound] = useState(false)
  const [singleDocHistory, setSingleDocHistory] = useState(null)
  const [singleDocName, setSingleDocName] = useState('')
  const [singleDocStatus, setSingleDocStatus] = useState('')
  const [singleDocAttachments, setSingleDocAttachments] = useState([])
  const [historyFetched, setHistoryFetched] = useState(false)
  const [documentValues, setDocumentValues] = useState({})
  const [scrollingToItem, setScrollingToItem] = useState(false)
  const [
    templateObjectWithUniqueVarIndexes,
    setTemplateObjectWithUniqueVarIndexes,
  ] = useState(null)
  const [linesAddedTo, setLinesAddedTo] = useState([])
  const [templateFetched, setTemplateFetched] = useState(false)
  const [templateFetching, setTemplateFetching] = useState(false)
  const [documentGettingReady, setDocumentGettingReady] = useState(false)
  const [templateNotFound, setTemplateNotFound] = useState(false)
  const [documentCheckboxValues, setDocumentCheckboxValues] = useState({})
  const [useCustomCover, setUseCustomCover] = useState(false)
  const [linesAdded, setLinesAdded] = useState(false)
  const [showSidePanel, setShowSidePanel] = useState(true)
  const [pdfPreviewData, setPdfPreviewData] = useState(null)
  const [previewLoading, setPreviewLoading] = useState(true)
  const [activeTab, setActiveTab] = useState('summary')
  const [templateObject, setTemplateObject] = useState(null)
  const [documentContacts, setDocumentContacts] = useState([])
  const [docSignatureRecipients, setDocSignatureRecipients] = useState([])
  const [currentDocVersionTime, setCurrentDocVersionTime] = useState({})
  const [view, setView] = useState('variables')
  const [showAgencyModal, setShowAgencyModal] = useState(false)
  const [showNotariesModal, setShowNotariesModal] = useState(false)
  const [showAddEditNotaryModal, setShowAddEditNotaryModal] =
    useState(false)
  const [notaryMode, setNotaryMode] = useState('add')
  const [selectedNotary, setSelectedNotary] = useState(null)
  const [isTemplatePage, setIsTemplatePage] = useState(false)
  const { setIsScroll, isScroll, hideActionBar, setHideActionBar } =
    useContext(UserContext)
  const [showMoreActions, setShowMoreActions] = useState(false)
  const [previewSettings, setPreviewSettings] = useState({ size: 'normal', watermark: false })
  const params = useParams()
  const history = useHistory()
  const bodyWrapEl = useRef()

    // meta sections - for repeatable / conditional content
    const [templateMetaSections, setTemplateMetaSections] = useState([])
    const [summarySections, setSummarySections] = useState([])
    const [progress, setProgress] = useState(0)


    useEffect(() => {

      if (!templateObjectWithUniqueVarIndexes) {
          return
      }

      const extractMetaBlocks = (sections) => {

          // Array.prototype.last = function() {
          //     return this[this.length - 1]
          // }

          const metaSections = []
          const currentMetaSectionStack = [{
              sections: [],
              repeatableIds: [],
              isMetaSection: true
          }]
          for (let i = 0; i < sections.length; i++) {
              const section = sections[i]
              section.index = i
              const sectionRepeatableIds = section.repeatable_section_ids || (section.repeatable_section_id ? [section.repeatable_section_id] : [])
              // check if section is sibling or direct child of current_full
              if (currentMetaSectionStack.length === 0) {
                  const newMetaSection = {
                      sections: [section],
                      repeatableIds: sectionRepeatableIds,
                      isMetaSection: true
                  }
                  currentMetaSectionStack.push(newMetaSection)
              } else if (areArraysEqual(sectionRepeatableIds, currentMetaSectionStack[currentMetaSectionStack.length - 1].repeatableIds)) {
                  currentMetaSectionStack[currentMetaSectionStack.length - 1].sections.push(section)
              } else if (sectionRepeatableIds.length > currentMetaSectionStack[currentMetaSectionStack.length - 1].repeatableIds.length) {
                  // validate if section is direct child of current
                  if (sectionRepeatableIds.length === currentMetaSectionStack[currentMetaSectionStack.length - 1].repeatableIds.length + 1 && currentMetaSectionStack[currentMetaSectionStack.length - 1].repeatableIds.length > 0 && areArraysEqual(sectionRepeatableIds.slice(0, currentMetaSectionStack[currentMetaSectionStack.length - 1].repeatableIds.length), currentMetaSectionStack[currentMetaSectionStack.length - 1].repeatableIds)) {
                      const newMetaSection = {
                          sections: [section],
                          repeatableIds: sectionRepeatableIds,
                          isMetaSection: true
                      }
                      currentMetaSectionStack.push(newMetaSection)
                  } else if (currentMetaSectionStack[currentMetaSectionStack.length - 1].repeatableIds.length === 0) {

                      const lastMetaSection = currentMetaSectionStack.pop()
                      if (lastMetaSection.sections.length > 0) {
                          metaSections.push(lastMetaSection)
                      }
                      const newMetaSection = {
                          sections: [section],
                          repeatableIds: sectionRepeatableIds,
                          isMetaSection: true
                      }
                      currentMetaSectionStack.push(newMetaSection)
                  } else {
                      throw new Error('Invalid section repeatable ids')
                  }
              } else if (sectionRepeatableIds.length < currentMetaSectionStack[currentMetaSectionStack.length - 1].repeatableIds.length) {
                  // jump out of current stack until we find a matching parent 
                  while (currentMetaSectionStack.length > 0 && (sectionRepeatableIds.length < currentMetaSectionStack[currentMetaSectionStack.length - 1].repeatableIds.length)) {
                      const closingSection = currentMetaSectionStack.pop()
                      if (currentMetaSectionStack.length === 0) {
                          metaSections.push(closingSection)
                      } else {
                          currentMetaSectionStack[currentMetaSectionStack.length - 1].sections.push(closingSection)
                      }
                  }
                  if (currentMetaSectionStack.length > 0) {
                      if (areArraysEqual(sectionRepeatableIds, currentMetaSectionStack[currentMetaSectionStack.length - 1].repeatableIds)) {
                          currentMetaSectionStack[currentMetaSectionStack.length - 1].sections.push(section)
                      } else {
                          const newMetaSection = {
                              sections: [section],
                              repeatableIds: sectionRepeatableIds,
                              isMetaSection: true
                          }
                          currentMetaSectionStack.push(newMetaSection)
                      }
                  } else {
                      const newMetaSection = {
                          sections: [section],
                          repeatableIds: sectionRepeatableIds,
                          isMetaSection: true
                      }
                      currentMetaSectionStack.push(newMetaSection)
                  }
              } else {
                  // are equal length but not equal
                  const closingSection = currentMetaSectionStack.pop()
                  if (currentMetaSectionStack.length === 0) {
                      metaSections.push(closingSection)
                  } else {
                      currentMetaSectionStack[currentMetaSectionStack.length - 1].sections.push(closingSection)
                  }
                  const newMetaSection = {
                      sections: [section],
                      repeatableIds: sectionRepeatableIds,
                      isMetaSection: true
                  }
                  currentMetaSectionStack.push(newMetaSection)
              }
          }
          while (currentMetaSectionStack.length > 0) {
              const closingSection = currentMetaSectionStack.pop()
              if (currentMetaSectionStack.length === 0) {
                  metaSections.push(closingSection)
              } else {
                  currentMetaSectionStack[currentMetaSectionStack.length - 1].sections.push(closingSection)
              }
          }
          return metaSections
      }

      const mSections = extractMetaBlocks(templateObjectWithUniqueVarIndexes.sections)
      setTemplateMetaSections(mSections)
      // return []
      // return metaSections

  }, [templateObjectWithUniqueVarIndexes])

  const { duplicatableValuesArray } = useVariablesActions({ values: documentValues })

  useEffect(() => {
      if (!templateMetaSections) {
          return
      }

      const extractSummaryRowsFromMetaSection = (metaSection, parentRepeatableIds, parentRepetitionIndices) => {
          const rows = []
          const { sections } = metaSection
          if (metaSection.repeatableIds && metaSection.repeatableIds.length > 0) {
              const repetitionValuesArray = duplicatableValuesArray([...(metaSection.repeatableIds || [])], parentRepetitionIndices)
              if (!repetitionValuesArray || repetitionValuesArray.length === 0) return []
              repetitionValuesArray.forEach((repetitionValues, repetitionIndex) => {
                  for (let i = 0; i < sections.length; i++) {
                      const subSection = sections[i]
                      if (subSection.isMetaSection) {
                          const subSectionRows = extractSummaryRowsFromMetaSection(subSection, [...(metaSection.repeatableIds || [])], [...(parentRepetitionIndices || []), repetitionIndex])
                          rows.push(...subSectionRows)
                          continue
                      }
                      if (!areSectionConditionsMet({ ...subSection, repetitionIndices: [...(parentRepetitionIndices || []), repetitionIndex] }, documentValues)) {
                          continue
                      }
                      if (subSection.type.startsWith('heading')) {
                          rows.push({
                              title: subSection.content,
                          })
                      }
                      if (subSection.variable) {
                          const row = { variable: subSection, repeatableIds: metaSection.repeatableIds, repetitionIndices: [...(parentRepetitionIndices || []), repetitionIndex] }
                          if (row.variable) {
                              row.value = repetitionValues[row.variable.variable]
                          }
                          rows.push(row)
                      }
                      if (subSection.variables) {
                          const variables = subSection.variables
                          for (let j = 0; j < variables.length; j++) {
                              const variable = variables[j]
                              const row = { variable, repeatableIds: metaSection.repeatableIds, repetitionIndices: [...(parentRepetitionIndices || []), repetitionIndex] }
                              if (row.variable) {
                                  row.value = repetitionValues[row.variable.variable]
                              }
                              rows.push(row)
                          }
                      }
                  }
              })

          } else {
              for (let i = 0; i < sections.length; i++) {
                  const subSection = sections[i]
                  if (subSection.isMetaSection) {
                      const subSectionRows = extractSummaryRowsFromMetaSection(subSection, [...(metaSection.repeatableIds || [])], parentRepetitionIndices)
                      rows.push(...subSectionRows)
                      continue
                  }
                  if (!areSectionConditionsMet({ ...subSection, repetitionIndices: parentRepetitionIndices }, documentValues)) {
                      continue
                  }
                  if (subSection.type.startsWith('heading')) {
                      rows.push({
                          title: subSection.content,
                      })
                  }
                  if (subSection.variable) {
                      const row = { variable: subSection, repeatableIds: metaSection.repeatableIds, repetitionIndices: parentRepetitionIndices }
                      if (row.variable) {
                          row.value = documentValues[row.variable.variable]
                      }
                      rows.push(row)
                  }
                  if (subSection.variables) {
                      const variables = subSection.variables
                      for (let j = 0; j < variables.length; j++) {
                          const variable = variables[j]
                          const row = { variable, repeatableIds: metaSection.repeatableIds, repetitionIndices: parentRepetitionIndices }
                          if (row.variable) {
                              row.value = documentValues[row.variable.variable]
                          }
                          rows.push(row)
                      }
                  }
              }
          }
          return rows
      }

      const rows = templateMetaSections.map((metaSection, metaSectionIndex) => extractSummaryRowsFromMetaSection(metaSection, [], []))
      const rowsFull = rows.flat()

      const sections = []
      let currentSummarySection = { title: '', fields: [] }
      for (let i = 0; i < rowsFull.length; i++) {
          const row = rowsFull[i]
          if (row.title) {
              if (currentSummarySection.fields.length > 0 || currentSummarySection.title) {
                  sections.push(currentSummarySection)
              }
              currentSummarySection = { title: row.title, fields: [] }
          } else if (row.variable) {
              currentSummarySection.fields.push({ ...row.variable, value: row.value, repeatableIds: row.repeatableIds, repetitionIndices: row.repetitionIndices })
          }
      }
      if (currentSummarySection.fields.length > 0 || currentSummarySection.title) {
          sections.push(currentSummarySection)
      }

      let totalProgress = 0
      let variablesTotal = 0
      let variablesDone = 0
      for (let i = 0; i < sections.length; i++) {
          const section = sections[i]
          const fields = section.fields
          let sectionVariablesTotal = 0
          let sectionVariablesDone = 0
          for (let j = 0; j < fields.length; j++) {
              const field = fields[j]
              if (field.variable && !excludedProgressVars.includes(field.variable)) {
                  sectionVariablesTotal++
                  if ((typeof field.value === 'string' && field.value !== '') || (typeof field.value !== 'string' && field.value !== undefined)) {
                      sectionVariablesDone++
                  }
              }
          }
          if (sectionVariablesTotal > 0) {
              section.progress = Math.round((sectionVariablesDone / sectionVariablesTotal) * 100)
          }
          variablesTotal += sectionVariablesTotal
          variablesDone += sectionVariablesDone
      }
      totalProgress = variablesTotal > 0 ? (variablesDone / variablesTotal) : 1
      setProgress(totalProgress)
      setSummarySections(sections)

      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateMetaSections, documentValues])

  // On document preview
  const handleDocumentPreview = useCallback(
    async (doc, values, template, initialPreview) => {


      const agencyFontConfig = () => {
        if (!agency) {
          return {}
        }
        const font_config = {
          heading_2: {
            color: agency.cover_color,
            borderColor: agency.cover_color,
          },
          heading_3: {
            color: "#ffffff",
            backgroundColor: agency.cover_color,
          },
          footer_title: {
            color: agency.cover_color,
          },
          cover_title: {
            color: agency.cover_color,
          }
        }
        return font_config
      }

      const agencyConfig = () => {
        if (!agency) {
          return {}
        }
        const config = {
          cover_color: agency.cover_color,
        }
        return config
      }

      const agencyImages = () => {
        if (!agency) {
          return {}
        }
        const images = {
          logo: agency.logo,
          cover: agency.cover,
        }
      }

      // setShowSidePanel(true)
      setPreviewLoading(true)
      setActiveTab('preview')
      let d = singleDoc
      if (doc) d = doc
      let dValues = documentValues
      if (values) dValues = values
      let t = templateObject
      if (template) t = template
      const data = await getPreviewData(
        { ...d, values: dValues, checkboxValues: documentCheckboxValues, custom_cover: initialPreview ? doc.custom_cover : useCustomCover },
        t,
        partner === 'jurisur' &&
        config.environment === 'development',
        { font_config: { font_size: previewSettings.size, ...agencyFontConfig() }, use_watermark: previewSettings.watermark, ...agencyConfig() }
      )
      if (!data) return setPreviewLoading(false)
      setPdfPreviewData(data)
      setPreviewLoading(false)
      // eslint-disable-next-line
    },
    [documentValues, singleDoc, templateObject, useCustomCover, documentCheckboxValues, partner, getPreviewData, previewSettings, agency]
  )

  // Set document based on id
  useEffect(() => {
    const fetchHistory = async (id, doc) => {
      try {
        const res = await fetch_document_history(id)
        const arr = getDocumentHistory(res)
        setSingleDocHistory(arr)
        if (arr[0]) {
          setCurrentDocVersionTime({
            time: arr[0].versions[arr[0].versions.length - 1]
              .created,
          })
        } else {
          setCurrentDocVersionTime({ time: doc.meta.created })
        }
      } catch (err) {
        console.log(err)
      } finally {
        setHistoryFetched(true)
      }
    }

    // Setup document
    const setupDocument = async (templatePage = false) => {
      setIsTemplatePage(templatePage)
      console.log('setup doc****************')
      setDocumentGettingReady(true)
      let doc
      let template
      if (templatePage) {
        template = templates[params.id]
        doc = {
          template: params.id,
          name: template.name,
        }
      } else {
        doc = standardTemplates[params.id]
        template = templates[doc.template]
      }
      if (!template) {
        setTemplateNotFound(true)
        return
      }
      setSingleDoc(doc)
      setDocumentId(params.id)
      if (!historyFetched) {
        await fetchHistory(params.id, doc)
      }
      if (
        !template.sections &&
        !templateFetched &&
        !templateFetching
      ) {
        setTemplateFetching(true)
        template = await getSingleTemplate(doc.template, true)
        setTemplateFetched(true)
      }
      setTemplateObject(template)

      let prefillValues
      const queryStringVariables = qs.parse(history.location.search, {
        ignoreQueryPrefix: true,
      })

      if (queryStringVariables.data_id) {
        // fetch data object
        let data = await fetch_document_data(
          queryStringVariables.data_id
        )
        console.log('doc data**', data)

        if (!data.error) {
          extractCustomerData(data)
          prefillValues = data
        } else {
          // todo display error
        }
      } else {
        prefillValues = processedQueryVariables(
          template,
          queryStringVariables
        )
      }
      // prefillValuesWithAdminData(prefillValues)
      let existingDocumentValuesWithPrefillData = joinValues(
        doc.values || {},
        prefillValues
      )
      extractCustomerData(existingDocumentValuesWithPrefillData)
      setDocumentValues(existingDocumentValuesWithPrefillData)
      setDocumentCheckboxValues(doc.checkboxValues || {})
      setSingleDocName(doc.name)
      setSingleDocStatus(doc.status)
      setSingleDocAttachments(doc.attachments || [])
      const copyOfTemplate = convertToTemplateObjWithUniqueVarIndexes(
        { ...template }
      )
      setTemplateObjectWithUniqueVarIndexes(copyOfTemplate)
      setUseCustomCover(doc.custom_cover || false)
      handleDocumentPreview(
        doc,
        existingDocumentValuesWithPrefillData,
        template,
        true
      )
      getCustomersFromData(
        existingDocumentValuesWithPrefillData,
        setDocumentContacts
      )
      setDocSignatureRecipients(doc.signatureRecipients || [])
      setSingleDocReady(true)
    }

    if (params.id && standardTemplatesLoaded && templatesLoaded && partner) {
      if (standardTemplates[params.id]) {
        if (
          (!singleDocReady && !documentGettingReady) ||
          params.id !== documentId
        ) {
          setupDocument()
        }
        setSingleDocNotFound(false)
      } else {
        setSingleDocNotFound(true)
        setSingleDocReady(true)
      }

    }
  }, [
    params.id,
    standardTemplates,
    templates,
    standardTemplatesLoaded,
    templatesLoaded,
    getDocumentHistory,
    historyFetched,
    singleDocReady,
    getSingleTemplate,
    templateFetched,
    templateFetching,
    documentGettingReady,
    history.location.search,
    history.location.pathname,
    joinValues,
    processedQueryVariables,
    handleDocumentPreview,
    partner,
    getCustomersFromData,
    documentId,
    extractCustomerData
  ])

  // Cleanup
  useEffect(() => {
    return () => {
      if (scrollToItemTimeout) {
        clearTimeout(scrollToItemTimeout)
      }
    }
  }, [])

  // Reset setup
  const resetSetup = () => {
    setSingleDocReady(false)
    setDocumentGettingReady(false)
  }

  // Handle variable item click
  const handleVariableItemClick = useCallback(async (id) => {
    const element = bodyWrapEl.current.querySelector(
      `[data-id="${id}"]`
    )
    if (element) {
      const input =
        element.querySelector('input') ||
        element.querySelector('textarea')
      if (input) {
        input.focus()
      }
      setScrollingToItem(true)
      window.scrollTo({
        top:
          element.getBoundingClientRect().top +
          window.pageYOffset -
          120,
        behavior: 'smooth',
      })
      scrollToItemTimeout = setTimeout(
        () => setScrollingToItem(false),
        1000
      )
    }
  }, [])

  // Handle section click
  const handleSectionClick = useCallback((id) => {
    const element = bodyWrapEl.current.querySelector(
      `[data-index="${id}"]`
    )
    if (element) {
      setScrollingToItem(true)
      window.scrollTo({
        top:
          element.getBoundingClientRect().top +
          window.pageYOffset -
          120,
        behavior: 'smooth',
      })
      scrollToItemTimeout = setTimeout(
        () => setScrollingToItem(false),
        1000
      )
    }
  }, [])

  // On document values change
  const handleDocumentValuesChange = useCallback(
    (variable, value) => {
      if (typeof variable === 'object' && Array.isArray(variable)) {
        let dv = { ...documentValues }
        for (let i in variable) {
          dv[variable[i]] = value[i]
        }
        setDocumentValues({ ...dv })
      } else {
        setDocumentValues({ ...documentValues, [variable]: value })
      }
    },
    [documentValues]
  )

  // On document checkbox values change
  const handleDocumentCheckboxValuesChange = useCallback(
    (id, value) => {
      setDocumentCheckboxValues({
        ...documentCheckboxValues,
        [id]: value,
      })
    },
    [documentCheckboxValues]
  )

  // On custom cover change
  const handleCustomCoverChange = useCallback((e) => {
    setUseCustomCover(e.target.checked)
  }, [])

  // On remove lines - when clicked on lines remove them from input
  const handleRemoveLines = useCallback(
    (variable, repId, repIndex) => {
      let addedTo
      if (repId && repIndex !== undefined && repIndex > -1) {
        addedTo = [...linesAddedTo].filter(
          (v) =>
            !(
              v.variable === variable &&
              v.belongs_to === repId &&
              v.index === repIndex
            )
        )
      } else {
        addedTo = [...linesAddedTo].filter(
          (v) => v.variable !== variable
        )
      }
      setLinesAddedTo(addedTo)
      let docValues = { ...documentValues }
      let findVar
      if (repId && repIndex !== undefined && repIndex > -1) {
        findVar = linesAddedTo.find(
          (v) =>
            v.variable === variable &&
            v.belongs_to === repId &&
            v.index === repIndex
        )
      } else {
        findVar = linesAddedTo.find((v) => v.variable === variable)
      }
      if (findVar) {
        if (findVar.belongs_to) {
          if (
            docValues[findVar.belongs_to] &&
            docValues[findVar.belongs_to][findVar.index] &&
            docValues[findVar.belongs_to][findVar.index][
            variable
            ]
          ) {
            docValues[findVar.belongs_to][findVar.index][
              variable
            ] = ''
          }
        } else {
          docValues[variable] = ''
        }
      }
      setDocumentValues(docValues)
      if (addedTo.length === 0) {
        setLinesAdded(false)
      }
    },
    [documentValues, linesAddedTo]
  )

  // On side panel toggle
  const handleToggleSidePanel = useCallback(() => {
    if (!showSidePanel) {
      handleDocumentPreview(singleDoc, documentValues, templateObject)
    }
    setShowSidePanel(!showSidePanel)
  }, [showSidePanel, handleDocumentPreview])

  // On tab change - only on smaller screens max-width 768px
  const handleTabChange = (e, tab, attachments = false) => {
    e.preventDefault()
    setActiveTab(tab)
    if (tab === 'preview') {
      handleDocumentPreview(singleDoc, documentValues, templateObject)
    }
    if (attachments) {
      setView('attachments')
    } else {
      setView('variables')
    }
  }

  const handleNavbarDocumentPreview = useCallback(() => {
    if (!showSidePanel) {
      handleToggleSidePanel()
    } else {
      handleDocumentPreview(singleDoc, documentValues, templateObject)
    }
  }, [showSidePanel, handleToggleSidePanel, handleDocumentPreview])

  // On save
  const handleSave = useCallback(async () => {
    setShowGlobalResponseLoader(true)
    setGlobalResponseLoaderText('Sauvegarde du document')
    try {
      let docValues = clearLinesFromValues({
        documentValues,
        linesAddedTo,
        setLinesAdded,
        setLinesAddedTo,
      })
      let documentUpdates = {
        values: docValues,
        checkboxValues: documentCheckboxValues,
        name: singleDocName,
        status: singleDocStatus,
        progress: progress,
        custom_cover: useCustomCover,
        attachments: singleDocAttachments,
      }
      await updateStandardTemplate({
        ...singleDoc,
        ...documentUpdates,
        attachments: singleDocAttachments,
        name: singleDocName
      }, documentId)
      setDocumentValues(docValues)
    } catch (err) {
      setNotification({
        msg: 'Une erreur est survenue, merci de réessayer',
        type: 'danger',
      })
    } finally {
      setShowGlobalResponseLoader(false)
      setGlobalResponseLoaderText('')
    }
    // eslint-disable-next-line
  }, [
    documentCheckboxValues,
    documentId,
    documentValues,
    linesAddedTo,
    singleDocName,
    singleDocStatus,
    templateObject,
    singleDoc,
    useCustomCover,
    singleDocAttachments,
  ])

  // On name change
  const handleNameChange = useCallback((e) => {
    const name = e.currentTarget.textContent
    setSingleDocName(name)
  }, [])

  // Add lines to empty inputs
  const handleAddLinesToEmptyInputs = useCallback(() => {
    if (linesAdded) {
      let docValues = { ...documentValues }
      linesAddedTo.forEach((v) => {
        if (v.belongs_to && v.index !== undefined) {
          if (
            docValues[v.belongs_to] &&
            docValues[v.belongs_to][v.index]
          ) {
            docValues[v.belongs_to][v.index][v.variable] = ''
          }
        } else {
          docValues[v.variable] = ''
        }
      })
      setDocumentValues(docValues)
      setLinesAdded(false)
      setLinesAddedTo([])
      return
    }

    const allVars = allVariables(documentValues, templateObject)
    const allowed = ['string', 'textarea', 'date', 'number']
    const onlyInputVars = allVars.filter(
      (v) => allowed.indexOf(v.type) !== -1
    )
    // console.log(onlyInputVars.filter(v => v.belongs_to))
    let docValues = { ...documentValues }
    let addedTo = []

    onlyInputVars.forEach((v) => {
      if (v.belongs_to && v.index !== undefined) {
        // console.log(v.belongs_to)
        addedTo.push(v)
        if (docValues[v.belongs_to]) {
          if (
            !docValues[v.belongs_to][v.index] ||
            !docValues[v.belongs_to][v.index][v.variable]
          ) {
            if (v.type === 'string') {
              docValues[v.belongs_to][v.index][v.variable] =
                TEXT_LINES
            } else if (v.type === 'textarea') {
              docValues[v.belongs_to][v.index][v.variable] =
                TEXTAREA_LINES
            } else if (v.type === 'date') {
              docValues[v.belongs_to][v.index][v.variable] =
                DATE_LINES
            } else if (v.type === 'number') {
              docValues[v.belongs_to][v.index][v.variable] =
                NUMBER_LINES
            }
          }
        } else {
          if (v.type === 'string') {
            docValues[v.belongs_to] = [
              { [v.variable]: TEXT_LINES },
            ]
          } else if (v.type === 'textarea') {
            docValues[v.belongs_to] = [
              { [v.variable]: TEXTAREA_LINES },
            ]
          } else if (v.type === 'date') {
            docValues[v.belongs_to] = [
              { [v.variable]: DATE_LINES },
            ]
          } else if (v.type === 'number') {
            docValues[v.belongs_to] = [
              { [v.variable]: NUMBER_LINES },
            ]
          }
        }
      } else {
        if (!docValues[v.variable]) {
          addedTo.push(v)
          if (v.type === 'string') {
            docValues[v.variable] = TEXT_LINES
          } else if (v.type === 'textarea') {
            docValues[v.variable] = TEXTAREA_LINES
          } else if (v.type === 'date') {
            docValues[v.variable] = DATE_LINES
          } else if (v.type === 'number') {
            docValues[v.variable] = NUMBER_LINES
          }
        }
      }
    })

    setDocumentValues(docValues)
    setLinesAdded(true)
    setLinesAddedTo(addedTo)
  }, [
    documentValues,
    linesAddedTo,
    templateObject,
    linesAdded,
    allVariables,
  ])

  // On attachment share update
  const handleAttachmentShareUpdate = (index, share) => {
    let da = [...singleDocAttachments]
    da[index].share = share
    setSingleDocAttachments(da)
  }

  // On attachment share save
  const handleAttachmentShareSave = async () => {
    if (documentId) {
      updateDocument(
        { attachments: singleDocAttachments },
        {
          ...singleDoc,
          id: documentId,
          attachments: singleDocAttachments,
        }
      )
    }
  }

  // On version restore
  const handleVersionRestore = (version) => {
    setDocumentValues(version.values)
    setSingleDocName(version.name)
    setCurrentDocVersionTime({ time: version.created })
  }

  // On agency modal open
  const handleOpenAgencyModal = () => {
    setShowAgencyModal(true)
  }

  // On agency modal close
  const handleCloseAgencyModal = () => {
    setShowAgencyModal(false)
  }

  // On notaries modal open
  const handleOpenNotariesModal = () => {
    setShowNotariesModal(true)
  }

  // On close notaries modal
  const handleCloseNotariesModal = () => {
    setShowNotariesModal(false)
  }

  // On open add/edit notary modal
  const handleOpenAddEditNotaryModal = () => {
    setShowAddEditNotaryModal(true)
  }

  // On close add/edit notary modal
  const handleCloseAddEditNotaryModal = () => {
    setShowAddEditNotaryModal(false)
  }

  // Prefill values with admin data
  const prefillValuesWithAdminData = (values) => {
    if (!user || !signInResult || !user.manufacturer) {
      return
    }

    const variablesToPrefill = [
      {
        variable_name: 'adr_caisse',
        value: user.manufacturer.adr_caisse || '',
      },
      {
        variable_name: 'adr_caisse_gestion',
        value: user.manufacturer.adr_caisse_gestion || '',
      },
      {
        variable_name: 'adr_caisse_syndic',
        value: user.manufacturer.adr_caisse_syndic || '',
      },
      {
        variable_name: 'adr_prefecture_carte_pro',
        value: user.manufacturer.adr_prefecture_carte_pro || '',
      },
      {
        variable_name: 'adr_prefecture_carte_pro_gestion',
        value:
          user.manufacturer.adr_prefecture_carte_pro_gestion ||
          '',
      },
      {
        variable_name: 'adr_prefecture_carte_pro_syndic',
        value:
          user.manufacturer.adr_prefecture_carte_pro_syndic || '',
      },
      {
        variable_name: 'adresse',
        value: user.manufacturer.adresse || user.manufacturer.address || '',
      },
      {
        variable_name: 'adresse_siege',
        value: user.manufacturer.adresse_siege || '',
      },
      {
        variable_name: 'banque_sequestre',
        value: user.manufacturer.banque_sequestre || '',
      },
      {
        variable_name: 'caisse',
        value: user.manufacturer.caisse || '',
      },
      {
        variable_name: 'caisse_gestion',
        value: user.manufacturer.caisse_gestion || '',
      },
      {
        variable_name: 'caisse_syndic',
        value: user.manufacturer.caisse_syndic || '',
      },
      {
        variable_name: 'capital',
        value: user.manufacturer.capital || '',
      },
      { variable_name: 'city', value: user.manufacturer.city || '' },
      {
        variable_name: 'code_ape',
        value: user.manufacturer.code_ape || '',
      },
      {
        variable_name: 'compte_sequestre',
        value: user.manufacturer.compte_sequestre || '',
      },
      {
        variable_name: 'cp_prefecture_carte_pro',
        value: user.manufacturer.cp_prefecture_carte_pro || '',
      },
      {
        variable_name: 'cp_prefecture_carte_pro_gestion',
        value:
          user.manufacturer.cp_prefecture_carte_pro_gestion || '',
      },
      {
        variable_name: 'cp_prefecture_carte_pro_syndic',
        value:
          user.manufacturer.cp_prefecture_carte_pro_syndic || '',
      },
      {
        variable_name: 'cp_siege',
        value: user.manufacturer.cp_siege || '',
      },
      {
        variable_name: 'date_de_creation',
        value: user.manufacturer.date_de_creation || '',
      },
      {
        variable_name: 'date_de_creation_gestion',
        value: user.manufacturer.date_de_creation_gestion || '',
      },
      {
        variable_name: 'date_de_creation_syndic',
        value: user.manufacturer.date_de_creation_syndic || '',
      },
      {
        variable_name: 'delivree_par_prefecture',
        value: user.manufacturer.delivree_par_prefecture || '',
      },
      {
        variable_name: 'delivree_par_prefecture_gestion',
        value:
          user.manufacturer.delivree_par_prefecture_gestion || '',
      },
      {
        variable_name: 'delivree_par_prefecture_syndic',
        value:
          user.manufacturer.delivree_par_prefecture_syndic || '',
      },
      {
        variable_name: 'detenteur_carte_pro',
        value: user.manufacturer.detenteur_carte_pro || '',
      },
      {
        variable_name: 'detenteur_carte_pro_gestion',
        value: user.manufacturer.detenteur_carte_pro_gestion || '',
      },
      {
        variable_name: 'detenteur_carte_pro_syndic',
        value: user.manufacturer.detenteur_carte_pro_syndic || '',
      },
      {
        variable_name: 'detention_fonds',
        value: user.manufacturer.detention_fonds || '',
      },
      { variable_name: 'dpo', value: user.manufacturer.dpo || '' },
      {
        variable_name: 'email',
        value: user.manufacturer.email || '',
      },
      {
        variable_name: 'email_location',
        value: user.manufacturer.email_location || '',
      },
      { variable_name: 'fax', value: user.manufacturer.fax || '' },
      {
        variable_name: 'filiale',
        value: user.manufacturer.filiale || '',
      },
      {
        variable_name: 'forme',
        value: user.manufacturer.forme || '',
      },
      {
        variable_name: 'holding',
        value: user.manufacturer.holding || '',
      },
      {
        variable_name: 'horaires',
        value: user.manufacturer.horaires || '',
      },
      {
        variable_name: 'jours_horaires',
        value: user.manufacturer.jours_horaires || '',
      },
      {
        variable_name: 'last_modified',
        value: user.manufacturer.last_modified || '',
      },
      {
        variable_name: 'latitude',
        value: user.manufacturer.latitude || 0,
      },
      {
        variable_name: 'legal_mention',
        value: user.manufacturer.legal_mention || '',
      },
      {
        variable_name: 'longitude',
        value: user.manufacturer.longitude || 0,
      },
      {
        variable_name: 'manufacturers_contact_email',
        value: user.manufacturer.manufacturers_contact_email || '',
      },
      {
        variable_name: 'manufacturers_contact_firstname',
        value:
          user.manufacturer.manufacturers_contact_firstname || '',
      },
      {
        variable_name: 'manufacturers_contact_name',
        value: user.manufacturer.manufacturers_contact_name || '',
      },
      {
        variable_name: 'manufacturers_contact_telephone',
        value:
          user.manufacturer.manufacturers_contact_telephone || '',
      },
      {
        variable_name: 'manufacturers_description',
        value: user.manufacturer.manufacturers_description || '',
      },
      {
        variable_name: 'manufacturers_fonction',
        value: user.manufacturer.manufacturers_fonction || '',
      },
      {
        variable_name: 'manufacturers_gender',
        value: user.manufacturer.manufacturers_gender || '',
      },
      {
        variable_name: 'manufacturers_image',
        value: user.manufacturer.manufacturers_image || '',
      },
      {
        variable_name: 'manufacturers_logo',
        value: user.manufacturer.manufacturers_logo || '',
      },
      {
        variable_name: 'manufacturers_name',
        value: user.manufacturer.manufacturers_name || user.manufacturer.name || '',
      },
      {
        variable_name: 'manufacturers_url',
        value: user.manufacturer.manufacturers_url || '',
      },
      {
        variable_name: 'manufacturers_url_googleplus',
        value: user.manufacturer.manufacturers_url_googleplus || '',
      },
      {
        variable_name: 'manufacturers_url_instagram',
        value: user.manufacturer.manufacturers_url_instagram || '',
      },
      {
        variable_name: 'manufacturers_url_like',
        value: user.manufacturer.manufacturers_url_like || '',
      },
      {
        variable_name: 'manufacturers_url_linkedin',
        value: user.manufacturer.manufacturers_url_linkedin || '',
      },
      {
        variable_name: 'manufacturers_url_pinterest',
        value: user.manufacturer.manufacturers_url_pinterest || '',
      },
      {
        variable_name: 'manufacturers_url_twitter',
        value: user.manufacturer.manufacturers_url_twitter || '',
      },
      {
        variable_name: 'manufacturers_url_youtube',
        value: user.manufacturer.manufacturers_url_youtube || '',
      },
      {
        variable_name: 'mediateur_adresse',
        value: user.manufacturer.mediateur_adresse || '',
      },
      {
        variable_name: 'mediateur_date_obtention_label',
        value:
          user.manufacturer.mediateur_date_obtention_label || '',
      },
      {
        variable_name: 'mediateur_nom',
        value: user.manufacturer.mediateur_nom || '',
      },
      {
        variable_name: 'mediateur_site_internet',
        value: user.manufacturer.mediateur_site_internet || '',
      },
      {
        variable_name: 'mention_carte_pro',
        value: user.manufacturer.mention_carte_pro || '',
      },
      {
        variable_name: 'mention_carte_pro_gestion',
        value: user.manufacturer.mention_carte_pro_gestion || '',
      },
      {
        variable_name: 'mention_carte_pro_syndic',
        value: user.manufacturer.mention_carte_pro_syndic || '',
      },
      {
        variable_name: 'montant_caisse',
        value: user.manufacturer.montant_caisse || '',
      },
      {
        variable_name: 'montant_caisse_gestion',
        value: user.manufacturer.montant_caisse_gestion || '',
      },
      {
        variable_name: 'montant_caisse_syndic',
        value: user.manufacturer.montant_caisse_syndic || '',
      },
      {
        variable_name: 'no_caisse',
        value: user.manufacturer.no_caisse || '',
      },
      {
        variable_name: 'no_caisse_gestion',
        value: user.manufacturer.no_caisse_gestion || '',
      },
      {
        variable_name: 'no_caisse_syndic',
        value: user.manufacturer.no_caisse_syndic || '',
      },
      {
        variable_name: 'no_carte_pro',
        value: user.manufacturer.no_carte_pro || '',
      },
      {
        variable_name: 'no_carte_pro_gestion',
        value: user.manufacturer.no_carte_pro_gestion || '',
      },
      {
        variable_name: 'no_carte_pro_syndic',
        value: user.manufacturer.no_carte_pro_syndic || '',
      },
      {
        variable_name: 'no_rc',
        value: user.manufacturer.no_rc || '',
      },
      {
        variable_name: 'nom_caisse_regionale',
        value: user.manufacturer.nom_caisse_regionale || '',
      },
      {
        variable_name: 'numero_orias',
        value: user.manufacturer.numero_orias || '',
      },
      {
        variable_name: 'postal_code',
        value: user.manufacturer.postal_code || '',
      },
      { variable_name: 'rcp', value: user.manufacturer.rcp || '' },
      {
        variable_name: 'rcp_adresse',
        value: user.manufacturer.rcp_adresse || '',
      },
      {
        variable_name: 'rcp_organisme',
        value: user.manufacturer.rcp_organisme || '',
      },
      { variable_name: 'rcs', value: user.manufacturer.rcs || '' },
      {
        variable_name: 'reseau',
        value: user.manufacturer.reseau || '',
      },
      {
        variable_name: 'secteur',
        value: user.manufacturer.secteur || '',
      },
      {
        variable_name: 'statut_name',
        value: user.manufacturer.statut_name || '',
      },
      {
        variable_name: 'telephone',
        value: user.manufacturer.telephone || user.manufacturer.phone || '',
      },
      {
        variable_name: 'tva_intra',
        value: user.manufacturer.tva_intra || '',
      },
      {
        variable_name: 'type_mandataire',
        value: user.manufacturer.type_mandataire || '',
      },
      {
        variable_name: 'ville_prefecture_carte_pro',
        value: user.manufacturer.ville_prefecture_carte_pro || '',
      },
      {
        variable_name: 'ville_prefecture_carte_pro_gestion',
        value:
          user.manufacturer.ville_prefecture_carte_pro_gestion ||
          '',
      },
      {
        variable_name: 'ville_prefecture_carte_pro_syndic',
        value:
          user.manufacturer.ville_prefecture_carte_pro_syndic ||
          '',
      },
      {
        variable_name: 'ville_rcs',
        value: user.manufacturer.ville_rcs || '',
      },
      {
        variable_name: 'ville_siege',
        value: user.manufacturer.ville_siege || '',
      },
    ]

    for (let v of variablesToPrefill) {
      values[v.variable_name] = v.value
      if (!values[v.variable_name] && signInResult?.agency && signInResult?.agency[v.variable_name]) {
        values[v.variable_name] = signInResult?.agency[v.variable_name]
      }
    }
  }

  const onPreviewSettingsChange = (settings) => {
    setPreviewSettings(settings)
  }

  if (templateNotFound) {
    return (
      <MainLayout
        className="page-document"
        pageTitle="Mes documents"
        titleIcon={<FileAlt2 />}
      >
        <ResourceNotFound
          title="Modèle non trouvé"
          text="Le modèle de ce document est introuvable"
        />
      </MainLayout>
    )
  }

  if (!singleDocReady) {
    return (
      <MainLayout
        className="page-document"
        pageTitle="Mes documents"
        titleIcon={<FileAlt2 />}
      >
        <ResponseLoader
          text="Ouverture du modèle type"
          className="response-loader-v2--dark"
        />
      </MainLayout>
    )
  }

  if (singleDocNotFound) {
    return (
      <MainLayout
        className="page-document"
        pageTitle="Mes documents"
        titleIcon={<FileAlt2 />}
      >
        <ResourceNotFound
          title="Document non trouvé"
          text={`Le document avec l'id "${params.id}" est introuvable`}
        />
      </MainLayout>
    )
  }

  const handleToggleMoreActions = () => {
    setShowMoreActions(!showMoreActions)
  }

  const handleScroll = () => {
    const element = document.getElementById('direction1')
    const { scrollTop } = element

    if (arrayStack[arrayStack.length - 1] < scrollTop) {
      arrayStack.push(scrollTop)
      setIsScroll(true)
    } else {
      arrayStack.length = 0
      arrayStack.push(scrollTop)
      setIsScroll(false)
    }
  }

  return (
    <MainLayout
      className="page-document"
      pageTitle="Mes documents"
      titleIcon={<FileAlt2 />}
    >
      <div
        className=""
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div className="single-document-mobile-title">
          <h1
            style={{ width: '80%' }}
            contentEditable
            suppressContentEditableWarning={true}
            onInput={handleNameChange}
          >
            {singleDoc.name}
          </h1>
          {/* <div style={{ position: 'relative' }}>
                        {showMoreActions && (
                            <div
                                className="more-dropdown"
                                style={{
                                    position: 'absolute',
                                    right: 10,
                                    top: 15,
                                    width: '200px',
                                    padding: '5px',
                                }}
                            >
                                <ul>
                                    <li
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                        onClick={() => {
                                            setHideActionBar(
                                                (prev) => !prev
                                            )
                                            setTimeout(() => {
                                                handleToggleMoreActions()
                                            }, 150)
                                        }}
                                    >
                                        {hideActionBar ? (
                                            <VisibilityIcon />
                                        ) : (
                                            <VisibilityOffIcon />
                                        )}
                                        <span
                                            className="text"
                                            style={{ marginLeft: '6px' }}
                                        >
                                            hide/show action panel
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div> */}
        </div>
      </div>
      <div
        className={`single-document ${showSidePanel ? 'opened' : ''
          }`}
      >
        <StandardTemplateSidebar
          singleDoc={singleDoc}
          currentDocVersionTime={currentDocVersionTime}
          documentValues={documentValues}
          onVariableItemClick={handleVariableItemClick}
          scrollingToItem={scrollingToItem}
          onSectionClick={handleSectionClick}
          templateData={templateObjectWithUniqueVarIndexes}
          linesAddedTo={linesAddedTo}
          tabActive={activeTab === 'summary'}
          onNameChange={handleNameChange}
          singleDocName={singleDocName}
          view={view}
          onSetView={setView}
          numOfAttachments={singleDocAttachments.length}
          status={singleDocStatus}
          onSetStatus={setSingleDocStatus}
          progress={progress}
          templateMetaSections={templateMetaSections}
          summarySections={summarySections}

        />
        <div
          className={`single-document__main document-main single-document-tab ${activeTab === 'main' ? 'tab-active' : ''
            } u-custom-scrollbar`}
          id="direction1"
          style={{
            height: isMobile ? '76%' : '',
          }}
          ref={bodyWrapEl}
          onScroll={() => handleScroll()}
        >
          <StandardTemplateMain
            singleDoc={{
              ...singleDoc,
              id: documentId,
              name: singleDocName,
              status: singleDocStatus,
              attachments: singleDocAttachments,
              values: documentValues,
              checkboxValues: documentCheckboxValues,
            }}
            templateData={templateObjectWithUniqueVarIndexes}
            documentValues={documentValues}
            onValuesChange={handleDocumentValuesChange}
            documentCheckboxValues={documentCheckboxValues}
            onCheckboxValueChange={
              handleDocumentCheckboxValuesChange
            }
            useCustomCover={useCustomCover}
            onCustomCoverChange={handleCustomCoverChange}
            onAgencyModalOpen={handleOpenAgencyModal}
            onNotariesModalOpen={handleOpenNotariesModal}
            linesAddedTo={linesAddedTo}
            onRemoveLines={handleRemoveLines}
            onSetLinesAddedTo={setLinesAddedTo}
            onSave={handleSave}
            onDocumentPreview={handleNavbarDocumentPreview}
            linesAdded={linesAdded}
            onAddLines={handleAddLinesToEmptyInputs}
            attachments={singleDocAttachments}
            onAttachmentsShareUpdate={
              handleAttachmentShareUpdate
            }
            onAttachmentsShareSave={handleAttachmentShareSave}
            documentId={documentId}
            documentName={singleDocName}
            template={templateObject}
            docContacts={documentContacts}
            docSignatureRecipients={docSignatureRecipients}
            onSetDocumentValues={setDocumentValues}
            docHistory={singleDocHistory}
            onVersionRestore={handleVersionRestore}
            onResetSetup={resetSetup}
            view={view}
            onSetView={setView}
            onSetDocumentAttachments={setSingleDocAttachments}
            onSetTab={handleTabChange}
            previewSettings={previewSettings}
            templateMetaSections={templateMetaSections}
          />
        </div>
        <SingleDocumentSidePanel
          onToggle={handleToggleSidePanel}
          previewData={pdfPreviewData}
          previewLoading={previewLoading}
          onPreviewRefresh={() => handleDocumentPreview(singleDoc, documentValues, templateObject)}
          tabActive={activeTab === 'preview'}
          view={view}
          onSetDocumentAttachments={setSingleDocAttachments}
          attachments={singleDocAttachments}
          docId={documentId}
          previewSettings={previewSettings}
          onPreviewSettingsChange={onPreviewSettingsChange}
        />
      </div>

      <div
        className="single-document-mobile-nav"
        style={{
          position: !isScroll ? 'fixed' : 'absolute',
          bottom: !isScroll ? '63px' : '0px',
        }}
      >
        <a
          href="/"
          className={activeTab === 'summary' ? 'active' : ''}
          onClick={(e) => handleTabChange(e, 'summary')}
        >
          <List width={'20px'} height={'20px'} />
        </a>
        <a
          href="/"
          className={
            activeTab === 'main' && view === 'variables'
              ? 'active'
              : ''
          }
          onClick={(e) => handleTabChange(e, 'main')}
        >
          <Pen width={'20px'} height={'20px'} />
        </a>
        <a
          href="/"
          className={
            activeTab === 'preview' && view === 'variables'
              ? 'active'
              : ''
          }
          onClick={(e) => handleTabChange(e, 'preview')}
        >
          <Eye2 width={'20px'} height={'20px'} />
        </a>
        <a
          href="/"
          className={
            (activeTab === 'main' || activeTab === 'preview') &&
              view === 'attachments'
              ? 'active'
              : ''
          }
          onClick={(e) => handleTabChange(e, 'main', true)}
        >
          <Paperclip width={'20px'} height={'20px'} />
        </a>
      </div>

      {showAgencyModal && (
        <AgencyModal
          onClose={handleCloseAgencyModal}
          onSetDocValues={setDocumentValues}
          token={token}
        />
      )}

      {showNotariesModal && (
        <NotariesModal
          onClose={handleCloseNotariesModal}
          onOpenAddEditNotaryModal={handleOpenAddEditNotaryModal}
          onSetSelectedNotary={setSelectedNotary}
        />
      )}

      {showAddEditNotaryModal && (
        <AddEditNotaryModal
          onClose={handleCloseAddEditNotaryModal}
          mode={notaryMode}
          notary={selectedNotary}
        />
      )}
    </MainLayout>
  )
}

export default StandardTemplateDetail
