import { useState, useEffect, useContext } from 'react'
import moment from 'moment'

import { AngleRight, ChevronLeft } from '../../assets/icons'
import { dateDisplayFormat, getFirstLetter } from '../../utils'
import { SIGNATURE_STATUS_TRANLSATIONS } from '../../constants'
import { useSignatureContext } from '../../context/signatures/signaturesState'
import { LoaderContext } from '../../context/loader/loaderState'
import { NotificationContext } from '../../context/notifications/notificationState'
import { notifySignatureParticipant } from '../../services/functions'

const SignaturesStatusPanel = ({ onClose, loading, signature }) => {
  const [updatedAt, setUpdatedAt] = useState('')
  const {selectedSignature} = useSignatureContext()
  const { setShowGlobalResponseLoader, setGlobalResponseLoaderText } = useContext(LoaderContext)
  const { setNotification } = useContext(NotificationContext)

  // Set updatedAt
  useEffect(() => {
    if(signature?.status_updated_at) {
      setUpdatedAt(moment(signature.status_updated_at).format(dateDisplayFormat))
    }else if(signature?.createdAt) {
      setUpdatedAt(moment(signature.createdAt).format(dateDisplayFormat))
    }
  }, [signature])
  
  const resendEmail = async (participant) => {
    setShowGlobalResponseLoader(true)
    setGlobalResponseLoaderText('Envoi du mail en cours...')
    const response = await notifySignatureParticipant({ folder_id: signature.folder_id, participant_id: participant.participantId })
    console.log(response)
    if(response.error) {
      setNotification({ type: 'danger', msg: response.error.message || 'Erreur lors de l\'envoi du mail' })
    } else {
      setNotification({ type: 'success', msg: 'Email envoyé avec succès' })
    }
    setShowGlobalResponseLoader(false)
  }
  
  return (
    <div className="signatures-status-panel">
      <div className="signatures-status-panel__toggle" onClick={onClose}><AngleRight /></div>
      <div className="signatures-status-panel__inner u-custom-scrollbar">
        <div className="signatures-status-panel__head">
          <h2><span>État des signatures</span></h2>
        </div>
        <div className="signatures-status-panel__head-mobile">
          <div className="close" onClick={onClose}><ChevronLeft /></div>
        
          <h2>{!loading && signature?.title}</h2>
          <p>{!loading && updatedAt}</p>
        </div>

          <h3>{selectedSignature?.title}</h3>
        <div className="signatures-status-panel__body">
          {loading && (
            <div className="loader-wrapper loader-wrapper--2">
              <div className="loader-v2"></div>
            </div>
          )}
          <ul className="recipients-list">
            {!loading && signature && signature.recipients && Array.isArray(signature.recipients) && signature.recipients.map((u, idx) => {
              return (
                <div className='status-item' key={`participant_${idx}`}>
                  <div className='type'>{getFirstLetter(u.firstName)}</div>
                  <div>
                    <b className='name'>{`${u.firstName} ${u.lastName}`}</b>
                    <span className={`sign-status ${u.status === "COMPLETE" || u.status === "PASS" ? 'green': ''}`}>{SIGNATURE_STATUS_TRANLSATIONS[u.status]}</span>
                    <div className='email'>{u.email}</div>
                    {/* TODO validate email resending via mailgun */}
                    {(u.status === "IN_PROGRESS" && Boolean(u.participantId)) && <div className='options'>
                      <span onClick={() => resendEmail(u)}>Relancer l'email</span>
                    </div>}
                  </div>
                </div>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default SignaturesStatusPanel