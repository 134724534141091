import React, { useState } from 'react'

import { Header, Footer, Sidebar, MobileMenu } from '../components/shared'

const MainLayout = ({ className, children, pageTitle, fullSidebar = false, titleIcon = null, showSearch = false, searchIn = '' }) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false)

  let mainClass = 'main-layout'

  if(className) {
    mainClass += ` ${className}`
  }
  if(!fullSidebar) {
    mainClass += ' main-layout--sidebar-shrinked'
  }

  // On hamburger menu click
  const handleToggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu)
  }

  return (
    <div className={mainClass}>
      <div className="main-layout__fix"></div>
      <Header showSearch={showSearch} searchIn={searchIn} />
      <main className="main-layout__main">
        <Sidebar full={fullSidebar} />
        <div className="main-layout__body">
          <div className="main-layout__body_mobile">
            <div className={showMobileMenu ? "hamburger-menu active" : "hamburger-menu"} onClick={handleToggleMobileMenu}>
              <div className="hamburger-menu__line"></div>
              <div className="hamburger-menu__line"></div>
              <div className="hamburger-menu__line"></div>
            </div>
            <p className={titleIcon ? 'highlighted' : ''}>{titleIcon}<span>{pageTitle}</span></p>
            {showMobileMenu && <MobileMenu />}
          </div>
          <div className="main-layout__body_content u-custom-scrollbar">
            { children }
          </div>
        </div>
      </main>
      <Footer />
    </div>
  )
}

export default MainLayout