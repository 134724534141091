import React from 'react'

const SenderModalContentAssociateSuccess = ({ onClose }) => {

  return (
    <div className=''>
      <h2 className='modal-title'>Associer un compte expéditeur AR24</h2>
      <ul className='modal-list'>
        <li className='modal-list-item'>
          <p className='text-success'>
            La demande a été transmise
          </p>
        </li>
        <li className='modal-list-item'>
          <p>
            Vous allez recevoir un e-mail de la part d'AR24 un e-mail vous invitant à confirmer l'association de votre compte expéditeur à Immo Docs. 
          </p>
        </li>
        <li className='modal-list-item'>
          <p>
            Dès validation, et si votre compte expéditeur est vérifié, les utilisateurs liés à votre agence pourront envoyer des lettres recommandées électroniques depuis Immo Docs.  
          </p>
        </li>
        <li className='modal-list-item'>
          <p>
            Il vous sera possible à tout moment de revoquer l'association de votre compte AR24 à Immo Docs sur le site ar24.fr.
          </p>
        </li>
      </ul>
      <ul className='flex flex-row justify-end gap-10'>
        <li>
          <button
            className='btn btn--large btn--transparent btn--border-primary'
            onClick={onClose}
          >
            Fermer
          </button>
        </li>
      </ul>
    </div>
  )
}

export default SenderModalContentAssociateSuccess