import { Modal } from '../../ui_new'
import { useContext, useEffect, useState } from "react";
import { RegisteredMailContext } from "../../../context";
import MailModalContentSender from './MailModalContentSender';
import MailModalMenu from './MailModalMenu';
import MailModalContentRecipients from './MailModalContentRecipients';
import MailModalContentDocuments from './MailModalContentDocuments';
import MailModalContentMessage from './MailModalContentMessage';
import MailModalContentSummary from './MailModalContentSummary';
import MailModalContentConfirmation from './MailModalContentConfirmation';

const MailModal = ({ onClose, data, loadingMessage, errorMessage, onClearError, responseStatus, onClearStatus }) => {

  const { sendAr24Mail } = useContext(RegisteredMailContext)

  const [step, setStep] = useState('sender')
  const [recipients, setRecipients] = useState([{
    firstName: '',
    lastName: '',
    email: '',
    type: '',
    consent: false,
  }])
  const [documents, setDocuments] = useState([])
  const [message, setMessage] = useState(null)

  useEffect(() => {
    if (data) {
      if(data.recipients) {
        setRecipients(data.recipients)
      }
      if(data.documents) {
        setDocuments(data.documents)
      }
    }
  }, [data])

  const onSummarySubmit = async () => {
    await sendAr24Mail({ recipients, documents, message, documentName: data.documentName, documentId: data.documentId, source: data.source, documentValues: data.documentValues, documentContentChanges: data.documentContentChanges })
  }

  const onSubmitMessage = () => {
    // setMessage(message)
    setStep('summary')
  }

  const displayContent = () => {
    return !loadingMessage && !errorMessage && !responseStatus
  }

  return (
    <Modal onClose={onClose} className="modal--padding-sm registered-mail-modal">
      {loadingMessage && 
        <div className="response-loader-v2__content">
          <p>{loadingMessage}</p>
          <div className="loader-v2 mt-20"></div>
        </div>
      }
      {errorMessage &&
       <div className=''>
        {/* <h2 className='modal-title'>Modifier le compte expéditeur</h2> */}
          <ul className='modal-list'>
            <li className='modal-list-item'>
              <p className='text-error' >{errorMessage}</p>
            </li>
          </ul>
          <ul className='flex flex-row justify-end gap-10'>
            <li>
              <button
                className='btn btn--large btn--transparent btn--border-primary'
                onClick={onClearError}
              >             
                Retour
              </button>
            </li>
          </ul>
        </div>
      }
      {responseStatus === 'success' &&
        <MailModalContentConfirmation onClose={onClose}  />
      }
      {displayContent() && <>
        <h2 className='modal-title'>Envoyer par lettre recommandée électronique</h2>
        {step !== 'confirmation' && <MailModalMenu currentItem={step} /> }
      </>}
      {displayContent() && step === 'sender' &&
        <MailModalContentSender
          onClose={onClose}
          onContinue={() => setStep('recipients')} />
      }
      {displayContent() && step === 'recipients' &&
        <MailModalContentRecipients
          recipients={recipients}
          setRecipients={setRecipients}
          onReturn={() => setStep('sender')}
          onContinue={() => setStep('documents')} />
      }
      {displayContent() && step === 'documents' &&
        <MailModalContentDocuments
          documents={documents}
          setDocuments={setDocuments}
          onReturn={() => setStep('recipients')}
          onContinue={() => setStep('message')} />
      }
      {displayContent() && step === 'message' &&
        <MailModalContentMessage
          message={message}
          setMessage={setMessage} 
          onReturn={() => setStep('documents')}
          onSubmit={onSubmitMessage} />
      }
      {displayContent() && step === 'summary' &&
        <MailModalContentSummary
          recipients={recipients}
          documents={documents}
          message={message}
          onReturn={() => setStep('message')}
          onSubmit={onSummarySubmit} />
      }
    </Modal>
  )
}

export default MailModal