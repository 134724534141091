import { useContext } from "react";
import { RegisteredMailContext } from "../../../context";
import React from 'react'

const MailModalContentSender = ({ onClose, onContinue }) => {

  const { showSenderModal, ar24Sender, resendSenderConfirmation } = useContext(RegisteredMailContext)

  const handleCreateSender = () => {
    // onClose()
    showSenderModal('create')
  }

  const handleAsociateSender = () => {
    // onClose()
    showSenderModal('associate')
  }

  const handleResendConfirmation = async () => {
    // onClose()
    // showSenderModal('modify')
    // await handleResendBasedOnStatus()
    await resendSenderConfirmation()
  }

  const handleOpenSender = () => {
    // onClose()
    showSenderModal('modify')
  }

  const senderStatus = ar24Sender?.status || 'none'


  return (
    <div className='registered-mail-send-modal-container'>
      <div className='registered-mail-send-modal-container__header'>
        {senderStatus === 'none' ? (
          <ul className='modal-list'>
            <li className='modal-list-item'>
              <p>Vous ne pouvez pas envoyer de lettre recommandée électronique car <b>aucun compte expéditeur AR24 n'est actuellement paramétré</b> sur Immo Docs.</p>
            </li>
            
            <li className='modal-list-item'>
              <p>
                Vous pouvez soit <b>créer un compte expéditeur</b>, soit <b>associer un compte expéditeur AR24 déjà existant</b>
              </p>
            </li>
          </ul>
        ) : senderStatus === 'api-access-pending' ? (
          <ul className='modal-list'>
            <li className='modal-list-item'>
              <p>Vous ne pouvez pas envoyer de lettre recommandée électronique car l'expéditeur associé au compte <b>{ar24Sender.email}</b> n'a pas encore confirmé l'association du compte AR24 sur Immo Docs.</p>
            </li>
            <li className='modal-list-item'>
              <p>Pour <b>confirmer l'association du compte AR24 à Immo Docs</b>, veuillez valider celle-ci depuis l'e-mail reçu à l'adresse "<b>{ar24Sender.email}</b>".</p>
            </li>
          </ul>
        ) : senderStatus === 'invitation-pending' || senderStatus === 'confirmation-pending' ? (
          <ul className='modal-list'>
            <li className='modal-list-item'>
              <p>Vous ne pouvez pas envoyer de lettre recommandée électronique car le <b>compte expéditeur AR24</b> au nom de <b>{ar24Sender.firstname} {ar24Sender.lastname}</b> n'a <b>pas encore confirmé</b> l'association du compte AR24 sur Immo Docs.</p>
            </li>
            <li className='modal-list-item'>
              <p>Pour <b>confirmer l'association du compte expéditeur AR24</b> sur Immo Docs, veuillez valider celle-ci depuis l'<b>email reçu</b> à l'adresse "{ar24Sender.email}".</p>
            </li>
          </ul>
        ) : senderStatus === 'verification-pending' || senderStatus === 'non-verified' ? (
          <ul className='modal-list'>
            <li className='modal-list-item'>
              <p>Vous ne pouvez pas envoyer de lettre recommandée électronique car le <b>compte expéditeur AR24</b> au nom de <b>{ar24Sender.firstname} {ar24Sender.lastname}</b> n'est pas encore validé.</p>
            </li>
            <li className='modal-list-item'>
              <p>Pour <b>valider le compte expéditeur</b>, renseignez la <b>clé OTP</b> inscrite sur l'<b>identifiant LRE</b> que vous avez reçu par voie postale dans Immo Docs (Lettres recommandées &gt; Gestion du compte expéditeur).</p>
            </li>
          </ul>
        ) : senderStatus === 'active' || senderStatus === 'verified' ? (
          <ul className='modal-list'>
            <li className='modal-list-item'>
              <p>L'envoi d'une lettre recommandée électronique vous sera facturé <b>3,49€ HT</b> (4.19€ TTC) <b>par destinataire</b>.</p>
            </li>
            <li className='modal-list-item'>
              <p>Chaque LRE sera envoyée au nom de <b>{ar24Sender.firstname} {ar24Sender.lastname}</b> (compte expéditeur paramétré sur Immo Docs).</p>
            </li>
            <li className='modal-list-item'>
              <p>Attention: avant d'envoyer une lettre recommandée électronique à un <b>particulier</b>, vous devez avoir <b>requis au préalable son consentement</b> à recevoir une lettre recommandée électronique <b>par e-mail</b> de votre part (Article L100 du Code des postes et des communications électroniques).<br/>
              Pour cela, un modèle de lettre de consentement est disponible dans votre bibliothèque Immo Docs.</p>
            </li>
          </ul>
        ) : null}
        {/* <h2>
          L’envoi de cette lettre recommandée
          électronique qualifiée vous sera facturée
          de 3,49€ HT (4.19€ TTC) par destinataire.
        </h2>
        <p>
          Sélectionnez l’expéditeur de la LRE qualifiée.
          Seuls les expéditeurs authentifiés peuvent
          faire des envois de lettres recommandées :
        </p> */}
        {/* <MailModalMenu currentItem='Expediteurs' /> */}
      </div>
      {/* <button
        className='btn btn--transparent'
        onClick={handleOpenAjouterModal}
      >
        Annuler
      </button> */}
      <div className='modal-btn-box'>
        <button
          className='btn btn--large btn--transparent btn--border-primary'
          onClick={onClose}
        >
          Annuler
        </button>
        {senderStatus === 'none' && <>
          <button
            className='btn btn--large btn--primary'
            onClick={handleCreateSender}
          >
            Créer un compte AR24
          </button>
          <button
            className='btn btn--large btn--primary'
            onClick={handleAsociateSender}
          >
            Associer un compte AR24
          </button>
        </>}
        {(senderStatus === 'confirmation-pending' || senderStatus === 'invitation-pending') && <button
          className='btn btn--large btn--success'
          onClick={handleResendConfirmation}
        >
          Renvoyer l'email de confirmation
        </button>}
        {(senderStatus === 'api-access-pending' || senderStatus === 'verification-pending' || senderStatus === 'non-verified') && <button
          className='btn btn--large btn--primary'
          onClick={handleOpenSender}
        >
          Accéder au compte
        </button>}
        {senderStatus === 'verified' && <button
          disabled={senderStatus !== 'active' && senderStatus !== 'verified'}
          className='btn btn--large btn--primary'
          onClick={onContinue}
        >
          Suivant
        </button>}
      </div>
    </div>
  )
}

export default MailModalContentSender