import { useContext, useState, useEffect, useCallback } from 'react' 
import { useHistory } from 'react-router-dom'

import { MainLayout } from '../layouts'
import { FolderOpen } from '../assets/icons'
import { TemplatesContentSidebar, TemplatePreviewModal, TemplatePreviewMobile, TemplatesTable } from '../components/misc'
import { TemplateFolderBox, TemplateBox, Alert } from '../components/ui_new'
import { DocumentsContext, FolderContext, LoaderContext, UserContext } from '../context'
import { isFeatureAuthorized, sortArrayOfObjects, sortedArrayFromObject, sorterWithPathAndOrder } from '../utils'
import { useSingleDocumentActions } from '../hooks'
import EditTemplateModal from '../components/sections/templates/EditTemplateModal'
import StandardTemplatesContentSidebar from '../components/misc/StandardTemplatesContentSidebar'
import StandardTemplateBox from '../components/ui_new/StandardTemplateBox'
import TemplateHistoryModal from '../components/sections/document-detail/TemplateHistoryModal'

const StandardTemplates = ({ standard }) => {
  const { createDocumentFromTemplate } = useSingleDocumentActions()
  const { templatesSelectedFilters, setTemplatesSelectedFilters, updateStandardTemplate, deleteStandardTemplate, standardTemplates, standardTemplatesLoaded, templates } = useContext(DocumentsContext)
  const { standardTemplatesFolders, standardTemplatesFoldersFetched, currentlyActiveFolder, folders, setCurrentlyActiveFolder } = useContext(FolderContext)
  const { userClaims } = useContext(UserContext)
  const { setShowGlobalResponseLoader, setGlobalResponseLoaderText } = useContext(LoaderContext)
  const [rootFolder, setRootFolder] = useState({
    id: 'templates-root-folder',
    name: 'Dossier racine',
    templates: []
  })
  const [rootFolders, setRootFolders] = useState([])
  const [activeTemplates, setActiveTemplates] = useState([])
  const [filteredTemplates, setFilteredTemplates] = useState([])
  const [filteredFolders, setFilteredFolders] = useState([])
  const [ready, setReady] = useState(false)
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [currentTemplate, setCurrentTemplate] = useState(null)
  const [showMobilePreview, setShowMobilePreview] = useState(false)
  const [currentMobileTemplate, setCurrentMobileTemplate] = useState(null)
  const [showEditTemplateModal, setShowEditTemplateModal] = useState(false);
  const [showDeleteTemplateAlert, setShowDeleteTemplateAlert] = useState(false);
  const [activeSort, setActiveSort] = useState('name');
  const [activeOrder, setActiveOrder] = useState('desc');
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const history = useHistory()

  // Set templates
  useEffect(() => {
    const setupTemplates = async () => {
      const copyOfTemplates = {...standardTemplates}
      const filteredTemplates = {}
      let templatesInRootFolder = []
      for(let key in copyOfTemplates) {
        if(!copyOfTemplates[key].deleted) {
          const template = copyOfTemplates[key]
          let folder = ''
          if(template.folderId && Array.isArray(template.folderId) && template.folderId[0]) {
            const folderFound = standardTemplatesFolders.find(f => f.id === template.folderId[0])
            if(folderFound) {
              folder = folderFound.name
            }else {
              templatesInRootFolder.push(template)
            }
          }else {
            templatesInRootFolder.push(template)
          }
          filteredTemplates[key] = { ...copyOfTemplates[key], folder }
        }
      }
      if(templatesInRootFolder.length > 0) {
        setRootFolder(prev => ({...prev, templates: templatesInRootFolder}))
      }
      let sort = 'name'
      let order = 'desc'
      let arr = sortedArrayFromObject(filteredTemplates, sorterWithPathAndOrder(sort, order))
      setActiveTemplates(arr)
      setFilteredTemplates(arr)
      setReady(true)
    }

    if(standardTemplatesLoaded && standardTemplatesFoldersFetched) {
      setupTemplates()
    }
  }, [standardTemplates, standardTemplatesLoaded, standardTemplatesFolders, standardTemplatesFoldersFetched])

  // Set root folders
  useEffect(() => {
    if(standardTemplatesFolders && Array.isArray(standardTemplatesFolders)) {
      setRootFolders(standardTemplatesFolders.filter(folder => !folder.parentFolder))
    }
  }, [standardTemplatesFolders])

  // On filter
  const handleFilter = useCallback(() => {
    let filtered = [...activeTemplates]
    
    let filteredFolds = []
    for(let key in templatesSelectedFilters) {
      if(key === 'folder' && templatesSelectedFilters[key] !== 'all') {
        filtered = filtered.filter(tmplt => tmplt.folderId && tmplt.folderId.includes(templatesSelectedFilters[key]))
        filteredFolds = standardTemplatesFolders.filter(f => f.parentFolder === templatesSelectedFilters[key])
      }else if(key === 'folder' && templatesSelectedFilters[key] === 'all'){
        filtered = filtered.filter(tmplt => !tmplt.folderId || tmplt.folderId.length === 0)
        filteredFolds = standardTemplatesFolders.filter(f => !f.parentFolder)
      }
      if(key === 'search' && templatesSelectedFilters[key] !== '') {
        filtered = filtered.filter(tmplt => tmplt.name.toLowerCase().includes(templatesSelectedFilters[key].trim().toLowerCase()))
      }
    }

    setFilteredFolders(filteredFolds)
    setFilteredTemplates(filtered)
  }, [activeTemplates, templatesSelectedFilters, standardTemplatesFolders])

  // Watch for filters change
  useEffect(() => {
    handleFilter()
  }, [templatesSelectedFilters, handleFilter])

  // On preview modal open
  const handleOpenPreviewModal = (template) => {
    setShowPreviewModal(true)
    setCurrentTemplate(template)
  }

  // On preview modal close
  const handleClosePreviewModal = () => {
    setShowPreviewModal(false)
    setCurrentTemplate(null)
  }

  // On mobile preview open
  const handleOpenMobilePreview = (template) => {
    setShowMobilePreview(true)
    setCurrentMobileTemplate(template)
  }

  // On mobile preview close
  const handleCloseMobilePreview = () => {
    setShowMobilePreview(false)
    setCurrentMobileTemplate(null)
  }

  // On create document
  const handleCreateDocument = async (template) => {
    setShowGlobalResponseLoader(true)
    setGlobalResponseLoaderText('Création du document')
    try {
      const id = await createDocumentFromTemplate(template, template.values, template.template)
      history.push(`/documents/${id}`)
    } catch (err) {

    } finally {
      setShowGlobalResponseLoader(false)
      setGlobalResponseLoaderText('')
    }
  }


  const editTemplateClickHandler = (template) => {
    setCurrentTemplate(template);
    setShowEditTemplateModal(true);
  }

  const updateTemplateHandler = async (data, id) => {
    await updateStandardTemplate(data, id);
    setShowEditTemplateModal(false);
    setCurrentTemplate(null);
  }

  const deleteTemplateClickHandler = (template) => {
    setCurrentTemplate(template);
    setShowDeleteTemplateAlert(true);
  }

  const deleteTemplateHandler = async () => {
    await deleteStandardTemplate(currentTemplate.id);
    setShowDeleteTemplateAlert(false);
    setCurrentTemplate(null);
  }

  const toggleTemplateHiddenClickHandler = async (template) => {
    await updateStandardTemplate({ hidden: !template.hidden }, template.template);
  }

  const handleOpenStandardTemplate = (template) => {
    history.push(`/standard-templates/${template.id}`);
  }


   // Handle sort
   const handleSort = (sort_by) => {
    const templates = sortArrayOfObjects(filteredTemplates, sort_by, activeOrder === 'desc' ? 'asc' : 'desc')
    setActiveSort(sort_by)
    setActiveOrder(activeOrder === 'desc' ? 'asc' : 'desc')
    setFilteredTemplates(templates)
    // saveSortingToLS(sort_by, activeOrder === 'desc' ? 'asc' : 'desc', 'documents')
  }

  const handleSelectFolderInTable = (folder) => {
    setTemplatesSelectedFilters(prev => ({ ...prev, folder: folder.id }))
    setCurrentlyActiveFolder(folder)
  }

  const handleOpenInfoModal = (standardTemplate) => {
    const baseTemplate = templates[standardTemplate.template]
    setCurrentTemplate(baseTemplate);
    setShowInfoModal(true);
  }

  const handleCloseInfoModal = () => {
    setCurrentTemplate(null);
    setShowInfoModal(false);
  }

  const handleOpenHistoryModal = (standardTemplate) => {
    const baseTemplate = templates[standardTemplate.template]
    setCurrentTemplate(baseTemplate);
    setShowHistoryModal(true);
  }

  const handleCloseHistoryModal = () => {
    setCurrentTemplate(null);
    setShowHistoryModal(false);
  }


   // Generate breadcrumb
   useEffect(() => {
    if (currentlyActiveFolder) {
      if (currentlyActiveFolder === 'root') {
        setBreadcrumbs(findItemAndParents(''));
      } else {
        setBreadcrumbs(findItemAndParents(currentlyActiveFolder.id));
      }
    }
  }, [currentlyActiveFolder, standardTemplatesFolders])

  const findItemAndParents = (itemId) => {
    // Helper function to recursively find parents
    function findParents(parents, currentData, targetId) {
      for (const item of currentData) {
        if (item.id === targetId) {
          // Found the item, add it to the parents array
          parents.push(item);
          if (item.parentFolder) {
            // If it has a parent folder, recursively find its parents
            findParents(parents, standardTemplatesFolders, item.parentFolder);
          }
          break;
        }
      }
    }

    // Find the item
    const foundItem = standardTemplatesFolders.find(item => item.id === itemId);
    if (!foundItem) {
      return null; // Item not found
    }
    // Array to store the parents
    const parents = [];
    // Find all parents recursively
    findParents(parents, standardTemplatesFolders, foundItem.parentFolder);

    // Add the found item to the beginning of the array
    parents.unshift(foundItem);
    return parents.reverse();
  }

  return (
    <MainLayout className="page-templates" pageTitle="Modèles" titleIcon={<FolderOpen />} showSearch={true} searchIn="templates">
      <div className="templates-content">
        <StandardTemplatesContentSidebar 
          selectedFilters={templatesSelectedFilters} 
          onSetSelectedFilters={setTemplatesSelectedFilters} 
          rootFolders={rootFolders} 
          standard={true}
        />
        <div className="templates-content__main">
          <ul className='flex mb-20'>
            <li>Dossier racine</li>{breadcrumbs?.map((item, index) => (
              <li key={item.id}>/ {item.name}
              </li>
            ))}
          </ul>
          {!ready && (
            <div className="loader-wrapper">
              <div className="loader-v2"></div>
            </div>
          )}
          {ready && (
            <TemplatesTable
              filteredTemplates={filteredTemplates}
              filteredFolders={filteredFolders}
              onSort={handleSort}
              onPreview={handleOpenStandardTemplate} 
              onInfo={handleOpenInfoModal}
              onHistory={handleOpenHistoryModal}
              onCreateDocument={handleCreateDocument}
              onTemplateDelete={deleteTemplateClickHandler}
              onTemplateEdit={editTemplateClickHandler}
              onTemplateToggleHidden={toggleTemplateHiddenClickHandler}
              rootFolders={rootFolders} 
              onSelectFolder={handleSelectFolderInTable}
              view="standard-templates" />
            // <ul className="templates-list">
            //   {filteredTemplates.filter((template) => (isFeatureAuthorized({ userClaims, rule: 'any_admin' }) || !template.hidden)).map((template) => {
            //     return (
            //       <StandardTemplateBox 
            //         standard
            //         key={template.id}
            //         template={template}
            //         onPreview={handleOpenPreviewModal}
            //         onCreateDocument={handleCreateDocument}
            //         onTemplateDelete={deleteTemplateClickHandler}
            //         onTemplateEdit={editTemplateClickHandler}
            //         onTemplateToggleHidden={toggleTemplateHiddenClickHandler} 
            //         onOpenStandardTemplate={handleOpenStandardTemplate}
            //       />
            //     )
            //   })}
            // </ul>
          )}

          {ready && (
            <div className="templates-content__main_mobile">
              <TemplateFolderBox folder={rootFolder} folderTemplates={rootFolder.templates} onPreview={handleOpenMobilePreview} />
              {rootFolders.map((folder) => {
                  const folderTemplates = activeTemplates.filter(tmplt => tmplt.folderId && Array.isArray(tmplt.folderId) && tmplt.folderId.includes(folder.id))
                  return (
                    <TemplateFolderBox key={folder.id} folder={folder} folderTemplates={folderTemplates} onPreview={handleOpenMobilePreview} />
                  )
                })
              }
            </div>
          )}
        </div>
      </div>

      {showPreviewModal && (
        <TemplatePreviewModal 
          onClose={handleClosePreviewModal}
          template={currentTemplate}
        />
      )}

      {showEditTemplateModal && <EditTemplateModal 
        onClose={() => setShowEditTemplateModal(false)}
        onEdit={updateTemplateHandler}
        currentTemplate={currentTemplate}
      />}

      {showDeleteTemplateAlert && <Alert 
        onClose={() => setShowDeleteTemplateAlert(false)}
        text="Etes-vous certain de vouloir supprimer ce modèle?"
        onSubmit={deleteTemplateHandler}
        deleteAlert
      />}
      {(showInfoModal || showHistoryModal) && <TemplateHistoryModal mode={showHistoryModal ? 'history' : 'info'} onClose={() => {
        handleCloseInfoModal()
        handleCloseHistoryModal()
      }} template={currentTemplate} /> }

      <TemplatePreviewMobile opened={showMobilePreview} template={currentMobileTemplate} onClose={handleCloseMobilePreview} />
    </MainLayout>
  )
}

export default StandardTemplates
