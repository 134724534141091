import { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import { MainLayout } from '../layouts'
import { DashboardActions, DashboardContentBox } from '../components/misc'
import { DocumentsContext, UserContext, SignatureContext, RegisteredMailContext } from '../context'
import { sortedArrayFromObject, sorterWithPathAndOrder, sortArrayOfObjects, availableOn, isFeatureEnabled, FEATURE } from '../utils'
import { Alert } from '../components/ui_new'

const TEMPLATE_INFORMATION_SHOWN_KEY = '_jurisur_template_info_23-02-2023'

const Dashboard = () => {
  const { documents, documentsLoaded, setDocsSelectedFilters } = useContext(DocumentsContext)
  const { partner, canUseSignatures, siteConfigsLoaded, siteConfigs } = useContext(UserContext)
  const { signatures, signaturesFetched, setSignaturesSelectedFilters } = useContext(SignatureContext)
  const { ar24MailFilter, setAr24MailFilter, ar24Mail, ar24MailFetched } = useContext(RegisteredMailContext)
  const [draftDocs, setDraftDocs] = useState([])
  const [readyDocs, setReadyDocs] = useState([])
  const [inProgressDocs, setInProgressDocs] = useState([])
  const [closedDocs, setClosedDocs] = useState([])
  const [selectedDocuments, setSelectedDocuments] = useState([])
  const [docsReady, setDocsReady] = useState(false)
  const [signaturesReady, setSignaturesReady] = useState(false)
  const date = moment()
  const history = useHistory()

  const [mailReady, setMailReady] = useState(false)
  const [sentMail, setSentMail] = useState([])
  const [acceptedMail, setAcceptedMail] = useState([])
  const [expiredMail, setExpiredMail] = useState([])

  // Set documents - draft and ready
  useEffect(() => {
    if(documentsLoaded) {
      const copyOfDocuments = {...documents}
      const filteredDocuments = {}
      for(let key in copyOfDocuments) {
        filteredDocuments[key] = copyOfDocuments[key]
      }
      let sort = 'meta.created'
      let order = 'asc'
      let docsArr = sortedArrayFromObject(filteredDocuments, sorterWithPathAndOrder(sort, order))
      setDraftDocs([...docsArr].filter(doc => doc.status === 'draft'))
      setReadyDocs([...docsArr].filter(doc => doc.status === 'ready'))
      setDocsReady(true)
    }
    // eslint-disable-next-line
  }, [documents, documentsLoaded])

  // Set documents(signatures) - in progress and closed
  useEffect(() => {
    if(canUseSignatures){
      if(signaturesFetched) {
        const signaturesArr = [...signatures]
        const closed = signaturesArr.filter(s => s.status === 'COMPLETED')
        const inProgress = signaturesArr.filter(s => s.status === 'IN_PROGRESS')
        let sort = 'title'
        let order = 'desc'
        const closedArr = sortArrayOfObjects(closed, sort, order)
        const inProgressArr = sortArrayOfObjects(inProgress, sort, order)
        setClosedDocs(closedArr)
        setInProgressDocs(inProgressArr)
        setSignaturesReady(true)
      }
    }
    // eslint-disable-next-line
  }, [signatures, signaturesFetched, canUseSignatures, siteConfigsLoaded])

  // Set mail - sent, received and expired
  useEffect(() => {
    if(ar24MailFetched) {
      let sort = 'meta.created'
      let order = 'asc'
      let docsArr = sortArrayOfObjects(ar24Mail, sort, order)
      const sentStatuses = ['waiting', 'dp', 'ev']
      const acceptedStatuses = ['AR']
      const expiredStatuses = ['refused', 'negligence', 'bounced', 'fail', 'cancelled_by_user', 'cancelled_after_7_days']
      setSentMail([...docsArr].filter(doc => sentStatuses.includes(doc.status)))
      setAcceptedMail([...docsArr].filter(doc => acceptedStatuses.includes(doc.status)))
      setExpiredMail([...docsArr].filter(doc => expiredStatuses.includes(doc.status)))
      setMailReady(true)
    }
    // eslint-disable-next-line
  }, [ar24Mail, ar24MailFetched])

  // On title click
  const handleTitleClick = (route, type, status) => {
    if(type === 'documents') {
      setDocsSelectedFilters(prev => ({...prev, status}))
    }else if(type === 'documents') {
      setDocsSelectedFilters(prev => ({...prev, status}))
    }else if(type === 'signatures') {
      setSignaturesSelectedFilters(prev => ({...prev, status}))
    }else if(type === 'signatures') {
      setSignaturesSelectedFilters(prev => ({...prev, status}))
    } else if(type === 'registered_mail') {
      setAr24MailFilter({ ...ar24MailFilter, status: status })
    }
    history.push(route)
  }

  return (
    <MainLayout className="page-dashboard" pageTitle="Accueil" fullSidebar={true}>
      <div className="dashboard-content u-custom-scrollbar">
        <div className="dashboard-content__top">
          <p><strong>Bonjour !</strong> Voici un récapitulatif de vos projets au {date.format('DD/MM/YYYY')}</p>
        </div>
        {selectedDocuments.length > 0 && (
          <div className="dashboard-content__actions">
            <DashboardActions selectedDocuments={selectedDocuments} onSetSelectedDocuments={setSelectedDocuments} />
          </div>
        )}
        <div className="dashboard-content__boxes-wrapper u-custom-scrollbar">
          <div className="dashboard-content__boxes">
            {/* Draft */}
            <DashboardContentBox
              title="Brouillon"
              documents={draftDocs}
              onSetSelectedDocuments={setSelectedDocuments}
              selectedDocuments={selectedDocuments}
              loading={!docsReady}
              onRedirect={() => handleTitleClick('/documents', 'documents', 'draft')}
            />

            {/* Ready to send */}
            <DashboardContentBox
              title="Prêt pour envoi"
              documents={readyDocs}
              onSetSelectedDocuments={setSelectedDocuments}
              selectedDocuments={selectedDocuments}
              loading={!docsReady}
              onRedirect={() => handleTitleClick('/documents', 'documents', 'ready')}
            />

            {/* In progress */}
            <DashboardContentBox
              title="Cérémonie en cours"
              documents={inProgressDocs}
              onSetSelectedDocuments={setSelectedDocuments}
              selectedDocuments={selectedDocuments}
              type="signatures"
              loading={!signaturesReady}
              onRedirect={() => handleTitleClick('/signatures', 'signatures', 'IN_PROGRESS')}
            />

            {/* Closed */}
            <DashboardContentBox
              title="Cérémonie clôturée"
              documents={closedDocs}
              onSetSelectedDocuments={setSelectedDocuments}
              selectedDocuments={selectedDocuments}
              type="signatures"
              loading={!signaturesReady}
              onRedirect={() => handleTitleClick('/signatures', 'signatures', 'COMPLETED')}
            />

            {isFeatureEnabled(FEATURE.AR24, siteConfigs) && 
              <DashboardContentBox
                title="LRE envoyées et reçues"
                documents={sentMail}
                onSetSelectedDocuments={setSelectedDocuments}
                selectedDocuments={selectedDocuments}
                type="registered_mail"
                loading={!mailReady}
                onRedirect={() => handleTitleClick('/registered-mail/tracking', 'registered_mail', ['waiting', 'ev'])}
              />
            }
            {isFeatureEnabled(FEATURE.AR24, siteConfigs) &&  <div className='flex flex-column gap-20'>
              <DashboardContentBox
                title="LRE Acceptées"
                documents={acceptedMail}
                onSetSelectedDocuments={setSelectedDocuments}
                selectedDocuments={selectedDocuments}
                type="registered_mail"
                loading={!mailReady}
                onRedirect={() => handleTitleClick('/registered-mail/tracking', 'registered_mail', ['AR'])}
              />
              <DashboardContentBox
                title="LRE Négligées et refusées"
                documents={expiredMail}
                onSetSelectedDocuments={setSelectedDocuments}
                selectedDocuments={selectedDocuments}
                type="registered_mail"
                loading={!mailReady}
                onRedirect={() => handleTitleClick('/registered-mail/tracking', 'registered_mail', ['negligence', 'refused'])}
              />
            </div> }

            <div className="fix"></div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default Dashboard