import React, { useEffect, useState } from 'react'
import HistoryIcon from '@material-ui/icons/History'

import Modal from '../../UI/Modal'
import HistoryBox from '../../ui_new/HistoryBox'
import LineLoader from '../../UI/LineLoader'
import Message from '../../UI/Message'

import moment from 'moment'

const dayFormat = 'DD/MM/YYYY'

const TemplateHistoryModal = ({ onClose, loading, template, mode = 'history' }) => {
  
  const [days, setDays] = useState([])

  
  useEffect(() => {
    const history = template?.versionNotes || []
    // process history into an array of days
    let daysMap = {}
    let daysArray = []
    for(let i = 0; i < history.length; i++) {
      let version = history[i]
      let dayValue = moment(version.created).format(dayFormat)
      if(!daysMap[dayValue]) {
        daysMap[dayValue] = {
          dayValue: dayValue,
          versions: []
        }
      }
      daysMap[dayValue].versions.push(version)
    }
    for(let d in daysMap) {
      daysMap[d].versions.sort((a, b) => b.created - a.created)
      daysArray.push(daysMap[d])
    }
    daysArray.sort((a, b) => {
      if(moment(a.dayValue, dayFormat).valueOf() > moment(b.dayValue, dayFormat).valueOf()) return -1
      else if(moment(a.dayValue, dayFormat).valueOf() < moment(b.dayValue, dayFormat).valueOf()) return 1
      return 0
    })
    setDays([...daysArray])
    // setTimeout(() => onSetHistory(prev => ({ ...prev, isLoading: false })), 300)

  }, [template])

  const isToday = (dayValue) => {
    return moment().format(dayFormat) === dayValue
  }

  const dayTitle = (day) => {
    if(isToday(day)) {
      return 'Aujourd’hui'
    }
    return day
  }
  
  const renderTime = (time) => {
    return moment(time).format('HH[h]mm')
  }

  const renderDay = (day, dayIndex) => {
    return (
      <div key={`day_${dayIndex}`} className="history-modal-v2__section">
        <h3>{ dayTitle(day.dayValue) }</h3>
        { day.versions.map((version, versionIndex) => {
          return (
            <HistoryBox 
              key={`version_${dayIndex}_${versionIndex}`}
              time={renderTime(version.created)}
              description={version.content}
              type="template"
            />
          )
        })}
      </div>
    )
  }

  return(
    <Modal onClose={onClose} bg2>
      <div className="history-modal-v2">
        { !template ? <div className='template-info'>
          <h1>Modèle non trouvé</h1>
          </div>
          :
        <>
          { mode === 'info' &&
            <div className="template-info">
              <h1>{template.name}</h1>
              {/* <p className='description-title'>Description</p> */}
              <p>{template.description || 'Aucune description'}</p>
            </div> }
          { mode === 'history' && <div>
            <h2><HistoryIcon /> Historique du modèle</h2>

            { loading ? (
              <LineLoader />
            ) : (
              <div>
                { days.length > 0 ? days.map(renderDay) : <Message type="primary" msg="Il n'y a pas encore d'historique à afficher pour ce template" />}
              </div> ) }
          </div> }
        </>}
      </div>
    </Modal>
  );
}

export default TemplateHistoryModal