import { DateInput, Select, Option } from '../ui_new'
import { ESIGN_STATUS_FILTER_OPTIONS, SIGNATURE_STATUS_TRANLSATIONS, VIALINK_SIGNATURE_STATUS_FILTER_OPTIONS } from '../../constants'
import { isMlsPartner } from '../../utils'
import { useContext } from 'react'
import { UserContext } from '../../context'

const SignaturesContentSidebar = ({ onSetSelectedFilters, selectedFilters }) => {

  const { partner } = useContext(UserContext)

  // On status change
  const handleStatusChange = (value) => {
    onSetSelectedFilters(prev => ({
      ...prev,
      status: value
    }))
  }

  // On date change - after
  const handleDateAfterChange = (date) => {
    onSetSelectedFilters(prev => ({
      ...prev,
      date_after: date.getTime()
    }))
  }

  // On date change - before
  const handleDateBeforeChange = (date) => {
    onSetSelectedFilters(prev => ({
      ...prev,
      date_before: date.getTime()
    }))
  }

  return (
    <div className="signatures-content__sidebar u-custom-scrollbar">
      <div className="signatures-content__sidebar_head">
        <h1><span>Mes signatures</span></h1>
      </div>
      <section className="sidebar-filters-section">
        <h3>Filtres</h3>
        <div className="sidebar-filters-section__item">
          <DateInput 
            label="Créé après" 
            onChange={handleDateAfterChange} 
            onChangeRaw={(e) => e.preventDefault()}
            isClearable={selectedFilters.date_after} 
            onClearableClick={() => onSetSelectedFilters(prev => ({ ...prev, date_after: '' }))}
            selected={selectedFilters.date_after || ''}
            maxDate={selectedFilters.date_before}
          />
        </div>
        <div className="sidebar-filters-section__item">
          <DateInput 
            label="Créé avant" 
            onChange={handleDateBeforeChange} 
            onChangeRaw={(e) => e.preventDefault()}
            isClearable={selectedFilters.date_before} 
            onClearableClick={() => onSetSelectedFilters(prev => ({ ...prev, date_before: '' }))}
            selected={selectedFilters.date_before}
            minDate={selectedFilters.date_after}
          />
        </div>
        <div className="sidebar-filters-section__item">
          <Select label="Statut" onChange={handleStatusChange} selected={selectedFilters.status}>
            <Option value="all">Tous</Option>
            {(isMlsPartner(partner) ? ESIGN_STATUS_FILTER_OPTIONS : VIALINK_SIGNATURE_STATUS_FILTER_OPTIONS).map((status, index) => (
              <Option key={index} value={status}>{SIGNATURE_STATUS_TRANLSATIONS[status]}</Option>
            ))}
          </Select>
        </div>
      </section>
    </div>
  )
}

export default SignaturesContentSidebar