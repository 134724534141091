import { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import { Check, Folder } from '../../assets/icons'
import { STATUS_LABELS_SHORT } from '../../constants'
import { DocumentsContext, UserContext } from '../../context'
import { FEATURE, dateDisplayFormat, isFeatureEnabled, manufacturerProperty } from '../../utils'
import CustomTooltip from './CustomTooltip'
import { useSingleDocumentActions } from '../../hooks'

const DocumentsTableRow = ({ id, doc, selectedDocuments, onSetSelectedDocuments, allChecked }) => {
  const { currentFilter, templates } = useContext(DocumentsContext)
  const { user } = useContext(UserContext)
  const [checked, setChecked] = useState(false)
  const [createdAt, setCreatedAt] = useState('')
  const [modifiedAt, setModifiedAt] = useState('')
  const history = useHistory()
  const { progress } = useSingleDocumentActions()

  // Set checked to false if selectedDocuments length is zero
  useEffect(() => {
    if(checked && selectedDocuments.length === 0) {
      setChecked(false)
    }
  }, [selectedDocuments, checked])

  // Set checked to true if allChecked is true
  useEffect(() => {
    if(allChecked) {
      setChecked(true)
    }
  }, [allChecked])

  // Set metadata
  useEffect(() => {
    if(doc?.meta?.created) {
      setCreatedAt(moment(doc.meta.created).format(dateDisplayFormat))
    }else if(doc.createdAt) {
      setCreatedAt(moment(doc.createdAt).format(dateDisplayFormat))
    }
    if(doc?.meta?.updated) {
      setModifiedAt(moment(doc.meta.updated).format(dateDisplayFormat))
    }else if(doc.updatedAt) {
      setModifiedAt(moment(doc.updatedAt).format(dateDisplayFormat))
    }
  }, [doc])

  // On document select
  const handleSelectDocument = () => {
    setChecked(!checked)
    if(checked) {
      onSetSelectedDocuments(prev => prev.filter(d => d.id !== id))
    }else {
      onSetSelectedDocuments(prev => [...prev, doc])
    }
  }

  // On go to doc
  const handleGoToDoc = () => {
    history.push(`/documents/${id}`)
  }

  // On drag start
  const handleDragStart = (e) => {
    const div = document.createElement('div')
    div.id = 'draggable-document-el'
    div.className = 'draggable-ghost-el-v2'
    div.innerText = `Déplacer le document ${doc.name}`
    document.getElementById('root').appendChild(div)
    e.dataTransfer.setData('document', JSON.stringify(doc))
    e.dataTransfer.setDragImage(div, 0, 0)
  }

  // On drag end
  const handleDragEnd = () => {
    if(document.getElementById('draggable-document-el')) {
      document.getElementById('draggable-document-el').remove()
    }
  }

  const documentProgressLabel = () => {
    if(doc.status !== "draft") {
      return ""
    }
    if(doc.progress !== undefined) {
      return `${(doc.progress * 100).toFixed(0)}%`
    }else if(templates?.[doc.template]?.sections) {
      return `${(progress({ templateObject: templates[doc.template], documentValues: doc.values, linesAddedTo: [] }) * 100).toFixed(0)}%`
    } else {
      return ""
    }
  }

  return (
    <div className={`documents-table-row ${checked ? 'selected' : ''}`}
      draggable={true}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}

    >
      <div className="documents-table-column documents-table-column__checkbox">
        <div className={`documents-table-column__check ${checked ? 'checked' : ''}`} onClick={handleSelectDocument}>
          <Check />
        </div>
      </div>
      <div className="documents-table-column documents-table-column__name"
        onClick={handleGoToDoc}>
        <CustomTooltip content={doc.name} position='top'>
          <p className='doc_name'>{doc.name}</p>
        </CustomTooltip>
      </div>
      <div className="documents-table-column documents-table-column__status"
        onClick={handleGoToDoc}>
        <p>
          <span className={`status status--${doc.status}`}></span>
          <span className="text">
            {STATUS_LABELS_SHORT[doc.status]} 
            {/* {doc.status === 'draft' && ` - ${(doc.progress * 100).toFixed(0)}%`} */}
          </span>
        </p>
      </div>
      <div className="documents-table-column documents-table-column__progress"
        onClick={handleGoToDoc}>
        <p>
          <span className="text">
            {documentProgressLabel()}
          </span>
        </p>
      </div>
      {isFeatureEnabled(FEATURE.INTERAGENCY) &&
      <div className="documents-table-column documents-table-column__author"
        onClick={handleGoToDoc}>
        <CustomTooltip content={doc.author ? `${doc.author.firstname} ${doc.author.lastname?.toUpperCase()}` : `agence (${manufacturerProperty(user?.manufacturer, 'name')})`}>
          <p>
            { doc.author ? `${doc.author.firstname} ${doc.author.lastname?.toUpperCase()}` : `agence (${manufacturerProperty(user?.manufacturer, 'name')})` }
          </p>
        </CustomTooltip>
      </div> }
      {isFeatureEnabled(FEATURE.INTERAGENCY) &&
      <div className="documents-table-column documents-table-column__owner"
        onClick={handleGoToDoc}>
        <CustomTooltip content={manufacturerProperty(user.agencies ? user.agencies.find(a => `${manufacturerProperty(a, 'id')}` === `${doc.owner}`) : user?.manufacturer, 'name')} position='top'>
          <p> 
            { manufacturerProperty(user.agencies ? user.agencies.find(a => `${manufacturerProperty(a, 'id')}` === `${doc.owner}`) : user?.manufacturer, 'name')}
          </p>
        </CustomTooltip>
      </div> }
      <div className="documents-table-column documents-table-column__date"
        onClick={handleGoToDoc}>
        <p>{createdAt}</p>
      </div>
      <div className="documents-table-column documents-table-column__date"
        onClick={handleGoToDoc}>
        <p>{modifiedAt}</p>
      </div>
      <div className="documents-table-column-mobile"
        onClick={handleGoToDoc}>
        <div className="date">{createdAt}</div>
        {doc.folder && <div className="folder"><Folder /> {doc.folder}</div>}
      </div>
      {/* {currentFilter === 'all' && (
        <div className="go-to-document" onClick={handleGoToDoc}></div>
      )} */}
    </div>
  )
}

export default DocumentsTableRow