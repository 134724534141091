import { Link } from 'react-router-dom'

import { AddNewDocument, SearchBar } from '../misc'
import { Juridoc, Logo } from '../../assets/icons'
import { useContext, useState } from 'react'
import { UserContext } from '../../context'
import SupportMenu from '../sections/dashboard/SupportMenu'

const Header = ({ showSearch, searchIn }) => {

  const [showSupportMenu, setShowSupportMenu] = useState(false)
  const { partner } = useContext(UserContext)

  const toggleSupportMenu = e => {
    e.preventDefault()
    setShowSupportMenu(!showSupportMenu)
  }

  return (
    <header className="header">
      <div className="logo">
        <Link to="/"><Logo /></Link>
      </div>
      <div className="header__right">
        {showSearch && <SearchBar searchIn={searchIn} />}
        <AddNewDocument />
        {/* <a href="/" className="link" onClick={toggleSupportMenu}><Juridoc /></a> */}
        {/* Support menu */}
        {partner && !partner.includes('mls') && (
          <SupportMenu />
        )}
      </div>
    </header>
  )
}

export default Header