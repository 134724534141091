import { Sort } from '../../assets/icons'
import { TemplatesTableRow, TemplatesFoldersTableRow, StandardTemplatesTableRow } from '../ui_new'

const TemplatesTable = ({ filteredTemplates, onSort, activeSort, activeOrder, filteredFolders, onSelectFolder, onPreview, onCreateDocument, onTemplateDelete, onTemplateEdit, onTemplateToggleHidden, currentlyActiveFolder, onInfo, onHistory, rootFolders, view = 'templates' }) => {

  return (
    <div className="documents-table">
      <div className="documents-table__head">
        <div className="documents-table-row">
          <div className="documents-table-column documents-table-column__name" onClick={() => onSort('name')}>
            <p className={`${activeSort === 'name' ? 'active' : ''}`}>Nom</p>
            <Sort />
          </div>
          {/* <div className="documents-table-column">
          </div> */}
          <div className="documents-table-column documents-table-column__date" onClick={() => onSort('meta.created')}>
            <p className={`${activeSort === 'meta.created' ? 'active' : ''}`}>Date de création</p>
            <Sort />
          </div>
          <div className="documents-table-column documents-table-column__date" onClick={() => onSort('meta.updated')}>
            <p className={`${activeSort === 'meta.updated' ? 'active' : ''}`}>Date de mise à jour</p>
            <Sort />
          </div>
          <div className="documents-table-column documents-table-column__history">
          </div>
        </div>
      </div>
      <div className="documents-table__body">
        {/* {((!currentlyActiveFolder || currentlyActiveFolder === 'root') ? rootFolders : currentlyActiveFolder?.subfolders)?.map((folder, idx) => { */}
        {filteredFolders?.map((folder, idx) => {
          return (
            <TemplatesFoldersTableRow
              key={idx}
              id={folder.id}
              folder={folder}
              onSelectFolder={onSelectFolder}
              view={view}
            />
          )
        })}
        {filteredTemplates?.map((template, idx) => {
          if(view === 'templates') {
            return (
              <TemplatesTableRow
                key={idx}
                id={template.id}
                template={template}
                onPreview={onPreview}
                onInfo={onInfo}
                onHistory={onHistory}
                onCreateDocument={onCreateDocument}
                onTemplateDelete={onTemplateDelete}
                onTemplateEdit={onTemplateEdit}
                onTemplateToggleHidden={onTemplateToggleHidden}
              />
            )
          } else if(view === 'standard-templates') {
            return (
              <StandardTemplatesTableRow
                key={idx}
                id={template.id}
                template={template}
                onPreview={onPreview}
                onInfo={onInfo}
                onHistory={onHistory}
                onCreateDocument={onCreateDocument}
                onTemplateDelete={onTemplateDelete}
                onTemplateEdit={onTemplateEdit}
                onTemplateToggleHidden={onTemplateToggleHidden}
              />
            )
          }
          return null
        })}
      </div>
    </div>
  )
}

export default TemplatesTable