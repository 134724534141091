import { Modal } from './'

const Alert = ({ onClose, onOkClose, okBtnText = 'Ok', text, bodyText, onSubmit, loading, deleteAlert = false, showOk = false, bodyHtml = '', noButton = false }) => {
  return(
    <Modal onClose={onClose} className="modal-v2--small">
      <div className="alert-v2">
        <div className="alert-v2__head">
          <h3>{text}</h3>
        </div>
        {bodyText && <div className="alert-v2__body">
          <p>{bodyText}</p>  
        </div>}
        { bodyHtml && <div className="alert-v2__body">
          <p dangerouslySetInnerHTML={{ __html: bodyHtml }} />
        </div> }
        {noButton ? null : !showOk ? <div className="alert-v2__foot">
          <button className="btn btn--transparent" onClick={onClose}>Annuler</button>
          <button className={`btn ${deleteAlert ? 'btn--danger' : ''}`} disabled={loading} onClick={onSubmit}>{loading ? "Supression..." : "Confirmer"}</button>
        </div>
        : <div className="alert-v2__foot">
          <button className={`btn`} disabled={loading} onClick={onOkClose ? onOkClose : onClose}>{okBtnText}</button>
        </div>}
      </div>
    </Modal>
  )
}

export default Alert