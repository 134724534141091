import { useState, useContext, useEffect } from 'react'

import { DashboardDocumentBox } from './'
import { ArrowDropDown } from '../../assets/icons'
import { Checkbox } from '../ui_new'
import { DocumentsContext, DocumentsFoldersContext } from '../../context'
import moment from 'moment'

const DashboardContentBox = ({ title, documents, onSetSelectedDocuments, selectedDocuments, type = 'documents', loading = true, onRedirect }) => {
  const { getTemplateById } = useContext(DocumentsContext)
  const { docFolders } = useContext(DocumentsFoldersContext)
  const [showContent, setShowContent] = useState(false)
  const [allChecked, setAllChecked] = useState(false)
  const [sortOrder, setSortOrder] = useState('desc')
  const [sortedDocuments, setSortedDocuments] = useState([])

  // Set all checked to false if documents length is 0
  useEffect(() => {
    if(selectedDocuments.length === 0 && allChecked) {
      setAllChecked(false)
    }
  }, [allChecked, selectedDocuments])

  useEffect(() => {
    const sortDocuments = (order) => (a, b) => {
      let dateA
      let dateB
      if(a?.meta?.created) {
        dateA = moment(a.meta.created)
      } else if(a.createdAt) {
        dateA = moment(a.createdAt)
      }
      if(b?.meta?.created) {
        dateB = moment(b.meta.created)
      } else if(b.createdAt) {
        dateB = moment(b.createdAt)
      }
      if(Boolean(dateA) && Boolean(dateB)) {
        return (dateA.isBefore(dateB) && order === 'desc') ? 1 : -1
      } else if(Boolean(dateA)) {
        return 1
      } else if(Boolean(dateB)) {
        return -1
      }
    }

    let docs = documents
    docs.sort(sortDocuments(sortOrder))
    setSortedDocuments([...docs])
  }, [documents, sortOrder])

  // On content toggle - on mobile/smaller screens only
  const handleToggleContent = () => {
    if(!showContent && documents.length === 0) return 
    setShowContent(!showContent)
  }

  // On all checked change
  const handleAllCheckedChange = (e) => {
    setAllChecked(e.target.checked)
    if(e.target.checked) {
      let updatedSelectedDocs = [...selectedDocuments]
      const selectedDocsIds = selectedDocuments.map(d => d.id)
      for(let i = 0; i < documents.length; i++) {
        if(!selectedDocsIds.includes(documents[i].id)) {
          updatedSelectedDocs.push(documents[i])
        }
      }
      onSetSelectedDocuments(updatedSelectedDocs)
    }else {
      let updatedSelectedDocs = []
      const docsIds = documents.map(d => d.id)
      for(let i = 0; i < selectedDocuments.length; i++) {
        if(!docsIds.includes(selectedDocuments[i].id)) {
          updatedSelectedDocs.push(selectedDocuments[i])
        }
      }
      onSetSelectedDocuments(updatedSelectedDocs)
    }
  }

  const handleSortOrderChange = (e) => {
    e.preventDefault()
    setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc')
  }

  return (
    <div className={`dashboard-content-box ${showContent ? 'opened' : ''}`}>
      <div className="dashboard-content-box__head">
        <h2 className="title" onClick={onRedirect}><span>{title}</span></h2>
        <div className="number">
          {loading ?<div className="loader-v2"></div> : documents.length}
        </div>
        <div className="toggle-icon" onClick={handleToggleContent}><ArrowDropDown /></div>
      </div>
      {type === 'documents' && documents.length > 0 && (
        <div className="dashboard-content-box__filter-row">
          <div className="dashboard-content-box__check-all">
            <Checkbox label="Cocher/décocher" checked={allChecked} onChange={handleAllCheckedChange} />
          </div>
          <div className="dashboard-content-box__sort-button" onClick={handleSortOrderChange}>
            { `Date ${sortOrder === 'desc' ? '↓' : '↑'}` }
          </div>
        </div>
      )}
      {type === 'documents' && documents.length > 0 && showContent && (
        <div className="dashboard-content-box__filter-row--mobile">
          <div className="dashboard-content-box__check-all">
            <Checkbox label="Cocher/décocher" checked={allChecked} onChange={handleAllCheckedChange} />
          </div>
          <div className="dashboard-content-box__sort-button" onClick={handleSortOrderChange}>
            { `Date ${sortOrder === 'desc' ? '↓' : '↑'}` }
          </div>
        </div>
      )}
      <div className="dashboard-content-box__body u-custom-scrollbar">
        {sortedDocuments.map((doc) => {
          let templateExists = false 
          let folder = null 
          if(type === 'documents') {
            const template = getTemplateById(doc.template)
            if(Object.keys(template).length > 0) templateExists = true

            if(doc.folderId && Array.isArray(doc.folderId) && doc.folderId[0]) {
              const folderFound = docFolders.find(f => f.id === doc.folderId[0])
              if(folderFound) {
                folder = folderFound
              }
            }
          }

          return (
            <DashboardDocumentBox 
              key={doc.id} 
              id={doc.id} 
              doc={doc}
              templateExists={templateExists} 
              folder={folder}
              onSetSelectedDocuments={onSetSelectedDocuments} 
              selectedDocuments={selectedDocuments} 
              type={type}
            />
          )
        })}
      </div>
    </div>
  )
}

export default DashboardContentBox