const ArrowDropDown = () => {
  return (
    <span className="svg-icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="15.408" height="9.297" viewBox="0 0 15.408 9.297">
        <path id="Path_1642" data-name="Path 1642" d="M0,0,7.012,7.793,14.072,0" transform="translate(0.669 0.604)" fill="none" stroke="#1e548a" strokeLinejoin="round" strokeWidth="1.8" />
      </svg>
    </span>
  )
}

export default ArrowDropDown