import React, { useEffect, useContext, useState, useCallback, Fragment, useRef } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async'
// import ReactGA from 'react-ga';

import DashboardLayout from './components/layouts/Dashboard';
import Dashboard from './components/pages/Dashboard';
import Documents from './components/pages/Documents';
import DocumentDetail from './components/pages/DocumentDetail';
import Templates from './components/pages/Templates';
import Signatures from './components/pages/Signatures';
import Recommended from './components/pages/Recommended';
import MyProfile from './components/pages/MyProfile';
import SignIn from './components/pages/SignIn';
import NotFound from './components/pages/NotFound';
import AgencyProfile from './components/pages/AgencyProfile';
import Help from './components/pages/Help';
import { fetch_templates, fetch_documents, fetch_actions, fetch_customers, fetch_admins, get_site_configs, get_signature_credentials, fetch_email_user, fetch_standard_templates } from './services/firestore';
import { DocumentsContext } from './context/documents/documentsState';
import { SignatureContext } from './context/signatures/signaturesState';
import { LoaderContext } from './context/loader/loaderState';
import { UserContext } from './context/user/userState';
import { NotificationContext } from './context/notifications/notificationState';
import { DocumentsFoldersContext } from './context/documentsFolders/documentsFoldersState';
import { SectionContext } from './context/sections/sectionsState';
import { SignatureTemplatesContext } from './context/signature_templates/signatureTemplatesState';
import { RegisteredMailContext } from './context'
import { signInWithJwtToken } from './services/auth';
import qs from 'qs'
import { createBrowserHistory } from 'history'
import config from './config.json'
import { FolderContext } from './context/folders/folderState';
import Notification from './components/UI/Notification';
import { signatureAvailable, availableOn, canUseTemplateCategories, isMlsPartner, canUseCoverPageSettings, partnerUsesV1UI, isFeatureEnabled, FEATURE } from './utils';

import firebase from './services/firebase'
import AgencyCoverPage from './components/pages/AgencyCoverPage';
import AppRoutes from './routing'

// ReactGA.initialize('G-434M13VMPY');
// ReactGA.pageview(window.location.pathname + window.location.search);

const App = () => {
  const { setTemplates, setDocuments, fetchDocuments, setActions, getSingleDocument, getSingleTemplate, setStandardTemplates } = useContext(DocumentsContext)
  const { fetchFolders, fetchStandardTemplatesFolders, fetchFoldersOrder } = useContext(FolderContext)
  const { 
    user,
    setUser,
    partner,
    setPartner,
    // setCustomers,
    setAdmins,
    setVerifiedCustomers,
    siteConfigs,
    setSiteConfigs,
    setSiteConfigsLoaded,
    setImmocloudConfig,
    setAuthenticationToken,
    setUserClaims,
    setUrlStore,
    setIsNotSQHorCAIpartner,
    setSignatureCredentials,
    showSignatures,
    setShowSignatures,
    siteConfigsLoaded,
    isNotSQHorCAIpartner,
    canUseSignatures,
    urlStore,
    signInResult,
    setSignInResult,
    agency,
    getAgency,
    setAgency,
    setCanUseSignaturesForMLSPartner
  } = useContext(UserContext) 
  const { fetchSignatures } = useContext(SignatureContext)
  const { setLoading } = useContext(LoaderContext)
  const { notification } = useContext(NotificationContext)
  const { fetchDocFolders } = useContext(DocumentsFoldersContext)
  const { fetchSections, fetchTagMatchings } = useContext(SectionContext)
  const { fetchSignatureTemplates } = useContext(SignatureTemplatesContext)
const { fetchAr24Sender, fetchAr24Mail } = useContext(RegisteredMailContext)
  const { setNotification } = useContext(NotificationContext)
  const [statusFetched, setStatusFetched] = useState(true);
  const [authToken, setAuthToken] = useState(null)
  const history = createBrowserHistory()
  const now = useRef()
  const routerRef = useRef()

  const [authStateListener, setAuthStateListener] = useState()

  useEffect(() => {
    now.current = Date.now()
    if(!authStateListener) {
      console.log('create listener')
      let listener = firebase.auth().onAuthStateChanged((user) => {
        console.log('on auth state changed', user)
        if(user) {
          // console.log('already signed in', user)
          let isEmailUser = false
          for(let provider of user.providerData) {
            if(provider.providerId === 'password') {
              isEmailUser = true
              break
            }
          }
          // if(isEmailUser) {
          //   const signInResultObject = await fetch_email_user()
          //   // console.log('user data', signInResultObject)
          //   setSignInResult({...signInResultObject, user})
          // }
        }
      })
      setAuthStateListener(listener)
    }
    signIn()
  }, [])

  useEffect(() => {
    if(!user || Object.keys(user).length === 0) {
      return
    }
    if(agency) {
      return
    }
    getAgency()
  }, [agency, user])

  useEffect(() => {
    if(!firebase.auth().currentUser || !signInResult) {
      return
    }
    onSignIn()
  }, [signInResult, firebase.auth().currentUser, authToken])

  const onSignIn = async () => {
    // console.log(signInResult)
    if(config.environment === 'development' && now.current) {
      console.log('func start ***************', Date.now() - now.current)
    }
    if(signInResult.agency) {
      signInResult.admin.manufacturer = signInResult.agency
    } else if(!signInResult.admin.manufacturer) {
      signInResult.admin.manufacturer = {}
    }
    if(signInResult.agencies) {
      signInResult.admin.agencies = signInResult.agencies
    }
    setUser({...signInResult.admin, uid: signInResult.user.uid, clientAccessToken: signInResult.client_access_token || ''})
    // if(signInResult.agency) {
    //   setAgency({...signInResult.agency})
    // }
    setUrlStore(signInResult.url_store)
    
    setPartner(signInResult.partner)
    setIsNotSQHorCAIpartner(signInResult.partner !== 'squarehabitat' && signInResult.partner !== 'cai')
    let idTokenResult = await firebase.auth().currentUser.getIdTokenResult(true)
    if(idTokenResult.claims) {
      setUserClaims(idTokenResult.claims)
    }
    let immoConfig = {
      site_id: signInResult.site_id
    }
    if(signInResult.customer_id) {
      immoConfig.handle = 'customer_id'
      immoConfig.id = signInResult.customer_id
    } else if(signInResult.product_id) {
      immoConfig.handle = 'product_id'
      immoConfig.id = signInResult.product_id
    }
    setImmocloudConfig(immoConfig)
    let doNotFetchDoc = ''
    let fetchedDoc = null
    let doNotFetchTmpl = ''
    let fetchedTmpl = null
    if(config.environment === 'development' && now.current) {
      console.log('start fetching data ***************', Date.now() - now.current)
    }
    if(history.location.pathname.includes('document-detail') || history.location.pathname.includes('/documents/')) {
      const paths = history.location.pathname.split('/')
      const docId = paths[paths.length - 1]
      doNotFetchDoc = docId
      const doc = await getSingleDocument(docId, true, (tmplt) => {
        fetchedTmpl = tmplt
      })
      if(!doc) {
        setLoading(false)
        setNotification({ msg: "Impossible de récupérer le document", type: 'danger' })
        return
      }
      doNotFetchTmpl = doc?.template
      fetchedDoc = doc
      setLoading(false)
      if(config.environment === 'development' && now.current) {
        console.log('document & template fetched ***************', Date.now() - now.current)
      }
    }else if(history.location.pathname.includes('/template/')) {
      const paths = history.location.pathname.split('/')
      const tmpltId = paths[paths.length - 1]
      const tmplt = await getSingleTemplate(tmpltId, true)
      doNotFetchTmpl = tmpltId 
      fetchedTmpl = tmplt
      setLoading(false)
      if(config.environment === 'development' && now.current) {
        console.log('template fetched ***************', Date.now() - now.current)
      }
    }
    if(signInResult.siteConfigs) {
      setSiteConfigs(signInResult.siteConfigs)
    } else {
      // setSiteConfigsLoaded(true)
      setSiteConfigs([])
    }
    // if(!isMlsPartner(signInResult.partner)) {
    //   getSiteConfigs(authToken)
    // } else {
    //   setSiteConfigsLoaded(true)
    // }
    await fetchDocuments(doNotFetchDoc, fetchedDoc)
    await fetchTemplates(doNotFetchTmpl, fetchedTmpl)
    if(config.environment === 'development' && now.current) {
      console.log('documents & templates loaded ***************', Date.now() - now.current)
    }
    // fetchActions()
    fetchFoldersOrder()
    fetchFolders()
    fetchDocFolders()
    if(canUseTemplateCategories(signInResult.partner)) {
      fetchSections()
    }
    if(isFeatureEnabled(FEATURE.CROSS_CATEGORY_TAG_MATCHING) && canUseTemplateCategories(signInResult.partner)) {
      fetchTagMatchings()
    }
    if(isFeatureEnabled(FEATURE.STANDARD_TEMPLATES, signInResult.siteConfigs)) {
      fetchStandardTemplates()
      fetchStandardTemplatesFolders()
    }
    fetchSignatureTemplates()
    
    if(isFeatureEnabled(FEATURE.AR24, signInResult.siteConfigs)) {
      fetchAr24Sender()
      fetchAr24Mail()
    }
    
    if(authToken) {
      if(signatureAvailable(authToken)) {
        fetchSignatures()
      }
      if(!isMlsPartner(signInResult.partner)) {
        fetchAdmins(authToken)
        // fetchVerifiedCustomers(authToken)
        getSignatureCredentials(authToken)
      }
      setAuthenticationToken(authToken)
    }
    if(isMlsPartner(signInResult.partner)) {
      setCanUseSignaturesForMLSPartner()
    }
    setLoading(false)
  }

  const signIn = async () => {
    setLoading(true)
    const queryStringVariables = qs.parse(history.location.search, { ignoreQueryPrefix: true })
    let { token } = queryStringVariables
    if(config.environment !== 'development') {
      if(!token && authToken) {
        token = authToken
      } else if(!token) {
        // alert('Missing required variable token')
        setLoading(false)
        routerRef.current.history.push('/signin')
        return
      } else if(!authToken) {
        setAuthToken(token)
      }
    }
    const signInResultObject = await signInWithJwtToken(token || authToken || '')
    if(signInResultObject.error) {
      alert(signInResultObject.error.message)
      setLoading(false)
      return
    }
    // console.log(signInResultObject)
    setSignInResult(signInResultObject)
  }

  // const processCustomers = (c) => {
  //   let processedCustomers = []
  //   for(let i in c) {
  //     let cust = c[i]
  //     let procCust = {}
  //     for(let key in cust) {
  //       let components = key.split('_')
  //       components.pop() // remove index
  //       let procKey = components.join('_')
  //       procCust[procKey] = cust[key]
  //     }
  //     processedCustomers.push(procCust)
  //   }
  //   setCustomers(processedCustomers)
  // }

  const fetchTemplates = async (exclude, tmpl) => {
    let t = await fetch_templates(exclude)
    if(t.error) {
      console.log('fetch templates error', t.error)
      return
    }
    setTemplates(tmpl ? { [tmpl.id]: tmpl, ...t } : t)
  }

  const fetchStandardTemplates = async () => {
    let t = await fetch_standard_templates()
    if(t.error) {
      console.log('fetch standard templates error', t.error)
      return
    }
    setStandardTemplates(t)
  }
  // const fetchActions = async () => {
  //   let t = await fetch_actions()
  //   if(t.error) {
  //     console.log('fetch actions error', t.error)
  //     return
  //   }
  //   setActions(t)
  // }

  const fetchVerifiedCustomers = async (token) => {
    const t = await fetch_customers(token)
    if(t.error) {
      console.log('fetch verified customers error', t.error)
      return
    }
    setVerifiedCustomers(t)
  }

  const fetchAdmins = async (token) => {
    const t = await fetch_admins(token)
    if(t.error) {
      console.log('fetch admins error', t.error)
      return
    }
    // console.log("set admins", t)
    setAdmins(t)
  }

  // const getSiteConfigs = async (token) => {
  //   try {
  //     const res = await get_site_configs(token)
  //     if(res.error) {
  //       console.log('get site configs error', res.error)
  //       return 
  //     }
  //     setSiteConfigs(res)
  //   } catch (error) {
  //     setSiteConfigsLoaded(true)
  //   }
  // }

  const getSignatureCredentials = async (token) => {
    try {
      const res = await get_signature_credentials(token)
      if(res.error) {
        console.log('get signature credentials error', res.error)
        return 
      }
      setSignatureCredentials(res[0] || null)
    } catch (error) {
      console.log(error)
    }
  }

  const signaturesAvailable = useCallback(() => {
    if(!partner) {
      return false
    }
    if(partner === 'kel') {
      return false
    }
    let available = false
    if(isNotSQHorCAIpartner) {
      if(!canUseSignatures) {
        if(urlStore) {
          available = true 
        }else {
          available = false
        }
      }else {
        available = true
      }
    }else {
      available = true
    }
    return available
  }, [partner, isNotSQHorCAIpartner, canUseSignatures, urlStore])

  useEffect(() => {
    if(siteConfigsLoaded && signaturesAvailable()) {
      setShowSignatures(true)
    } else {
      setShowSignatures(false)
    }
  }, [signaturesAvailable, siteConfigsLoaded, setShowSignatures])
  
  return (
    <HelmetProvider>
      <BrowserRouter ref={routerRef}>
        <Helmet>
          {isFeatureEnabled(FEATURE.FXO_CHATBOT, siteConfigs, partner) && (
            <script src="https://widget.flowxo.com/embed.js" data-fxo-widget="eyJ0aGVtZSI6IiM2N2MxOGUiLCJ3ZWIiOnsiYm90SWQiOiI2MDE1MjMxZTdkMTYxNzAwMzJkNmNkYmIiLCJ0aGVtZSI6IiM2N2MxOGUiLCJsYWJlbCI6Ik1vZHVsZSBkJ2Fzc2lzdGFuY2UganVyaWRpcXVlIGRlIEp1cmlzw7tyIn0sIndlbGNvbWVUZXh0IjoiUG9zZXogdm90cmUgcXVlc3Rpb24ganVyaWRpcXVlICEifQ==" async defer></script>
          )}

          {/* <!-- copier dans le head de toutes les pages HTML sur lesquelles vous souhaitez faire apparaître le widget --> */}
          {/* <!-- stonly-widget - copy into the head --> */}
          <script>{`window.STONLY_WID = "cb4eadfd-821e-11ee-bc11-06cb0cb2a85e";`}</script>
          <script>
          {`!function(s,t,o,n,l,y,w,g,d,e){s.StonlyWidget||((d=s.StonlyWidget=function(){
            d._api?d._api.apply(d,arguments):d.queue.push(arguments)}).scriptPath=n,d.apiPath=l,d.sPath=y,d.queue=[],
            (g=t.createElement(o)).async=!0,(e=new XMLHttpRequest).open("GET",n+"version?v="+Date.now(),!0),
            e.onreadystatechange=function(){4===e.readyState&&(g.src=n+"stonly-widget.js?v="+
            (200===e.status?e.responseText:Date.now()),(w=t.getElementsByTagName(o)[0]).parentNode.insertBefore(g,w))},e.send())
            }(window,document,"script","https://stonly.com/js/widget/v2/");`}
          </script>
        </Helmet>
        <Switch>
          { partner && partnerUsesV1UI(partner) && <Route path="/" exact>
            <DashboardLayout>
              <Dashboard statusFetched={statusFetched} onStatusFetched={setStatusFetched} />
            </DashboardLayout>
          </Route> }
          { partner && partnerUsesV1UI(partner) && <Route path="/documents" exact>
            <DashboardLayout>
              <Documents />
            </DashboardLayout>
          </Route> }
          { partner && partnerUsesV1UI(partner) && <Route path="/document-detail" exact>
            <DocumentDetail token={authToken} />
          </Route> }
          { partner && partnerUsesV1UI(partner) && <Route path="/document-detail/:documentId" exact>
            <DocumentDetail token={authToken} />
          </Route> }
          { partner && partnerUsesV1UI(partner) && <Route path="/templates" exact>
            <DashboardLayout>
              <Templates />
            </DashboardLayout>
          </Route> }
          { partner && partnerUsesV1UI(partner) && <Route path="/template/:templateId" exact>
            <DocumentDetail token={authToken} />
          </Route> }
          <Route path="/signin" exact>
            <SignIn />
          </Route>
          { partner && partnerUsesV1UI(partner) && showSignatures && <Route path="/signatures" exact>
            <DashboardLayout>
              <Signatures />
            </DashboardLayout>
          </Route>}
          {/* <Route path="/recommended" exact>
            <DashboardLayout>
              <Recommended />
            </DashboardLayout>
          </Route> */}
          { partner && partnerUsesV1UI(partner) &&  <Route path="/settings/my-profile" exact>
            <DashboardLayout>
              <MyProfile />
            </DashboardLayout>
          </Route> }
          { partner && partnerUsesV1UI(partner) && <Route path="/settings/agency" exact>
            <DashboardLayout>
              <AgencyProfile />
            </DashboardLayout>
          </Route> }
          { partner && partnerUsesV1UI(partner) && isFeatureEnabled(FEATURE.COVER_PAGE_SETTINGS, [], partner, user) && <Route path="/settings/cover-page" exact>
            <DashboardLayout>
              <AgencyCoverPage />
            </DashboardLayout>
          </Route> }
          { partner && partnerUsesV1UI(partner) &&  availableOn(['development', 'staging']) && <Route path="/help" exact>
            <DashboardLayout>
              <Help />
            </DashboardLayout>
          </Route>}
          { partner && partnerUsesV1UI(partner) &&  <Route path="*">
            <NotFound />
          </Route> }
        </Switch>
        { partner && !partnerUsesV1UI(partner) && <AppRoutes token={authToken} /> }
        {notification.msg && <Notification />}
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
