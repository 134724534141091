import { memo } from 'react'

const VariableText = ({ type, item, index }) => {
  return type.includes('heading') 
    ? <span className="doc-variable-el" data-id={`section-${index}`}>{item}</span> 
    : item ? (<span>{item}<span className="raw-text-space"></span></span>) : null
}

export default memo(VariableText, (prevProps, nextProps) => {
  if (prevProps.item === nextProps.item) return true
  return false
})