import React, { useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { RegisteredMailContext } from '../context'
import RegistredMailLayout from '../layouts/RegisteredMailLayout'
import { statusLabelForAr24Sender } from '../utils'
import moment from 'moment'
import { Redo } from '../assets/icons'

const RegisteredMailSender = () => {

  const [refreshingStatus, setRefreshingStatus] = useState(false)

  const {
    ar24Sender,
    ar24SenderFetched,
    showSenderModal,
    fetchAr24Sender,
    ar24SenderEvents
  } = useContext(RegisteredMailContext)

  // const [events, setEvents] = useState([])

  // useEffect(() => {
  //   const evnts = []

  //   setEvents(evnts)
  // }, [ar24Sender])

  const onModifySender = () => {
    showSenderModal('modify')
  }

  const onCreateSender = () => {
    showSenderModal('add')
  }

  const labelForEvent = (type) => {
    if (type === 'user-create') {
      return 'Création de compte'
    } else if (type === 'user-associate') {
      return 'Association de compte'
    } else if (type === 'user-update') {
      return 'Mise à jour des informations'
    } else if (type === 'identity-verification-request') {
      return 'Commande identifiant LRE'
    } else {
      return type
    }
  }


  const handleStatusRefreshClick = async () => {
    setRefreshingStatus(true)
    await fetchAr24Sender()
    setRefreshingStatus(false)
  }

  return (
    <RegistredMailLayout
      onCreateSender={onCreateSender}
      sidebarSection="sender"
      onModifySender={onModifySender}>
      <div className="registered-mail-sent">
        <div className="settings-profile__mobile-nav-top">
          <Link to="/registered-mail/tracking">Suivi des envois</Link>
          <Link to="/registered-mail/sender">Gestion compte expéditeur</Link>
        </div>
        <div className="registered-mail__box">
          {!ar24SenderFetched ? (
            <div className="loader-wrapper">
              <div className="loader-v2"></div>
            </div>
          ) : !ar24Sender ? (
            <div className='mt-20'>
              <p className='text-left'>Aucun compte expéditeur n'a été ajouté.</p>
              <button
                className='btn item-btn mt-20'
                onClick={onCreateSender}
              >
                Ajouter un compte AR24
              </button>
            </div>
          ) : (
            <>
              <div className='flex flex-row align-center justify-start gap-5 mb-20'>
                <span className='sender-status-title'>Statut du compte : </span>
                <div className='flex flex-row align-center'>
                  <div className={`status-value ${ar24Sender.status}`}>{statusLabelForAr24Sender(ar24Sender) || ar24Sender?.status}</div>
                  <button className={`icon-btn icon-btn--transparent refresh-status ${refreshingStatus ? 'refreshing' : ''}`} onClick={handleStatusRefreshClick}><Redo /></button>
                </div>
              </div>
              {/* EVENTS TABLE */}
              <table>
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Date</th>
                    <th>Prénom expéditeur</th>
                    <th>Nom expéditeur</th>
                    <th>Email expéditeur</th>
                    <th>Fait par</th>
                    <th>Montant</th>
                  </tr>
                </thead>
                <tbody>
                  {ar24SenderEvents.map((event, index) => (
                    <tr key={index}>
                      <td>{labelForEvent(event.type)}</td>
                      <td>{moment(event.meta.created).format('DD/MM/YYYY HH:mm')}</td>
                      <td>{ar24Sender?.firstname}</td>
                      <td>{ar24Sender?.lastname || ''}</td>
                      <td>{ar24Sender?.email}</td>
                      <td>{event.created_by?.admin_name}<br/>({event.created_by?.agency_name})</td>
                      <td>{(event.price || 0).toFixed(2)}€ HT</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>

          // SENDER TABLE
          // <table>
          //   <thead>
          //     <tr>
          //       <th>Prénom expéditeur</th>
          //       <th>Nom expéditeur</th>
          //       <th>Email expéditeur</th>
          //       <th>Statut du compte</th>
          //       <th>Date de création / association</th>
          //       <th>Fait par</th>
          //       <th>Type</th>
          //       <th>Montant</th>
          //       <th>Actions</th>
          //     </tr>
          //   </thead>
          //   <tbody>

          //     {ar24Sender && (
          //       <tr>
          //         <td>{ar24Sender.firstname}</td>
          //         <td>{ar24Sender.lastname}</td>
          //         <td>{ar24Sender.email}</td>
          //         <td>
          //           <div className='flex flex-row align-center justify-center'>
          //             <div className={`status-value ${ar24Sender.status}`}>{statusLabelForAr24Sender(ar24Sender) || ar24Sender?.status}</div>
          //             <button className={`icon-btn icon-btn--transparent refresh-status ${refreshingStatus ? 'refreshing' : ''}`} onClick={handleStatusRefreshClick}><Redo /></button>
          //           </div>
          //         </td>
          //         <td>{moment(ar24Sender.meta.created).format('DD/MM/YYYY')}</td>
          //         <td>{ar24Sender.created_by?.admin_name}<br/>({ar24Sender.created_by?.agency_name})</td>
          //         <td>{labelForCreationType(ar24Sender.creation_type)}</td>
          //         <td>{(ar24Sender.amount_spent || 0).toFixed(2)}€ HT</td>
          //         <td>
          //           <button
          //             className='btn'
          //             type='button'
          //             onClick={() => onModifySender(ar24Sender)}>
          //             Modifier
          //           </button>
          //         </td>
          //       </tr>
          //     )
          //     }
          //   </tbody>
          // </table>
          )}
        </div>
        <div className="settings-profile__mobile-nav-bottom">
        </div>
      </div>
    </RegistredMailLayout>
  )
}

export default RegisteredMailSender