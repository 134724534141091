import { useState, useContext } from 'react'

import { Delete, Close, Copy } from '../../assets/icons'
import { DocumentsContext, NotificationContext, LoaderContext } from '../../context'
import { Alert } from '../ui_new'

const AttachmentsActions = ({ selectedAttachments, onSetSelectedAttachments, attachments, onSetDocumentAttachments, onSetAllChecked, docId }) => {
  const { updateDocument, setCopiedAttachments, setAttachmentsFrom } = useContext(DocumentsContext)
  const { setNotification } = useContext(NotificationContext)
  const { setShowGlobalResponseLoader } = useContext(LoaderContext)
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)

  // On attachments copy
  const handleCopyAttachments = (e) => {
    e.preventDefault()
    setCopiedAttachments(selectedAttachments)
    setAttachmentsFrom(docId)
    setNotification({ msg: `Les annexes ont bien été copiés(${selectedAttachments.length})`, type: 'info' })
    onSetSelectedAttachments([])
    onSetAllChecked(false)
  }

  // On attachments delete
  const handleAttachmentsDelete = async (e) => {
    e.preventDefault()
    setShowGlobalResponseLoader(true)
    try {
      const ids = selectedAttachments.map(a => a.id)
      const updatedAttachments = attachments.filter(a => !ids.includes(a.id))
      await updateDocument({ attachments: updatedAttachments }, { id: docId, attachments: updatedAttachments })
      onSetDocumentAttachments(updatedAttachments)
      onSetAllChecked(false)
      onSetSelectedAttachments([])
    } catch (err) {
      console.log(err)
    } finally {
      setShowGlobalResponseLoader(false)
    }
  }

  // On actions close
  const handleCloseActions = () => {
    onSetSelectedAttachments([])
    onSetAllChecked(false)
  }

  // On open delete alert
  const handleOpenDeleteAlert = (e) => {
    e.preventDefault()
    setShowDeleteAlert(true)
  }

  // On close delete alert
  const handleCloseDeleteAlert = () => {
    setShowDeleteAlert(false)
  }

  return (
    <div className="dashboard-actions dashboard-actions--attachments">
      <div className="dashboard-actions__selected">
        <div className="number">{selectedAttachments.length}</div>
        <p>Élément(s) sélectionné(s)</p>
      </div>
      <div className="dashboard-actions__buttons">
        <a href="/" onClick={handleCopyAttachments} className="icon-duplicate"><Copy /></a>
        <a href="/" onClick={handleOpenDeleteAlert} className="icon-delete"><Delete /></a>
      </div>
      <div className="dashboard-actions__close" onClick={handleCloseActions}>
        <Close />
      </div>

      {showDeleteAlert && <Alert 
        onClose={handleCloseDeleteAlert}
        text="Êtes-vous sûr de vouloir supprimer les pièces jointes sélectionnées ?"
        onSubmit={handleAttachmentsDelete}
        deleteAlert
      />}
    </div>
  )
}

export default AttachmentsActions