import { useContext } from 'react';
import { NavLink } from 'react-router-dom'

import { UserSolid, Building, BgImage } from '../../assets/icons'
import { UserContext } from '../../context';
import { FEATURE, isFeatureEnabled } from '../../utils';

const SettingsContentSidebar = () => {

  const { user, partner } = useContext(UserContext);
  
  return (
    <div className="settings-content__sidebar u-custom-scrollbar">
      <div className="settings-content__sidebar_head">
        <h1><span>Mes Paramètres</span></h1>
      </div>
      <div className="settings-content__nav">
        <ul>
          <li><NavLink to="/settings/profile"><UserSolid /> Profil</NavLink></li>
          <li><NavLink to="/settings/agency"><Building /> Agences</NavLink></li>
          { isFeatureEnabled(FEATURE.COVER_PAGE_SETTINGS, [], partner, user) && <li><NavLink to="/settings/cover-page"><BgImage /> Mise en page</NavLink></li> }
        </ul>
      </div>
    </div>
  )
}

export default SettingsContentSidebar