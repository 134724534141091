import moment from 'moment'

import { History } from '../../assets/icons'
import { Modal, HistoryBox, HistoryEventBox } from '../ui_new'
import { useEffect, useState } from 'react'

const dayFormat = 'DD/MM/YYYY'

const SingleDocumentHistory = ({ onClose, docHistory, docEvents, onRestore }) => {

  
  const [fullHistory, setFullHistory] = useState([])  
  
  useEffect(() => {
    if(!docHistory || !docEvents) return
    let fullHistoryDaysMap = {}

    for(let day of docHistory) {
      if(!fullHistoryDaysMap[day.dayValue]) {
        fullHistoryDaysMap[day.dayValue] = {
          allEvents: []
        }
      }
      fullHistoryDaysMap[day.dayValue].allEvents.push(...day.versions.map(version => ({
        history_type: 'version',
        ...version
      })))
    }
    for(let day of docEvents) {
      if(!fullHistoryDaysMap[day.dayValue]) {
        fullHistoryDaysMap[day.dayValue] = {
          allEvents: []
        }
      }
      fullHistoryDaysMap[day.dayValue].allEvents.push(...day.events.map(event => ({
        history_type: 'event',
        ...event
      }))
      )
    }

    let daysArray = []

    for(let d in fullHistoryDaysMap) {
      fullHistoryDaysMap[d].allEvents.sort((a, b) => b.created - a.created)
      daysArray.push({
        ...fullHistoryDaysMap[d],
        dayValue: d
      })
    }

    daysArray.sort((a, b) => {
      if(moment(a.dayValue, dayFormat).valueOf() > moment(b.dayValue, dayFormat).valueOf()) return -1
      else if(moment(a.dayValue, dayFormat).valueOf() < moment(b.dayValue, dayFormat).valueOf()) return 1
      return 0
    })

    setFullHistory(daysArray)

  }, [docHistory, docEvents])

  // Check if today
  const isToday = (dayValue) => {
    return moment().format(dayFormat) === dayValue
  }

  // Get day title
  const dayTitle = (day) => {
    if(isToday(day)) {
      return 'Aujourd’hui'
    }
    return day
  }
  
  // Render time
  const renderTime = (time) => {
    return moment(time).format('HH[h]mm')
  }

  // Get variables array
  const versionVariablesArray = (variables) => {
    let varArr = []
    for(let key in variables) {
      varArr.push({ label: key, input: variables[key] })
    }
    return varArr
  }

  // On restore click
  const handleRestoreClick = (version) => {
    onRestore(version)
    onClose()
  }

  // Render day
  const renderDay = (day, dayIndex) => {
    return (
      <div key={`day_${dayIndex}`} className="history-modal-v2__section">
        <h3>{ dayTitle(day.dayValue) }</h3>
        { day.allEvents.map((event, eventIndex) => {
          if(event.history_type === 'version') {
            return (
              <HistoryBox
                key={`version_${dayIndex}_${eventIndex}`}
                time={renderTime(event.created)}
                progress={event.progress}
                numberOfEntries={Object.keys(event.values).length}
                onRestoreClick={() => handleRestoreClick(event) }
                inputsData={versionVariablesArray(event.values)}
              />
            )
          } else {
            return (
              <HistoryEventBox
                key={`event_${dayIndex}_${eventIndex}`}
                time={renderTime(event.created)}
                event={event}
              />
            )
          }
        })}
        {/* { day.versions.map((version, versionIndex) => {
          return (
            <HistoryBox 
              key={`version_${dayIndex}_${versionIndex}`}
              time={renderTime(version.created)}
              progress={version.progress}
              numberOfEntries={Object.keys(version.values).length}
              onRestoreClick={() => handleRestoreClick(version) }
              inputsData={versionVariablesArray(version.values)}
            />
          )
        })} */}
      </div>
    )
  }

  return (
    <Modal onClose={onClose} className="modal-v2--bg-2">
      <div className="history-modal-v2">
        <h2><History /> Historique du document</h2>

        { (!docHistory || !docEvents) ? (
          <div className="loader-v2"></div>
        ) : (
          <div>
            { fullHistory.length > 0 ? fullHistory.map(renderDay) : <div className="message-v2">Il n'y a pas encore d'historique à afficher pour ce document</div>}
          </div> ) }
      </div>
    </Modal>
  )
}

export default SingleDocumentHistory