import { useState, useEffect, useContext, useCallback } from 'react'

import { MainLayout } from '../layouts'
import { FileAlt2, Folder, SlidersH } from '../assets/icons'
import { DocumentsContentSidebar, DocumentsTable, DocumentsActions } from '../components/misc'
import { DocumentsContext, DocumentsFoldersContext } from '../context'
import { sortedArrayFromObject, sorterWithPathAndOrder, getSorting, sortArrayOfObjects, saveSortingToLS } from '../utils'
import Alert from './../components/ui_new/Alert';
import { useDocumentsActions } from '../hooks'

const Documents = () => {
  const { documents, documentsLoaded, docsSelectedFilters, setDocsSelectedFilters, currentFilter, resetDocsSelectedFilters, setCurrentFilter, archivedDocsFetched, fetchArchivedDocs, deletedDocsFetched, fetchDeletedDocs, archivedDocuments, deletedDocuments, fetchMoreDocuments } = useContext(DocumentsContext)
  const { docFolders, docFoldersFetched } = useContext(DocumentsFoldersContext)
  const [selectedDocuments, setSelectedDocuments] = useState([])
  const [allChecked, setAllChecked] = useState(false)
  const [activeDocs, setActiveDocs] = useState([])
  const [filteredDocs, setFilteredDocs] = useState([])
  const [activeSort, setActiveSort] = useState('name')
  const [activeOrder, setActiveOrder] = useState('desc')
  const [ready, setReady] = useState(false)
  const [filteredFolders, setFilteredFolders] = useState([])
  const [alertDisplay, setAlertDisplay] = useState(false)
  const { downloadDocuments } = useDocumentsActions(selectedDocuments)


  // set status from search string
  useEffect(() => {
    let query = window.location.search
    if(!query) {
      return
    }
    if(query[0] === '?') {
      query = query.substring(1)
    }
    let components = query.split('&')
    let statusFound
    for(let comp of components) {
      let keyValue = comp.split('=')
      if(keyValue.length < 2) {
        continue
      }
      if(keyValue[0] === 'status') {
        let filters = docsSelectedFilters
        filters.status = keyValue[1]
        setDocsSelectedFilters(filters)
        statusFound = true
      }
    }
    if(!statusFound) {
      let filters = docsSelectedFilters
      filters.status = 'all'
      setDocsSelectedFilters(filters)
    }
  }, [])

  // Set documents
  useEffect(() => {
    const setupDocuments = async () => {
      console.log('setupDocuments')
      let copyOfDocuments
      if(currentFilter === 'all') {
        copyOfDocuments = {...documents}
      }else if(currentFilter === 'archived') {
        copyOfDocuments = {...archivedDocuments}
      }else if(currentFilter === 'deleted') {
        copyOfDocuments = {...deletedDocuments}
      }

      const filteredDocuments = {}
      for(let key in copyOfDocuments) {
        const doc = copyOfDocuments[key]
        let folder = ''
        if(doc.folderId && Array.isArray(doc.folderId) && doc.folderId[0]) {
          const folderFound = docFolders.find(f => f.id === doc.folderId[0])
          if(folderFound) {
            folder = folderFound.name
          }
        }
        filteredDocuments[key] = { ...copyOfDocuments[key], folder }
      }
      let sort = 'meta.updated'
      let order = 'desc'
      const sortLS = getSorting()
      if(sortLS && sortLS.documents) {
        sort = sortLS.documents.value
        order = sortLS.documents.order
      }
      setActiveSort(sort)
      setActiveOrder(order)
      let docsArr = sortedArrayFromObject(filteredDocuments, sorterWithPathAndOrder(sort, order))
      setActiveDocs(docsArr)
      setFilteredDocs(docsArr)
      setReady(true)
    }

    console.log({ documentsLoaded, docFoldersFetched })
    if(documentsLoaded && docFoldersFetched) {
      setupDocuments()
    }
  }, [documents, documentsLoaded, docFoldersFetched, docFolders, currentFilter, archivedDocuments, deletedDocuments])

  // On filter
  const handleFilter = useCallback(() => {
    let filtered = [...activeDocs]

    for(let key in docsSelectedFilters) {
      if(key === 'status' && docsSelectedFilters[key] !== 'all') {
        filtered = filtered.filter(d => d.status === docsSelectedFilters[key])
      }
      if(key === 'date_after' && docsSelectedFilters[key] !== '') {
        filtered = filtered.filter(d => d.meta.created > docsSelectedFilters[key])
      }
      if(key === 'date_before' && docsSelectedFilters[key] !== '') {
        filtered = filtered.filter(d => d.meta.created < docsSelectedFilters[key])
      }
      if(key === 'folder' && docsSelectedFilters[key] !== 'all') {
        filtered = filtered.filter(d => d.folderId && d.folderId.includes(docsSelectedFilters[key]))
      }else if(key === 'folder' && docsSelectedFilters[key] === 'all'){
        filtered = filtered.filter(d => !d.folderId || d.folderId.length === 0)
      }
      if(key === 'search' && docsSelectedFilters[key] !== '') {
        filtered = filtered.filter(d => (d.name || '').toLowerCase().includes(docsSelectedFilters[key].trim().toLowerCase()))
      }
      if(key === 'owner' && docsSelectedFilters[key] !== 'all') {
        filtered = filtered.filter(d => `${d.owner}` === `${docsSelectedFilters[key]}`)
      }
      if(key === 'author' && docsSelectedFilters[key] !== 'all') {
        filtered = filtered.filter(d => d.author?.id === docsSelectedFilters[key])
      }
    }

    let filteredFolds = []
    if(currentFilter === 'all') {
      for(let f of docFolders) {
        if((docsSelectedFilters.folder === 'all' && !f.parentFolder) || (f.parentFolder && f.parentFolder === docsSelectedFilters.folder)) {
          filteredFolds.push(f)
        }
      }
    }

    setFilteredDocs(filtered)
    setFilteredFolders(filteredFolds)
  }, [activeDocs, docsSelectedFilters, docFolders])

  // Watch for docsSelectedFilters change
  useEffect(() => {
    handleFilter()
  }, [docsSelectedFilters, handleFilter])

  // Handle sort
  const handleSort = (sort_by) => {
    const docs = sortArrayOfObjects(filteredDocs, sort_by, activeOrder === 'desc' ? 'asc' : 'desc')
    setActiveSort(sort_by)
    setActiveOrder(activeOrder === 'desc' ? 'asc' : 'desc')
    setFilteredDocs(docs)
    saveSortingToLS(sort_by, activeOrder === 'desc' ? 'asc' : 'desc', 'documents')
  }

  // On change current
  const handleChangeCurrentFilter = async (value) => {
    if(value === currentFilter) {
      // reset folder to root
      if(value === 'all') {
        let docsFilters = {...docsSelectedFilters}
        docsFilters.folder = 'all'
        setDocsSelectedFilters({...docsFilters})
        return
      } 
    }
    
    setCurrentFilter(value)
    setReady(false)
    let docs = {}
    if(value === 'all') {
      docs = { ...documents }
    }else if(value === 'archived') {
      if(archivedDocsFetched) {
        docs = { ...archivedDocuments }
      }else {
        const archived = await fetchArchivedDocs()
        docs = { ...archived }
      }
    }else if (value === 'deleted') {
      if(deletedDocsFetched) {
        docs = { ...deletedDocuments }
      }else {
        const deleted = await fetchDeletedDocs()
        docs = { ...deleted }
      }
    }
    const filteredDocuments = {}
    
    for(let key in docs) {
      const doc = docs[key]
      let folder = ''
      if(doc.folderId && Array.isArray(doc.folderId) && doc.folderId[0]) {
        const folderFound = docFolders.find(f => f.id === doc.folderId[0])
        if(folderFound) {
          folder = folderFound.name
        }
      }
      filteredDocuments[key] = { ...docs[key], folder }
    }

    let sort = 'name'
    let order = 'desc'
    const sortLS = getSorting()
    if(sortLS && sortLS.documents) {
      sort = sortLS.documents.value
      order = sortLS.documents.order
    }
    setActiveSort(sort)
    setActiveOrder(order)
    let docsArr = sortedArrayFromObject(filteredDocuments, sorterWithPathAndOrder(sort, order))
    setActiveDocs(docsArr)
    setFilteredDocs(docsArr)
    resetDocsSelectedFilters()
    setSelectedDocuments([])
    setAllChecked(false)
    setTimeout(() => {
      setReady(true)
    }, 100)
  }

  const handleSelectFolder = (folderId) => {
    let docsFilters = {...docsSelectedFilters}
    docsFilters.folder = folderId
    setDocsSelectedFilters({...docsFilters})
  }

  const handleCloseDocxDownloadAlert =  () => {
    setAlertDisplay(false)     
  }
  const handleDocxDownload = () => {
    setAlertDisplay(false)
    DownloadButton()
  }

  const displayAlertModal = () => {
    setAlertDisplay(true)
  }

  const DownloadButton = async (e) => {
     downloadDocuments()
     setAllChecked(false)
     setSelectedDocuments([])
  }  

  return (
    <MainLayout className="page-documents" pageTitle="Mes documents" titleIcon={<FileAlt2 />} showSearch={true} searchIn="documents">
      <div className="documents-content">
        <DocumentsContentSidebar 
          selectedFilters={docsSelectedFilters} 
          onSetSelectedFilters={setDocsSelectedFilters} 
          onChangeCurrent={handleChangeCurrentFilter}
        />
        <div className="documents-content__main u-custom-scrollbar">
          {selectedDocuments.length > 0 && (
            <div className="documents-content__main_actions">
              <DocumentsActions 
                selectedDocuments={selectedDocuments} 
                onSetSelectedDocuments={setSelectedDocuments} 
                onSetAllChecked={setAllChecked}
                displayAlertModal={displayAlertModal}
              />
            </div>
          )}
        
          {!ready 
            ? (
              <div className="loader-wrapper">
                <div className="loader-v2"></div>
              </div>
            ) : (
              <DocumentsTable
                filteredFolders={filteredFolders}
                selectedDocuments={selectedDocuments} 
                onSetSelectedDocuments={setSelectedDocuments} 
                allChecked={allChecked}
                onSetAllChecked={setAllChecked}
                filteredDocs={filteredDocs}
                onSort={handleSort}
                activeSort={activeSort}
                activeOrder={activeOrder}
                onSelectFolder={handleSelectFolder}
               
              />
            )}
          {ready && <div className='load-more-wrapper'>
            <button className="btn btn-primary" onClick={fetchMoreDocuments}>Charger plus</button>
          </div>}

        </div>
        {alertDisplay ? 
         <Alert 
          onClose={handleCloseDocxDownloadAlert}
          onOkClose={handleDocxDownload}
          text="Note importante"
          bodyText="Immo Docs ne pourra être tenu pour responsable des modifications apportées au .docx"
          showOk
          okBtnText="Télécharger le .docx"
        /> : null}
      
  
      </div>
    </MainLayout>
  )
}

export default Documents 