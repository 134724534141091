import { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'

import { Times } from '../../assets/icons'

const Modal = ({ onClose, children, className }) => {
  const modalContent = useRef()
  let modalClasses = 'modal-v2'

  if(className) {
    modalClasses += ` ${className}`
  }

  useEffect(() => {
    document.body.style.overflowY = 'hidden'
    window.scrollTo(0,0)
    
    return () => {
      document.body.style.overflowY = null
    }
  }, [])

  return ReactDOM.createPortal(
    <div className={modalClasses}>
      <div className="modal-v2__inner">
        <div className="modal-v2__content-wrapper">
          <div className="modal-v2__bg" onClick={onClose}></div>
          <div className="modal-v2__content" ref={modalContent}>
            <div className="modal-v2__content_close" onClick={onClose}><Times /></div>
            { children }
          </div>
        </div>
      </div>
    </div>,
    document.getElementById('modal-root')
  );
}

export default Modal