import moment from 'moment'
import { useState } from 'react';
import { Close, FileAlt, Paperclip, ChevronLeft } from '../../assets/icons'
import { Select, Option, CustomTooltip } from '../ui_new'
import { STATUS_LABELS_SHORT } from '../../constants'
import { SingleDocumentSummary } from './'
import { useSingleDocumentActions } from '../../hooks'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import { Search } from '../../assets/icons';
import { Clear, ClearAll } from '@material-ui/icons';


const SingleDocumentSidebar = ({
    singleDoc,
    currentDocVersionTime,
    documentValues,
    onVariableItemClick,
    scrollingToItem,
    onSectionClick,
    templateData,
    linesAddedTo,
    tabActive,
    onNameChange,
    view,
    onSetView,
    numOfAttachments,
    status,
    onSetStatus,
    progress,
    templateMetaSections,
    summarySections
}) => {
    const { extractVariableTypesFromSections } = useSingleDocumentActions()
    const [search, setSearch] = useState({
        state: 'not-started', // or started
        value: ''
    });
    const [searchResults, setSearchResults] = useState('');
    const [activeResult, setActiveResult] = useState(0);
    const [resultsTotal, setResultsTotal] = useState(0);


    // On status change
    const handleStatusChange = (value) => {
        onSetStatus(value)
    }
    const { isMobile } = useWindowDimensions()

    const onPressEnterOnSearchInput = (event) => {
        if (event.keyCode === 13) {
            doSearch()
        }
    }

    const doSearch = () => {
        // Remove previous highlights
        const previousHighlights = document.querySelectorAll('mark');
        previousHighlights.forEach(highlight => {
            const parent = highlight.parentNode;
            parent.replaceChild(document.createTextNode(highlight.textContent), highlight);
            parent.normalize(); // Merge adjacent text nodes
        });
        // reset data
        // setSearch(prev => ({ state: 'not-started', value: '' }));
        setSearchResults([]);
        setActiveResult(0);

        if (search.value.length === 0) {
            return;
        }
        // Get all elements that contain text nodes
        const elements = document.querySelectorAll(".document-main__body :not(script):not(style)");

        // Variable to track if scroll is done
        let scrollDone = false;
        setSearch(prev => ({ ...prev, state: 'started' }));
        // Iterate through each element
        let total = 0;
        elements.forEach(element => {
            // Iterate through each child node of the element
            Array.from(element.childNodes).forEach(node => {
                // Check if the node is a text node and contains the desired text
                if (node.nodeType === 3 && node.textContent?.toLowerCase().includes(search.value?.toLowerCase())) {
                    const wrapper = document.createElement('span');
                    wrapper.innerHTML = node.textContent.replace(new RegExp(`(${search.value})`, 'gi'), '<mark>$1</mark>');
                    element.replaceChild(wrapper, node);
                    setSearchResults(prev => [...prev, element]);
                    total += element.querySelectorAll('mark').length; 
                    // Scroll to the first occurrence of the text
                    if (!scrollDone) {
                        wrapper.scrollIntoView({ behavior: 'smooth', block: 'center' });
                        scrollDone = true;
                    }
                }
            });
        });
        setResultsTotal(total);
    }
    const prevResult = () => {
        if (activeResult >= 0 && searchResults[activeResult - 1]) {
            searchResults[activeResult - 1].scrollIntoView({ behavior: 'smooth', block: 'center' });
            setActiveResult(prev => prev - 1);
        }
    }
    const nextResult = () => {
        if (activeResult < searchResults.length && searchResults[activeResult + 1]) {
            searchResults[activeResult + 1].scrollIntoView({ behavior: 'smooth', block: 'center' });
            setActiveResult(prev => prev + 1);
        }
    }

    const clearSearch = () => {
        // Remove previous highlights
        const previousHighlights = document.querySelectorAll('mark');
        previousHighlights.forEach(highlight => {
            const parent = highlight.parentNode;
            parent.replaceChild(document.createTextNode(highlight.textContent), highlight);
            parent.normalize(); // Merge adjacent text nodes
        });
        setSearch(prev => ({ ...prev, state: 'not-started', value: '' }));
        setSearchResults([]);
    }

    return (
        <div
            className={`single-document__left single-document-tab ${
                tabActive ? 'tab-active' : ''
            }`}
            style={{ height: isMobile ? '80.5%' : '' }}
        >
            <div className="single-document__left_nav">
                <CustomTooltip content="Variables">
                    <button
                        className={`icon-btn icon-btn--transparent ${
                            view === 'variables' ? 'active' : ''
                        }`}
                        onClick={() => onSetView('variables')}
                    >
                        <FileAlt />
                    </button>
                </CustomTooltip>
                <CustomTooltip content="Annexes">
                    <button
                        className={`icon-btn icon-btn--transparent ${
                            view === 'attachments' ? 'active' : ''
                        }`}
                        onClick={() => onSetView('attachments')}
                    >
                        <Paperclip />{' '}
                        {numOfAttachments > 0 && (
                            <span className="num">{numOfAttachments}</span>
                        )}
                    </button>
                </CustomTooltip>
            </div>
            <section className="head">
                <h1>
                    <span>Mes documents</span>
                </h1>
            </section>
            <section className="doc-info">
                <h2
                    contentEditable={true}
                    suppressContentEditableWarning={true}
                    onInput={onNameChange}
                >
                    {singleDoc.name}
                </h2>

                <div className="status-row">
                    <Select
                        selected={status}
                        onChange={handleStatusChange}
                        type="status"
                    >
                        {Object.entries(STATUS_LABELS_SHORT).map(
                            ([stKey, stValue], idx) => (
                                <Option key={idx} value={stKey}>
                                    {stValue}
                                </Option>
                            )
                        )}
                    </Select>

                    <p className="doc-progress"><span>{ `${(progress * 100).toFixed(0)}%` }</span></p>
                </div>

                {/* <p className="doc-status"><span className={`${singleDoc.status}`}></span> {STATUS_LABELS_SHORT[singleDoc.status]}</p> */}

                <div className="doc-version">
                    <FileAlt />
                    <p>Version du</p>
                    <p>
                        {currentDocVersionTime &&
                            currentDocVersionTime.time &&
                            moment(currentDocVersionTime.time).format(
                                'DD/MM/YYYY HH:mm'
                            )}
                    </p>
                </div>
            </section>
            <section className="search">
                <div className="search-bar">
                    <div className="search-bar__body">
                        <div className="search-bar__body_icon cursor-pointer" onClick={doSearch}>
                            <Search />
                        </div>
                        <input
                            type="text"
                            placeholder="Rechercher…"
                            value={search.value}
                            onChange={(e) => setSearch(prev => ({ ...prev, value: e.target.value }))}
                            onKeyDown={onPressEnterOnSearchInput}
                        />
                        {search.state !== "not-started" && (
                            <div
                                className="search-bar__body_icon search-bar__body_icon_end icon-btn icon-btn--sm icon-btn--transparent cursor-pointer"
                                onClick={clearSearch}
                            >
                                <Close />
                            </div>
                        )}
                    </div>
                    {search.state === 'started' && <>
                        <div className='flex align-center mt-10'>
                            <p className='search-results'>{`${resultsTotal} résultat${resultsTotal > 1 ? 's' : ''} trouvé${resultsTotal > 1 ? 's' : ''}`}</p>
                            <button className='btn btn--transparent prev' onClick={prevResult}><ChevronLeft /></button>
                            <button className='btn btn--transparent next' onClick={nextResult}><ChevronLeft /></button>
                        </div>
                    </>}
                </div>
            </section>
            <section className="summary">
                <h3>Sommaire</h3>
                <br />
                {/* TODO: fix summary for nested repeatables and re-enable */}
                <SingleDocumentSummary
                    documentValues={documentValues}
                    variableTypes={extractVariableTypesFromSections()}
                    onItemClick={onVariableItemClick}
                    scrollingToItem={scrollingToItem}
                    onSectionClick={onSectionClick}
                    templateData={templateData}
                    linesAddedTo={linesAddedTo}
                    templateMetaSections={templateMetaSections}
                    summarySections={summarySections}
                />
            </section>
        </div>
    )
}

export default SingleDocumentSidebar
