import React, { useEffect } from 'react';

import Modal from '../../UI/Modal';
import Input from '../../UI/Input';
import Button from '../../UI/Button';
import useForm from '../../../hooks/useForm';
import validate from '../../../helpers/validate';

const EditTemplateModal = ({ onClose, onEdit, currentTemplate, templatesLoading }) => {
  const initialData = {
    name: {
      required: true,
      value: ''
    }
  };
  const { formData, errors, setErrors, changeHandler, setFormData } = useForm(initialData, validate);

  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      name: {
        ...prev.name,
        value: currentTemplate.name
      }
    }));
    // eslint-disable-next-line
  }, [currentTemplate]);

  const submitHandler = (e) => {
    e.preventDefault();
    
    const newErrors = validate(formData, true);
    setErrors(newErrors);

    if(Object.keys(newErrors).length === 0) {
      const data = {
        name: formData.name.value.trim()
      }
      if(data.name !== '') {
        onEdit(data, currentTemplate.id);
      }
    }
  }

  return(
    <Modal onClose={onClose} small>
      <div className="folder-modal">
        <h2>Renommer le modèle</h2>
        <form onSubmit={submitHandler} className="form">
          <Input 
            name="name"
            value={formData.name.value}
            onChange={changeHandler}
            error={errors.name}
            placeholder="Indiquez le titre du modèle"
          />
          <div className="form__actions">
            <Button text="Annuler" transparent onButtonClick={onClose} />
            <Button 
              type="submit" 
              text={templatesLoading ? "Mise à jour..." : "Sauvegarder"} 
              primary 
              disabled={templatesLoading} 
            />
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default EditTemplateModal;