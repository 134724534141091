import { array } from 'prop-types'
import { useContext, useEffect, useState, useCallback } from 'react'
import { set } from 'react-ga'
import { AngleRight } from '../../assets/icons'
import { UserContext } from '../../context'
import useScrollDirection from '../utils/useScrollDirection'
import { SingleDocumentPreview, SingleDocumentAttachmentsUpload } from './'

const arrayStack = [-Infinity]
const SingleDocumentSidePanel = ({
    onToggle,
    previewData,
    previewLoading,
    onPreviewRefresh,
    tabActive,
    view,
    onSetDocumentAttachments,
    attachments,
    docId,
    previewSettings = { size: 'normal', watermark: false },
    onPreviewSettingsChange
}) => {
    const { setIsScroll } = useContext(UserContext)

    const handleScroll = useCallback(() => {
        const element = document.getElementById('direction')
        const { scrollTop } = element

        if (arrayStack[arrayStack.length - 1] < scrollTop) {
            arrayStack.push(scrollTop)
            setIsScroll(true)
        } else {
            arrayStack.length = 0
            arrayStack.push(scrollTop)
            setIsScroll(false)
        }
    }, [])

    return (
        <div
            className={`single-document__right single-document-side-panel single-document-tab ${
                tabActive ? 'tab-active' : ''
            }`}
        >
            <div
                className="single-document-side-panel__toggle"
                onClick={onToggle}
            >
                <AngleRight />
            </div>
            <div
                id="direction"
                className="single-document-side-panel__body u-custom-scrollbar"
                onScroll={(e) => {
                    handleScroll()
                }}
            >
                {view === 'variables' && (
                    <SingleDocumentPreview
                        data={previewData}
                        loading={previewLoading}
                        onPreviewRefresh={onPreviewRefresh}
                        previewSettings={previewSettings}
                        onPreviewSettingsChange={onPreviewSettingsChange}
                    />
                )}
                {view === 'attachments' && (
                    <SingleDocumentAttachmentsUpload
                        onSetDocumentAttachments={onSetDocumentAttachments}
                        attachments={attachments}
                        docId={docId}
                    />
                )}
            </div>
        </div>
    )
}

export default SingleDocumentSidePanel
