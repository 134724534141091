import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import config from '../config.json';
import {
  JURISUR_SORT_LS,
  JURISUR_PAGINATION_LS,
  AR24_SENDER_STATUS_LABEL,
} from '../constants';

const sortedArrayFromObject = (obj, sorter) => {
  let arr = [];
  for (let key in obj) {
    let item = obj[key];
    item.id = key;
    arr.push(item);
  }
  if (sorter) {
    arr.sort(sorter);
  }
  return arr;
};

const sorterWithPathAndOrder = (path, order) => (a, b) => {
  const splitted = path.split('.');
  let valA = a[splitted[0]];
  let valB = b[splitted[0]];
  if (splitted.length === 1) {
    if (typeof valA === 'string' && typeof valB === 'string') {
      if (valA.toLowerCase() < valB.toLowerCase())
        return -1 * (order === 'desc' ? -1 : 1);
      else if (valA.toLowerCase() > valB.toLowerCase())
        return 1 * (order === 'desc' ? -1 : 1);
      else return 0;
    } else {
      if (valA < valB) return -1 * (order === 'desc' ? -1 : 1);
      else if (valA > valB) return 1 * (order === 'desc' ? -1 : 1);
      else return 0;
    }
  } else {
    if (splitted[1].includes('__arr')) {
      const newPath = splitted[1].split('__arr');
      if (valA[0]?.[newPath[0]] < valB[0]?.[newPath[0]])
        return -1 * (order === 'desc' ? -1 : 1);
      else if (valA[0]?.[newPath[0]] > valB[0]?.[newPath[0]])
        return 1 * (order === 'desc' ? -1 : 1);
      else return 0;
    } else {
      if (valA?.[splitted[1]] < valB?.[splitted[1]])
        return -1 * (order === 'desc' ? -1 : 1);
      else if (valA?.[splitted[1]] > valB?.[splitted[1]])
        return 1 * (order === 'desc' ? -1 : 1);
      else return 0;
    }
  }
};

const sortArrayOfObjects = (arr, value, order) => {
  const splitted = value.split('.');
  if (value === 'recipients.email__arr') {
    return arr.sort((a, b) => {
      if (
        a.recipients[0].email.toLowerCase() <
        b.recipients[0].email.toLowerCase()
      ) {
        return -1 * (order === 'desc' ? -1 : 1);
      } else if (
        a.recipients[0].email.toLowerCase() >
        b.recipients[0].email.toLowerCase()
      ) {
        return 1 * (order === 'desc' ? -1 : 1);
      } else return 0;
    });
  }
  return arr.sort((a, b) => {
    let valA =
      splitted.length === 1 ? a[splitted[0]] : a[splitted[0]]?.[splitted[1]];
    let valB =
      splitted.length === 1 ? b[splitted[0]] : b[splitted[0]]?.[splitted[1]];

    if (typeof valA === 'string' && typeof valB === 'string') {
      valA = valA.toLowerCase();
      valB = valB.toLowerCase();
    }
    if (valA < valB) return -1 * (order === 'desc' ? -1 : 1);
    else if (valA > valB) return 1 * (order === 'desc' ? -1 : 1);
    else return 0;
  });
};

const getCreatedAtFromDocuments = (documents) => {
  const arr = [];
  for (let key in documents) {
    const value = documents[key].meta.created;
    const label = moment(value).format('MMMM YYYY');
    const start = moment(value).startOf('month').valueOf();
    const end = moment(value).endOf('month').valueOf();
    arr.push({ value, label, start, end });
  }
  // return only unique objects
  return arr.filter((v, i, a) => a.findIndex((t) => t.label === v.label) === i);
};

// const dateValueFormat = 'YYYY-MM-DD[T]HH:mmZZ'
const dateValueFormat = 'DD/MM/YYYY';

const urlSuffixForEnvironment = (environment) => {
  switch (environment) {
    case 'development':
      return '_dev';
    case 'staging':
      return '_stg';
    default:
      return '';
  }
};

const getAllParentFolders = (allFolders, folder, folders = []) => {
  if (!folder) {
    return folders;
  }
  const parentFolderId = folder.parentFolder;
  if (parentFolderId) {
    const pF = [...allFolders].find((f) => f.id === parentFolderId);
    return pF ? getAllParentFolders(allFolders, pF, [...folders, pF]) : [];
  } else {
    return folders;
  }
};

const folderHasSubfolders = (folders, folder) => {
  return !!folders.find((f) => f.parentFolder === folder.id);
};

const folderHasTemplates = (templates, folder) => {
  const arr = [];
  for (let key in templates) {
    arr.push({ ...templates[key], id: key });
  }
  return !!arr.find(
    (t) => !t.deleted && t.folderId && t.folderId.includes(folder.id)
  );
};

const getFirstLevelSubfolders = (folders, folder) => {
  return folder
    ? [...folders].filter((f) => f.parentFolder === folder.id)
    : [...folders].filter(
        (f) => f.parentFolder === null || f.parentFolder === undefined
      );
};

const isOverflown = (el) => {
  if (el) {
    const { scrollHeight, clientHeight, scrollWidth, clientWidth } = el;
    return scrollHeight > clientHeight || scrollWidth > clientWidth;
  } else {
    return false;
  }
};

const convertToTemplateObjWithUniqueVarIndexes = (obj) => {
  const copyOfTemplate = { ...obj };
  const tmplSections = [...copyOfTemplate.sections];
  const updatedSections = tmplSections.map((s, i) => {
    const section = { ...s };
    if (section.variable) {
      section.idx = `${section.variable}-${uuidv4()}`;
      return section;
    } else if (section.variables) {
      section.variables = [...section.variables].map((v) => ({
        ...v,
        idx: `${v.variable}-${uuidv4()}`,
      }));
      return section;
    } else {
      return section;
    }
  });
  copyOfTemplate.sections = updatedSections;

  if (copyOfTemplate.footer) {
    const updatedFooter = { ...copyOfTemplate.footer };
    if (updatedFooter.variables) {
      updatedFooter.variables = [...updatedFooter.variables].map((v) => ({
        ...v,
        idx: `${v.variable}-${uuidv4()}`,
      }));
    }
    copyOfTemplate.footer = updatedFooter;
  }
  return copyOfTemplate;
};

// Read file
const readFileAsync = (file) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
};

// Convert base64 to blob
const base64toBlob = (b64Data, contentType, sliceSize) => {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

// Blob to file
const blobToFile = (blob, name) => {
  blob.lastModifiedDate = new Date();
  blob.name = name;
  return blob;
};

// 1. SH token, 2. CAI token
const signatureAvailable = (token = '') => {
  return true; // still using this function for now, in case they change their mind
  // return config.environment === 'development' ||
  //   config.environment === 'staging' ||
  //   token === 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhZG1pbl9pZCI6Njk0Nzg2LCJzaXRlX2lkIjo2MDQ5MiwibWFudWZhY3R1cmVyX2lkIjoyOTAwNzB9.cz_z5yyBrYsxTtFfozKKjqbDbN8m4QLs0aqlAeSRjBc' ||
  //   token === 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhZG1pbl9pZCI6NTMxMzg4LCJzaXRlX2lkIjozMzE2OSwibWFudWZhY3R1cmVyX2lkIjoxNzE5MTEsImN1c3RvbWVyX2lkIjpudWxsLCJwcm9kdWN0X2lkIjoiMjgyMzE4MTIifQ.qXaDkwQiMhxj7b8KixWI4kr4n3KID95CI4fUK8ldnTs'
};

export const ENVIRONMENT = {
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production',
}

const availableOn = (arr) => {
  return arr && arr.includes(config.environment);
};

export const FEATURE = {
  AR24: 'ar24',
  STANDARD_TEMPLATES: 'standard-templates',
  CROSS_CATEGORY_TAG_MATCHING: 'cross-category-tag-matching',
  INTERAGENCY: 'interagency',
  FXO_CHATBOT: 'fxo-chatbot',
  COVER_PAGE_SETTINGS: 'cover-page-settings',
}

const FEATURES_DEPLOYMENT_SCHEME = {
  [FEATURE.AR24]: {
    development: true,
    staging: true,
    production: true,
  },
  [FEATURE.STANDARD_TEMPLATES]: {
    development: true,
    staging: true,
    production: true,
  },
  [FEATURE.CROSS_CATEGORY_TAG_MATCHING]: {
    development: true,
    staging: true,
    production: true,
  },
  [FEATURE.INTERAGENCY]: {
    development: true,
    staging: true,
    production: true,
  },
  [FEATURE.FXO_CHATBOT]: {
    development: true,
    staging: false,
    production: false,
  },
  [FEATURE.COVER_PAGE_SETTINGS]: {
    development: true,
    staging: true,
    production: true,
  },
}

const FEATURE_PARTNER_CONDITIONS = {
  [FEATURE.FXO_CHATBOT]: ['jurisur'],
}

const isFeatureEnabled = (feature, siteConfigs = [], partner = 'jurisur', user) => {
  if(feature === FEATURE.COVER_PAGE_SETTINGS) {
    return canUseCoverPageSettings(user, partner)
  }
  if(!FEATURES_DEPLOYMENT_SCHEME[feature]) {
    throw new Error(`Unknown feature ${feature}`)
  }
  if(!Boolean(FEATURES_DEPLOYMENT_SCHEME[feature]) || !FEATURES_DEPLOYMENT_SCHEME[feature][config.environment]) {
    return false
  }
  if(feature === FEATURE.AR24) {
    const configLRE = siteConfigs.find(c => c.key === 'MODULE_LRE')
    if(configLRE && configLRE.value) {
      return true
    }    
    return false
  }
  if(FEATURE_PARTNER_CONDITIONS[feature]) {
    return FEATURE_PARTNER_CONDITIONS[feature].includes(partner)
  }

  return true
}


const canUseTemplateCategories = (partner) => {
  const arr = [
    'jurisur',
    'squarehabitat',
    'cai',
    'mls1',
    'mls2',
    'mls3',
    'alphabet',
    'kel'
  ];
  return arr.includes(partner);
};

const isMlsPartner = (partner) => {
  if (!partner) return false;
  return partner.substring(0, 3) === 'mls';
};

const copyToClipboard = (text) => {
  const input = document.createElement('textarea');
  input.innerHTML = text;
  document.body.appendChild(input);
  input.select();
  const result = document.execCommand('copy');
  document.body.removeChild(input);
  return result;
};

const areSectionConditionsMet = (section, values) => {
  // return true
  // console.log("are section conditions met", section, values);
  // const { repeatableIndex } = section
  const { repetitionIndices, repeatableIndex } = section;

  // if(repeatableIds && repeatableIds.length > 0) {
  //   return true // TODO fix, workaround for nested repeatables update
  // }

  if (
    (!section.conditions || section.conditions?.length === 0) &&
    !section.condition
  ) {
    return true;
  } else if (section.conditions?.length > 0) {
    for (let i in section.conditions) {
      let cond = section.conditions[i];
      if (!isConditionMet(cond, values, repeatableIndex, repetitionIndices)) {
        return false;
      }
    }
    return true;
  } else {
    return isConditionMet(section.condition, values, repeatableIndex, repetitionIndices);
  }
};

const isConditionMet = (cond, values, repeatableIndex, repetitionIndices) => {
  // console.log('isConditionMet', cond, repeatableIndex)
  let targetValue = cond.value;
  let sourceValue
  if(cond.repeatableIds) {
    // let testSourceValue = duplicatableValuesArray(values, cond.repeatableIds, repetitionIndices)
    sourceValue = nestedValue(values, cond.variable, cond.repeatableIds, repetitionIndices)
    // console.log("is condition met", { sourceValue, targetValue, cond, repetitionIndices })
  } else if(cond.repeatableId) {
    sourceValue = values[cond.repeatableId]?.[repeatableIndex]?.[cond.variable] || null
  } else {
    sourceValue = values[cond.variable];
  }
  // console.log("is condition met", { sourceValue, targetValue, cond, repeatableIndex, repetitionIndices })
  
  if (typeof targetValue === 'number') {
    sourceValue = parseFloat(sourceValue);
  }
  if (cond.relation === 'EQ') {
    return sourceValue === targetValue;
  } else if (cond.relation === 'NE') {
    return sourceValue !== targetValue;
  } else if (cond.relation === 'GT') {
    return sourceValue > targetValue;
  } else if (cond.relation === 'LT') {
    return sourceValue < targetValue;
  }
}

const nestedValue = (values, variable, repSectionIds, repIndices) => {
  let vals = {...values}
  let repeatableSectionIds = repSectionIds ? [...repSectionIds] : []
  let repetitionIndices = repIndices ? [...repIndices] : []
  while(repeatableSectionIds.length > 0) {
    let repId = repeatableSectionIds.shift()
    let repIndex = repetitionIndices.shift()
    vals = vals[repId]
    if(!vals) {
      return null
    }
    if(repIndex !== undefined) {
      vals = vals[repIndex]
    }
  }
  return vals?.[variable]
}

const EVENT_TYPES = {
  DOCUMENT_CREATE: 'document_create',
  DOCUMENT_DELETE: 'document_delete',
  DOCUMENT_DOWNLOAD: 'document_download',
  DOCUMENT_DOWNLOAD_PDF: 'document_download_pdf',
  DOCUMENT_DOWNLOAD_DOCX: 'document_download_docx',
  DOCUMENT_PREVIEW: 'document_preview',
  IMMOCLOUD_UPLOAD: 'immocloud_upload',
  DOCUMENT_SHARE: 'document_share',
};

const isCoverPageVariable = (v) => {
  if(!v || !v.includes) return false
  return v.includes('cover_page_');
};

const coverPageConfig = (useCustomCover, agency, user, documentName, hasCoverPage = true, partner) => {
  let coverPageVariables = {};
  let coverPageImages = {};
  let coverPageOptions = {};
  if (agency?.cover_use_logo) {
    if (agency?.cover_logo_input !== "agency" && agency?.logo) {
      coverPageImages.logo = agency.logo;
    } else if(agency?.cover_logo_input === "agency") {
      coverPageImages.logo = agencyLogoUrl(user.manufacturer);
    } else {
      coverPageImages.logo = '';
    }
  } else {
    coverPageImages.logo = '';
  }
  if (agency?.cover_image_input !== 'none') {
    if (agency?.cover && agency?.cover_image_input === 'custom') {
      coverPageImages.cover = agency?.cover;
    }
  } else {
    coverPageImages.cover = '';
  }
  if (agency?.cover_color) {
    coverPageOptions.cover_color = agency.cover_color;
    if(!coverPageOptions.font_config) {
      coverPageOptions.font_config = {}
    }
    coverPageOptions.font_config.heading_color = agency.cover_color
    coverPageOptions.font_config.heading_2 = {
      color: agency.cover_color,
      borderColor: agency.cover_color,
    }
    coverPageOptions.font_config.heading_3 = {
      color: "#ffffff",
      backgroundColor: agency.cover_color,
    }
    coverPageOptions.font_config.footer_title = {
      color: agency.cover_color,
    }
    coverPageOptions.font_config.cover_title = {
      color: agency.cover_color,
    }
  }
  if (agency?.cover_admin_info && agency?.cover_admin_photo && user.picture) {
    coverPageImages.cover_admin_photo = user.picture;
  } else {
    coverPageImages.cover_admin_photo = '';
  }
  coverPageVariables = matchCoverPageVariables(
    user,
    agency?.cover_agency_info,
    agency?.cover_admin_info
  );
  if(agency?.font_family) {
    if(!coverPageOptions.font_config) {
      coverPageOptions.font_config = {}
    }
    coverPageOptions.font_config.font_family = agency.font_family
  }
  if(!useCustomCover) {
    coverPageOptions.remove_cover_page = true;
    coverPageImages.cover_admin_photo = '';
    coverPageImages.logo = '';
  }
  coverPageVariables.cover_page_title = documentName;

  return {
    coverPageVariables,
    coverPageImages,
    coverPageOptions,
  };
};

const matchCoverPageVariables = (user, useAgency, useAdmin) => {
  const values = {};
  if (useAgency) {
    const agencyLines = [];
    agencyLines.push(manufacturerProperty(user?.manufacturer, 'name') || 'Immo Docs');
    agencyLines.push(
      manufacturerProperty(user?.manufacturer, 'address').trim()
    );
    agencyLines.push(
      `${manufacturerProperty(user?.manufacturer, 'zip')} ${manufacturerProperty(user?.manufacturer, 'city')}`.trim()
    );
    agencyLines.push(
      manufacturerProperty(user?.manufacturer, 'phone').trim()
    );
    agencyLines.push(manufacturerProperty(user?.manufacturer, 'email').trim());
    values.cover_page_agency = manufacturerProperty(user?.manufacturer, 'name') || 'Immo Docs'
    values.cover_page_address = manufacturerProperty(user?.manufacturer, 'address').trim()
    values.cover_page_cp = manufacturerProperty(user?.manufacturer, 'zip').trim()
    values.cover_page_city = manufacturerProperty(user?.manufacturer, 'city').trim()
    values.cover_page_phone = manufacturerProperty(user?.manufacturer, 'phone').trim()
    values.cover_page_email = manufacturerProperty(user?.manufacturer, 'email').trim()
    let linesOffset = 0;
    for (let i = 0; i < agencyLines.length; i++) {
      if (agencyLines[i]) {
        values[`cover_page_line${i + 1 + linesOffset}`] = agencyLines[i];
      } else {
        linesOffset--;
      }
    }
  }
  if (useAdmin) {
    const adminLines = [];
    adminLines.push(`${user.firstname} ${user.lastname}`.trim());
    adminLines.push(`${user.email || ''}`.trim());
    adminLines.push(`${user.phone || ''} - ${user.mobile_phone || ''}`.trim());
    adminLines.push(
      `${
        user.fonctions && user.fonctions.length > 0
          ? user.fonctions[0].name
          : ''
      }`.trim()
    );
    adminLines.push(`${user.rsac || ''} - ${user.ville_rsac || ''}`.trim());
    adminLines.push(
      `${user.num_rcp_pro || ''} - ${user.organisme_rcp_pro || ''}`.trim()
    );
    values.cover_page_admin_name = `${user.firstname} ${user.lastname}`.trim()
    values.cover_page_admin_email = `${user.email || ''}`.trim()
    values.cover_page_admin_phone = `${user.mobile_phone || user.phone || ''}`.trim()
    values.cover_page_admin_role = `${user.fonctions && user.fonctions.length > 0 ? user.fonctions[0].name : ''}`.trim()
    let linesOffset = 6;
    for (let i = 0; i < adminLines.length; i++) {
      if (adminLines[i] !== '' && adminLines[i] !== '-') {
        values[`cover_page_line${i + 1 + linesOffset}`] = adminLines[i];
      } else {
        linesOffset--;
      }
    }
  }
  return values;
};

// Save sort to LS
const saveSortingToLS = (value, order, type) => {
  const sortLS = localStorage.getItem(JURISUR_SORT_LS);
  if (sortLS) {
    const sortingObj = JSON.parse(sortLS);
    let obj = { ...sortingObj };
    obj[type] = { value, order };
    localStorage.setItem(JURISUR_SORT_LS, JSON.stringify(obj));
  } else {
    let obj = {};
    obj[type] = { value, order };
    localStorage.setItem(JURISUR_SORT_LS, JSON.stringify(obj));
  }
};

// Get jurisur sort from LS
const getSorting = () => {
  const sortLS = localStorage.getItem(JURISUR_SORT_LS);
  return JSON.parse(sortLS);
};

// Save pagination data to LS
const savePaginationDataToLS = (numOfItemsToShow, currentPage, all, type) => {
  const paginationLS = localStorage.getItem(JURISUR_PAGINATION_LS);
  if (paginationLS) {
    const paginationObj = JSON.parse(paginationLS);
    let obj = { ...paginationObj };
    obj[type] = { items: numOfItemsToShow, current: currentPage, all };
    localStorage.setItem(JURISUR_PAGINATION_LS, JSON.stringify(obj));
  } else {
    let obj = {};
    obj[type] = { items: numOfItemsToShow, current: currentPage, all };
    localStorage.setItem(JURISUR_PAGINATION_LS, JSON.stringify(obj));
  }
};

// Get jurisur pagination data from LS
const getPaginationData = () => {
  const paginationLS = localStorage.getItem(JURISUR_PAGINATION_LS);
  return JSON.parse(paginationLS);
};

const formulasInSection = (s) => {
  let formulas = []
  if(s.type === 'text' || s.type.includes('heading')) {
    return findFormulasInSection(s)
  } else if(s.type === 'table') {
    for(let c of s.head) {
      formulas.push(...findFormulasInSection(c))
    }
    for(let c of s.row) {
      formulas.push(...findFormulasInSection(c))
    }
  } else if(s.type === 'static-table') {
    if(!s.rows) return []
    for(let row of s.rows) {
      if(!row.cells) continue
      for(let cell of row.cells) {
        if(!cell.sections) continue
        for(let cellSection of cell.sections) {
          formulas.push(...formulasInSection(cellSection))
        }
      }
    }
  }
  return formulas
}

const getTemplateFormulas = (templateObject) => {
  let formulas = []
  for(let s of (templateObject?.sections || [])) {
    formulas.push(...formulasInSection(s))
  }
  let uniqueFormulas = []
  formulas.forEach(f => {
    if(!uniqueFormulas.find(uf => uf.handle === f.handle)) {
      uniqueFormulas.push(f)
    }
  })
  return uniqueFormulas
}

const findFormulasInSection = (section) => {
  if(!section.content) {
    return []
  }
  let formulaRegex = /\{f\(([^}]*)\}/g
  // let fieldRegex = /\{d\.([^}]*)\}|\{f\(([^}]*)\}/g
  let matches = section.content.match(formulaRegex)
  let formulas = []
  if(matches?.length > 0) {
    matches.forEach(m => {
      formulas.push({ handle: m, repeatableId: section.repeatable_section_id })
    })
  }
  return formulas
}

const insertFormulaResults = (values, documentFormulas) => {
  let results = {...values}
  for(let f of documentFormulas) {
    if(f.repeatableId) {
      if(!results[f.repeatableId]) {
        continue
      }
      for(let i in results[f.repeatableId]) {
        let { key, value } = formulaResult(values, f.handle, f.repeatableId, i)
        results[f.repeatableId][key] = value
      }
    } else {
      let { key, value } = formulaResult(values, f.handle)
      results[key] = value
    }
  }

  return results
}

const NUMBER_FORMATTERS = {
  "format('in_words')": (v) => {
    if(!v) return ''
    return numberToWords(v, 'fr')
  },
  "format('in_words_euro')": (v) => {
    if(!v) return ''
    return numberToWords(v, 'fr', 'euro')
  },
  "format('ht_5')": (v) => {
    if(!v) return ''
    const val = parseFloat(v) / 1.05
    return val.toFixed(nonZeroDecimalsCount(val))
  },
  "format('ht_5_5')": (v) => {
    if(!v) return ''
    const val = parseFloat(v) / 1.055
    return val.toFixed(nonZeroDecimalsCount(val))
  },
  "format('ht_20')": (v) => {
    if(!v) return ''
    const val = parseFloat(v) / 1.2
    return val.toFixed(nonZeroDecimalsCount(val))
  },
  "format('ttc_5')": (v) => {
    if(!v) return ''
    const val = parseFloat(v) * 1.05
    return val.toFixed(nonZeroDecimalsCount(val))
  },
  "format('ttc_5_5')": (v) => {
    if(!v) return ''
    const val = parseFloat(v) * 1.055
    return val.toFixed(nonZeroDecimalsCount(val))
  },
  "format('ttc_20')": (v) => {
    if(!v) return ''
    const val = parseFloat(v) * 1.2
    return val.toFixed(nonZeroDecimalsCount(val))
  }
}

const nonZeroDecimalsCount = (v, maxDecimalsCount = 3) => {
  const str = `${parseFloat(v).toFixed(maxDecimalsCount)}`
  let decimalPart = str.split('.')[1]
  while(decimalPart && decimalPart[decimalPart.length - 1] === '0') {
    decimalPart = decimalPart.slice(0, -1)
  }
  if(decimalPart) {
    return decimalPart.length
  }
  return 0
}

const formulaResult = (values, handle, repId, repIndex) => {

  const cleanedHandle = handle
    .substring(1, handle.length - 1)
    .replace(/\./g, '');

  let formulaBody = handle.substring(3, handle.length - 1).split(':')[0]
  formulaBody = formulaBody.substring(0, formulaBody.length - 1)
  const formattersString = handle.substring(3, handle.length - 1).split(':').slice(1).join(':')
  const formatters = formattersString.split(':').filter(f => f !== '')

  let { result, valid } = evaluateFormula(values, formulaBody, repId, repIndex);

  if(formatters.length > 0) {
    let val = parseFloat(result)
    if(isNaN(val)) {
      return { key: cleanedHandle, value: 'Formule invalide' }
    }
    for(let i = 0; i < formatters.length; i++) {
      const f = formatters[i]
      if(NUMBER_FORMATTERS[f]) {
        val = NUMBER_FORMATTERS[f](val)
      }
    }
    result = val
  }
  if (!valid) {
    return { key: cleanedHandle, value: 'Formule invalide' }
  }
  return { key: cleanedHandle, value: result };
};

const functionWords = ['sum', 'inWords'];

const evaluateFormula = (values, formula, repId, repIndex) => {
  const parsed = parseFormula(formula);
  let functionIndex = -1;

  for (let i = 0; i < parsed.length; i++) {
    if (functionWords.includes(parsed[i])) {
      functionIndex = i;
      break;
    }
  }

  while (functionIndex !== -1) {
    let body = functionBody(parsed, functionIndex + 2);
    let functionName = parsed[functionIndex];
    let val = '';
    if (functionName === 'sum') {
      val = evaluateSum(values, body.join(''), repId, repIndex);
    } else if (functionName === 'inwords') {
      val = evaluateInWords(values, body.join(''), repId, repIndex);
    }
    parsed.splice(functionIndex, body.length + 3, val);
    functionIndex = -1;
    for (let i = 0; i < parsed.length; i++) {
      let component = parsed[i];
      if (functionWords.includes(component)) {
        functionIndex = i;
        break;
      }
    }
  }
  for (let i = 0; i < parsed.length; i++) {
    let dataValue = values[parsed[i]];
    if (dataValue) {
      parsed[i] = dataValue;
    }
  }

  let result;
  if (parsed.length === 1) {
    result = parsed[0];
  } else {
    result = evaluateExpression(parsed.join(' '));
  }

  return { result, valid: true };
};

const functionBody = (parsedFormula, startIndex) => {
  let openParentheses = 0;
  let body = [];
  for (let i = startIndex; i < parsedFormula.length; i++) {
    if (parsedFormula[i] === ')' && openParentheses === 0) {
      return body;
    } else if (parsedFormula[i] === ')') {
      openParentheses--;
    } else if (parsedFormula[i] === '(') {
      openParentheses++;
    }
    body.push(parsedFormula[i]);
  }
  return body;
};

const operators = ['+', '-', '*', '/'];
const parentheses = ['(', ')'];

const parseFormula = (formula) => {
  let currentComponent = '';
  let components = [];
  for (let i = 0; i < formula.length; i++) {
    let char = formula[i];
    if (operators.includes(char) || parentheses.includes(char)) {
      if (currentComponent) {
        components.push(currentComponent);
      }
      components.push(char);
      currentComponent = '';
      continue;
    }
    currentComponent += formula[i];
  }
  if (currentComponent) {
    components.push(currentComponent);
  }
  return components;
};

const evaluateExpression = (string) => {
  const parts = string.split(' ');
  const parsed = [];
  for (let p of parts) {
    let part = p.trim();
    if (part) {
      let number = parseFloat(part);
      if (!isNaN(number)) {
        parsed.push(number);
      } else {
        parsed.push(part);
      }
    }
  }

  while (parsed.includes('(') && parsed.includes(')')) {
    let block = [];
    let blockStart;
    let blockEnd;
    for (let i = 0; i < parsed.length; i++) {
      let p = parsed[i];
      if (p === ')') {
        blockEnd = i;
        break;
      }
      if (p === '(') {
        blockStart = i;
      }
    }
    block = parsed.slice(blockStart + 1, blockEnd);
    let result = evaluateExpression(block.join(' '));
    parsed.splice(blockStart, block.length + 2, result);
  }

  while (parsed.includes('*') || parsed.includes('/')) {
    let operandA;
    let operandB;
    let operator;
    let operationStart;
    for (let i = 0; i < parsed.length; i++) {
      let p = parsed[i];
      if (p === '*' || p === '/') {
        operandA = parsed[i - 1];
        operandB = parsed[i + 1];
        operator = p;
        operationStart = i - 1;
        break;
      }
    }
    let result =
      operator === '*' ? operandA * operandB : parseFloat(operandA / operandB);
    parsed.splice(operationStart, 3, result);
  }

  while (parsed.includes('+') || parsed.includes('-')) {
    let operandA;
    let operandB;
    let operator;
    let operationStart;
    for (let i = 0; i < parsed.length; i++) {
      let p = parsed[i];
      if (p === '+' || p === '-') {
        operandA = parsed[i - 1] || 0;
        operandB = parsed[i + 1] || 0;
        operator = p;
        operationStart = i - 1;
        break;
      }
    }
    let result = operator === '+' ? operandA + operandB : operandA - operandB;
    parsed.splice(operationStart, 3, result);
  }

  if (parsed.length === 1) {
    let number = parseFloat(parsed[0]);
    if (isNaN(number)) {
      return '0';
    }
    let stringToFixed = `${number.toFixed(2)}`;
    let string = `${parseFloat(stringToFixed)}`;
    if (string.length < stringToFixed.length) {
      return string;
    } else {
      return stringToFixed;
    }
  }
  return parsed.join(' ');
};

const evaluateSum = (values, body, repId, repIndex) => {
  let result = '';
  let [objectKey, propertyKey] = body.split('.');
  let data = values;
  if (repId) {
    data = data[repId][repIndex];
  }
  let obj = data[objectKey];
  if (!obj) {
    return result;
  }
  result = 0;
  let isArea = false;
  for (let i in obj) {
    let val = obj[i][propertyKey];
    if (!val) {
      continue;
    }
    if (typeof val === 'number') {
      result += parseFloat(val);
    } else if (typeof val === 'string') {
      if (isValueArea(val)) {
        isArea = true;
        let areaInCa = areaInCaUnits(val);
        result += areaInCa;
      } else {
        result += parseFloat(val)
      }
    }
  }
  if (isArea) {
    return printAreaValue(
      convertAreaToLargestUnits({
        ha: 0,
        a: 0,
        ca: result,
      })
    );
  }
  return result;
};

const isValueArea = (val) => {
  const areaValueRegex = /^(?:[0-9]+ha ?)?(?:[0-9]+a ?)?(?:[0-9]+ca)?$/;
  return areaValueRegex.test(val);
};

const evaluateInWords = (values, body, repId, repIndex) => {
  if (body === '') {
    return '';
  }
  let number = parseFloat(body);
  if (isNaN(number)) {
    let { result, valid } = evaluateFormula(values, body, repId, repIndex);
    if (!valid) {
      return '';
    }
    number = parseFloat(result);
  }
  return numberToWordsFrench(number);
};

const roundAmountToString = (amount) => {
  const amountString = `${amount}`
  if(amountString.includes('.')) {
    const [integer, decimal] = amountString.split('.')
    if(decimal.length > 2) {
      const roundedDecimal = roundCents(decimal)
      if(roundedDecimal === '100') {
        return `${parseInt(integer) + 1}`
      } else {
        return `${integer}.${roundedDecimal}`
      }
    } else {
      return amountString
    }
  } else {
    return amountString
  }
}

const roundCents = (decimalString) => {
  if(decimalString.length <= 2) {
    return decimalString
  }
  else {
    let rounded
    if(decimalString[2] >= 5) {
      rounded = (parseInt(decimalString.slice(0, 2)) + 1).toString()
    } else {
      rounded = decimalString.slice(0, 2)
    }
    if(rounded.length === 1) {
      return `0${rounded}`
    } else {
      return `${rounded}`
    }
  }
}

const numberToWordsFrench = (number, type) => {
  let result, iteration, iterationText, hundreds, hundredsText;
  
  if(type === 'euro') {
    number = parseFloat(roundAmountToString(number))
  }
  let numberString = `${number}`;
  if (numberString.includes('.')) {
    let [integer, decimal] = numberString.split('.');
    let decimalInWords = '';
    if(type === 'euro' && decimal.length === 1) {
      decimal += '0'
    } else if(type === 'euro' && decimal.length > 2) {
      decimal = decimal.substring(0, 2)
    }
    if(type !== 'euro') {
      while (decimal[0] === '0') {
        decimalInWords += 'zéro ';
        decimal = decimal.substring(1);
      }
    }
    decimalInWords += numberToWordsFrench(parseInt(decimal));
    if(type === 'euro') {
      const eurosInWords = numberToWordsFrench(parseInt(integer))
      const lastWord = eurosInWords.split(' ').pop()
      const allEuroPrefixWords = [...euroPrefixIterationWords, ...euroPrefixIterationWords.map(w => `${w}s`)]
      return `${eurosInWords} ${allEuroPrefixWords.includes(lastWord) ? "d'" : ''}euro${parseInt(integer) > 1 ? 's' : ''} et ${decimalInWords} centime${decimal > 1 ? 's' : ''}`;
    }
    return `${numberToWordsFrench(
      parseInt(integer)
    )} virgule ${decimalInWords}`;
  }

  if (number === 0) {
    return 'zéro';
  }

  const originalNumber = number;

  result = '';
  iteration = 1;

  while (number > 0) {
    hundreds = number % 1000;
    number = Math.floor(number / 1000);

    if (hundreds > 0) {
      // -- 3 digits to text
      hundredsText = getHundredsText(hundreds);
      if(`${originalNumber}` !== "80000" && number === 0 && hundredsText === 'quatre-vingt') {
        hundredsText += 's';
      }
      if (iteration > 1) {
        // -- mille, million, …
        iterationText = getIterationText(iteration);
        if((iterationText || '').trim() !== 'mille' && (hundredsText || '').trim() !== 'un') {
          iterationText += 's';
        }
        hundredsText = hundredsText + iterationText + ' ';
        if ((hundredsText || '').trim() === 'un mille') {
          hundredsText = ' mille ';
        }
      }

      result = hundredsText + result;
    }
    iteration = iteration + 1;
  }

  let inWordsResult = result.trim()
  if(type === 'euro') {
    const lastWord = inWordsResult.split(' ').pop()
    const allEuroPrefixWords = [...euroPrefixIterationWords, ...euroPrefixIterationWords.map(w => `${w}s`)]
    inWordsResult += ` ${allEuroPrefixWords.includes(lastWord) ? "d'" : ''}euro` + (parseInt(originalNumber) > 1 ? 's' : '')
  }

  return inWordsResult
};

const numberToWords = (number, language = 'fr', type = '') => {
  if (language === 'fr') {
    return numberToWordsFrench(number, type);
  }
  return `Translation to ${language} is not yet supported`;

}

const getHundredsText = (number) => {
  let result, hundreds, tens;
  result = '';

  hundreds = Math.floor(number / 100);
  if (hundreds > 0) {
    number = number % 100;
    if (hundreds > 1) {
      // -- deux cents, trois cents, …
      result = result + getLessThanTwentyText(hundreds) + ' cent' + (number > 0 ? '' : 's');
    } else {
      result = result + 'cent';
    }
  }

  if (number > 0) {
    if (hundreds > 0) {
      // -- space after "cent(s)"
      result = result + ' ';
    }

    if (number < 20) {
      // -- < 20 ⇒ look up
      result = result + getLessThanTwentyText(number);
    } else {
      tens = Math.floor(number / 10);
      number = number % 10;

      switch (tens) {
        case 7:
          // -- soixante dix, soixante onze, …
          result = result + 'soixante';
          number = number + 10;
          break;
        case 8:
          if (number > 0) result = result + 'quatre-vingt';
          else result = result + 'quatre-vingt';
          break;
        case 9:
          // -- quatre-vingt dix, quatre-vingt onze, …
          result = result + 'quatre-vingt';
          number = number + 10;
          break;
        default:
          // -- vingt, trente, quarante, …
          result = result + getTensText(tens);
      }

      if (number > 0) {
        if (number === 1 && tens !== 8) result = result + ' et un';
        else result = result + '-' + getLessThanTwentyText(number);
      }
    }
  }

  return result;
};

const getLessThanTwentyText = (number) => {
  switch (number) {
    case 1:
      return 'un';
    case 2:
      return 'deux';
    case 3:
      return 'trois';
    case 4:
      return 'quatre';
    case 5:
      return 'cinq';
    case 6:
      return 'six';
    case 7:
      return 'sept';
    case 8:
      return 'huit';
    case 9:
      return 'neuf';
    case 10:
      return 'dix';
    case 11:
      return 'onze';
    case 12:
      return 'douze';
    case 13:
      return 'treize';
    case 14:
      return 'quatorze';
    case 15:
      return 'quinze';
    case 16:
      return 'seize';
    case 17:
      return 'dix-sept';
    case 18:
      return 'dix-huit';
    case 19:
      return 'dix-neuf';
    default:
      return '';
  }
};

const getTensText = (tens) => {
  switch (tens) {
    case 2:
      return 'vingt';
    case 3:
      return 'trente';
    case 4:
      return 'quarante';
    case 5:
      return 'cinquante';
    case 6:
      return 'soixante';
    default:
      return '';
  }
};

const getIterationText = (iteration) => {
  switch (iteration) {
    case 1:
      return '';
    case 2:
      return ' mille';
    case 3:
      return ' million';
    case 4:
      return ' milliard';
    case 5:
      return ' billion';
    case 6:
      return ' billiard';
    case 7:
      return ' trillion';
    case 8:
      return ' trilliard';
    case 9:
      return ' quadrillion';
    default:
      return ' ??? ';
  }
};

const euroPrefixIterationWords = [
  "million",
  "milliard",
  "billion",
  "billiard",
  "trillion",
  "trilliard",
  "quadrillion",
]

// Get file data
const getFileData = (file, fileType = '', cb) => {
  if (file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();

      reader.onload = (e) => {
        let components = file.name.split('.');
        const format = components[components.length - 1];
        components.splice(components.length - 1, 1);
        const name = components.join('.');
        const type = file.type ? file.type : fileType;
        resolve({
          data: e.target.result,
          name: name,
          format: format,
          type: type,
        });
        cb &&
          cb({ data: e.target.result, name: name, format: format, type: type });
      };
      reader.onerror = (err) => {
        console.log('reader on error', err);
        reject();
      };
      reader.readAsDataURL(file);
    });
  }
};

// Get first letter of the string
const getFirstLetter = (text) => {
  if (typeof text !== 'string') return;
  return text.slice(0, 1);
};

const areaInCaUnits = (value) => {
  let parsed = parseAreaValue(value);
  return parsed.ha * 10000 + parsed.a * 100 + parsed.ca;
};

const convertAreaToLargestUnits = (parsed) => {
  while (parsed.ca > 100) {
    parsed.a += Math.floor(parsed.ca / 100);
    parsed.ca = parsed.ca % 100;
  }
  while (parsed.a > 100) {
    parsed.ha += Math.floor(parsed.a / 100);
    parsed.a = parsed.a % 100;
  }
  return parsed;
};

const parseAreaValue = (value) => {
  const parsed = {
    ha: 0,
    a: 0,
    ca: 0,
  };
  if (!value) {
    return parsed;
  }
  value = value.replace(/s/g, '');
  if (typeof value === 'number') {
    parsed.ca = value;
    return convertAreaToLargestUnits(parsed);
  }

  let parsedNumber = parseInt(value);
  if (value === `${parsedNumber}`) {
    parsed.ca = parsedNumber;
    return convertAreaToLargestUnits(parsed);
  }
  let components = value.split('a');

  for (let i = 0; i < components.length; i++) {
    if (components[i].includes('h')) {
      let val = parseInt(components[i].replace(/h/g, ''));
      parsed.ha = isNaN(val) ? 0 : val;
    } else if (components[i].includes('c')) {
      let val = parseInt(components[i].replace(/c/g, ''));
      parsed.ca = isNaN(val) ? 0 : val;
    } else if (components[i].length > 0) {
      let val = parseInt(components[i]);
      parsed.a = isNaN(val) ? 0 : val;
    }
  }

  return convertAreaToLargestUnits(parsed);
};

const printAreaValue = (parsedArea) => {
  let val = '';
  if (parsedArea.ha > 0) {
    val += `${parsedArea.ha}ha`;
  }
  if (parsedArea.a > 0) {
    val += val.length > 0 ? ' ' : '';
    val += `${parsedArea.a}a`;
  }
  if (parsedArea.ca > 0) {
    val += val.length > 0 ? ' ' : '';
    val += `${parsedArea.ca}ca`;
  }
  return val;
};

// Convert bytes to sizes(kb, mb...)
const bytesToSize = (bytes) => {
  const units = ['byte', 'kilobyte', 'megabyte', 'terabyte', 'petabyte'];
  const unit = Math.floor(Math.log(bytes) / Math.log(1024));
  return new Intl.NumberFormat('en', {
    style: 'unit',
    unit: units[unit],
    maximumFractionDigits: 1,
  }).format(bytes / 1024 ** unit);
};

const canUseCoverPageSettings = (user, partner) => {
  if(partner === 'jurisur') {
    return user && user.delegation
  } else if(partner === 'kel') {
    return true
  }
  return false
};

const partnerUsesV1UI = (partner) => {
  return isMlsPartner(partner) || partner === 'alphabet';
};

const statusLabelForAr24Sender = (user) => {
  return AR24_SENDER_STATUS_LABEL[user.status]
};

const isObject = (item) => {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
const mergeDeep = (target, ...sources) => {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}


const agencyLogoUrl = (agency) => {
  if(!agency) {
    return ''
  }
  let logoUrl = ''
  if(agency.logo) {
    logoUrl = agency.logo
  } else if(agency.manufacturers_logo) {
    logoUrl = agency.manufacturers_logo
  }
  let isRelativeUrl = false
  if(logoUrl.startsWith('..')) {
    isRelativeUrl = true
    logoUrl = logoUrl.replace('..', '')
  }
  if(logoUrl.startsWith('/')) {
    isRelativeUrl = true
    logoUrl = logoUrl.replace('/', '')
  }

  if(!isRelativeUrl) {
    return logoUrl
  }

  let { baseUrl } = agency
  if(!baseUrl) {
    return ''
  }
  if(!baseUrl.endsWith('/')) {
    baseUrl = `${baseUrl}/`
  }
  return `${baseUrl}${logoUrl}`
}

/**
 * 
 * @param {Object} user 
 * @param {String} property "id", "name", "address", "zip", "city", "phone", "email"
 */
const manufacturerProperty = (agency, property) => {
  if(!agency) {
    return ''
  }
  switch(property) {
    case 'id':
      return agency.id || agency.manufacturers_id || ''
    case 'name':
      return agency.manufacturers_name || agency.name || ''
    case 'address':
      return agency.address || agency.adresse || ''
    case 'zip':
      return agency.postal_code || ''
    case 'city':
      return agency.city || ''
    case 'phone':
      return agency.phone || agency.telephone || ''
    case 'email':
      return agency.email || ''
    default:
      throw(new Error(`Invalid manufacturer property ${property}`))
  }
}

const adminProperty = (user, property) => {
  switch(property) {
    case 'phone':
      return user?.mobile_phone || user?.phone || user?.telephone || ''
    case 'email':
      return user?.email || ''
    default:
      throw(new Error(`Invalid admin property ${property}`))
  }
}

const fileNameWithoutExtension = (fileName) => {
  const lastDotIndex = fileName.lastIndexOf('.')
  if(lastDotIndex === -1) return fileName
  return fileName.substring(0, lastDotIndex)
}

const dateDisplayFormat = "D MMM YYYY HH:mm"

const isFeatureAuthorized = ({ userClaims, rule, resource }) => {
  let isAllowed = false
  if(!userClaims) {
    return false
  }
  
  if(rule === 'any_admin') { // admin access in any authorized manufacturer
    if(userClaims.authorized_manufacturers?.length > 0) {
      const adminManufacturers = userClaims.authorized_manufacturers.filter(m => m.access === 'admin')
      isAllowed = adminManufacturers.length > 0
    }
  } else if(rule === 'current_full') { // full access in main manufacturer
    if(userClaims.authorized_manufacturers?.length > 0) {
      const authorizedManufacturer = userClaims?.authorized_manufacturers.find(m => m.id === userClaims?.manufacturer_id)
      if(authorizedManufacturer?.access === 'full' || authorizedManufacturer?.access === 'admin') {
        isAllowed = true
      }
    }
  } else if(rule === 'any') {
    return true
  }
  
  return isAllowed
}

const areArraysEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
      return false
  }
  for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
          return false
      }
  }
  return true
}

/**
 *
 * @param {'documents'|'standard-templates'|'templates'} view
 * @param {any} userClaims
 * @returns
 */
const areFolderActionsAuthorized = (view, userClaims) => {
  if(view === 'documents') {
    return isFeatureAuthorized({ userClaims, rule: 'current_full', resource: 'document_folders_actions' })
  }
  if(view === 'standard-templates') {
    return isFeatureAuthorized({ userClaims, rule: 'current_full', resource: 'standard_template_folders_actions' })
  }
  if(view === 'templates') {
    return isFeatureAuthorized({ userClaims, rule: 'any_admin', resource: 'template_folders_actions' })
  }
  return false
}

export {
  sortedArrayFromObject,
  sorterWithPathAndOrder,
  getCreatedAtFromDocuments,
  dateValueFormat,
  urlSuffixForEnvironment,
  getAllParentFolders,
  folderHasSubfolders,
  folderHasTemplates,
  getFirstLevelSubfolders,
  sortArrayOfObjects,
  isOverflown,
  convertToTemplateObjWithUniqueVarIndexes,
  readFileAsync,
  base64toBlob,
  signatureAvailable,
  availableOn,
  copyToClipboard,
  blobToFile,
  areSectionConditionsMet,
  EVENT_TYPES,
  isCoverPageVariable,
  matchCoverPageVariables,
  canUseTemplateCategories,
  saveSortingToLS,
  getSorting,
  savePaginationDataToLS,
  getPaginationData,
  formulaResult,
  coverPageConfig,
  getFileData,
  getFirstLetter,
  parseAreaValue,
  printAreaValue,
  bytesToSize,
  isMlsPartner,
  canUseCoverPageSettings,
  partnerUsesV1UI,
  statusLabelForAr24Sender,
  insertFormulaResults,
  getTemplateFormulas,
  mergeDeep,
  isFeatureEnabled,
  agencyLogoUrl,
  dateDisplayFormat,
  numberToWords,
  manufacturerProperty,
  fileNameWithoutExtension,
  adminProperty,
  isFeatureAuthorized,
  NUMBER_FORMATTERS,
  areArraysEqual,
  areFolderActionsAuthorized
};
