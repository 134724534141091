import { useState, forwardRef, useEffect, useContext } from 'react'
import { createPortal } from 'react-dom'

import { Folder, Home, AngleLeft, AngleRight, FileAlt } from '../../assets/icons'
import { DocumentsFoldersContext, FolderContext } from '../../context'
import { useFoldersActions } from '../../hooks'

const FoldersPopup = forwardRef(({ onCancel, folders, foldersLoading, loading, data, resource, view = 'documents', position }, ref) => {
  const { moveFolder, moveToRootFolder } = useFoldersActions()
  const { docFolders } = useContext(DocumentsFoldersContext)
  const { folders: tmpltFolders } = useContext(FolderContext)
  const [level, setLevel] = useState('root')
  const [selectedFolder, setSelectedFolder] = useState(null)
  const [dataArr, setDataArr] = useState([])

  // Set data arr
  useEffect(() => {
    if(data) {
      let arr = []
      if(view === 'documents') {
        for(let key in data) {
          if(!data[key].deleted && !data[key].archived) {
            arr.push({ id: key, ...data[key] })
          }
        }
      }else if(view === 'templates') {
        for(let key in data) {
          if(!data[key].deleted) {
            arr.push({ id: key, ...data[key] })
          }
        }
      }
      setDataArr(arr)
    }
  }, [data, view])

  // On folder select
  const handleFolderSelect = (selected, level) => {
    setSelectedFolder(selected)
    setLevel(level)
  }

  // Go back
  const handleGoBack = () => {
    if(selectedFolder.parentFolder) {
      const folder = [...folders].filter(folder => folder.id === selectedFolder.parentFolder)[0];
      setSelectedFolder(folder);
    }else {
      setLevel('root');
      setSelectedFolder(null);
    }
  }

  // Drag start
  const handleDragStart = (e, folder) => {
    const div = document.createElement('div')
    div.id = 'draggable-folder-el'
    div.className = 'draggable-ghost-el-v2'
    div.innerText = `Déplacer le dossier ${folder.name}`
    document.getElementById('root').appendChild(div)
    e.dataTransfer.setData('folder', JSON.stringify(folder))
    e.dataTransfer.setDragImage(div, 0, 0)
  }
  
  // Drag over
  const handleDragOver = (e) => {
    e.preventDefault()
    const folderBox = e.target.closest('.f-list-item')
    if(folderBox && !folderBox.classList.contains('hover')) {
      folderBox.classList.add('hover')
    }
  }

  // Drag leave
  const handleDragLeave = (e) => {
    e.preventDefault()
    const folderBox = e.target.closest('.f-list-item')
    if(folderBox && folderBox.classList.contains('hover')) {
      folderBox.classList.remove('hover')
    }
  }
  
  // Drop
  const handleDrop = async (e, folder) => {
    const data = e.dataTransfer.getData('folder') ? JSON.parse(e.dataTransfer.getData('folder')) : null

    const folderBox = e.target.closest('.f-list-item')
    if(folderBox && folderBox.classList.contains('hover')) {
      folderBox.classList.remove('hover')
    }

    if(data) {
      await moveFolder({ data, folder, folders: view === 'templates' ? tmpltFolders : docFolders, view })
      return
    }

    const templateData = e.dataTransfer.getData('template') ? JSON.parse(e.dataTransfer.getData('template')) : null

    if(templateData) {
      // onMoveResourceToFolder(templateData, folder);
    }
  }

  // Drag end 
  const handleDragEnd = () => {
    if(document.getElementById('draggable-folder-el')) {
      document.getElementById('draggable-folder-el').remove()
    }
  }

  // On move btn click
  const handleMove = async () => {
    if(selectedFolder) {
      await moveFolder({ data: resource, folder: selectedFolder, folders: view === 'documents' ? docFolders : tmpltFolders, view })
    }else {
      await moveToRootFolder(resource, view)
    }
    // if(view === 'documents') {
    // }else if(view === 'templates') {
    //   if(selectedFolder) {
    //     await moveFolder({ data: resource, folder: selectedFolder, folders: tmpltFolders, view })
    //   }else {
    //     await moveToRootFolder(resource, 'documents')
    //   }
    // }
  }

  return createPortal(
    <div className="folders-popup-v2" ref={ref} style={position ? { top: position.top, left: position.left } : {}}>
      <div className={level === 'root' ? "folders-popup-v2__head root" : "folders-popup-v2__head inner"}>
        <div className="main">
          <span className="icon">
            <Home />
          </span>
          <h5>Biblitohèque</h5>
        </div>
        <div className="back">
          <span className="icon" onClick={handleGoBack}>
            <AngleLeft  />  
          </span>
          <h5>{selectedFolder && selectedFolder.name}</h5>
        </div>
      </div>
      <div className="folders-popup-v2__body">
        {foldersLoading ? 
          <div style={{padding: '10px'}}><div className="loader-v2"></div></div> : 
          <ul className={level === 'root' ? "folders-popup-v2__body_list root" : "folders-popup-v2__body_list inner"}>
          {folders.length > 0 && folders.map((folder, i) => {
            if(!folder.parentFolder) {
              return <li 
                key={i} 
                className="root f-list-item" 
                onDragStart={(e) => handleDragStart(e, folder)} 
                onDragOver={handleDragOver} 
                onDragLeave={handleDragLeave}
                onDrop={(e) => handleDrop(e, folder)} 
                onDragEnd={handleDragEnd}
                draggable
              >
                <div className="root" onClick={(e) => handleFolderSelect(folder, 'inner', e)}>
                  <Folder />
                  <p>{folder.name}</p>
                  <span className="go-to-folder"><AngleRight /></span>
                </div>
              </li>
            }else {
              return null
            }
          })}
          {selectedFolder && dataArr.length > 0 && folders.map((folder, i) => {
            if(folder.parentFolder === selectedFolder.id) {
              return <li 
                key={i} 
                className="inner f-list-item" 
                onClick={(e) => handleFolderSelect(folder, 'inner', e)}
                onDragStart={(e) => handleDragStart(e, folder)} 
                onDragOver={handleDragOver} 
                onDragLeave={handleDragLeave}
                onDrop={(e) => handleDrop(e, folder)} 
                onDragEnd={handleDragEnd}
                draggable
              >
              <div className="root">
                <Folder />
                <p>{folder.name}</p>
              </div>
            </li>
            }
            return null
          })}
          {selectedFolder && dataArr.length > 0 && dataArr.map(resource => {
            if(resource.folderId && resource.folderId.length > 0 && resource.folderId.includes(selectedFolder.id) ) {
              return <li key={resource.id} className="inner">
              <div className="root data">
                <FileAlt />
                <p>{resource.name}</p>
              </div>
            </li>
            }
            return null
          })}
        </ul>}
      </div>
      <div className="folders-popup-v2__foot">
        <button className="btn btn--transparent" onClick={onCancel}>Annuler</button>
        <button className="btn" onClick={handleMove} disabled={loading}>{!loading ? "Déplacer ici" : "Chargement ..."}</button>
      </div>

    </div>, document.getElementById('modal-root')
  )
})

export default FoldersPopup