import React, { useState, useContext } from 'react' 
import MessageIcon from '@material-ui/icons/MessageOutlined'
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfiedOutlined'
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfiedOutlined'
import SentimentNeutralIcon from '@material-ui/icons/SentimentSatisfied'
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfiedOutlined'
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfiedOutlined'

import Modal from '../../UI/Modal'
import Textarea from '../../UI/Textarea'
import Button from '../../UI/Button'
import ResponseLoader from '../../UI/ResponseLoader'
import { send_feedback } from '../../../services/functions'
import { NotificationContext, UserContext } from '../../../context'

const GiveFeedbackModal = ({ onClose }) => {
  const { setNotification } = useContext(NotificationContext)
  const { user } = useContext(UserContext)
  const [message, setMessage] = useState('')
  const [selectedRating, setSelectedRating] = useState('')
  const [showLoader, setShowLoader] = useState(false)

  // Change rating
  const changeRatingHandler = (e, rating) => {
    e.preventDefault()
    setSelectedRating(rating)
  }

  // Send feedback
  const submitHandler = async (e) => {
    e.preventDefault()

    setShowLoader(true)
    let content = null
    if(message.trim() !== '') {
      content = message.split('\n').map((text) => `<p>${text ? text : '&nbsp;'}</p>`).join('')
    }

    // email html template
    let emailTop = `
      <div style="background-color: #2663EB; color: #ffffff; margin-bottom: 20px; padding: 10px; font-size: 16px;">Rating: <span style="font-weight: bold;">${selectedRating}</span></div>
    `
    let emailHtml = emailTop

    if(content) {
      emailHtml += `
        <div>${content}</div>
      `
    }

    try {
      const res = await send_feedback('damir@appvelvet.com', emailHtml, 'Immo Docs App - User Feedback')
      if(res.success) {
        setNotification({ msg: `Commentaires envoyés`, type: 'success' })
      }else {
        setNotification({ msg: `Une erreur est survenue. Merci de réessayer`, type: 'danger' })
      }
    } catch (err) {
      console.log(err)
      setNotification({ msg: `Une erreur est survenue. Merci de réessayer`, type: 'danger' })
    }
    setShowLoader(false)
    onClose()
  }

  return (
    <Modal onClose={onClose} medium noPadding>
      <div className="report-a-bug">
        <div className="report-a-bug__head">
          <h3><MessageIcon /> {`Envoyer des commentaires`}</h3>
        </div>
        <div className="report-a-bug__body">
          <p>{`Comment évaluez-vous votre expérience avec l'application ?`}</p>
          <form onSubmit={submitHandler}>
            <div className="emojis">
              <a href="/#" onClick={(e) => changeRatingHandler(e, 'terrible')} className={selectedRating === 'terrible' ? 'active' : ''}>
                <SentimentVeryDissatisfiedIcon />
                <span>{`Terrible`}</span>
              </a>
              <a href="/#" onClick={(e) => changeRatingHandler(e, 'bad')} className={selectedRating === 'bad' ? 'active' : ''}>
                <SentimentDissatisfiedIcon />
                <span>{`Mauvais`}</span>
              </a>
              <a href="/#" onClick={(e) => changeRatingHandler(e, 'okay')} className={selectedRating === 'okay' ? 'active' : ''}>
                <SentimentNeutralIcon />
                <span>{`D'accord`}</span>
              </a>
              <a href="/#" onClick={(e) => changeRatingHandler(e, 'great')} className={selectedRating === 'great' ? 'active' : ''}>
                <SentimentSatisfiedIcon />
                <span>{`Superbe`}</span>
              </a>
              <a href="/#" onClick={(e) => changeRatingHandler(e, 'excellent')} className={selectedRating === 'excellent' ? 'active' : ''}>
                <SentimentVerySatisfiedIcon />
                <span>{`Excellent`}</span>
              </a>
            </div>
            <Textarea value={message} onChange={(e) => setMessage(e.target.value)} formEl placeholder={`Laissez-nous un message (facultatif)`} />
            <div className="btn-wrapper">
              <Button type="submit" text={`Envoyer des commentaires`} primary medium disabled={selectedRating === ''} />
            </div>
          </form>
        </div>
      </div>

      {showLoader && <ResponseLoader text={`Envoi de commentaires`} />}
    </Modal>
  )
}

export default GiveFeedbackModal