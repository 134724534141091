import { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import { Check, Folder } from '../../assets/icons'
import { STATUS_LABELS_SHORT } from '../../constants'
import { DocumentsContext } from '../../context'
import { FEATURE, dateDisplayFormat, isFeatureEnabled } from '../../utils'

const DocumentsTableFolderRow = ({ id, folder, onSelectFolder }) => {

  const [createdAt, setCreatedAt] = useState('')
  const [modifiedAt, setModifiedAt] = useState('')

  // Set metadata
  useEffect(() => {
    if(folder?.meta?.created) {
      setCreatedAt(moment(folder.meta.created).format(dateDisplayFormat))
    }else if(folder.createdAt) {
      setCreatedAt(moment(folder.createdAt).format(dateDisplayFormat))
    }
    if(folder?.meta?.updated) {
      setModifiedAt(moment(folder.meta.updated).format(dateDisplayFormat))
    }else if(folder.updatedAt) {
      setModifiedAt(moment(folder.updatedAt).format(dateDisplayFormat))
    }
  }, [folder])

  const handleClick = () => {
    onSelectFolder(folder.id)
  }

  return (
    <div className={`documents-table-row`} onClick={handleClick}>
      <div className="documents-table-column">
        <div className="folder-row-icon"><Folder /></div>
      </div>
      <div className="documents-table-column documents-table-column__name">
        <p>{folder.name}</p>
      </div>
      <div className="documents-table-column documents-table-column__date">
        <p>{createdAt}</p>
      </div>
      <div className="documents-table-column documents-table-column__date">
        <p>{modifiedAt}</p>
      </div>
      <div className="documents-table-column-mobile">
      </div>
    </div>
  )
}

export default DocumentsTableFolderRow