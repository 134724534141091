import React from 'react'

const MailModalContentConfirmation = ({ onClose }) => {

  return (
    <div className='registered-mail-send-modal-container'>
      <div className='registered-mail-send-modal-container__header'>
        <ul className='modal-list'>
          <li className='modal-list-item'>          
            <p className='text-success'>
              La LRE a bien été envoyée à chaque destinataire
            </p>
          </li>
          <li className='modal-list-item'>
            <p>Vous retrouverez le statut de chaque LRE envoyée :</p>
            <p>{" - en page d'accueil"}</p>
            <p>{" - dans la rubrique : Lettres recommandées > Suivi des LRE"}</p>
          </li>
        </ul>
      </div>
      <div className='modal-btn-box'>
        <button
          className='btn btn--large btn--transparent btn--border-primary'
          onClick={onClose}
        >
          Fermer
        </button>
      </div>
    </div>
  )
}

export default MailModalContentConfirmation