import {useState, useEffect, useContext} from 'react'
import moment from 'moment'

import { Redo, LongArrowAltRight } from '../../assets/icons'
import { CustomTooltip } from './'
import { FEATURE, getFirstLetter, isFeatureEnabled } from '../../utils'
import { DocumentsContext, RegisteredMailContext, UserContext} from "../../context"
import useVialinkActions from '../../hooks/useVialinkActions'
import { Download } from '../../assets/icons'
import { SIGNATURE_STATUS_TRANLSATIONS } from '../../constants'

const SignaturesTableRow = ({ id, signature, selectedSignatures, onSetSelectedSignatures, allChecked, onOpenStatusPanel, onTitleClick = () => {}, onOpenSignatureDetail, onDownload }) => {
  const { refreshStatus } = useVialinkActions()
  const { documents } = useContext(DocumentsContext)
  const { createAr24MailRequestFromSignature } = useContext(RegisteredMailContext)
  const { siteConfigs } = useContext(UserContext)
  // const { fetchSignaturePreview } = useSignaturesActions()
  const [checked, setChecked] = useState(false)
  const [updatedAt, setUpdatedAt] = useState('')
  const [refreshing, setRefreshing] = useState(false)

  // Set checked to false if selectedSignatures length is zero
  useEffect(() => {
    if(checked && selectedSignatures.length === 0) {
      setChecked(false)
    }
  }, [selectedSignatures, checked])

  // Set checked to true if allChecked is true
  useEffect(() => {
    if(allChecked) {
      setChecked(true)
    }
  }, [allChecked])

  // Set updatedAt
  useEffect(() => {
    if(signature?.meta?.updated) {
      setUpdatedAt(moment(signature.meta.updated).format('D MMM YYYY HH:mm'))
    }else if(signature?.meta?.created) {
      setUpdatedAt(moment(signature.meta.created).format('D MMM YYYY HH:mm'))
    }
  }, [signature])

  // On signature select
  const handleSelectSignature = () => {
    setChecked(!checked)
    if(checked) {
      onSetSelectedSignatures(prev => prev.filter(s => s.id !== id))
    }else {
      onSetSelectedSignatures(prev => [...prev, signature])
    }
  }

  // Get recipients as array of object with short value(first letter) and full value
  const getRecipients = (mobile = false) => {
    const recipients = [...signature.sort_recipients] 
    let showNumberCircle = false
    let number = 0
    if(!mobile && recipients.length > 4) {
      showNumberCircle = true 
      number = recipients.length - 3
    }else if(mobile && recipients.length > 2) {
      showNumberCircle = true 
      number = recipients.length - 1
    }
    let arr = []
    let iterationNum = !mobile ? 3 : 1;
    if(showNumberCircle) {
      for(let i = 0; i < iterationNum; i++) {
        arr.push({ short_value: getFirstLetter(recipients[i]), value: recipients[i] })
      }
      arr.push({ short_value: `+${number}`, value: recipients.slice(iterationNum, recipients.length).join(','), num: true })
    }else {
      for(let i = 0; i < recipients.length; i++) {
        arr.push({ short_value: getFirstLetter(recipients[i]), value: recipients[i] })
      }
    }
    return arr
  }

  // On status click
  const handleStatusClick = () => {
    if(signature.status === 'IN_PROGRESS') {
      onOpenStatusPanel(signature)
    }
  }

  // On status refresh click
  const handleStatusRefreshClick = async () => {
    refreshStatus({ signature, setRefreshing })
  }

  // On title click
  const handleTitleClick = () => {
    onTitleClick(signature)
  }

  const handleSendRegisteredMail = () => {
    createAr24MailRequestFromSignature(signature)
  }

  const translatedStatus = SIGNATURE_STATUS_TRANLSATIONS[signature.status] || signature.status;

  const download = (signature) => {
   onDownload(signature)
  }

  return (
    <div className={`signatures-table-row ${checked ? 'selected' : ''}`}>
      {/* <div className="signatures-table-column zero-width"> */}
        {/* <div className={`signatures-table-column__check ${checked ? 'checked' : ''}`} onClick={handleSelectSignature}>
          <Check />
        </div> */}
      {/* </div> */}
      <div className="signatures-table-column align-left stretch">
        <CustomTooltip content={signature.title}>
          <p className='signature-title' onClick={handleTitleClick}>{`${signature.title}`}</p>
        </CustomTooltip>
      </div>
      <div className="signatures-table-column small-text-column date-column">
        <p>{moment(signature.meta?.created).format('D MMM YYYY HH:mm')}</p>
        <div className="recipients-mobile" onClick={handleStatusClick}>
          <div className="letter-circle-v2">{getFirstLetter(signature.sender.email)}</div>
          <div className="arrow">
            <LongArrowAltRight />
          </div>
          {getRecipients(true).map((r, idx) => {
            return (
              <div key={idx} className={`letter-circle-v2 ${r.num ? 'active' : ''}`}>{r.short_value}</div>
            )
          })}
        </div>
      </div>
      <div className="signatures-table-column recipients-column">
        <p>
          {getRecipients().map((r, idx) => {
            return (
              <CustomTooltip key={idx} content={r.value}>
                <span className={`letter-circle-v2 ${r.num ? 'active' : ''}`}>{r.short_value}</span>
              </CustomTooltip>
            )
          })}
        </p>
      </div>
      <div className="signatures-table-column">
        <p>
          <CustomTooltip content={signature.sender.email}>
            <span className="letter-circle-v2">{getFirstLetter(signature.sender.email)}</span>
          </CustomTooltip>
        </p>
      </div>
      <div className="signatures-table-column small-text-column status-column align-left">

        { signature.status === "COMPLETED" ? 
          <button className='icon-btn icon-btn--transparent icon-btn--svg-lg' onClick={() => download(signature)}><Download/></button> 
          : signature.status === "IN_PROGRESS" ?
          <CustomTooltip content={`Dernier statut vérifié le ${moment(signature.status_updated_at).format('DD MMM YYYY à HH[h]mm')}`} >
            <button className={`icon-btn icon-btn--transparent refresh-status ${refreshing ? 'refreshing' : ''}`} onClick={handleStatusRefreshClick}><Redo /></button>
          </CustomTooltip>
          : <div className='icon-btn icon-btn--transparent icon-btn--placeholder' />
        }
        <p className={`${signature.status}`} onClick={handleStatusClick}>
          <span className="st"></span><span className="text">{translatedStatus}</span>
        </p>
      </div>
      <div className="signatures-table-column small-text-column date-column align-left">
        <p>{moment(signature.expires, "YYYY-MM-DD").format("D MMM YYYY")}</p>
        {/* <div className="recipients-mobile" onClick={handleStatusClick}>
          <div className="letter-circle-v2">{getFirstLetter(signature.sender.email)}</div>
          <div className="arrow">
            <LongArrowAltRight />
          </div>
          {getRecipients(true).map((r, idx) => {
            return (
              <div key={idx} className={`letter-circle-v2 ${r.num ? 'active' : ''}`}>{r.short_value}</div>
            )
          })}
        </div> */}
      </div>
      <div className="signatures-table-column small-text-column date-column flex-row actions gap-5">
        <button className='btn' disabled={signature.status !== "IN_PROGRESS"} onClick={() => onOpenSignatureDetail(signature)}>Modifier</button>
        { isFeatureEnabled(FEATURE.AR24, siteConfigs) && <button className='btn' disabled={signature.status !== "COMPLETED"} onClick={handleSendRegisteredMail}>Envoyer par LRE</button> }
      </div>
    </div>
  )
}

export default SignaturesTableRow