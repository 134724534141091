import React, { createContext, useContext, useReducer, useState } from 'react';

import signatureReducer from './signaturesReducer';
import { GET_SIGNATURES, ADD_SIGNATURE, DELETE_SIGNATURE, SET_SIGNATURES } from '../types';
import { fetch_signatures, create_signature, update_signature } from '../../services/firestore';
import { LoaderContext } from '../loader/loaderState';
import { NotificationContext } from '../notifications/notificationState';

export const SignatureContext = createContext();

const SignatureState = ({ children }) => {
  const initialState = {
    signatures: [],
    signaturesFetched: false
  }
  const [signaturesWithStatus, setSignaturesWithStatus] = useState([]);
  const [signaturesSelectedFilters, setSignaturesSelectedFilters] = useState({
    search: '',
    status: 'all',
    date_after: '',
    date_before: ''
  })
  const [state, dispatch] = useReducer(signatureReducer, initialState);
  const [selectedSignature, setSelectedSignature] = useState(undefined)

  const { setShowGlobalResponseLoader } = useContext(LoaderContext)
  const { setNotification } = useContext(NotificationContext)

  // Fetch signatures
  const fetchSignatures = async (onSuccess = () => {}) => {
    try {
      const res = await fetch_signatures();
      const arr = [];
      for(let id in res) {
        const signature = {...res[id]};
        signature.id = id;
        arr.push(signature);
      }
      const sortedArr = [...arr].sort((a, b) => {
        if (a.createdAt < b.createdAt) {
          return 1;
        }
        if (a.createdAt > b.createdAt) {
          return -1;
        }
        return 0;
      });
      dispatch({
        type: GET_SIGNATURES,
        payload: sortedArr
      });
      onSuccess();
    }catch(err) {
      console.log(err);
    }
  }

  const fetchMoreSignatures = async () => {
    try {
      setShowGlobalResponseLoader(true)
      const oldestSignaturePerAgency = {}
      for(let signature of state.signatures) {
        if(!oldestSignaturePerAgency[signature.owner]) {
          oldestSignaturePerAgency[signature.owner] = signature.meta.updated
        } else {
          if(signature.meta.updated < oldestSignaturePerAgency[signature.owner]) {
            oldestSignaturePerAgency[signature.owner] = signature.meta.updated
          }
        }
      }
      const oldestSignaturePerAgencyEncoded = Object.keys(oldestSignaturePerAgency).map(key => `${key}:${oldestSignaturePerAgency[key]}`).join(',')
      const res = await fetch_signatures(oldestSignaturePerAgencyEncoded)
      const arr = [];
      for(let id in res) {
        const signature = {...res[id]};
        signature.id = id;
        arr.push(signature);
      }
      const sortedArr = [...state.signatures, ...arr].sort((a, b) => {
        if (a.createdAt < b.createdAt) {
          return 1;
        }
        if (a.createdAt > b.createdAt) {
          return -1;
        }
        return 0;
      });
      const uniqueSignatures = []
      const uniqueSignaturesIds = []
      sortedArr.forEach(signature => {
        if(!uniqueSignaturesIds.includes(signature.id)) {
          uniqueSignatures.push(signature)
          uniqueSignaturesIds.push(signature.id)
        }
      })
      dispatch({
        type: GET_SIGNATURES,
        payload: uniqueSignatures
      });

      setShowGlobalResponseLoader(false)
      
    }catch(err) {
      console.log(err);
      setShowGlobalResponseLoader(false)
      setNotification({ type: 'danger', msg: 'Erreur lors du chargement des signatures' })
    }
  }

  // Add signature
  const addSignature = async (data) => {
    try {
      const res = await create_signature(data);

      if(state.signaturesFetched) {
        const signature = {...data, id: res.id};
        const signaturesCopy = [...state.signatures];
        signaturesCopy.unshift(signature);
        dispatch({
          type: ADD_SIGNATURE,
          payload: signaturesCopy
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  // Update signature
  const updateSignature = async (id, data) => {
    try {
      await update_signature(id, data);
      const signatures = [...state.signatures];
      const findS = signatures.find(s => s.id === id);
      const updatedS = {...findS, ...data};
      const updatedSignatures = signatures.map(s => s.id === id ? updatedS : s);

      dispatch({
        type: SET_SIGNATURES,
        payload: updatedSignatures
      });
    } catch (err) {
      console.log(err);
    }
  }

  const updateSignatureLocal = (id, data) => {
    const signatures = [...state.signatures];
      const findS = signatures.find(s => s.id === id);
      const updatedS = {...findS, ...data};
      const updatedSignatures = signatures.map(s => s.id === id ? updatedS : s);

      dispatch({
        type: SET_SIGNATURES,
        payload: updatedSignatures
      }); 
  }

  // Delete signature
  const deleteSignature = async (ids) => {
    try {
      const signatures = [...state.signatures];
      const filtered = signatures.filter(s => !ids.includes(s.id));
      dispatch({
        type: DELETE_SIGNATURE,
        payload: filtered
      });
    } catch (err) {
      console.log(err);
    }
  } 

  return <SignatureContext.Provider value={{
    signatures: state.signatures,
    signaturesFetched: state.signaturesFetched,
    signaturesWithStatus,
    fetchSignatures,
    fetchMoreSignatures,
    addSignature,
    deleteSignature,
    setSignaturesWithStatus,
    updateSignature,
    updateSignatureLocal,
    signaturesSelectedFilters, 
    setSignaturesSelectedFilters,selectedSignature, setSelectedSignature
  }}>
    {children}
  </SignatureContext.Provider>
}

export default SignatureState;

export const useSignatureContext = ()=> useContext(SignatureContext)