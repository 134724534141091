const MailModalMenu = ({currentItem}) => {
    return (
        <ul className='registered-mail-send-modal-menu'>
            <li className={currentItem === 'sender' ? 'current' : ''}>Expéditeur</li>
            <li className={currentItem === 'recipients' ? 'current' : ''}>Destinataire(s)</li>
            <li className={currentItem === 'documents' ? 'current' : ''}>Document(s)</li>
            <li className={currentItem === 'message' ? 'current' : ''}>Message</li>
            <li className={currentItem === 'summary' ? 'current' : ''}>Récapitulatif</li>
        </ul>
    )
}

export default MailModalMenu